import { TextField } from "@mui/material"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { DimensionValue } from "domain/ConversionList/domain/domain"
import {
    TOUCHPOINT_FILTER_DIMENSIONS,
    TouchpointFilterDimension,
} from "domain/ConversionList/domain/touchpointFilterDimension"
import React from "react"
import { VirtualizedAutocomplete } from "shared/component/mui/VirtualizedAutocomplete"

export interface TouchpointDimensionFilterValueAutocompleteProps {
    selectedTouchpointFilterDimension: TouchpointFilterDimension
    selectedTouchpointFilterDimensionValues: DimensionValue[]
    updateSelectedTouchpointFilterDimensionValues: (dimensionValues: DimensionValue[]) => void
}

export const TouchpointDimensionFilterValueAutocomplete = ({
    selectedTouchpointFilterDimension,
    selectedTouchpointFilterDimensionValues,
    updateSelectedTouchpointFilterDimensionValues,
}: TouchpointDimensionFilterValueAutocompleteProps) => {
    const values = ConversionListContextSelectors.useDimensionValuesQuery({
        touchpointFilterDimension: selectedTouchpointFilterDimension,
    })

    // TODO: Handle values.isError state

    return (
        <VirtualizedAutocomplete
            multiple={true}
            loading={values.isPending}
            options={values.data || []}
            getOptionLabel={(option) => option.name}
            getOptionKey={(option) => option.value}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            disableCloseOnSelect={true}
            className="multi-select-touchpoint-filter"
            renderOption={(props, option) => <li {...props}>{option.name}</li>}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={
                        selectedTouchpointFilterDimensionValues.length === 0
                            ? `Select ${TOUCHPOINT_FILTER_DIMENSIONS[selectedTouchpointFilterDimension].displayName.toLowerCase()}...`
                            : ""
                    }
                    sx={{ width: "100%" }}
                    InputLabelProps={{ shrink: false }}
                />
            )}
            sx={{
                minWidth: {
                    xs: "240px", // On smallest screens
                    sm: "260px", // On small screens
                },
                width: "100%",
                maxWidth: {
                    xs: "100%", // Full width on small screens
                    sm: "100%", // Full width on small screens
                    md: "500px", // Limited width only on medium+ screens (when not wrapped)
                },
            }}
            value={selectedTouchpointFilterDimensionValues}
            onChange={(_, value) => updateSelectedTouchpointFilterDimensionValues(value)}
        />
    )
}
