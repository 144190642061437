import { produce } from "immer"
import React, { ReactNode, useCallback, useContext, useMemo, useState } from "react"

/**
 * We use this context to let child elements interact with the settings of the main root that displays them. This root element could e.g.
 * be a page or a modal. An example use case could be that we want child elements to be able to update the footer of the parent depending
 * whether a certain tab has been selected.
 */

export enum FooterState {
    SHOW_DEFAULT_FOOTER = "SHOW_DEFAULT_FOOTER",
    HIDE_FOOTER = "HIDE_FOOTER",
}

export type ElementSetting = {
    // this could e.g. be a dimensionIdentifier that is used as a setting (is_expert_mode=1) but could also be a key that is not available as a dimension (show_as_bars=1, group_by_foo_bar=0)
    key: string
    value: any
}

export type RootElementContextProperties = {
    // footer currently only used in modals; either a react node with all elements that should be displayed in the footer or one of the FooterState values
    footer?: ReactNode | FooterState
    updateFooter?: (reactNodeOrFooterState: ReactNode | FooterState) => void

    // the footer that should be displayed per default if children don't want to update anything
    defaultFooter?: ReactNode
    updateDefaultFooter?: (reactNode: ReactNode) => void

    elementSettings?: ElementSetting[]
    updateElementSettings?: (setting: ElementSetting) => void
}
export const RootElementContext = React.createContext<RootElementContextProperties>({ elementSettings: [] })

export type RootElementContextProviderProps = {
    children?: ReactNode
}

export const RootElementContextProvider = ({ children }: RootElementContextProviderProps) => {
    // per default, if no changes are made: show the default footer
    const [footer, setFooter] = useState<ReactNode | FooterState>(FooterState.SHOW_DEFAULT_FOOTER)
    const [defaultFooter, setDefaultFooter] = useState<ReactNode>()
    const [elementSettings, setElementSettings] = useState<ElementSetting[]>([])

    const updateElementSettings = useCallback((newSetting: ElementSetting) => {
        setElementSettings(
            produce((draft) => {
                const setting = draft.find((setting) => setting.key === newSetting.key)
                if (setting) {
                    setting.value = newSetting.value
                } else {
                    draft.push(newSetting)
                }
            }),
        )
    }, [])

    const rootElementContextProperties: RootElementContextProperties = useMemo(() => {
        return {
            footer: footer,
            updateFooter: setFooter,

            defaultFooter: defaultFooter,
            updateDefaultFooter: setDefaultFooter,

            elementSettings: elementSettings,
            updateElementSettings: updateElementSettings,
        }
    }, [footer, defaultFooter, elementSettings, updateElementSettings])

    return <RootElementContext.Provider value={rootElementContextProperties}>{children}</RootElementContext.Provider>
}

export const useRootElementContext = (): RootElementContextProperties => {
    const context = useContext(RootElementContext)
    if (!context) {
        throw new Error("Missing RootElementContext in its parent.")
    }
    return context
}
