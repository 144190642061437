import { PropertyFilterChipProps } from "./PropertyFilterChip"
import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material"
import React from "react"

// Custom tooltip with wider max-width and better formatting, positioned on the left side with arrow
export const StyledTooltip = styled(({ className, children, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }}>
        {children}
    </Tooltip>
))(({ theme: _ }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: "450px", // Increased from 350px to 450px
        whiteSpace: "pre-line", // Enable line breaks
        fontSize: "13px",
        lineHeight: 1.5,
        padding: "10px 12px",
        overflowWrap: "break-word",
        wordBreak: "break-all", // Add break-all for better handling of long values
    },
}))

// Bold text styling for tooltip
const TooltipContent = styled("div")({
    "& .bold": {
        fontWeight: "bold",
    },
    "& .tooltip-header": {
        marginBottom: "8px",
        lineHeight: 1.4,
    },
    "& .value-tag": {
        display: "inline-block",
        backgroundColor: "rgba(0, 0, 0, 0.04)",
        borderRadius: "4px",
        padding: "2px 6px",
        margin: "2px",
        fontSize: "12px",
        maxWidth: "100%",
        wordBreak: "break-all",
        overflowWrap: "break-word",
    },
    "& .value-tags-container": {
        display: "flex",
        flexWrap: "wrap",
        gap: "4px",
        width: "100%",
    },
})

// Helper to pluralize dimension names for multiple values
const pluralizeDimension = (dimension: string, count: number): string => {
    if (!dimension || count <= 1) return dimension || ""

    // Simple English pluralization rules
    if (dimension.endsWith("y")) {
        return dimension.slice(0, -1) + "ies"
    } else {
        return dimension + "s"
    }
}

export const PropertyFilterChipTooltip: React.FC<PropertyFilterChipProps> = (props) => {
    // Format tooltip content with values appropriately styled
    if ("label" in props && props.label) {
        const match = props.label.match(/^(.*?) is (.*?) (.*)/)
        if (match && match.length >= 4) {
            const [, role, dimension, valuesStr] = match

            // Try to extract values by splitting on commas
            // This is a best effort - it won't be perfect for all cases
            if (valuesStr) {
                const valuesList = valuesStr.replace(/ \+\d+ more$/, "").split(", ")

                // Use different text based on number of values
                if (valuesList.length > 1) {
                    // Ensure we're passing a string to pluralizeDimension
                    const pluralDimension = pluralizeDimension(dimension || "", valuesList.length)
                    return (
                        <TooltipContent>
                            <div className="tooltip-header">
                                <span className="bold">{role}</span> is one of the following{" "}
                                <span className="bold">{pluralDimension}</span>:
                            </div>
                            <div className="value-tags-container">
                                {valuesList.map((val: string, index: number) => (
                                    <span key={index} className="value-tag">
                                        {val}
                                    </span>
                                ))}
                            </div>
                        </TooltipContent>
                    )
                } else {
                    return (
                        <TooltipContent>
                            <div className="tooltip-header">
                                <span className="bold">{role}</span> is <span className="bold">{dimension}</span>
                            </div>
                            <span className="value-tag">{valuesList[0] || ""}</span>
                        </TooltipContent>
                    )
                }
            }
            return (
                <TooltipContent>
                    <div className="tooltip-header">
                        <span className="bold">{role}</span> is <span className="bold">{dimension}</span>
                    </div>
                </TooltipContent>
            )
        }
        // If no pattern match, just use the label as-is
        return <TooltipContent>{props.label}</TooltipContent>
    }

    // If using structured props
    if ("role" in props && "dimension" in props && "values" in props) {
        if (!props.values || props.values.length === 0) {
            // Handle empty values array
            return (
                <TooltipContent>
                    <div className="tooltip-header">
                        <span className="bold">{props.role}</span> is <span className="bold">{props.dimension}</span>
                    </div>
                    <span>No values selected</span>
                </TooltipContent>
            )
        } else if (props.values.length > 1) {
            // Ensure we're passing a string to pluralizeDimension
            const pluralDimension = pluralizeDimension(props.dimension || "", props.values.length)
            return (
                <TooltipContent>
                    <div className="tooltip-header">
                        <span className="bold">{props.role}</span> is one of the following{" "}
                        <span className="bold">{pluralDimension}</span>:
                    </div>
                    <div className="value-tags-container">
                        {props.values.map((val: string, index: number) => (
                            <span key={index} className="value-tag">
                                {val}
                            </span>
                        ))}
                    </div>
                </TooltipContent>
            )
        } else if (props.values.length === 1) {
            return (
                <TooltipContent>
                    <div className="tooltip-header">
                        <span className="bold">{props.role}</span> is <span className="bold">{props.dimension}</span>
                    </div>
                    <span className="value-tag">{props.values[0] || ""}</span>
                </TooltipContent>
            )
        }
    }

    // Fallback for any other unexpected case - show minimal tooltip
    return (
        <TooltipContent>
            <div className="tooltip-header">Filter</div>
        </TooltipContent>
    )
}
