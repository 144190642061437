import { Stack } from "@mui/material"
import { Box } from "@mui/system"
import { ConfigPanel } from "domain/ConversionList/components/ConfigPanel/ConfigPanel"
import { ConversionListColumnConfiguratorAdapter } from "domain/ConversionList/components/ConversionListColumnConfiguratorAdapter"
import { GridPanel } from "domain/ConversionList/components/GridPanel/GridPanel"
import { ADDITIONAL_HEIGHT_OFFSET } from "domain/ConversionList/domain/constants"
import { TOP_BAR_HEIGHT } from "layout/MainLayout/constants"
import React from "react"

export const ConversionList = () => {
    return (
        <Box
            className={"conversion-list"}
            sx={{
                height: `calc(100vh - ${TOP_BAR_HEIGHT} - ${ADDITIONAL_HEIGHT_OFFSET})`,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
            }}
        >
            <ConversionListColumnConfiguratorAdapter>
                <Stack
                    direction="row"
                    spacing={0}
                    sx={{
                        height: "100%",
                        flex: 1,
                        minHeight: 0,
                    }}
                >
                    <GridPanel />
                    <ConfigPanel />
                </Stack>
            </ConversionListColumnConfiguratorAdapter>
        </Box>
    )
}
