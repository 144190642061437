import EastRoundedIcon from "@mui/icons-material/EastRounded"
import Button from "@mui/material/Button"
import Paper from "@mui/material/Paper"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import React from "react"
import ContentDialogTrigger from "shared/component/content/ContentDialogTrigger"
import UrlService from "shared/service/url.service"
import { FONT_WEIGHT_BOLD } from "styles/theme/constants"

const ReportingMethodInfoDialogTrigger: React.FC = () => {
    const infoContent = (
        <>
            <h3>Time of Touchpoint (ToTP)</h3>
            Conversion shares are distributed based on when each Touchpoint appeared during a Customer Journey.
            <ul>
                <li>
                    Within the attribution lookback window only Touchpoints from successful Customer Journeys are
                    considered.
                </li>
                <li>
                    Only as Journeys reach their end, the analysis of a given time period becomes more complete. Note:
                    Costs are always distributed to Time of Touchpoint.
                </li>
            </ul>
            <h3>Time of Conversion (ToCnv)</h3>
            <div>
                <ul>
                    <li>
                        This perspective provides a backward view, highlighting the influence of each Touchpoint leading
                        up to the Conversion.
                    </li>
                    <li>
                        Keep in mind, current Touchpoints may have an impact that is not immediately visible until the
                        Conversion happens.
                    </li>
                </ul>
            </div>
            <br />
            <h3>When should I use Time of Touchpoint (ToTP)?</h3>
            <p>
                A frequent question we hear from customers when they first use our system is, "Why do you have two
                different metrics for e.g. Total Price and Revenue in your Attribution Model?".
            </p>
            <p>
                {" "}
                To start with, we have two metrics because depending on your question you will need to look at the data
                from either the reference point of the touchpoint and look forward, or from the reference point of the
                conversion and look backward. We call these two different views Time-of-Touchpoint (ToTP) and
                Time-of-Conversion (ToCnv).
            </p>
            <p>
                The easiest way to think about the difference in the two views, is through a simple example. Suppose we
                have a linear attribution model and our journey has 5 touchpoints, this means each touchpoint is awarded
                20% of the conversion.
            </p>
            <p style={{ fontWeight: FONT_WEIGHT_BOLD }}>Sample Journey</p>
            <img
                src={`${UrlService.getUiServiceApiUrl()}/static/content/info/reporting_method/journey.png`}
                width={"400"}
            />
            <br />
            <p>
                Under the Time-of-conversion view, the entire conversion is aggregated on the day of conversion. Under
                the time-of-touchpoint view, the single conversion is distributed across each day there was a touchpoint
                awarded some share. This results in a table like below:
            </p>
            <p>
                <TableContainer
                    component={Paper}
                    sx={{
                        "& th, th *": {
                            fontSize: "12px!important",
                            color: "#000!important",
                            fontWeight: FONT_WEIGHT_BOLD + "!important",
                        },
                    }}
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>Day</TableCell>
                                <TableCell>Time of Touchpoint</TableCell>
                                <TableCell>Time of Conversion</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>Two days before</TableCell>
                                <TableCell>0,6</TableCell>
                                <TableCell>0,0</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>One day before</TableCell>
                                <TableCell>0,2</TableCell>
                                <TableCell>0,0</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Conversion day</TableCell>
                                <TableCell>0,2</TableCell>
                                <TableCell>1,0</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <br />
            </p>
            <p>
                Time-of-conversion is the traditional view. This view is easy to compare to a Last Cookie Wins (LCW)
                Commission model because, in most cases, the winning touchpoint in LCW happens on the day of conversion.
                This creates very comparable metrics and you can look at a percent change in how the models view the
                world. Another positive aspect is that it is static.
            </p>
            <p>
                The time-of-touchpoint view is a better view when considering cost comparisons and efficiencies. Costs,
                because not all costs result in a conversion, are distributed on the time of the touchpoint. Essentially
                meaning that when you have a click under the CPC model, you incur the cost in that hour. Having the same
                time aggregation logic creates for more accurate numbers in CPO or ROI. Meaning, we are looking at the
                actual costs you incurred on that day/hour vs. the actual attribution you were awarded on that day/hour.
            </p>
            <p>
                To make this last point clearer, let’s again look at some real data using a campaign that had a defined
                stopping point. I also chose a customer with a traditionally long buying cycle. The bar chart represents
                the time of touchpoint, while the line chart represents the time of conversion. You can see a clear end
                of the campaign when looking at the bar chart (red circle), however, the line chart continues. Why?
                Because the touchpoints near the end of the campaign were at the start of the journey for those buyers
                that completed their transactions after the campaign ended.
            </p>
            <p>
                <br />
                <img
                    src={`${UrlService.getUiServiceApiUrl()}/static/content/info/reporting_method/totp_vs_tocvn.png`}
                    width={"500"}
                />
            </p>
            <br />
            <p>To clarify, look at the following chart: </p>
            <p>
                <br />
                <img
                    src={`${UrlService.getUiServiceApiUrl()}/static/content/info/reporting_method/costs_tocnv.png`}
                    width={"500"}
                />
                <br />
            </p>
            <p>
                Here you can see the costs stop when the campaign stops. What is the ROAS (Basket Value / Costs) on the
                day I circled in blue? Nearly 30.000€ / 0€, which is quite obviously non-sense. But looking at the ROAS
                for the two Red Circles, yields a perfectly computable, and very healthy 18x return. And this is why our
                efficiency metrics are based on Time of Touchpoint Attribution.
            </p>
        </>
    )
    return (
        <ContentDialogTrigger infoContent={infoContent} dialogHeader={"Time of Touchpoint vs Time of Conversion"}>
            <Button
                startIcon={<EastRoundedIcon />}
                sx={{
                    "& .MuiSvgIcon-root": { width: "18px", margin: "-2px -3px 0 0" },
                }}
            >
                Find out more about Reporting Methods
            </Button>
        </ContentDialogTrigger>
    )
}

export default ReportingMethodInfoDialogTrigger
