import { TextField } from "./primitives/TextField"
import { FormElementProperties } from "domain/types"
import React, { type JSX } from "react"
import { useRootElementContext } from "shared/component/layout/context/RootElementContext"

export const FormElementTextarea = ({
    layoutElementConfig,
    editable,
    isRequiredField,
}: FormElementProperties): JSX.Element => {
    const { updateElementSettings } = useRootElementContext()

    const { useAsSetting, formFieldConfig } = layoutElementConfig

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (useAsSetting) {
            updateElementSettings({ key: formFieldConfig.dimensionIdentifier, value: event.target.value })
        }
    }

    return (
        <TextField
            textFieldType={{ type: "text", multiline: true }}
            name={formFieldConfig.dimensionIdentifier}
            label={formFieldConfig.displayName}
            onChange={onChange}
            disabled={!editable}
            required={isRequiredField}
        />
    )
}
