import FilterComponentUtil from "domain/filter/component/FilterComponentUtil"
import { FormSelectElementProperties } from "domain/types"
import React, { type JSX } from "react"
import { useFormContext } from "shared/component/forms/FormContext"
import { Autocomplete } from "shared/component/forms/elements/primitives/Autocomplete"
import { useRootElementContext } from "shared/component/layout/context/RootElementContext"

export const FormElementSelect = ({
    onChangeListener,
    layoutElementConfig,
    showNotSetOption,
    showNoChangeOption,
    editable,
    isRequiredField,
}: FormSelectElementProperties): JSX.Element => {
    const { updateElementSettings } = useRootElementContext()
    const { loadingFieldStates } = useFormContext()

    const { useAsSetting, formFieldConfig } = layoutElementConfig

    const selectEntries = layoutElementConfig.selectConfig?.selectEntries?.entries || []
    const isLoading = loadingFieldStates[layoutElementConfig.formFieldConfig.dimensionIdentifier]

    let options: { optionIdentifier: string | number; optionLabel: string }[] = undefined
    if (!isLoading) {
        options = selectEntries.map((entry) => ({
            optionIdentifier: entry.value as string | number,
            optionLabel: FilterComponentUtil.getFilterValue(entry),
        }))
        if (showNotSetOption) {
            options = [{ optionIdentifier: null, optionLabel: "Not set" }, ...options]
        }
        if (showNoChangeOption) {
            options = [{ optionIdentifier: "__NO_CHANGE__", optionLabel: "No change" }, ...options]
        }
    }

    const onChange = (value: string | number, textValue: string) => {
        if (useAsSetting) {
            updateElementSettings({ key: formFieldConfig.dimensionIdentifier, value: value })
        }

        if (onChangeListener) {
            onChangeListener({ value: value, textValue: textValue })
        }
    }

    return (
        <Autocomplete
            name={layoutElementConfig.formFieldConfig.dimensionIdentifier}
            label={layoutElementConfig.formFieldConfig.displayName}
            isLoading={isLoading}
            disabled={!editable}
            options={options}
            onChange={onChange}
            required={isRequiredField}
        />
    )
}
