import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined"
import SearchIcon from "@mui/icons-material/Search"
import { IconButton, TextField } from "@mui/material"
import { ToolsContext } from "domain/legacy/widget/ToolsContext"
import React, { type JSX, useContext, useEffect, useState } from "react"

export const GenericDataGridSearchForm = React.memo((): JSX.Element => {
    const toolsContext = useContext(ToolsContext)
    const formRef = React.createRef<any>()
    const [searchTerm, setSearchTerm] = useState("")

    const handleSubmit = (e: any) => {
        e.preventDefault()

        toolsContext?.updateSearchTerm(searchTerm)
    }

    useEffect(() => {
        setSearchTerm(toolsContext?.searchTerm)
    }, [toolsContext?.searchTerm])

    useEffect(() => {
        toolsContext?.updateSearchTerm(searchTerm)
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm])

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        setSearchTerm(event.target.value)
    }

    const onClear = () => {
        setSearchTerm("")
        formRef.current.focus()
    }

    return (
        <TextField
            className={"panel-searchform search"}
            inputRef={formRef}
            onChange={onChange}
            placeholder={"Search"}
            variant={"outlined"}
            size={"small"}
            value={searchTerm}
            InputProps={{
                startAdornment: (
                    <IconButton onClick={handleSubmit}>
                        <SearchIcon fontSize={"small"} />
                    </IconButton>
                ),
                endAdornment: (
                    <IconButton sx={{ visibility: searchTerm ? "visible" : "hidden" }} onClick={onClear}>
                        <HighlightOffOutlinedIcon fontSize={"small"} />
                    </IconButton>
                ),
                sx: { paddingLeft: 0, paddingRight: 0 },
            }}
        />
    )
})

export default GenericDataGridSearchForm
