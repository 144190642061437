import { PropertyFilterDialogBase } from "domain/ConversionList/components/ConfigPanel/TouchpointProperties/PropertyFilterDialog/PropertyFilterDialogBase"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { DimensionValue, TouchpointPropertyFilter } from "domain/ConversionList/domain/domain"
import { TouchpointCustomerJourneyRole } from "domain/ConversionList/domain/touchpointCustomerJourneyRole"
import { TouchpointFilterDimension } from "domain/ConversionList/domain/touchpointFilterDimension"
import { produce } from "immer"
import React from "react"

export interface EditPropertyFilterDialogProps {
    touchpointPropertyFilter: TouchpointPropertyFilter
    touchpointPropertyFilterIndex: number
}

export const EditPropertyFilterDialog = ({
    touchpointPropertyFilter,
    touchpointPropertyFilterIndex,
}: EditPropertyFilterDialogProps) => {
    const touchpointPropertyFilters = ConversionListContextSelectors.useTouchpointPropertyFilters()
    const updateTouchpointPropertyFilters = ConversionListContextSelectors.useUpdateTouchpointPropertyFilters()

    const [selectedTouchpointCustomerJourneyRole, setSelectedTouchpointCustomerJourneyRole] =
        React.useState<TouchpointCustomerJourneyRole>(touchpointPropertyFilter.customerJourneyRole)
    const [selectedTouchpointFilterDimension, setSelectedTouchpointFilterDimension] =
        React.useState<TouchpointFilterDimension>(touchpointPropertyFilter.filterDimension)
    const [selectedTouchpointFilterDimensionValues, setSelectedTouchpointFilterDimensionValues] = React.useState<
        DimensionValue[]
    >(touchpointPropertyFilter.values)

    const handleApplyButtonClick = () => {
        if (selectedTouchpointFilterDimensionValues === null) {
            // TODO: Handle case
            console.error("selectedTouchpointFilterDimensionValue is null")
            return
        }

        updateTouchpointPropertyFilters(
            produce(touchpointPropertyFilters, (draft) => {
                draft[touchpointPropertyFilterIndex] = produce(touchpointPropertyFilter, (draft) => {
                    draft.customerJourneyRole = selectedTouchpointCustomerJourneyRole
                    draft.filterDimension = selectedTouchpointFilterDimension
                    draft.values = selectedTouchpointFilterDimensionValues
                })
            }),
        )
    }

    return (
        <PropertyFilterDialogBase
            selectedTouchpointCustomerJourneyRole={selectedTouchpointCustomerJourneyRole}
            updateSelectedTouchpointCustomerJourneyRole={setSelectedTouchpointCustomerJourneyRole}
            selectedTouchpointFilterDimension={selectedTouchpointFilterDimension}
            updateSelectedTouchpointFilterDimension={setSelectedTouchpointFilterDimension}
            selectedTouchpointFilterDimensionValues={selectedTouchpointFilterDimensionValues}
            updateSelectedTouchpointFilterDimensionValues={setSelectedTouchpointFilterDimensionValues}
            applyButtonText="Apply filter"
            onApplyButtonClick={handleApplyButtonClick}
        />
    )
}
