import React from "react"

// Counter for generating unique IDs
let idCounter = 0

/**
 * Generates a unique ID with an optional prefix
 * @param prefix - String prefix for the ID
 * @returns A unique string ID
 */
export const getUniqueId = (prefix: string) => {
    return `${prefix}-${++idCounter}`
}
