import { Box, CircularProgress, List, ListItem, ListItemText, Paper, Typography } from "@mui/material"
import { Widget } from "domain/legacy/widget/Widget"
import { WidgetContext } from "domain/legacy/widget/WidgetContext"
import { DataListWidgetSettingsDTO } from "domain/types/backend/widget.types"
import React, { ReactNode, useContext } from "react"

type Props = {
    children?: ReactNode
}

export const DataListWidget = (props: Props) => {
    const widgetDataContext = useContext(WidgetContext)
    const { response, isLoading } = widgetDataContext
    const widgetSettings = widgetDataContext.widgetSettings as DataListWidgetSettingsDTO

    /*
    data class JenkinsStatusSummary(
        var totalJobCount: Int,
        var failingJobCount: Int,
        var jobDetails: List<JenkinsJobSummary>
    )

    class JenkinsJobSummary(
        val id: Int,
        val name: String,
        val jenkinsHost: String,
        val lastBuild: LocalDateTime?,
        val lastSuccess: LocalDateTime?,
        val lastFailure: LocalDateTime?,
        val lastComment: String,
        val lastResult: JenkinsBuildState,
        val lastFailureCount: Int?,
        val lastSuccessCount: Int?,
        val lastSkippedCount: Int?,
        val lastDuration: Int?,
        val lastBuildNumber: Int,
        val lastBuildId: Int,
    ) {

     */
    // todo carina get as config from server
    const itemRowsKey = "response.dataSet.rows[0].summary.value.jobDetails"
    const items = response ? eval(itemRowsKey) : undefined
    const headerTemplate =
        "${response.dataSet.rows[0].summary.value.failingJobCount} of ${response.dataSet.rows[0].summary.value.totalJobCount} jobs are failing"
    const descriptionTemplate = "lastResult: ${item.lastResult} at ${item.lastBuild}"
    const titleTemplate = "${item.name}"
    const linkTargetTemplate = "${item.buildLink}"

    return (
        <div>
            <Widget isLoading={isLoading} response={response}>
                {/*<div style={{height: 400, width: 1600}}>*/}
                {/*    <TimeLineWidget/>*/}
                {/*</div>*/}
                <Box className="data-list-widget" sx={{ width: "500px" }}>
                    <Paper variant="outlined">
                        {response && (
                            <Box sx={{ p: 1, borderBottom: 1, borderColor: "divider" }}>
                                <Typography>{eval("`" + headerTemplate + "`")}</Typography>
                            </Box>
                        )}
                        {isLoading ? (
                            <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                <CircularProgress size={24} />
                            </Box>
                        ) : (
                            <List sx={{ width: "100%" }}>
                                {items?.map((item: any, index: number) => (
                                    <ListItem
                                        key={index}
                                        divider={index < items.length - 1}
                                        secondaryAction={
                                            <a
                                                target="_blank"
                                                href={eval("`" + linkTargetTemplate + "`")}
                                                rel="noreferrer"
                                            >
                                                Show
                                            </a>
                                        }
                                    >
                                        <ListItemText
                                            primary={eval("`" + titleTemplate + "`")}
                                            secondary={eval("`" + descriptionTemplate + "`")}
                                        />
                                    </ListItem>
                                ))}
                            </List>
                        )}
                        {widgetSettings.listFooter && (
                            <Box sx={{ p: 1, borderTop: 1, borderColor: "divider" }}>
                                <Typography>{widgetSettings.listFooter}</Typography>
                            </Box>
                        )}
                    </Paper>
                </Box>
            </Widget>
        </div>
    )
}
