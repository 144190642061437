import { MetricIdentifiableSettings, SelectedState } from "domain/ColumnConfigurator/components/types"
import { ColumnConfiguratorContextSlices } from "domain/ColumnConfigurator/context/ColumnConfiguratorContextSlices"
import { WidgetStaticConfiguration } from "domain/ColumnConfigurator/types"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { CONVERSION_LIST_DATA_GROUP } from "domain/ConversionList/domain/columnConfigurator"
import { columnCategories } from "domain/ConversionList/domain/domain"
import { PropsWithChildren, useCallback } from "react"
import React from "react"

type ConversionListColumnConfiguratorContextProps = {
    openColumnConfigurator: () => void
    resetGridColumnState: () => void
    onApplyColumnConfigurator: () => void
}

const ConversionListColumnConfiguratorContext = React.createContext<
    ConversionListColumnConfiguratorContextProps | undefined
>(undefined)

export const ConversionListColumnConfiguratorContextProvider = ({ children }: PropsWithChildren) => {
    const { open: openColumnConfigurator } = ColumnConfiguratorContextSlices.useWidgetState()
    const selectedColumns = ConversionListContextSelectors.useSelectedColumns()
    const resetGridColumnState = ConversionListContextSelectors.useResetGridColumnState()
    const leftPinnedColumns = ConversionListContextSelectors.useLeftPinnedColumns()
    const { getColumnConfiguratorOutputConfiguration, close: closeColumnConfigurator } =
        ColumnConfiguratorContextSlices.useWidgetState()
    const updateSelectedColumns = ConversionListContextSelectors.useUpdateSelectedColumns()

    const onApplyColumnConfigurator = useCallback(() => {
        const output = getColumnConfiguratorOutputConfiguration()
        const selectedMetricColumnFields = output.selectedMetrics.map((column) => column.identifier)
        updateSelectedColumns(
            selectedMetricColumnFields,
            leftPinnedColumns.filter((column) => selectedMetricColumnFields.indexOf(column) !== -1),
        )
        closeColumnConfigurator()
    }, [getColumnConfiguratorOutputConfiguration, closeColumnConfigurator, updateSelectedColumns])

    const openColumnConfiguratorWithSelectedColumns = () => {
        const selectedMetrics: MetricIdentifiableSettings[] = selectedColumns.map((column) => ({
            identifier: column,
            showBars: false,
        }))

        const selectedState: SelectedState = new SelectedState({
            selectedDimensions: [],
            selectedMetrics: selectedMetrics,
            widgetId: undefined,
            leftPinnedMetrics: leftPinnedColumns ? [...leftPinnedColumns] : [],
        })

        const widgetStaticConfiguration = new WidgetStaticConfiguration({
            supportedColumnSettings: [],
            maxDimensions: 0,
            supportedDataGroups: [CONVERSION_LIST_DATA_GROUP],
            initiallyExpandedGroups: new Set(Object.keys(columnCategories)),
        })

        openColumnConfigurator(selectedState, widgetStaticConfiguration)
    }

    return (
        <ConversionListColumnConfiguratorContext.Provider
            value={{
                onApplyColumnConfigurator,
                openColumnConfigurator: openColumnConfiguratorWithSelectedColumns,
                resetGridColumnState,
            }}
        >
            {children}
        </ConversionListColumnConfiguratorContext.Provider>
    )
}

export const useConversionListColumnConfiguratorContext = (): ConversionListColumnConfiguratorContextProps => {
    const context = React.useContext(ConversionListColumnConfiguratorContext)
    if (!context) {
        throw new Error(
            "useConversionListColumnConfiguratorContext must be used within a ConversionListColumnConfiguratorContextProvider",
        )
    }
    return context
}
