import { InlineButtonDTO } from "domain/actions/InlineButtonDTO"
import { ActionDTOIdentifierEnum } from "generated/models"

export class RowActions {
    actions: InlineButtonDTO[]
    invokeActionHandler: (identifier: ActionDTOIdentifierEnum) => void

    constructor(actions: InlineButtonDTO[], invokeActionHandler: (identifier: ActionDTOIdentifierEnum) => void) {
        this.actions = actions
        this.invokeActionHandler = invokeActionHandler
    }
}
