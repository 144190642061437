type NullableMapper<T, U> = (item: T) => U | null | undefined

const ArrayUtil = {
    /**
     * Gets a sublist of the array after [fromIndex]
     *
     * @param array
     * @param fromIndex
     */
    tail: (array: any[], fromIndex: number): any[] => {
        return array.slice(fromIndex, array.length)
    },

    /**
     * Gets first N elements from the array
     *
     * @param array
     * @param maxElement
     */
    getFirstNElements: <T>(array: T[], maxElement: number) => {
        return array.slice(0, Math.min(maxElement, array.length))
    },

    /**
     * Like map, but filters out null and undefined values.
     *
     * @param array
     * @param fn
     */
    mapNotNullOrUndefined: <T, U>(array: T[], fn: NullableMapper<T, U>): U[] => {
        const result: U[] = []

        for (const item of array) {
            const mapped = fn(item)
            if (mapped !== null && mapped !== undefined) {
                result.push(mapped)
            }
        }

        return result
    },

    move: <T>(array: T[], fromIndex: number, toIndex: number): void => {
        if (fromIndex < 0 || fromIndex >= array.length || toIndex < 0 || toIndex >= array.length) {
            throw new Error("Index out of bounds")
        }

        const element = array[fromIndex]

        if (fromIndex < toIndex) {
            // Shift elements left from fromIndex + 1 to toIndex
            for (let i = fromIndex; i < toIndex; i++) {
                array[i] = array[i + 1]
            }
        } else if (fromIndex > toIndex) {
            // Shift elements right from toIndex to fromIndex - 1
            for (let i = fromIndex; i > toIndex; i--) {
                array[i] = array[i - 1]
            }
        }

        array[toIndex] = element
    },
}

export default ArrayUtil
