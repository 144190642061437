import CodeSvg from "./images/code.svg?react"
import TimelineSvg from "./images/timeline.svg?react"
import { Box } from "@mui/material"
import IconButton from "@mui/material/IconButton"
import {
    GRID_DETAIL_PANEL_TOGGLE_FIELD,
    GridAlignment,
    GridColDef,
    GridColumnHeaderParams,
    GridColumnOrderChangeParams,
    GridPinnedColumnFields,
    GridRenderCellParams,
} from "@mui/x-data-grid-pro"
import type { GridSortModel } from "@mui/x-data-grid-pro"
import { CustomerJourney } from "domain/ConversionList/components/CustomerJourney/CustomerJourney"
import { ColumnHeader } from "domain/ConversionList/components/GridPanel/ColumnHeader"
import { GridControls } from "domain/ConversionList/components/GridPanel/GridControls"
import {
    PixelDrawerOrMessage,
    PixelTransactionData,
} from "domain/ConversionList/components/ShowPixels/PixelDrawerOrMessage"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import {
    customerJourneyIconColumnConfig,
    pixelIconColumnConfig,
    transactionTsColumnConfig,
} from "domain/ConversionList/domain/columnConfigurations"
import { calculateGridPanelWidth } from "domain/ConversionList/domain/constants"
import {
    CAMPAIGN_VALUE_COLUMN_FIELD,
    CHANNEL_VALUE_COLUMN_FIELD,
    CONVERSION_LIST_TTC_VALUE_COLUMN_FIELD,
    COUNT_DEVICES_VALUE_COLUMN_FIELD,
    COUNT_TOUCHPOINTS_VALUE_COLUMN_FIELD,
    CURRENCY_VALUE_COLUMN_FIELD,
    CUSTOMER_JOURNEY_ICON_VALUE_COLUMN_FIELD,
    DEVICE_TYPE_VALUE_COLUMN_FIELD,
    PIXEL_ICON_VALUE_COLUMN_FIELD,
    REVENUE_VALUE_COLUMN_FIELD,
    TOTAL_PRICE_VALUE_COLUMN_FIELD,
    TRANSACTION_TS,
    TRANSACTION_TS_VALUE_COLUMN_FIELD,
    TRANSACTION_UID_VALUE_COLUMN_FIELD,
} from "domain/ConversionList/domain/dimensionIdentifiers"
import { ColumnConfigDTO, ColumnRendererDTOTypeEnum } from "generated/models"
import { SortSettingsDTO } from "generated/models"
import { produce } from "immer"
import { useDrawerContext } from "layout/MainLayout/Drawer/DrawerContext"
import React, { useCallback, useMemo, useState } from "react"
import { CustomizedDataGrid } from "shared/component/mui/datagrid/CustomizedDataGrid"
import { columnRenderer } from "shared/component/renderers/renderers"

const DEFAULT_SORT_SETTINGS: Readonly<SortSettingsDTO> = {
    sortProperties: [`${TRANSACTION_TS}.value`],
    sortAscending: false,
}

type CustomerJourneyPanelProps = {
    transactionUid: string
    transactionTs: string
    transactionChannel: string
    transactionDeviceType: string
    timeToConversion: string
    totalPrice: string
    revenue: string
    devices: number
    allTouchPoints: number
    currency: string
}

export const GridPanel = () => {
    const { isDrawerOpen } = useDrawerContext()
    const [showPixelAnchorEl, setShowPixelAnchorEl] = React.useState<HTMLElement | null>(null)
    const [customerJourneyDrawerOpen, setCustomerJourneyDrawerOpen] = React.useState<boolean>(false)
    const [customerJourneyTransactionState, setCustomerJourneyTransactionState] =
        React.useState<CustomerJourneyPanelProps>({
            transactionUid: "",
            transactionTs: "",
            transactionChannel: "",
            transactionDeviceType: "",
            timeToConversion: "",
            totalPrice: "",
            revenue: "",
            devices: 0,
            allTouchPoints: 0,
            currency: "",
        })

    const [paginationSettings, setPaginationSettings] = React.useState({
        page: 0,
        pageSize: 50,
    })

    const type = ConversionListContextSelectors.useConversionListType()
    const queryConfig = ConversionListContextSelectors.useCurrentQueryConfig()

    // Right now, the realtime conversion list uses a separate /pagination endpoint, while the historical conversion list
    // returns pagination information in the loadData reponse.
    const usesSeparatePaginationQuery = type === "realtime"

    const sortSettings = ConversionListContextSelectors.useSortSettings()
    const setSortSettings = ConversionListContextSelectors.useSetSortSettings()

    const columns = ConversionListContextSelectors.useColumns()
    const selectedColumns = ConversionListContextSelectors.useSelectedColumns()
    const updateSelectedColumns = ConversionListContextSelectors.useUpdateSelectedColumns()
    const columnConfigs = useMemo(
        () => selectedColumns.map((fieldName) => columns.columnDetails[fieldName]!.columnConfigDTO),
        [columns, selectedColumns],
    )

    const leftPinnedColumns = ConversionListContextSelectors.useLeftPinnedColumns()

    const queryResult = ConversionListContextSelectors.useLoadDataQuery({
        queryConfig,
        paginationSettings,
        sortSettings,
    })

    const paginationQueryResult = ConversionListContextSelectors.useLoadPaginationQuery({
        enabled: usesSeparatePaginationQuery,
        queryConfig,
        paginationSettings,
        sortSettings,
    })

    const paginationQueryResultProp = (() => {
        if (!usesSeparatePaginationQuery) {
            return undefined
        }
        if (paginationQueryResult.isSuccess) {
            return {
                ...paginationQueryResult,
                data: {
                    pageSize: paginationQueryResult.data.pageSize,
                    pages: paginationQueryResult.data.pages,
                    totalEntities: paginationQueryResult.data.totalEntities,
                    page: paginationSettings.page,
                },
            }
        }
        return paginationQueryResult
    })()

    const handleSortModelChange = React.useCallback(
        (sortModel: GridSortModel) => {
            if (sortModel.length === 0 || sortModel[0] === undefined) {
                setSortSettings(DEFAULT_SORT_SETTINGS)
            } else {
                setSortSettings({
                    sortProperties: [sortModel[0].field],
                    sortAscending: sortModel[0].sort === "asc",
                })
            }
        },
        [setSortSettings],
    )

    const [pixelDrawerState, setPixelDrawerState] = useState<PixelDrawerStateType>({
        open: false,
        data: {
            transactionUid: "",
            timestamp: "",
            campaignId: 0,
        },
    })

    const closePixelDrawer = useCallback(() => {
        setPixelDrawerState(
            produce((draft) => {
                draft.open = false
            }),
        )
    }, [setPixelDrawerState])

    const handlePixelDialogClickOnIcon = (params: GridRenderCellParams) => (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setCustomerJourneyDrawerOpen(false)

        setPixelDrawerState((prev) =>
            produce(prev, (draft) => {
                draft.open = true
                draft.data = {
                    transactionUid: params.row[TRANSACTION_UID_VALUE_COLUMN_FIELD].value,
                    timestamp: params.row[TRANSACTION_TS_VALUE_COLUMN_FIELD].value,
                    campaignId: params.row[CAMPAIGN_VALUE_COLUMN_FIELD].value,
                }
            }),
        )
        setShowPixelAnchorEl(event.currentTarget)
    }

    // These columns are always shown in the front of the grid rows
    const frontColumns = [customerJourneyIconColumnConfig, pixelIconColumnConfig, transactionTsColumnConfig]
    const frontColumnIdentifiers = frontColumns.map((column) => column.columnIdentifier)

    const makeGridColDef = (dataColumn: ColumnConfigDTO): GridColDef => ({
        field: dataColumn.columnIdentifier,
        width: dataColumn.gridColumnProperties.width || 150,
        sortable: dataColumn.gridColumnProperties.sortable,
        // front columns are always shown in the front of the grid rows
        disableReorder: frontColumnIdentifiers.includes(dataColumn.columnIdentifier),
        disableColumnMenu: [CUSTOMER_JOURNEY_ICON_VALUE_COLUMN_FIELD, PIXEL_ICON_VALUE_COLUMN_FIELD].includes(
            dataColumn.columnIdentifier,
        ),
        pinnable: !frontColumnIdentifiers.includes(dataColumn.columnIdentifier),
        renderHeader: (_: GridColumnHeaderParams) => {
            if (
                [CUSTOMER_JOURNEY_ICON_VALUE_COLUMN_FIELD, PIXEL_ICON_VALUE_COLUMN_FIELD].includes(
                    dataColumn.columnIdentifier,
                )
            )
                return null

            return (
                <ColumnHeader
                    label={dataColumn.gridColumnProperties.columnHeader}
                    columnCategory={columns.columnDetails[dataColumn.columnIdentifier]?.columnCategory}
                />
            )
        },
        renderCell: (params) => {
            if (dataColumn.columnIdentifier === CUSTOMER_JOURNEY_ICON_VALUE_COLUMN_FIELD) {
                return (
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <IconButton onClick={() => openCustomerJourney(params)}>
                            <TimelineSvg style={{ width: "16px", height: "16px" }} />
                        </IconButton>
                    </Box>
                )
            }
            if (dataColumn.columnIdentifier === PIXEL_ICON_VALUE_COLUMN_FIELD) {
                return (
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <IconButton onClick={handlePixelDialogClickOnIcon(params)}>
                            <CodeSvg style={{ width: "16px", height: "16px" }} />
                        </IconButton>
                    </Box>
                )
            }

            return (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                    {dataColumn.gridColumnProperties.renderer ? columnRenderer(dataColumn)(params) : params.value}
                </Box>
            )
        },
        // Align right for metrics, left for dimensions
        align: getCellAlignment(dataColumn),
    })

    const openCustomerJourney = (params: GridRenderCellParams) => {
        closePixelDrawer()

        setCustomerJourneyTransactionState(
            produce(customerJourneyTransactionState, (draft) => {
                draft.transactionUid = params.row[TRANSACTION_UID_VALUE_COLUMN_FIELD].value
                draft.timeToConversion = params.row[CONVERSION_LIST_TTC_VALUE_COLUMN_FIELD]?.value ?? ""
                draft.totalPrice = params.row[TOTAL_PRICE_VALUE_COLUMN_FIELD]?.value ?? "0"
                draft.revenue = params.row[REVENUE_VALUE_COLUMN_FIELD]?.value ?? "0"
                draft.devices = params.row[COUNT_DEVICES_VALUE_COLUMN_FIELD]?.value ?? 0
                draft.allTouchPoints = params.row[COUNT_TOUCHPOINTS_VALUE_COLUMN_FIELD]?.value ?? 0
                draft.currency = params.row[CURRENCY_VALUE_COLUMN_FIELD]?.value ?? 0
                draft.transactionTs = params.row[TRANSACTION_TS_VALUE_COLUMN_FIELD]?.value ?? 0
                draft.transactionChannel = params.row[CHANNEL_VALUE_COLUMN_FIELD]?.value ?? 0
                draft.transactionDeviceType = params.row[DEVICE_TYPE_VALUE_COLUMN_FIELD]?.value ?? 0
            }),
        )
        setCustomerJourneyDrawerOpen(true)
    }

    /**
     * Get the cell alignment based on the column configuration.
     *
     * @param columnConfig
     */
    const getCellAlignment = (columnConfig: ColumnConfigDTO): GridAlignment => {
        if (columnConfig.columnIdentifier === CUSTOMER_JOURNEY_ICON_VALUE_COLUMN_FIELD) return "center"
        if (columnConfig.columnIdentifier === PIXEL_ICON_VALUE_COLUMN_FIELD) return "center"
        if (columnConfig.gridColumnProperties.renderer.type == ColumnRendererDTOTypeEnum.ICON) return "center"
        if (columnConfig.gridColumnProperties.isMetric) return "right"

        return "left"
    }

    const handleColumnOrderChange = (params: GridColumnOrderChangeParams) => {
        const newList = produce(selectedColumns, (draft) => {
            draft.splice(params.oldIndex - 1 - 2, 1)
            draft.splice(params.targetIndex - 1 - 2, 0, params.column.field)
        })
        updateSelectedColumns(
            newList,
            leftPinnedColumns.filter((column) => newList.indexOf(column) !== -1),
        )
    }

    const pinnedColumns: GridPinnedColumnFields = {
        left:
            leftPinnedColumns.length > 0
                ? // if there are pinned columns, we need to add the detail panel toggle column and the front columns to the left pinned columns
                  // because the detail panel toggle column and the front columns are not part of the column configs
                  [GRID_DETAIL_PANEL_TOGGLE_FIELD, ...frontColumnIdentifiers, ...leftPinnedColumns]
                : [],
    }

    const handlePinnedColumnsChange = (pinnedColumns: GridPinnedColumnFields) => {
        updateSelectedColumns(
            selectedColumns,
            pinnedColumns.left?.filter((column) => column !== GRID_DETAIL_PANEL_TOGGLE_FIELD) ?? [],
        )
    }

    const visibleColumns = [
        ...frontColumns,
        ...columnConfigs.filter((column) => !frontColumnIdentifiers.includes(column.columnIdentifier)),
    ]

    const closeCustomerJourneyDrawer = useCallback(() => {
        setCustomerJourneyDrawerOpen(false)
    }, [setCustomerJourneyDrawerOpen])

    return (
        <Box
            className="grid-panel"
            sx={{
                width: calculateGridPanelWidth(isDrawerOpen),
                height: "100%",
                display: "flex",
                flexDirection: "column",
                flex: 1,
                overflow: "hidden", // Contain any potential overflow
            }}
        >
            <GridControls />
            <CustomizedDataGrid
                visibleColumns={visibleColumns}
                queryResult={queryResult}
                onPageChange={(page, pageSize) => {
                    const newPage = paginationSettings.pageSize === pageSize ? page : 0
                    setPaginationSettings({ page: newPage, pageSize: pageSize })
                }}
                muiDataGridProps={{
                    sortingMode: "server",
                    sortingOrder: ["asc", "desc"],
                    onSortModelChange: handleSortModelChange,
                    disableColumnSelector: true,
                    onColumnOrderChange: handleColumnOrderChange,
                    pinnedColumns: pinnedColumns,
                    onPinnedColumnsChange: handlePinnedColumnsChange,
                    initialState: {
                        sorting: {
                            sortModel: sortSettings.sortProperties?.map((field) => {
                                return { field: field, sort: sortSettings.sortAscending ? "asc" : "desc" }
                            }),
                        },
                    },
                    sx: {
                        minHeight: "400px",
                    },
                }}
                makeGridColDef={makeGridColDef}
                pinning="left"
                paginationQueryResult={paginationQueryResultProp}
            />
            <CustomerJourney
                open={customerJourneyDrawerOpen}
                onClose={closeCustomerJourneyDrawer}
                transaction={customerJourneyTransactionState}
            />
            <PixelDrawerOrMessage
                anchorEl={showPixelAnchorEl}
                onWarnMessageClose={() => setShowPixelAnchorEl(null)}
                shown={pixelDrawerState.open}
                onCloseDialog={closePixelDrawer}
                pixelTransactionData={pixelDrawerState.data}
            />
        </Box>
    )
}

type PixelDrawerStateType = {
    open: boolean
    data: PixelTransactionData
}
