import { Box, Tooltip, Typography } from "@mui/material"
import { ColumnCategory } from "domain/ConversionList/domain/domain"
import React from "react"
import CartIcon from "shared/component/icons/color/CartIcon"
import GearIcon from "shared/component/icons/color/GearIcon"
import MoneyIcon from "shared/component/icons/color/MoneyIcon"

export type ColumnHeaderProps = {
    label: string
    columnCategory?: ColumnCategory
}

// Helper function to get the tooltip category tag text
const getCategoryTagText = (category?: ColumnCategory): string => {
    switch (category) {
        case "winningTouchpoint":
            return "Winning Touchpoint"
        case "conversion":
            return "Conversion"
        case "customParameter":
            return "Custom"
        default:
            return ""
    }
}

// Helper function to get a sample description for each category type
const getDescriptionText = (category?: ColumnCategory): string => {
    switch (category) {
        case "winningTouchpoint":
            return "The winning touchpoint for the given conversion based on the last cookie wins model."
        case "conversion":
            return "Information about the conversion event."
        case "customParameter":
            return "Custom parameter that provides additional data for analysis."
        default:
            return ""
    }
}

export const ColumnHeader = ({ label, columnCategory }: ColumnHeaderProps) => {
    // Process the label to remove any prefixes
    const processedLabel = processLabel(label, columnCategory)

    // Prepare tooltip content
    const tooltipContent = (
        <Box sx={{ p: 1, maxWidth: 400 }}>
            {/* Header/Title */}
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: "bold" }}>
                {processedLabel}
            </Typography>

            {/* Category Tag */}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    bgcolor:
                        columnCategory === "winningTouchpoint"
                            ? "rgba(255, 248, 225, 0.9)"
                            : columnCategory === "conversion"
                              ? "rgba(230, 245, 240, 0.9)"
                              : columnCategory === "customParameter"
                                ? "rgba(235, 235, 235, 0.9)"
                                : "rgba(240, 240, 240, 0.8)",
                    borderRadius: 1,
                    py: 0.5,
                    px: 1,
                    mb: 1,
                    width: "fit-content",
                }}
            >
                {columnCategory === "winningTouchpoint" && (
                    <MoneyIcon
                        sx={{
                            mr: 1,
                            width: 18,
                            height: 18,
                        }}
                    />
                )}
                {columnCategory === "conversion" && (
                    <CartIcon
                        sx={{
                            mr: 1,
                            width: 18,
                            height: 18,
                        }}
                    />
                )}
                {columnCategory === "customParameter" && (
                    <GearIcon
                        sx={{
                            mr: 1,
                            width: 18,
                            height: 18,
                        }}
                    />
                )}
                <Typography variant="body2" sx={{ fontWeight: 500 }}>
                    {getCategoryTagText(columnCategory)}
                </Typography>
            </Box>

            {/* Description */}
            <Typography variant="body2" color="text.secondary">
                {getDescriptionText(columnCategory)}
            </Typography>
        </Box>
    )

    return (
        <Tooltip
            title={tooltipContent}
            arrow
            placement="top-start"
            enterDelay={400}
            leaveDelay={100}
            enterNextDelay={100}
            sx={{
                cursor: "help",
                "& .MuiTooltip-tooltip": {
                    transition:
                        "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 133ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                },
            }}
            PopperProps={{
                modifiers: [
                    {
                        name: "offset",
                        options: {
                            offset: [0, 8],
                        },
                    },
                ],
            }}
        >
            <div
                style={{
                    display: "flex",
                    alignItems: "flex-start",
                    minHeight: "30px",
                    width: "100%",
                    height: "100%",
                    position: "relative",
                    padding: "4px 8px",
                    boxSizing: "border-box",
                    cursor: "help",
                }}
            >
                <div
                    style={{
                        display: "block",
                        fontWeight: 500,
                        wordBreak: "break-word",
                        whiteSpace: "normal",
                        lineHeight: "1.2",
                        overflow: "visible",
                        hyphens: "auto",
                        maxHeight: "none",
                        width: "100%",
                    }}
                >
                    {processedLabel}
                </div>
            </div>
        </Tooltip>
    )
}

// Process label based on column category
const processLabel = (label: string, columnCategory?: ColumnCategory): string => {
    // Use the makeColumnCategoryLabel function to get prefix based on category
    const categoryPrefix = makeColumnCategoryLabel(columnCategory)

    // If there's a prefix and the label starts with that prefix, remove it
    if (categoryPrefix && label.startsWith(`${categoryPrefix}:`)) {
        return label.replace(new RegExp(`^${categoryPrefix}:\\s*`, "i"), "")
    }

    return label
}

// Implement the makeColumnCategoryLabel function
const makeColumnCategoryLabel = (columnCategory: ColumnCategory | undefined) => {
    switch (columnCategory) {
        case "conversion":
            return "Conversion"
        case "winningTouchpoint":
            return "Winning TP"
        case "customParameter":
            return "Custom"
        case undefined:
            return ""
        default:
            return ""
    }
}
