import { ColumnUniqueName, DataColumn, DataGroupUniqueName, DataGroups, Metrics } from "domain/ColumnConfigurator/types"
import SetUtil from "shared/util/SetUtil"

export class DimensionMetricCompatibility {
    readonly compatibleMetrics: ReadonlySet<ColumnUniqueName>
    readonly metricToIncompatibleDimensions: ReadonlyMap<ColumnUniqueName, Set<ColumnUniqueName>>

    constructor(
        compatibleMetrics: Set<ColumnUniqueName>,
        metricToIncompatibleDimensions: Map<ColumnUniqueName, Set<ColumnUniqueName>>,
    ) {
        this.compatibleMetrics = compatibleMetrics
        this.metricToIncompatibleDimensions = metricToIncompatibleDimensions
    }

    isMetricCompatibleWithSelectedDimensions(metricName: ColumnUniqueName) {
        return this.compatibleMetrics.has(metricName)
    }

    getIncompatibleDimensionsForMetric(columnUnqueName: ColumnUniqueName) {
        return this.metricToIncompatibleDimensions.get(columnUnqueName) ?? new Set()
    }

    isColumnIncompatibleWithSelectedDimensions(column: DataColumn) {
        return column.columnType === "metric" && !this.isMetricCompatibleWithSelectedDimensions(column.uniqueName)
    }
}

// TODO: Is this hacky? Is it performant enough?
export const calculateDimensionMetricCompatibility = (
    metrics: Metrics,
    dataGroups: DataGroups,
    selectedDimensions: Set<ColumnUniqueName>,
): DimensionMetricCompatibility => {
    const compatibleMetrics = new Set<ColumnUniqueName>()
    const metricToIncompatibleDimensions = new Map<ColumnUniqueName, Set<ColumnUniqueName>>()

    const dataGroupToIncompatibleDimensions = new Map<DataGroupUniqueName, Set<ColumnUniqueName>>()

    // Calculate which metrics are compatible with the selected dimensions. A metric is compatible if it is present in
    // at least one data group that contains all selected dimensions. If a data group contains a dimension that it is
    // not compatible with, then that dimension is added to the set of incompatible dimensions for that data group.
    for (const dataGroup of dataGroups.values()) {
        const dataGroupDimensions = new Set(dataGroup.dimensions)
        const difference = SetUtil.difference(selectedDimensions, dataGroupDimensions)
        if (difference.size === 0) {
            for (const metric of dataGroup.metrics) {
                compatibleMetrics.add(metric)
            }
        } else {
            dataGroupToIncompatibleDimensions.set(dataGroup.uniqueName, difference)
        }
    }

    // Every metric that is not compatible with the selected dimensions is added to the set of incompatible metrics.
    const incompatibleMetrics = SetUtil.difference(new Set(metrics.keys()), compatibleMetrics)

    // For every incompatible metric, find the data group that has the least number of incompatible dimensions. This
    // data group is the one that is most compatible with the selected dimensions. The dimensions that are incompatible
    // with the metric are added to the map of incompatible dimensions for that metric. These dimensions can be shown
    // to the user as a hint for why the metric is not compatible.
    for (const incompatibleMetric of incompatibleMetrics) {
        const possibleDataGroups = Array.from(dataGroups.values()).filter((dataGroup) =>
            dataGroup.metrics.has(incompatibleMetric),
        )
        const possibleDataGroupsToIncompatibleDimensions = Array.from(
            dataGroupToIncompatibleDimensions.entries(),
        ).filter(([dataGroupUniqueName, _]) => {
            return possibleDataGroups.some((dataGroup) => dataGroup.uniqueName === dataGroupUniqueName)
        })

        if (possibleDataGroupsToIncompatibleDimensions.length > 0) {
            const possibleDataGroupToIncompatibleDimensions = possibleDataGroupsToIncompatibleDimensions.sort(
                (a, b) => {
                    return a[1].size - b[1].size
                },
            )[0]

            if (possibleDataGroupToIncompatibleDimensions !== undefined) {
                const minimalIncompatibleDimensions: Set<string> = possibleDataGroupToIncompatibleDimensions[1]
                metricToIncompatibleDimensions.set(incompatibleMetric, minimalIncompatibleDimensions)
            }
        }
    }

    return new DimensionMetricCompatibility(compatibleMetrics, metricToIncompatibleDimensions)
}
