import { GridRenderCellParams, GridRowModel } from "@mui/x-data-grid-pro"
import { NOT_AVAILABLE } from "Constants"
import { RowActions } from "domain/actions/RowActions"
import { ColumnField } from "domain/dimension/service/DimensionService"
import MetricUtil from "domain/legacy/widget/MetricUtil"
import { MetricDTO } from "domain/types"
import { ColumnConfigDTO, ColumnRendererDTOTypeEnum, ColumnResponseDTO } from "generated/models"
import React, { type JSX } from "react"
import { currencyMetricRenderer } from "shared/component/renderers/currency-metric.renderer"
import { dataRenderer } from "shared/component/renderers/data.renderer"
import { durationRenderer } from "shared/component/renderers/duration.renderer"
import { iconValueNameRenderer } from "shared/component/renderers/icon-value-name.renderer"
import { iconRenderer } from "shared/component/renderers/icon.renderer"
import InlineButtonsRenderer from "shared/component/renderers/inline-buttons.renderer"
import { optimizationLevelRenderer } from "shared/component/renderers/optimization-level.renderer"
import { prefixRenderer } from "shared/component/renderers/prefix.renderer"
import { tooltipRenderer } from "shared/component/renderers/tooltip.renderer"
import { valueComparisonRenderer } from "shared/component/renderers/value-comparison.renderer"
import { yesNoRenderer } from "shared/component/renderers/yes-no.renderer"
import { assertExhaustive } from "shared/util/TypeUtil"
import formatter from "shared/util/formatter"

export const columnRenderer = (columnConfigDTO: ColumnConfigDTO): GridCellFormatter => {
    const handleUndefinedValue = (columnResponseDTO: ColumnResponseDTO) => {
        if (!columnResponseDTO) return

        const isSet = (value: any) => value !== null && value !== undefined
        return {
            ...columnResponseDTO,
            value: isSet(columnResponseDTO.value)
                ? columnConfigDTO?.gridColumnProperties?.isMetric
                    ? MetricUtil.metricValueFormatter({ round: 2 } as MetricDTO)(columnResponseDTO.value)
                    : columnResponseDTO.value
                : NOT_AVAILABLE,
            name: isSet(columnResponseDTO.name) ? columnResponseDTO.name : NOT_AVAILABLE,
        }
    }

    const fn: Formatter = agnosticRenderer(columnConfigDTO)

    if (columnConfigDTO.gridColumnProperties.nullValuesVisible) {
        return (params: GridRenderCellParams<any, ColumnResponseDTO>) =>
            fn(handleUndefinedValue(params.value), params.row)
    } else if (fn) {
        return (params: GridRenderCellParams<any, ColumnResponseDTO>) => fn(params.value, params.row)
    } else {
        return (params: GridRenderCellParams<any, ColumnResponseDTO>) => params.value.value
    }
}

export const agnosticRenderer = (columnConfigDTO: ColumnConfigDTO): Formatter => {
    const { renderer } = columnConfigDTO.gridColumnProperties

    let fn: Formatter
    switch (renderer.type) {
        case ColumnRendererDTOTypeEnum.CURRENCY_METRIC:
            fn = (value: ColumnResponseDTO, row: GridRowModel<any>) =>
                currencyMetricRenderer(
                    value?.value?.toString(),
                    row[ColumnField.valueField("currency").toString()].value,
                )
            break
        case ColumnRendererDTOTypeEnum.DATA:
            fn = (value, _) => dataRenderer(value, renderer.cssClasses)
            break
        case ColumnRendererDTOTypeEnum.DATE:
            fn = (value, _) =>
                tooltipRenderer(formatter.formatDate(value?.value?.toString(), "DD.MM.YYYY"), renderer.cssClasses)
            break
        case ColumnRendererDTOTypeEnum.DATE_TIME:
            fn = (value, _) =>
                tooltipRenderer(
                    formatter.formatDateTime(value?.value?.toString(), "DD.MM.YYYY HH:mm"),
                    renderer.cssClasses,
                )
            break
        case ColumnRendererDTOTypeEnum.DATE_TIME_SECONDS:
            fn = (value) =>
                tooltipRenderer(
                    formatter.formatDateTime(value?.value?.toString(), "DD.MM.YYYY HH:mm:ss"),
                    renderer.cssClasses,
                )
            break
        case ColumnRendererDTOTypeEnum.DURATION:
            fn = (value, _) => tooltipRenderer(durationRenderer(value?.value), renderer.cssClasses)
            break
        case ColumnRendererDTOTypeEnum.ICON:
            fn = (value, _) => iconRenderer(value)
            break
        case ColumnRendererDTOTypeEnum.ICON_VALUE_NAME:
            fn = iconValueNameRenderer(columnConfigDTO)
            break
        case ColumnRendererDTOTypeEnum.INLINE_BUTTONS:
            fn = (value, _) =>
                value ? <InlineButtonsRenderer rowActions={value.data as unknown as RowActions} /> : <></>
            break
        case ColumnRendererDTOTypeEnum.NAME:
            fn = (value, _) => tooltipRenderer(value?.name, renderer.cssClasses)
            break
        case ColumnRendererDTOTypeEnum.OPTIMIZATION_LEVEL:
            fn = optimizationLevelRenderer
            break
        case ColumnRendererDTOTypeEnum.POSTFIX_VALUE:
            fn = (value, _) =>
                tooltipRenderer(
                    formatter.applyPostfix(value.value, columnConfigDTO.gridColumnProperties.postfix),
                    renderer.cssClasses,
                )
            break
        case ColumnRendererDTOTypeEnum.PREFIX_NAME:
            fn = (value, _) => tooltipRenderer(prefixRenderer(value), renderer.cssClasses)
            break
        case ColumnRendererDTOTypeEnum.VALUE:
            fn = (value, _) => tooltipRenderer(value?.value, renderer.cssClasses)
            break
        case ColumnRendererDTOTypeEnum.VALUE_COMPARISON:
            fn = valueComparisonRenderer
            break
        case ColumnRendererDTOTypeEnum.YES_NO:
            fn = yesNoRenderer
            break
        default:
            assertExhaustive(renderer.type)
    }

    return fn
}

export type Formatter = (value: ColumnResponseDTO, row?: GridRowModel<any>) => any
export type GridCellFormatter = (params: GridRenderCellParams) => JSX.Element | string | number | boolean
