import { ColumnUniqueName, DataColumn } from "../../../types"
import { MemoizedSelectedColumnsSidebarListElement } from "./SelectedColumnsSidebarListElement/SelectedColumnsSidebarListElement"
import { DndContext, closestCenter } from "@dnd-kit/core"
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers"
import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { Box } from "@mui/material"
import List from "@mui/material/List"
import { ColumnUtil } from "domain/ColumnConfigurator/components/ColumnUtil"
import { ColumnSelectAction, IdentifiableSettings } from "domain/ColumnConfigurator/components/types"
import { useReportingConfigurationContext } from "domain/reporting/ReportingConfigurationContext"
import React, { type JSX } from "react"
import { log } from "shared/util/log"

export interface SelectedColumnsSidebarListProps {
    selectedColumns: IdentifiableSettings[]
    onSelectedColumnsChanged: (columnIdentifier: ColumnUniqueName, action: ColumnSelectAction) => void
    leftPinnedColumns: ColumnUniqueName[]
    onColumnSettingsChanged: (columnSettings: IdentifiableSettings) => void
    onColumnMoved: (columnIdentifier: ColumnUniqueName, newPosition: number | "end") => void
}

export const SelectedColumnsSidebarList = ({
    selectedColumns,
    onSelectedColumnsChanged,
    leftPinnedColumns,
    onColumnSettingsChanged,
    onColumnMoved,
}: SelectedColumnsSidebarListProps): JSX.Element => {
    const {
        helpers: { getColumn },
    } = useReportingConfigurationContext()
    const selectedIdentifiers = ColumnUtil.getIdentifiers(selectedColumns)

    // NOTE: O(n*m), but should be fine for small numbers of pinned columns
    const selectedWithoutPinned = selectedIdentifiers.filter((identifier) => !leftPinnedColumns.includes(identifier))

    const makeDraggableItem = (columnName: ColumnUniqueName, isPinned = false): React.ReactNode => {
        const column = getColumn(columnName)

        if (!column) {
            log.error(
                `Column definition for column ${columnName} not found. Not rendering in selected columns sidebar.`,
            )
            return undefined
        }

        return (
            <DraggableItem
                key={columnName}
                id={columnName}
                column={column}
                isPinned={isPinned}
                onSelectedColumnsChanged={onSelectedColumnsChanged}
                onColumnSettingsChanged={onColumnSettingsChanged}
                onColumnMoved={onColumnMoved}
                pinnedColumnsCount={leftPinnedColumns.length}
            />
        )
    }

    const notDraggableListItems = leftPinnedColumns.map((identifier) => makeDraggableItem(identifier, true))
    const draggableListItems = selectedWithoutPinned.map((identifier) => makeDraggableItem(identifier))

    return (
        <Box className={"selected-columns-sidebar-list"}>
            <List dense={true}>{notDraggableListItems}</List>
            <DndContext
                collisionDetection={closestCenter}
                onDragEnd={(event) => {
                    const { active, over } = event
                    if (over !== null && active.id !== over?.id) {
                        onColumnMoved(active.id.toString(), selectedIdentifiers.indexOf(over.id.toString()))
                    }
                }}
                modifiers={[restrictToVerticalAxis, restrictToParentElement]}
            >
                <SortableContext items={selectedIdentifiers} strategy={verticalListSortingStrategy}>
                    <List dense={true}>{draggableListItems}</List>
                </SortableContext>
            </DndContext>
        </Box>
    )
}

const DraggableItem = ({
    id,
    column,
    isPinned,
    onSelectedColumnsChanged,
    onColumnSettingsChanged,
    onColumnMoved,
    pinnedColumnsCount,
}: {
    id: ColumnUniqueName
    column: DataColumn
    isPinned: boolean
    onSelectedColumnsChanged: (columnIdentifier: ColumnUniqueName, action: ColumnSelectAction) => void
    onColumnSettingsChanged: (columnSettings: IdentifiableSettings) => void
    onColumnMoved: (columnIdentifier: ColumnUniqueName, newPosition: number | "end") => void
    pinnedColumnsCount: number
}) => {
    const { attributes, listeners, setNodeRef, transform, isDragging } = useSortable({ id })

    const style = {
        transform: CSS.Transform.toString(transform),
    }

    const metricTags = column.columnType === "metric" ? column.tags : undefined

    // We offset the index position to account for pinned columns
    const offsetOnColumnMoved = (columnIdentifier: ColumnUniqueName, newPosition: number | "end") => {
        onColumnMoved(columnIdentifier, newPosition === "end" ? newPosition : newPosition + pinnedColumnsCount)
    }

    return (
        // Element content will be hidden via CSS using the is-dragging class; content would otherwise be distorted and look weird
        <Box
            ref={setNodeRef}
            sx={style}
            {...attributes}
            className={`draggable-item ${isDragging ? "is-dragging" : "not-dragging"}`}
        >
            <MemoizedSelectedColumnsSidebarListElement
                column={column}
                onSelectedColumnsChanged={onSelectedColumnsChanged}
                onColumnSettingsChanged={onColumnSettingsChanged}
                onColumnMoved={offsetOnColumnMoved}
                metricTags={metricTags}
                draggableListeners={isPinned ? undefined : listeners}
            />
        </Box>
    )
}
