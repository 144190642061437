import { Box } from "@mui/material"
import { PropertyFilterChip } from "domain/ConversionList/components/ConfigPanel/TouchpointProperties/PropertyFilterChip"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { TOUCHPOINT_CUSTOMER_JOURNEY_ROLES } from "domain/ConversionList/domain/touchpointCustomerJourneyRole"
import { TOUCHPOINT_FILTER_DIMENSIONS } from "domain/ConversionList/domain/touchpointFilterDimension"
import { produce } from "immer"
import React from "react"

export const CurrentPropertyFiltersList = () => {
    const touchpointPropertyFilters = ConversionListContextSelectors.useTouchpointPropertyFilters()
    const updateTouchpointPropertyFilters = ConversionListContextSelectors.useUpdateTouchpointPropertyFilters()
    const openPropertyFilterDialog = ConversionListContextSelectors.useOpenPropertyFilterDialog()

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                marginBottom: 1,
            }}
        >
            {touchpointPropertyFilters.map((filter, index) => {
                const roleLabel = TOUCHPOINT_CUSTOMER_JOURNEY_ROLES[filter.customerJourneyRole].displayName
                const filterDimensionLabel = TOUCHPOINT_FILTER_DIMENSIONS[filter.filterDimension].displayName

                const handleDelete = () => {
                    const newFilters = produce(touchpointPropertyFilters, (draft) => {
                        draft.splice(index, 1)
                    })
                    updateTouchpointPropertyFilters(newFilters)
                }

                const handleEdit = () => {
                    openPropertyFilterDialog({ type: "edit", id: filter.id })
                }

                return (
                    <PropertyFilterChip
                        key={filter.id}
                        role={roleLabel}
                        dimension={filterDimensionLabel}
                        values={filter.values.map((v) => v.name)}
                        onDelete={handleDelete}
                        onEdit={handleEdit}
                    />
                )
            })}
        </Box>
    )
}
