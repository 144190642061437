import { ContainerSizeContext } from "domain/legacy/widget/ContainerSizeContext"
import { LoadResponseDTOReportingDataSetDTO } from "generated/models"
import React, { ReactNode, useContext, useEffect } from "react"
import Spinner from "shared/component/Spinner"

type Props = {
    children?: ReactNode
    isLoading: boolean
    response?: LoadResponseDTOReportingDataSetDTO
}

/**
 * This component renders the children, if the response was successful,
 * shows spinner, if data is loading, and shows alert, if the response was not successful.
 *
 * This component must be wrapped in the [ContainerSizeContextProvider],
 * that provides container reference for the container size computing.
 *
 * @param props
 * @constructor
 */
export const Widget = (props: Props) => {
    const containerSizeContext = useContext(ContainerSizeContext)

    // trigger resize logic after the component is mounted
    useEffect(() => {
        containerSizeContext.handleResize()
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    let content: ReactNode = <div />
    if (props.response) {
        if (props.response.response?.success) {
            content = props.children
        } else {
            content = (
                <div>
                    Unable to load data. If this problem persists, please try to add further filters or use a shorter
                    time span
                </div>
            )
        }
    }

    return (
        <div className={"widget"} ref={containerSizeContext.containerRef}>
            <Spinner spinning={props.isLoading}>{content}</Spinner>
        </div>
    )
}
