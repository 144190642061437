import {
    CHANNEL,
    LINE_ITEM,
    PROVIDER,
    PUBLISHER,
    SUB_CAMPAIGN,
} from "domain/ConversionList/domain/dimensionIdentifiers"

export type TouchpointFilterDimension = "channel" | "line_item" | "publisher" | "sub_campaign" | "api_provider"

export const TOUCHPOINT_FILTER_DIMENSIONS: Readonly<
    Record<
        TouchpointFilterDimension,
        {
            displayName: string
            dimensionIdentifier: string
        }
    >
> = {
    channel: {
        displayName: "Channel",
        dimensionIdentifier: CHANNEL,
    },
    line_item: {
        displayName: "Line Item",
        dimensionIdentifier: LINE_ITEM,
    },
    publisher: {
        displayName: "Publisher",
        dimensionIdentifier: PUBLISHER,
    },
    sub_campaign: {
        displayName: "Sub-Campaign",
        dimensionIdentifier: SUB_CAMPAIGN,
    },
    api_provider: {
        displayName: "API Provider",
        dimensionIdentifier: PROVIDER,
    },
}
