import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Box, useTheme } from "@mui/material"
import { TagsUtil } from "domain/ColumnConfigurator/TagsUtil"
import { ValueTab } from "domain/ColumnConfigurator/components/ColumnSelectionPanel/MetricSelectionPanel/MetricSelectionAccordion/matrix/ValueTab"
import TooltipTab from "domain/ColumnConfigurator/components/ColumnSelectionPanel/MetricSelectionPanel/TooltipTab"
import { ColumnConfiguratorContextSlices } from "domain/ColumnConfigurator/context/ColumnConfiguratorContextSlices"
import {
    AttributionTiming,
    ColumnUniqueName,
    OriginalValueType,
    ReturnedValueType,
    UpdatedValueType,
} from "domain/ColumnConfigurator/types"
import React, { type JSX } from "react"
import { run } from "shared/util/FunctionUtil"
import { BORDER_RADIUS } from "styles/theme/constants"

type TabPosition = "left" | "right" | "centered"

type Params = {
    attributionTiming: AttributionTiming
    /**
     * Metrics which must already be filtered by the attribution timing (ToTP/ToCNV)
     */
    attributionMetrics: ReadonlySet<ColumnUniqueName>
    onCheckboxChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
    hasSearchQuery: boolean
    tabPosition: TabPosition
}
export const AttributionTab = ({
    attributionTiming,
    attributionMetrics,
    onCheckboxChangeHandler,
    hasSearchQuery,
    tabPosition,
}: Params): JSX.Element | null => {
    const { supportedModels } = ColumnConfiguratorContextSlices.useSupportedColumns()
    const theme = useTheme()

    const supportsOnlyUpdatedValueMetrics: boolean = run(() => {
        const model = supportedModels[attributionTiming.id]
        return (
            model.isSupported &&
            model[UpdatedValueType.instance.id] &&
            !model[OriginalValueType.instance.id] &&
            !model[ReturnedValueType.instance.id]
        )
    })

    const updatedValueMetrics = React.useMemo(
        () => TagsUtil.filterMetricsByTag(attributionMetrics, UpdatedValueType.instance.tag),
        [attributionMetrics],
    )
    const originalValueMetrics = React.useMemo(
        () => TagsUtil.filterMetricsByTag(attributionMetrics, OriginalValueType.instance.tag),
        [attributionMetrics],
    )
    const returnedValueMetrics = React.useMemo(
        () => TagsUtil.filterMetricsByTag(attributionMetrics, ReturnedValueType.instance.tag),
        [attributionMetrics],
    )

    const hasUpdatedValueMetrics = updatedValueMetrics.size > 0
    const hasOriginalValueMetrics = originalValueMetrics.size > 0
    const hasReturnedValueMetrics = returnedValueMetrics.size > 0

    const valueTypes = React.useMemo(() => {
        const types = []
        if (hasUpdatedValueMetrics) {
            types.push(UpdatedValueType.instance.id)
        }
        if (hasOriginalValueMetrics) {
            types.push(OriginalValueType.instance.id)
        }
        if (hasReturnedValueMetrics) {
            types.push(ReturnedValueType.instance.id)
        }
        return types
    }, [hasUpdatedValueMetrics, hasOriginalValueMetrics, hasReturnedValueMetrics])

    const [selectedTab, setSelectedTab] = React.useState(() => {
        if (valueTypes.includes(UpdatedValueType.instance.id)) {
            return UpdatedValueType.instance.id
        }
        if (valueTypes.includes(OriginalValueType.instance.id)) {
            return OriginalValueType.instance.id
        }
        return ReturnedValueType.instance.id
    })

    const tabToShow = React.useMemo(() => {
        if (valueTypes.includes(selectedTab)) {
            return selectedTab
        }
        if (valueTypes.includes(UpdatedValueType.instance.id)) {
            return UpdatedValueType.instance.id
        }
        if (valueTypes.includes(OriginalValueType.instance.id)) {
            return OriginalValueType.instance.id
        }
        if (valueTypes.includes(ReturnedValueType.instance.id)) {
            return ReturnedValueType.instance.id
        }
        return undefined
    }, [selectedTab, valueTypes])

    if (tabToShow === undefined) {
        return null
    }

    const handleChange = (_event: React.SyntheticEvent, value: any) => {
        setSelectedTab(value)
    }

    const leftPos: string =
        tabPosition === "left" ? "0" : tabPosition === "right" ? "50%" : tabPosition === "centered" ? "30%" : "0"
    const rightPos: string =
        tabPosition === "left" ? "50%" : tabPosition === "right" ? "0" : tabPosition === "centered" ? "30%" : "0"

    return (
        <TabPanel
            value={attributionTiming.id}
            className={"attribution-tab"}
            sx={{
                padding: "0!important",
                border: 0,
                "& .MuiTabs-root": {
                    borderBottom: 0,
                    position: "relative",
                    "& .MuiTab-root": {
                        padding: "0 24px",
                        minHeight: "30px",
                        margin: "0 8px",
                        [theme.breakpoints.down("md")]: {
                            minWidth: "unset",
                            padding: "0 3px",
                            margin: "0 3px",
                        },
                    },
                },
                "& .MuiTabs-scroller": {
                    position: "absolute",
                    left: leftPos,
                    right: rightPos,
                    width: "unset",
                },
                "& .MuiTabs-flexContainer": {
                    justifyContent: "center",
                },
                "& .MuiTabs-indicator": {
                    bottom: "0px",
                    borderRadius: BORDER_RADIUS,
                    height: "28px",
                    backgroundColor: "rgba(53,98,227, 0.2)",
                },
                "& .MuiTouchRipple-root": {
                    display: "none",
                },
                "& .MuiBadge-badge": {
                    boxShadow: "none",
                    right: "-12px",
                    top: "3px",
                    // height: "13px",
                    // padding: 0,
                },
                "& .Mui-selected .MuiBadge-badge": {
                    boxShadow: theme.shadows[1],
                },
            }}
        >
            <TabContext value={tabToShow}>
                {!supportsOnlyUpdatedValueMetrics && (
                    <Box>
                        <TabList onChange={handleChange} aria-label="Value Type">
                            {hasUpdatedValueMetrics && (
                                <TooltipTab
                                    label={"Updated"}
                                    value={UpdatedValueType.instance.id}
                                    tooltip={
                                        <>
                                            <h3>Updated Values</h3>Metrics consider the latest Total Price and Revenue,
                                            reflecting any changes through conversion management after the initial
                                            conversion event.
                                        </>
                                    }
                                    disableTooltipInteractive
                                    badgeContent={hasSearchQuery ? updatedValueMetrics.size : undefined}
                                />
                            )}
                            {hasOriginalValueMetrics && (
                                <TooltipTab
                                    label={"Original"}
                                    value={OriginalValueType.instance.id}
                                    tooltip={
                                        <>
                                            <h3> Original Values</h3>Metrics consider the initial Total Price and
                                            Revenue as tracked at the moment of the conversion event, before any
                                            updates.
                                        </>
                                    }
                                    disableTooltipInteractive
                                    badgeContent={hasSearchQuery ? originalValueMetrics.size : undefined}
                                />
                            )}
                            {hasReturnedValueMetrics && (
                                <TooltipTab
                                    label={"Returned"}
                                    value={ReturnedValueType.instance.id}
                                    tooltip={
                                        <>
                                            <h3>Returned Values</h3>Metrics report on the difference between initial and
                                            updated values for Total Price and Revenue.
                                        </>
                                    }
                                    disableTooltipInteractive
                                    badgeContent={hasSearchQuery ? returnedValueMetrics.size : undefined}
                                />
                            )}
                        </TabList>
                    </Box>
                )}
                {hasUpdatedValueMetrics && (
                    <ValueTab
                        valueType={UpdatedValueType.instance}
                        filteredMetrics={updatedValueMetrics}
                        onCheckboxChangeHandler={onCheckboxChangeHandler}
                    />
                )}
                {hasOriginalValueMetrics && (
                    <ValueTab
                        valueType={OriginalValueType.instance}
                        filteredMetrics={originalValueMetrics}
                        onCheckboxChangeHandler={onCheckboxChangeHandler}
                    />
                )}
                {hasReturnedValueMetrics && (
                    <ValueTab
                        valueType={ReturnedValueType.instance}
                        filteredMetrics={returnedValueMetrics}
                        onCheckboxChangeHandler={onCheckboxChangeHandler}
                    />
                )}
            </TabContext>
        </TabPanel>
    )
}
