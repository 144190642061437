import { MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import {
    TOUCHPOINT_CUSTOMER_JOURNEY_ROLES,
    TouchpointCustomerJourneyRole,
} from "domain/ConversionList/domain/touchpointCustomerJourneyRole"
import React, { type JSX } from "react"
import { match } from "ts-pattern"

export interface TouchpointCustomerJourneyRoleSelectProps {
    selectedTouchpointCustomerJourneyRole: TouchpointCustomerJourneyRole
    updateSelectedTouchpointCustomerJourneyRole: (role: TouchpointCustomerJourneyRole) => void
}

export const TouchpointCustomerJourneyRoleSelect = ({
    selectedTouchpointCustomerJourneyRole,
    updateSelectedTouchpointCustomerJourneyRole,
}: TouchpointCustomerJourneyRoleSelectProps): JSX.Element => {
    const conversionListType = ConversionListContextSelectors.useConversionListType()

    const onChange = (event: SelectChangeEvent<TouchpointCustomerJourneyRole>) => {
        updateSelectedTouchpointCustomerJourneyRole(event.target.value as TouchpointCustomerJourneyRole)
    }

    return match(conversionListType)
        .with("historical", () => (
            <Select
                value={selectedTouchpointCustomerJourneyRole}
                onChange={onChange}
                sx={{ minWidth: "120px", width: "120px" }}
            >
                {Object.entries(TOUCHPOINT_CUSTOMER_JOURNEY_ROLES).map(([role, props]) => {
                    return (
                        <MenuItem key={role} value={role}>
                            {props.displayName}
                        </MenuItem>
                    )
                })}
            </Select>
        ))
        .with("realtime", () => (
            <>{TOUCHPOINT_CUSTOMER_JOURNEY_ROLES[selectedTouchpointCustomerJourneyRole].displayName}</>
        ))
        .exhaustive()
}
