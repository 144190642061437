import { FormControlLabel, Switch as MuiSwitch } from "@mui/material"
import React from "react"
import { useFormContext } from "shared/component/forms/FormContext"
import { getUniqueId } from "shared/component/forms/elements/primitives/util"

export interface SwitchProps {
    name: string
    label: string
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    required?: boolean
}

export const Switch = ({ name, label, onChange: onChangeProp, required = false }: SwitchProps) => {
    const { current: id } = React.useRef(getUniqueId(name))

    const { formik } = useFormContext()

    const fieldProps = formik.getFieldProps(name)

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        fieldProps.onChange(event)
        if (onChangeProp) {
            onChangeProp(event)
        }
    }

    return (
        <FormControlLabel
            control={
                <MuiSwitch
                    id={id}
                    className={fieldProps.name + " form-field form-field-switch"}
                    name={fieldProps.name}
                    value={fieldProps.value}
                    onChange={onChange}
                    onBlur={fieldProps.onBlur}
                    size={"medium"}
                    required={required}
                />
            }
            label={label}
        />
    )
}
