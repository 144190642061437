import { ColumnField } from "domain/dimension/service/DimensionService"
import { ColumnConfigDTO, ColumnResponseDTO } from "generated/models"
import React, { lazy } from "react"
import SvgValueNameRenderer from "shared/component/renderers/svg-value-name/svg-value-name.renderer"

// Define the type for our SVG value name renderer components
interface SvgValueNameRendererProps {
    value: ColumnResponseDTO
}

// Map column identifiers to their corresponding directory names
const columnIdentifierToDirectoryMap: Record<string, string> = {
    geo: "geo_country",
    device: "device_type",
    // Add more mappings as needed
}

// Create a cache for lazy-loaded renderers to prevent recreating them on every render
const lazyRendererCache = new Map<string, React.LazyExoticComponent<React.ComponentType<SvgValueNameRendererProps>>>()

export const iconValueNameRenderer = (columnConfigDTO: ColumnConfigDTO) => {
    const columnIdentifier = ColumnField.recognize(columnConfigDTO.columnIdentifier).identifier

    // Map the column identifier to the correct directory name
    const directoryName = columnIdentifierToDirectoryMap[columnIdentifier] || columnIdentifier

    // Get or create a lazy component for the renderer from cache
    if (!lazyRendererCache.has(columnIdentifier)) {
        lazyRendererCache.set(
            columnIdentifier,
            lazy(() => import(`./svg-value-name/${directoryName}/${directoryName}.svg-value-name.renderer.tsx`)),
        )
    }
    const LazyRenderer = lazyRendererCache.get(columnIdentifier)

    // Return a function that renders the lazy component with Suspense
    return (value, _) => (
        <React.Suspense fallback={<SvgValueNameRenderer value={value} />}>
            <LazyRenderer value={value} />
        </React.Suspense>
    )
}
