import { Autocomplete, AutocompleteChangeReason, TextField, styled } from "@mui/material"
import { ColumnCategory, columnCategories } from "domain/ConversionList/domain/domain"
import React, { SyntheticEvent } from "react"

// Type for column item
export type ColumnItem = {
    fieldName: string
    label: string
    columnCategory: ColumnCategory
}

/**
 * Component for displaying column selection dropdown
 */
export const ColumnSelector = ({
    columns,
    currentValue,
    onChange,
}: {
    columns: ColumnItem[]
    currentValue: ColumnItem | null
    onChange: (
        event: SyntheticEvent<Element, Event>,
        value: ColumnItem | null,
        reason: AutocompleteChangeReason,
    ) => void
}) => (
    <Autocomplete
        className="search-column-autocomplete"
        options={columns}
        value={currentValue}
        onChange={onChange}
        groupBy={(option) => option.columnCategory}
        renderGroup={(params) => (
            <li key={params.key}>
                <GroupHeader>{columnCategories[params.group as ColumnCategory].displayNamePlural}</GroupHeader>
                <GroupItems>{params.children}</GroupItems>
            </li>
        )}
        renderInput={(params) => <TextField {...params} label="" placeholder="Search column" variant="outlined" />}
        sx={{ width: 300, display: "inline-block", maxWidth: "100%", mb: 1 }}
        size="small"
    />
)

// Styled components for the Autocomplete groups
export const GroupHeader = styled("div")(({ theme }) => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px",
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.paper,
}))

export const GroupItems = styled("ul")({
    padding: 0,
})
