import { HistoricalConversionListPage } from "domain/ConversionList/HistoricalConversionListPage"
import { RealtimeConversionListPage } from "domain/ConversionList/RealtimeConversionListPage"
import { ConversionListElementProperties, LayoutElementType } from "domain/types"
import { useGridColumnStateContext } from "domain/user/settings/context/GridColumnStateContext"
import { GridElementConfigDTO } from "generated/models"
import { GridElementDTO, LayoutElementDTO } from "generated/models"
import { produce } from "immer"
import React, { type JSX } from "react"
import { log } from "shared/util/log"
import { match } from "ts-pattern"

const ConversionListElement = ({
    layoutElementConfig: { conversionListType, children },
}: ConversionListElementProperties): JSX.Element => {
    const userSettingsContext = useGridColumnStateContext()
    const updateSelectedColumnIdentifiers = (
        selectedColumnIdentifiers: ReadonlyArray<string>,
        pinnedColumnIdentifiers: ReadonlyArray<string>,
    ) => {
        userSettingsContext.setGridColumnState(
            gridElementConfig.gridId,
            selectedColumnIdentifiers as string[],
            pinnedColumnIdentifiers as string[],
        )
    }

    const gridElementConfig = isGridElement(children[0]) ? children[0].elementConfig : undefined
    if (!gridElementConfig) {
        throw new Error("Expected a grid element as the first child of a conversion list element")
    }

    const resetGridColumnState = () => {
        userSettingsContext.deleteGridColumnState(gridElementConfig.gridId)
    }

    const selectedColumnIdentifiers =
        userSettingsContext.getGridColumnState(gridElementConfig.gridId)?.selectedColumnIdentifiers ??
        gridElementConfig.gridConfig.visiblePerDefaultColumns

    const pinnedColumnIdentifiers =
        userSettingsContext.getGridColumnState(gridElementConfig.gridId)?.pinnedColumnIdentifiers ??
        gridElementConfig.gridConfig.supportedColumnConfigs
            .filter((column) => column.gridColumnProperties.isFixed)
            .map((column) => column.columnIdentifier)

    const ConversionListPage = match(conversionListType)
        .with("HISTORICAL", () => HistoricalConversionListPage)
        .with("REAL_TIME", () => RealtimeConversionListPage)
        .exhaustive()

    return (
        <ConversionListPage
            gridElementConfig={copyGridElementConfigWithSelectedColumns(
                gridElementConfig,
                selectedColumnIdentifiers,
                pinnedColumnIdentifiers,
            )}
            updateSelectedColumnIdentifiers={updateSelectedColumnIdentifiers}
            resetGridColumnState={resetGridColumnState}
        />
    )
}

const copyGridElementConfigWithSelectedColumns = (
    gridElementConfig: GridElementConfigDTO,
    selectedColumnIdentifiers: string[],
    pinnedColumnIdentifiers: string[],
): GridElementConfigDTO => {
    return produce(gridElementConfig, (draft) => {
        draft.gridConfig.visiblePerDefaultColumns = selectedColumnIdentifiers
            .map((columnIdentifier) => {
                const column = Array.from(gridElementConfig.gridConfig.supportedColumnConfigs || []).find(
                    (column) => column.columnIdentifier === columnIdentifier,
                )
                if (!column) {
                    log.warn(`Column with identifier ${columnIdentifier} not found in supported columns`)
                }
                return column?.columnIdentifier
            })
            .filter((column) => column !== undefined)

        draft.gridConfig.supportedColumnConfigs = draft.gridConfig.supportedColumnConfigs.map((column) => {
            return {
                ...column,
                gridColumnProperties: {
                    ...column.gridColumnProperties,
                    isFixed: pinnedColumnIdentifiers.includes(column.columnIdentifier),
                },
            }
        })
    })
}

export const isGridElement = (layoutElement?: LayoutElementDTO): layoutElement is GridElementDTO => {
    return layoutElement !== undefined && layoutElement.elementType === LayoutElementType.GRID
}

export default ConversionListElement
