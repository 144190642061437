import { Moment } from "moment"

/**
 * Moment has a built-in toISOString method, but it includes the timezone, which we don't want in all cases.
 * This method returns a string in the format "YYYY-MM-DDTHH:mm:ss".
 *
 * @param datetime
 */
const toISOLocalString = (datetime: Moment): string => {
    return datetime.format("YYYY-MM-DDTHH:mm:ss")
}

export const TimeUtil = {
    toISOLocalString: toISOLocalString,
}
