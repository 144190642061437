export type ApiErrorDTO = {
    httpStatus?: any
    message: string
}

export type ErrorResponse = {
    errors: string[]
    message: string
}

export enum QueryModeType {
    COMPARE = "COMPARE",
    PLOT = "PLOT",
    FORM = "FORM",
}

export enum ConversionListExportMode {
    LAST_WINNER_CONVERSIONS = "LAST_WINNER_CONVERSIONS",
    LAST_WINNER_CONVERSIONS_PLUS_TPS = "LAST_WINNER_CONVERSIONS_PLUS_TPS",
    EACH_WINNER_CONVERSIONS = "EACH_WINNER_CONVERSIONS",
}
