import { Drawer } from "@mui/material"
import { ContainerSizeContext } from "domain/legacy/widget/ContainerSizeContext"
import { WidgetContext } from "domain/legacy/widget/WidgetContext"
import { QueryWidgetSettingsDTO } from "domain/types/backend/widget.types"
import React, { useContext, useRef } from "react"
import * as R from "remeda"
import { v1 as uuid } from "uuid"

type Props = {
    children: React.ReactNode
}

export const WidgetSettingsPanel = (props: Props) => {
    const containerSizeContext = useContext(ContainerSizeContext)
    const widgetDataContext = useContext(WidgetContext)
    const { widgetSettings, updateWidgetSettings } = widgetDataContext
    const containerRef = useRef<HTMLDivElement>(null)

    const updateSettingsPanelVisible = (isVisible: boolean) => {
        const newSettings: QueryWidgetSettingsDTO = R.clone(widgetSettings)
        newSettings.settingsPanelVisible = isVisible
        updateWidgetSettings(newSettings)
    }

    const onClose = () => {
        updateSettingsPanelVisible(false)
    }

    const hiddenClassName = "drawer-hidden"

    const id = "widget-drawer-" + uuid()
    const className = "widget-drawer " + (widgetSettings.settingsPanelVisible ? "open" : `closed ${hiddenClassName}`)
    return (
        <div className={className} id={id} ref={containerRef}>
            <Drawer
                anchor="right"
                role="presentation"
                onClose={onClose}
                open={widgetSettings.settingsPanelVisible}
                disablePortal
                onTransitionEnd={(e) => {
                    const rootEl = document.body.querySelector("#" + id)
                    if (!widgetSettings.settingsPanelVisible) {
                        rootEl?.classList.add(hiddenClassName)
                    } else {
                        rootEl?.classList.remove(hiddenClassName)
                    }
                }}
                sx={{
                    position: "absolute",
                    "& .MuiBackdrop-root": {
                        position: "absolute",
                    },
                    "& .MuiDrawer-paper": {
                        position: "absolute",
                        width:
                            containerSizeContext.containerSize.width > 420
                                ? 380
                                : containerSizeContext.containerSize.width - 20,
                        padding: "15px",
                        border: "1px solid #636e7b",
                    },
                }}
                ModalProps={{
                    container: containerRef.current,
                }}
            >
                {props.children}
            </Drawer>
        </div>
    )
}
