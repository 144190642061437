import AutorenewRoundedIcon from "@mui/icons-material/AutorenewRounded"
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded"
import { ToolbarButton } from "domain/types/backend/layout.types"
import { ActionDTO, ActionDTOIdentifierEnum } from "generated/models"
import { ToolbarButtonDTO, ToolbarConfigDTO } from "generated/models"
import React, { type JSX } from "react"
import ResponsiveButton from "shared/component/ResponsiveButton"
import ToolbarButtonComponent from "shared/component/ToolbarButtonComponent"

type Props = {
    config: ToolbarConfigDTO
    actions: ActionDTO[]
    onInvoke?: (action: ActionDTO) => void
    selectedRows?: { [key: string]: number }
    showDownload?: boolean
    disableButtons?: boolean
    downloadProcessing?: boolean
    onDownload?: () => void
}

export const ToolbarComponent: React.FC<Props> = React.memo((props: Props): JSX.Element => {
    const invokeAction = (action: ActionDTO) => {
        const { onInvoke } = props
        onInvoke(action)
    }

    const createToolbarButtonComponent = (button: ToolbarButtonDTO, disabled: boolean) => {
        const { actions, selectedRows } = props
        const actionTypes = Object.keys(selectedRows)

        const action = actions.find((action) => action.identifier === button.actionIdentifier)
        if (!action) {
            throw new Error("No action was found for the button actionIdentifier " + button.actionIdentifier)
        }

        const toolbarButton = { toolbarButtonDTO: button, action: action } as ToolbarButton

        const rowCount =
            actionTypes.indexOf(toolbarButton.toolbarButtonDTO.actionIdentifier) == -1
                ? action.selectionConfig.minSelected
                : selectedRows[toolbarButton.toolbarButtonDTO.actionIdentifier]

        return (
            <ToolbarButtonComponent
                disabled={disabled}
                button={toolbarButton}
                onInvoke={invokeAction}
                selectedRows={rowCount}
                key={button.actionIdentifier}
            />
        )
    }

    const buttons = props.config?.buttons || []
    const { onDownload, disableButtons, downloadProcessing } = props

    const configuredButtons = Array.from(buttons).map((button) => {
        if (button.actionIdentifier == ActionDTOIdentifierEnum.DOWNLOAD) {
            return (
                <div
                    className={"toolbar-button-wrapper default-wrapper"}
                    aria-label="Download"
                    key={buttons.keys.length}
                >
                    <ResponsiveButton
                        className="toolbar-button download-button"
                        variant="text"
                        onClick={onDownload}
                        disabled={disableButtons || downloadProcessing}
                    >
                        {downloadProcessing ? <AutorenewRoundedIcon /> : <DownloadRoundedIcon />}
                    </ResponsiveButton>
                </div>
            )
        } else {
            return createToolbarButtonComponent(button, props.disableButtons)
        }
    })

    return <div className="toolbar action-toolbar">{configuredButtons}</div>
})
