import React from "react"
import { Context, useContextSelector } from "use-context-selector"

export const useChangeDetection = (deps: any[]) => {
    const isInitialMount = React.useRef(true)
    const [hasChanges, setHasChanges] = React.useState(false)

    React.useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false
            return
        }

        setHasChanges(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps)

    const applyChanges = React.useCallback(() => {
        setHasChanges(false)
    }, [])

    return [hasChanges, applyChanges] as const
}

export const useSafeContextSelector = <T, Selected>(
    context: Context<T | undefined>,
    selector: (value: T) => Selected,
): Selected => {
    // Define a wrapper selector for the undefined check
    const wrappedSelector = (value: T | undefined): Selected => {
        if (value === undefined) {
            throw new Error(
                "useSafeContextSelector was provided with an undefined context. Ensure the context is correctly provided to your component tree.",
            )
        }
        return selector(value)
    }

    // Utilize the original useContextSelector with the wrapped selector
    return useContextSelector(context, wrappedSelector)
}
