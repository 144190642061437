import { Box, Drawer } from "@mui/material"
import { styled } from "@mui/material/styles"
import { TouchPointMapper } from "domain/ConversionList/components/CustomerJourney/TouchPointMapper"
import { CustomerJourneyDetailContainer } from "domain/ConversionList/components/CustomerJourney/components/CustomerJourneyDetailContainer"
import { CustomerJourneyDetailsSection } from "domain/ConversionList/components/CustomerJourney/components/CustomerJourneyDetailsSection"
import { CustomerJourneyDrawerHeader } from "domain/ConversionList/components/CustomerJourney/components/CustomerJourneyDrawerHeader"
import { CustomerJourneyTimelineContainer } from "domain/ConversionList/components/CustomerJourney/components/CustomerJourneyTimelineContainer"
import { CustomerJourneyToggleSection } from "domain/ConversionList/components/CustomerJourney/components/CustomerJourneyToggleSection"
import { Spinner } from "domain/ConversionList/components/CustomerJourney/components/Spinner"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { CustomerJourneyConfig } from "domain/ConversionList/domain/customerJourneyConfig"
import { CJMode } from "domain/ConversionList/domain/touchpointType"
import { ConversionListOptionalSettingsDTOCjModeEnum } from "generated/models"
import { produce } from "immer"
import React, { useCallback, useState } from "react"
import { match } from "ts-pattern"

type CustomerJourneyProps = {
    open: boolean
    onClose: () => void
    transaction: Transaction
}

type Transaction = {
    transactionTs: string
    transactionChannel: string
    transactionDeviceType: string
    timeToConversion: string
    totalPrice: string
    revenue: string
    devices: number
    allTouchPoints: number
    transactionUid: string
    currency: string
}

export const CustomerJourney = ({ open: open, onClose: onClose, transaction: transaction }: CustomerJourneyProps) => {
    const [viewType, setViewType] = useState<ViewType>("timeline")
    const [customerJourneyConfig, setCustomerJourneyConfig] = React.useState<CustomerJourneyConfig>({
        cjMode: ConversionListOptionalSettingsDTOCjModeEnum.ALL,
        showBlockedTouchpoints: false,
    })

    const queryConfig = ConversionListContextSelectors.useCurrentQueryConfig()
    const customerJourney = ConversionListContextSelectors.useLoadCustomerJourneyQuery({
        queryConfig: {
            ...queryConfig,
            customerJourneyConfig: customerJourneyConfig,
        },
        transactionUid: transaction.transactionUid,
        enabled: open && transaction.transactionUid !== "",
    })

    const touchPoints: TouchPoint[] = customerJourney.data ? TouchPointMapper.getTouchPoints(customerJourney.data) : []
    const touchPointsWithConversion = [...touchPoints, getConversionTP(touchPoints, transaction)]

    // Handle view type change
    const handleViewTypeChange = useCallback(
        (_event: React.MouseEvent<HTMLElement>, newViewType: ViewType | null) => {
            if (newViewType !== null) {
                setViewType(newViewType)
            }
        },
        [setViewType],
    )

    // Handle granularity change
    const handleGranularityChange = useCallback(
        (_event: React.MouseEvent<HTMLElement>, newGranularity: CJMode | null) => {
            if (newGranularity !== null) {
                setCustomerJourneyConfig(
                    produce(customerJourneyConfig, (draft) => {
                        draft.cjMode = newGranularity
                    }),
                )
            }
        },
        [setCustomerJourneyConfig, customerJourneyConfig],
    )

    return (
        <Drawer
            sx={DRAWER_SX}
            variant="persistent"
            anchor="right"
            open={open}
            slotProps={{
                paper: {
                    elevation: 16,
                },
            }}
        >
            <DrawerContent className="component-JourneyDrawerContent">
                <CustomerJourneyDrawerHeader onClose={onClose} />
                <CustomerJourneyDetailsSection
                    touchpointsCount={transaction.allTouchPoints}
                    timeToConversion={transaction.timeToConversion}
                    totalPrice={transaction.totalPrice}
                    revenue={transaction.revenue}
                    devices={transaction.devices}
                    currency={transaction.currency}
                />
                <CustomerJourneyToggleSection
                    viewType={viewType}
                    handleViewTypeChange={handleViewTypeChange}
                    granularity={customerJourneyConfig.cjMode}
                    handleGranularityChange={handleGranularityChange}
                />
                {/* Content area that changes based on view type */}
                <Box className={"customer-journey-content"} position="relative" width="100%" flex="1" bgcolor="#FAFBFF">
                    {match(viewType)
                        .with("timeline", () => (
                            <CustomerJourneyTimelineContainer touchPoints={touchPointsWithConversion} />
                        ))
                        .with("detail", () => (
                            <CustomerJourneyDetailContainer touchPoints={touchPointsWithConversion} />
                        ))
                        .exhaustive()}

                    {(customerJourney.isLoading || customerJourney.isPlaceholderData) && <Spinner />}
                </Box>
            </DrawerContent>
        </Drawer>
    )
}

/**
 * Creates a conversion touchpoint object based on the provided transaction data.
 * @param touchPoints
 * @param transaction
 */
const getConversionTP = (touchPoints: TouchPoint[], transaction: Transaction): TouchPoint => {
    return {
        id: touchPoints.length + 1,
        date: transaction.transactionTs.split(" ")[0]!,
        time: transaction.transactionTs.split(" ")[1]!,
        type: "Conversion",
        channel: transaction.transactionChannel,
        provider: "",
        publisher: "",
        marketing: false,
        organic: true,
        role: "Converter",
        isConversion: true,
        deviceType: transaction.transactionDeviceType,
        identityType: "",
        subId: "",
    }
}

// Update the drawer width constants
const MINIMUM_LEFT_SPACE = 200
// MAX_DRAWER_WIDTH is removed - we'll only use viewport calculation
const MIN_DRAWER_WIDTH = 750 // Minimum width to prevent button wrapping

const DRAWER_SX = {
    minWidth: MIN_DRAWER_WIDTH,
    maxWidth: `calc(100vw - ${MINIMUM_LEFT_SPACE}px)`,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
        minWidth: MIN_DRAWER_WIDTH,
        width: "auto",
        maxWidth: `calc(100vw - ${MINIMUM_LEFT_SPACE}px)`,
        boxSizing: "border-box",
        top: 0,
        height: "100%",
        border: "none",
        display: "flex",
        flexDirection: "column",
        overflowX: "visible",
    },
}

// Styled component for the drawer content
const DrawerContent = styled(Box)(({ theme }) => ({
    height: "100%",
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    flexDirection: "column",
    overflowY: "auto", // Make the entire drawer content scrollable
    scrollbarWidth: "none", // Hide scrollbar in Firefox
    "&::-webkit-scrollbar": {
        display: "none", // Hide scrollbar in Chrome, Safari, Edge
    },
    msOverflowStyle: "none", // Hide scrollbar in IE and Edge
}))
