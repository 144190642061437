/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime"
import type { ContainerElementDTO } from "./ContainerElementDTO"
import {
    ContainerElementDTOFromJSON,
    ContainerElementDTOFromJSONTyped,
    ContainerElementDTOToJSON,
} from "./ContainerElementDTO"
import type { DimensionDTO } from "./DimensionDTO"
import { DimensionDTOFromJSON, DimensionDTOFromJSONTyped, DimensionDTOToJSON } from "./DimensionDTO"
import type { FormActionDTO } from "./FormActionDTO"
import { FormActionDTOFromJSON, FormActionDTOFromJSONTyped, FormActionDTOToJSON } from "./FormActionDTO"
import type { FormButtonDTO } from "./FormButtonDTO"
import { FormButtonDTOFromJSON, FormButtonDTOFromJSONTyped, FormButtonDTOToJSON } from "./FormButtonDTO"

/**
 *
 * @export
 * @interface FormConfigDTO
 */
export interface FormConfigDTO {
    /**
     *
     * @type {FormActionDTO}
     * @memberof FormConfigDTO
     */
    actions?: FormActionDTO
    /**
     *
     * @type {Array<FormButtonDTO>}
     * @memberof FormConfigDTO
     */
    buttons: Array<FormButtonDTO>
    /**
     *
     * @type {boolean}
     * @memberof FormConfigDTO
     */
    keepOpenAfterCreateAndEdit: boolean
    /**
     *
     * @type {ContainerElementDTO}
     * @memberof FormConfigDTO
     */
    layoutConfig: ContainerElementDTO
    /**
     *
     * @type {DimensionDTO}
     * @memberof FormConfigDTO
     */
    mainDimension?: DimensionDTO
    /**
     *
     * @type {string}
     * @memberof FormConfigDTO
     */
    title: string
    /**
     *
     * @type {string}
     * @memberof FormConfigDTO
     */
    type: FormConfigDTOTypeEnum
}

/**
 * @export
 */
export const FormConfigDTOTypeEnum = {
    CREATE: "CREATE",
    EDIT: "EDIT",
} as const
export type FormConfigDTOTypeEnum = (typeof FormConfigDTOTypeEnum)[keyof typeof FormConfigDTOTypeEnum]

/**
 * Check if a given object implements the FormConfigDTO interface.
 */
export function instanceOfFormConfigDTO(value: object): value is FormConfigDTO {
    if (!("buttons" in value) || value["buttons"] === undefined) return false
    if (!("keepOpenAfterCreateAndEdit" in value) || value["keepOpenAfterCreateAndEdit"] === undefined) return false
    if (!("layoutConfig" in value) || value["layoutConfig"] === undefined) return false
    if (!("title" in value) || value["title"] === undefined) return false
    if (!("type" in value) || value["type"] === undefined) return false
    return true
}

export function FormConfigDTOFromJSON(json: any): FormConfigDTO {
    return FormConfigDTOFromJSONTyped(json, false)
}

export function FormConfigDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FormConfigDTO {
    if (json == null) {
        return json
    }
    return {
        actions: json["actions"] == null ? undefined : FormActionDTOFromJSON(json["actions"]),
        buttons: (json["buttons"] as Array<any>).map(FormButtonDTOFromJSON),
        keepOpenAfterCreateAndEdit: json["keepOpenAfterCreateAndEdit"],
        layoutConfig: ContainerElementDTOFromJSON(json["layoutConfig"]),
        mainDimension: json["mainDimension"] == null ? undefined : DimensionDTOFromJSON(json["mainDimension"]),
        title: json["title"],
        type: json["type"],
    }
}

export function FormConfigDTOToJSON(value?: FormConfigDTO | null): any {
    if (value == null) {
        return value
    }
    return {
        actions: FormActionDTOToJSON(value["actions"]),
        buttons: (value["buttons"] as Array<any>).map(FormButtonDTOToJSON),
        keepOpenAfterCreateAndEdit: value["keepOpenAfterCreateAndEdit"],
        layoutConfig: ContainerElementDTOToJSON(value["layoutConfig"]),
        mainDimension: DimensionDTOToJSON(value["mainDimension"]),
        title: value["title"],
        type: value["type"],
    }
}
