import { DimensionMetricCompatibility } from "../../../DimensionMetricCompatibility"
import {
    MetricCheckboxState,
    Tile,
    TileType,
} from "domain/ColumnConfigurator/components/ColumnSelectionPanel/MetricSelectionPanel/types"
import {
    ColumnUniqueName,
    Metric,
    Metrics,
    MetricsFrontendGroup,
    MetricsFrontendGroupUniqueName,
    MetricsFrontendGroups,
} from "domain/ColumnConfigurator/types"

const MATRIX_GROUPS: ReadonlyArray<MetricsFrontendGroupUniqueName> = [
    "kpi_frontend_group_data_driven_attribution",
    "kpi_frontend_group_data_driven_attribution_extended",
    "kpi_frontend_group_data_driven_attribution_fvi",
    "kpi_frontend_group_returned_products",
    "kpi_frontend_group_rule_based_attribution",
]
const MATRIX_TILE_IDENTIFIER = "matrix"

const getTiles = (metricsFrontendGroups: MetricsFrontendGroups): Map<Tile, MetricsFrontendGroup[]> => {
    const tileNameToFrontendGroups = Array.from(metricsFrontendGroups.values())
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .reduce((acc, group) => {
            const tile = getTile(group)

            acc.set(tile.uniqueName, acc.get(tile.uniqueName) || [])
            acc.get(tile.uniqueName)?.push(group)

            return acc
        }, new Map<string, MetricsFrontendGroup[]>())

    const resultEntries: [Tile, MetricsFrontendGroup[]][] = Array.from(tileNameToFrontendGroups.keys()).map(
        (identifier) => {
            const groups = tileNameToFrontendGroups.get(identifier) ?? []
            const tile =
                identifier === MATRIX_TILE_IDENTIFIER
                    ? ({ uniqueName: identifier, displayName: "Attribution Results", type: TileType.MATRIX } as Tile)
                    : ({
                          uniqueName: identifier,
                          displayName: groups[0]?.displayName ?? "N/A",
                          type: TileType.REGULAR,
                      } as Tile)

            return [tile, groups]
        },
    )

    // Move matrix tile to the end
    const matrixIndex = resultEntries.findIndex(([tile, _]) => tile.uniqueName === MATRIX_TILE_IDENTIFIER)
    if (matrixIndex >= 0) {
        const element = resultEntries.splice(matrixIndex, 1)[0]!
        resultEntries.push(element)
    }

    return new Map(resultEntries)
}

const getTile = (group: MetricsFrontendGroup): Tile => {
    return MATRIX_GROUPS.indexOf(group.uniqueName) >= 0
        ? ({ uniqueName: MATRIX_TILE_IDENTIFIER, displayName: "Attribution Results", type: TileType.MATRIX } as Tile)
        : ({ uniqueName: group.uniqueName, displayName: group.displayName, type: TileType.REGULAR } as Tile)
}

export const TileUtil = {
    matrixFrontendGroupNames: MATRIX_GROUPS,
    matrixTileIdentifier: MATRIX_TILE_IDENTIFIER,
    /**
     * Returns a map of tiles to metrics frontend groups.
     *
     * The iteration order of the map is by sort order of its frontend group, except for the matrix tile, which is
     * always last.
     */
    getTiles: getTiles,
    /**
     * Returns a map of column unique names to metrics.
     *
     * The iteration order of the map is by display name of the metrics.
     *
     * @param frontendGroupMetrics
     * @param metrics
     */
    getColumnMap: (
        frontendGroupMetrics: ReadonlySet<ColumnUniqueName>,
        metrics: Metrics,
    ): Map<ColumnUniqueName, Metric> => {
        type ResultEntry = [ColumnUniqueName, Metric]
        const resultEntries: ResultEntry[] = Array.from(frontendGroupMetrics.values())
            .map((metricName) => {
                // We're sure that the metric exists because we're iterating over the metrics in the group

                const metric = metrics.get(metricName)!
                return [metricName, metric] as ResultEntry
            })
            .sort((a, b) => a[1].displayName.localeCompare(b[1].displayName))

        return new Map(resultEntries)
    },
    getMetricsFrontendGroupCheckboxState: (
        frontendGroupMetrics: ReadonlySet<ColumnUniqueName>,
        dimensionMetricCompatibility: DimensionMetricCompatibility,
        selectedMetrics: string[],
    ): Map<ColumnUniqueName, MetricCheckboxState> => {
        const checkedMetrics: Map<ColumnUniqueName, MetricCheckboxState> = new Map()

        for (const metricName of frontendGroupMetrics) {
            // At the start, assume all metrics are disabled and unchecked
            const state: MetricCheckboxState = { visibility: "disabled", checked: false }
            checkedMetrics.set(metricName, state)
        }

        // Mark all compatible metrics as visible
        for (const metricName of dimensionMetricCompatibility.compatibleMetrics) {
            const state = checkedMetrics.get(metricName)
            if (state?.visibility === "disabled") {
                checkedMetrics.set(metricName, { ...state, visibility: "visible" })
            }
        }

        // Mark all selected metrics as checked
        for (const metricName of selectedMetrics) {
            const state = checkedMetrics.get(metricName)
            if (state !== undefined) {
                checkedMetrics.set(metricName, { ...state, checked: true })
            }
        }

        return checkedMetrics
    },
}
