import { Size } from "domain/types/frontend/dimension.types"
import React, { type JSX, MutableRefObject, ReactNode, createContext, useEffect, useState } from "react"

type ContainerSizeContextProviderProps = {
    containerRef?: MutableRefObject<any>
    children?: ReactNode
}

type ContainerSizeContextProps = ContainerSizeContextProviderProps & {
    containerSize?: Size
    handleResize?: () => void
}

export const ContainerSizeContext = createContext<ContainerSizeContextProps>({
    containerRef: undefined,
    containerSize: { width: undefined, height: undefined },
})

/**
 * This provider computes the size of the container with the [containerRef].
 *
 * @param props
 * @constructor
 */
export const ContainerSizeContextProvider = (props: ContainerSizeContextProviderProps): JSX.Element => {
    const [containerSize, setContainerSize] = useState<Size>({ width: 0, height: 0 })

    useEffect(() => {
        // Add event listener
        window.addEventListener("resize", handleResize)
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize)
        // TODO: should only be run on mount, but what if handleResize changes?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []) // Empty array ensures that effect is only run on mount

    // Handler to call on window resize
    const handleResize = () => {
        if (props.containerRef?.current) {
            const { current } = props.containerRef
            const boundingRect = current.getBoundingClientRect()
            const width = Math.round(boundingRect.width)
            const height = Math.round(boundingRect.height)

            if (width !== containerSize.width || height !== containerSize.height) {
                // Set container width/height to state
                setContainerSize({
                    width: Math.round(width),
                    height: Math.round(height),
                })
            }
        }
    }

    return (
        <ContainerSizeContext.Provider
            value={{
                containerSize: containerSize,
                containerRef: props.containerRef,
                handleResize: handleResize,
            }}
        >
            {props.children}
        </ContainerSizeContext.Provider>
    )
}
