import {
    DimensionValueListDTO,
    FormFieldConfigDTO,
    LayoutElementDTO,
    SelectConfigDTO,
    SelectFormElementDTO,
} from "generated/models"

export type FormElementDTO = LayoutElementDTO & {
    formFieldConfig: FormFieldConfigDTO
    useAsSetting: boolean
}

export type FieldKeyValue = {
    name: string
    value: any
}

export enum FormCustomListener {
    SUB_CAMPAIGN_CREATE_FORM_ON_CHANNEL_CHANGE = "SUB_CAMPAIGN_CREATE_FORM_ON_CHANNEL_CHANGE",
}

export type SelectConfig = SelectConfigDTO & {
    selectEntries?: DimensionValueListDTO
}

export type SelectFormElement = SelectFormElementDTO & {
    selectConfig: SelectConfig
}

export enum FormSubmitMethod {
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE",
}
