import { DRAWER_WIDTH_COLLAPSED, DRAWER_WIDTH_EXPANDED } from "layout/MainLayout/constants"

// Width of the config panel in the ConversionList component
export const CONFIG_PANEL_WIDTH = "270px"

// Additional height to subtract from viewport height (for margins, padding, etc.)
// Adjusted to ensure the layout fits precisely in the viewport without extra white space
export const ADDITIONAL_HEIGHT_OFFSET = "0px"

// Helper function to calculate the width of the GridPanel based on the drawer state
export const calculateGridPanelWidth = (isDrawerOpen: boolean) => {
    const drawerWidth = isDrawerOpen ? DRAWER_WIDTH_EXPANDED : DRAWER_WIDTH_COLLAPSED
    return `calc(100vw - ${CONFIG_PANEL_WIDTH} - ${drawerWidth})`
}
