import { Checkbox } from "@mui/material"
import React from "react"

export type SelectAllNoneCheckboxProps = {
    state: "checked" | "unchecked" | "indeterminate"
    onSelectAll: () => void
    onSelectNone: () => void
}

export const SelectAllNoneCheckbox = ({ state, onSelectAll, onSelectNone }: SelectAllNoneCheckboxProps) => {
    const handleChange = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        if (state === "unchecked") {
            onSelectAll()
        } else {
            onSelectNone()
        }
    }

    return (
        <Checkbox
            checked={state === "checked"}
            indeterminate={state === "indeterminate"}
            onClick={handleChange}
            className={"select-all-none"}
        />
    )
}
