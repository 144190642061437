import { ConversionListPageProps } from "domain/ConversionList/ConversionListPageProps"
import { ConversionListContainer } from "domain/ConversionList/components/ConversionListContainer"
import { TimeRange } from "domain/ConversionList/domain/domain"
import moment from "moment"
import React from "react"

export interface RealtimeConversionListPageProps extends ConversionListPageProps {}

export const RealtimeConversionListPage = ({
    ConversionListExternalDependencyContextOverride,
    gridElementConfig,
    updateSelectedColumnIdentifiers,
    resetGridColumnState,
}: RealtimeConversionListPageProps) => {
    return (
        <div className="realtime-conversions-page" style={{ height: "100%" }}>
            <ConversionListContainer
                type="realtime"
                initialTimeRange={today}
                gridElementConfig={gridElementConfig}
                updateSelectedColumnIdentifiers={updateSelectedColumnIdentifiers}
                resetGridColumnState={resetGridColumnState}
                ConversionListExternalDependencyContextOverride={ConversionListExternalDependencyContextOverride}
            />
        </div>
    )
}

const today: TimeRange = [moment().startOf("day"), moment().endOf("day")]
