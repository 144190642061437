import { Tooltip, Typography } from "@mui/material"
import { ColumnResponseDTO } from "generated/models"
import React from "react"

export const yesNoRenderer = (columnResponseDTO: ColumnResponseDTO): React.ReactElement => {
    const value = columnResponseDTO !== undefined && Boolean(columnResponseDTO.value)

    return (
        <Tooltip title={<Typography>{value ? "Yes" : "No"}</Typography>} arrow placement="top">
            <div className={value ? "yes-value" : "no-value"}>{value ? <span>Yes</span> : <span>No</span>}</div>
        </Tooltip>
    )
}
