import { DialogContentText } from "@mui/material"
import { Typography } from "@mui/material"
import { Tooltip } from "@mui/material"
import Box from "@mui/material/Box"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid2"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import { styled } from "@mui/material/styles"
import { StyledTimeSpanSelection } from "domain/ConversionList/components/Exports/StyledTimeSpanSelection"
import { useConversionListContextSelector } from "domain/ConversionList/context/ConversionListContext"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { CustomerJourneyConfig } from "domain/ConversionList/domain/customerJourneyConfig"
import { TRANSACTION_TS_VALUE_COLUMN_FIELD } from "domain/ConversionList/domain/dimensionIdentifiers"
import { mapFiltersToConditionClause } from "domain/ConversionList/domain/filters"
import {
    ConversionListOptionalSettingsDTO,
    ConversionListOptionalSettingsDTOCjModeEnum,
    ConversionListOptionalSettingsDTOListTypeEnum,
    CsvExportSettingsDTO,
    ExcelExportSettingsDTO,
    ExportQuerySettingsDTO,
    QuerySettingsDTO,
} from "generated/models"
import { useExportCenterContext } from "layout/MainLayout/ExportCenter/ExportCenterContext"
import { CustomDialog } from "layout/components/CustomDialog"
import moment, { Moment } from "moment/moment"
import React from "react"
import { TimeUtil } from "shared/util/TimeUtil"
import { log } from "shared/util/log"
import { FONT_WEIGHT_SEMI_BOLD } from "styles/theme/constants"
import { match } from "ts-pattern"
import { v4 as uuid } from "uuid"

// Custom styled dialogue components
const StyledCustomDialog = styled(CustomDialog)(({ theme: _ }) => ({
    "& .MuiDialogActions-root .MuiButton-root": {
        fontSize: "14px",
    },
    "& .MuiDialogActions-root": {
        paddingTop: 0,
    },
}))

const TAB = "tab"
export const ExportDialog = ({ shown, onCloseDialog }: { shown: boolean; onCloseDialog: () => void }) => {
    const dataManagerIdentifier = useConversionListContextSelector((ctx) => ctx.dataManagerIdentifier)
    const appContext = ConversionListContextSelectors.useAppContext()
    const exportCenterContext = useExportCenterContext()
    const selectedDataColumnNames = ConversionListContextSelectors.useSelectedColumns()

    // TODO: temporary hardcoded value, should be replaced with the actual value
    const customerJourneyConfig: CustomerJourneyConfig = {
        cjMode: ConversionListOptionalSettingsDTOCjModeEnum.ALL,
        showBlockedTouchpoints: false,
    }
    const conversionTypeSelection = ConversionListContextSelectors.useConversionTypeSelection()
    const conversionStateSelection = ConversionListContextSelectors.useConversionStateSelection()
    const consentStateSelection = ConversionListContextSelectors.useConsentStateSelection()
    const fullOrSoftConversionSelection = ConversionListContextSelectors.useFullOrSoftConversionSelection()
    const trackingStateSelection = ConversionListContextSelectors.useTrackingStateSelection()
    const touchpointPropertyFilters = ConversionListContextSelectors.useTouchpointPropertyFilters()
    const searchConfig = ConversionListContextSelectors.useSearchConfig()
    const type = ConversionListContextSelectors.useConversionListType()
    const [start, end] = ConversionListContextSelectors.useTimeRange()
    const sortSettings = ConversionListContextSelectors.useSortSettings()
    const cjProcessingStateSelection = ConversionListContextSelectors.useCJProcessingStateSelection()

    const [selectedFormatValue, setSelectedFormatValue] = React.useState<"excel" | "csv">("excel")
    const [selectedCsvSeparator, setSelectedCsvSeparator] = React.useState(",")
    const [selectedCsvEnclosure, setSelectedCsvEnclosure] = React.useState('"')
    const [selectedCsvDateFormat, setSelectedCsvDateFormat] = React.useState("yyyy-MM-dd")
    const [selectedCsvPrintHeader, setSelectedCsvPrintHeader] = React.useState(false)
    const [showCsvSection, setShowCsvSection] = React.useState(false)

    const currentMinMaxTimeRange = (): [Moment, Moment] => {
        const now = moment()

        switch (type) {
            case "realtime": {
                const threeDaysAgo = now.clone().subtract(3, "day").startOf("day")
                const todayAtEndOfDay = now.clone().endOf("day")
                return [threeDaysAgo, todayAtEndOfDay]
            }
            case "historical": {
                // Example: If today is 2025-03-20, this will be 2022-03-21 00:00:00
                const threeYearsAgoAtStartOfDay = now.clone().subtract(3, "year").add(1, "day").startOf("day")
                const oneDayAgoAtEndOfDay = now.clone().subtract(1, "day").endOf("day")
                return [threeYearsAgoAtStartOfDay, oneDayAgoAtEndOfDay]
            }
        }
    }

    const handleFormatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value as "excel" | "csv"

        setSelectedFormatValue(value)
        setShowCsvSection(value === "csv")
    }

    const handleExport = () => {
        const enumType = match(type)
            .with("historical", () => ConversionListOptionalSettingsDTOListTypeEnum.HISTORICAL)
            .with("realtime", () => ConversionListOptionalSettingsDTOListTypeEnum.REAL_TIME)
            .exhaustive()
        const optionalSettings: ConversionListOptionalSettingsDTO = {
            showBlockedTouchpoints: customerJourneyConfig.showBlockedTouchpoints,
            type: "com.exactag.reporting.conversionlist.infrastructure.dto.ConversionListOptionalSettingsDTO",
            cjMode: customerJourneyConfig.cjMode,
            listType: enumType,
        }

        // We recreate the query config here, because the `currentQueryConfig` in our context gets only updated when
        // the apply button gets pressed. But we want to start the export with the current settings, so users don't
        // have to wait for an initial list display
        const filter = mapFiltersToConditionClause({
            sortSettings: sortSettings,
            timeRange: [start, end],
            conversionTypeSelection: conversionTypeSelection,
            conversionStateSelection: conversionStateSelection,
            fullOrSoftConversionSelection: fullOrSoftConversionSelection,
            trackingStateSelection: trackingStateSelection,
            touchpointPropertyFilters: touchpointPropertyFilters,
            searchConfig: searchConfig,
            consentStateSelection: consentStateSelection,
            cjProcessingStateSelection: cjProcessingStateSelection,
        })

        const querySettings: QuerySettingsDTO = {
            appContext: appContext,
            // transaction_ts is always the first column in the export
            columnNames: [
                TRANSACTION_TS_VALUE_COLUMN_FIELD,
                ...selectedDataColumnNames.filter((columnField) => columnField !== TRANSACTION_TS_VALUE_COLUMN_FIELD),
            ],
            filter: filter,
            mode: undefined, // not used in this export
            sortSettings: sortSettings,
            timespanSettings: { start: TimeUtil.toISOLocalString(start!), end: TimeUtil.toISOLocalString(end!) },
            optionalSettings: optionalSettings,
            queryIdentifier: { value: uuid() },
        }

        const excelExportSettings = { type: "ExcelExportSettingsDTO", withHeaders: false } as ExcelExportSettingsDTO
        const csvExportSettings = {
            type: "CsvExportSettingsDTO",
            withHeaders: selectedCsvPrintHeader,
            separator: selectedCsvSeparator === TAB ? "\t" : selectedCsvSeparator,
            dateFormat: selectedCsvDateFormat,
            quoteChar: selectedCsvEnclosure,
        } as CsvExportSettingsDTO

        const exportSettings: ExportQuerySettingsDTO = {
            dataManagerIdentifier: dataManagerIdentifier,
            exportSettings: selectedFormatValue === "excel" ? excelExportSettings : csvExportSettings,
            querySettings: querySettings,
        }

        exportCenterContext.createExportTask("/api/reporting", exportSettings).then(() => log.info("Export started"))
        onCloseDialog()
    }

    return (
        <StyledCustomDialog
            className={"conversion-list-export-dialog"}
            open={shown}
            header="Download"
            maxWidth={"md"}
            sx={{
                "& .MuiPaper-root": {
                    minWidth: { xs: "95vw", sm: "605px" },
                    maxWidth: "705px",
                    width: "90vw",
                    maxHeight: { xs: "90vh", sm: "auto" },
                    overflow: "auto",
                },
            }}
            content={
                <DialogContentText component="div" sx={{ pl: 3, pr: 2, py: 0.5, overflow: "visible", pb: 0 }}>
                    <Box sx={{ marginBottom: 1.5 }}>
                        <Box
                            sx={{
                                width: "100%",
                                p: 0,
                                mb: 1,
                                position: "relative",
                            }}
                        >
                            <Typography
                                variant="body1"
                                sx={{ mb: 0.5, fontWeight: FONT_WEIGHT_SEMI_BOLD, fontSize: "14px" }}
                            >
                                Time Span
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 1.5, color: "text.secondary" }}>
                                {type == "realtime"
                                    ? "You can download up to 3 days of data."
                                    : "You can download up to 36 months of data."}
                            </Typography>
                            <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
                                <Box
                                    sx={{
                                        width: "100%",
                                        "& .MuiTextField-root, & input, & fieldset": {
                                            backgroundColor: "transparent !important",
                                        },
                                    }}
                                >
                                    <StyledTimeSpanSelection
                                        minDateTime={currentMinMaxTimeRange()[0]}
                                        maxDateTime={currentMinMaxTimeRange()[1]}
                                        direction="row"
                                    />
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ mt: 2.5, mb: 1.5, height: "1px", backgroundColor: "#F2F4F7" }} />
                    </Box>
                    <Box sx={{ marginTop: 1, marginBottom: 1.5 }}>
                        <RadioGroup
                            defaultValue="excel"
                            value={selectedFormatValue}
                            onChange={handleFormatChange}
                            name="radio-export-format"
                            sx={{ ml: 0 }}
                        >
                            <Tooltip title="Excel downloads are limited to 1 million rows" placement="right" arrow>
                                <FormControlLabel
                                    value="excel"
                                    control={<Radio />}
                                    label={
                                        <Typography fontWeight={FONT_WEIGHT_SEMI_BOLD} fontSize="14px">
                                            Download as Excel
                                        </Typography>
                                    }
                                    sx={{ width: "fit-content" }}
                                />
                            </Tooltip>
                            <Tooltip title="CSV downloads support up to 50 million rows" placement="right" arrow>
                                <FormControlLabel
                                    value="csv"
                                    control={<Radio />}
                                    label={
                                        <Typography fontWeight={FONT_WEIGHT_SEMI_BOLD} fontSize="14px">
                                            Download as CSV
                                        </Typography>
                                    }
                                    id="csv-radio-option"
                                    sx={{ width: "fit-content" }}
                                />
                            </Tooltip>
                        </RadioGroup>
                    </Box>
                    <Box
                        sx={{
                            marginLeft: 3.7,
                            marginBottom: 0.5,
                            marginTop: -2,
                            maxHeight: showCsvSection ? { xs: "none", sm: "300px" } : 0,
                            overflow: showCsvSection ? { xs: "visible", sm: "hidden" } : "hidden",
                            transition: "max-height 0.5s cubic-bezier(0.4, 0, 0.2, 1)",
                            transformOrigin: "top center",
                            paddingTop: 1,
                            paddingRight: 0,
                        }}
                    >
                        <Box
                            sx={{
                                opacity: showCsvSection ? 1 : 0,
                                transform: showCsvSection ? "translateY(0)" : "translateY(-15px)",
                                transition: "opacity 0.3s ease-out, transform 0.4s cubic-bezier(0.2, 0.8, 0.2, 1.1)",
                                transitionDelay: showCsvSection ? "0.1s" : "0s",
                            }}
                        >
                            <CsvControls
                                onSetCsvSeparator={setSelectedCsvSeparator}
                                onSetCsvDateFormat={setSelectedCsvDateFormat}
                                onSetCsvEnclosure={setSelectedCsvEnclosure}
                                onSetCsvPrintHeader={setSelectedCsvPrintHeader}
                                selectedSeparatorValue={selectedCsvSeparator}
                                selectedEnclosureValue={selectedCsvEnclosure}
                                selectedDateValue={selectedCsvDateFormat}
                                selectedShowHeader={selectedCsvPrintHeader}
                            />
                        </Box>
                    </Box>
                </DialogContentText>
            }
            footer={{
                kind: "yesButton",
                yesText: "Start Download",
                onYesButtonClick: handleExport,
                submitting: false,
            }}
            onClose={onCloseDialog}
        ></StyledCustomDialog>
    )
}

export const CsvControls = React.forwardRef(
    (
        props: {
            onSetCsvSeparator: (arg0: string) => void
            onSetCsvEnclosure: (arg0: string) => void
            onSetCsvDateFormat: (arg0: string) => void
            onSetCsvPrintHeader: (arg0: boolean) => void
            selectedSeparatorValue: string
            selectedEnclosureValue: string
            selectedDateValue: string
            selectedShowHeader: boolean
        },
        ref,
    ) => {
        const handleSeparatorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            props.onSetCsvSeparator(event.target.value)
        }

        const handleEnclosureChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            props.onSetCsvEnclosure(event.target.value)
        }

        const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            props.onSetCsvDateFormat(event.target.value)
        }

        const handleShowHeaderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            props.onSetCsvPrintHeader(event.target.value == "true")
        }

        const CodeSpan = ({ children, sx }: { children: React.ReactNode; sx?: any }) => (
            <Box
                component="span"
                sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "monospace",
                    backgroundColor: "rgba(0, 0, 0, 0.03)",
                    border: "1px solid rgba(0, 0, 0, 0.07)",
                    borderRadius: 0.5,
                    px: 0.7,
                    height: "22px",
                    minWidth: "22px",
                    mx: 0.5,
                    opacity: 0.7,
                    position: "relative",
                    top: "-1px",
                    lineHeight: 1,
                    "& > *": {
                        lineHeight: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    },
                    ...sx,
                }}
            >
                {children}
            </Box>
        )

        return (
            <Box
                sx={{
                    width: "100%",
                    backgroundColor: "#F0F3FD",
                    border: "1px solid",
                    borderColor: "#d7e0f9",
                    borderRadius: 1,
                    p: 1.5,
                    mb: 0.5,
                    position: "relative",
                }}
                ref={ref}
            >
                <Grid container spacing={1} columns={12}>
                    <RadioGroup
                        sx={{ width: "100%", mt: { xs: 0, sm: 0.5 } }}
                        value={props.selectedSeparatorValue}
                        name="separator"
                        onChange={handleSeparatorChange}
                    >
                        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 0 }}>
                            <Typography
                                variant="body2"
                                fontWeight={FONT_WEIGHT_SEMI_BOLD}
                                sx={{ width: 110, flexShrink: 0, mt: 0.7 }}
                            >
                                Separator:
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", sm: "row" },
                                    gap: { xs: 0.1, sm: 0.5 },
                                    width: "100%",
                                }}
                            >
                                <FormControlLabel
                                    value=","
                                    control={<Radio size="small" />}
                                    label={
                                        <Box
                                            component="span"
                                            sx={{
                                                display: "flex",
                                                flexWrap: "nowrap",
                                                whiteSpace: "nowrap",
                                                width: "90px",
                                            }}
                                        >
                                            Comma <CodeSpan>,</CodeSpan>
                                        </Box>
                                    }
                                />
                                <FormControlLabel
                                    value=";"
                                    control={<Radio size="small" />}
                                    label={
                                        <Box
                                            component="span"
                                            sx={{ display: "flex", flexWrap: "nowrap", whiteSpace: "nowrap" }}
                                        >
                                            Semicolon <CodeSpan>;</CodeSpan>
                                        </Box>
                                    }
                                />
                                <FormControlLabel
                                    value="|"
                                    control={<Radio size="small" />}
                                    label={
                                        <Box
                                            component="span"
                                            sx={{ display: "flex", flexWrap: "nowrap", whiteSpace: "nowrap" }}
                                        >
                                            Pipe <CodeSpan>|</CodeSpan>
                                        </Box>
                                    }
                                />
                                <FormControlLabel
                                    value={TAB}
                                    control={<Radio size="small" />}
                                    label={
                                        <Box
                                            component="span"
                                            sx={{ display: "flex", flexWrap: "nowrap", whiteSpace: "nowrap" }}
                                        >
                                            Tab{" "}
                                            <Box
                                                component="span"
                                                sx={{
                                                    display: "inline-flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                    fontFamily: "monospace",
                                                    backgroundColor: "rgba(0, 0, 0, 0.03)",
                                                    border: "1px solid rgba(0, 0, 0, 0.07)",
                                                    borderRadius: 0.5,
                                                    px: 0.7,
                                                    height: "22px",
                                                    minWidth: "22px",
                                                    mx: 0.5,
                                                    fontSize: "14px",
                                                    opacity: 0.7,
                                                    position: "relative",
                                                    top: "-1px",
                                                    lineHeight: 1,
                                                }}
                                            >
                                                ⇥
                                            </Box>
                                        </Box>
                                    }
                                />
                            </Box>
                        </Box>
                    </RadioGroup>

                    <RadioGroup
                        sx={{ width: "100%", mt: { xs: 0, sm: 0.5 }, mb: { xs: 0.5, sm: 0 } }}
                        value={props.selectedEnclosureValue}
                        name="enclosure"
                        onChange={handleEnclosureChange}
                    >
                        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 0 }}>
                            <Typography
                                variant="body2"
                                fontWeight={FONT_WEIGHT_SEMI_BOLD}
                                sx={{ width: 110, flexShrink: 0, mt: 0.7 }}
                            >
                                Enclosure:
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", sm: "row" },
                                    gap: { xs: 0.1, sm: 0.5 },
                                    width: "100%",
                                }}
                            >
                                <FormControlLabel
                                    value='"'
                                    control={<Radio size="small" />}
                                    label={
                                        <Box
                                            component="span"
                                            sx={{
                                                display: "flex",
                                                flexWrap: "nowrap",
                                                whiteSpace: "nowrap",
                                                width: "90px",
                                            }}
                                        >
                                            Quote <CodeSpan>&quot;</CodeSpan>
                                        </Box>
                                    }
                                />
                                <FormControlLabel
                                    value="'"
                                    control={<Radio size="small" />}
                                    label={
                                        <Box
                                            component="span"
                                            sx={{ display: "flex", flexWrap: "nowrap", whiteSpace: "nowrap" }}
                                        >
                                            Single Quote <CodeSpan>'</CodeSpan>
                                        </Box>
                                    }
                                />
                            </Box>
                        </Box>
                    </RadioGroup>

                    <RadioGroup
                        sx={{ width: "100%", mt: { xs: 0, sm: 0.5 }, mb: { xs: 0.5, sm: 0 } }}
                        value={props.selectedDateValue}
                        name="date_format"
                        onChange={handleDateChange}
                    >
                        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 0 }}>
                            <Typography
                                variant="body2"
                                fontWeight={FONT_WEIGHT_SEMI_BOLD}
                                sx={{ width: 110, flexShrink: 0, mt: 0.7 }}
                            >
                                Date Format:
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", sm: "row" },
                                    gap: { xs: 0.1, sm: 0.5 },
                                    width: "100%",
                                }}
                            >
                                <FormControlLabel
                                    value="yyyy-MM-dd"
                                    control={<Radio size="small" />}
                                    label={
                                        <Box
                                            component="span"
                                            sx={{ display: "flex", flexWrap: "nowrap", whiteSpace: "nowrap" }}
                                        >
                                            YYYY-MM-DD <CodeSpan sx={{ ml: 0.5 }}>2023-12-31</CodeSpan>
                                        </Box>
                                    }
                                />
                                <FormControlLabel
                                    value="dd.MM.yyyy"
                                    control={<Radio size="small" />}
                                    label={
                                        <Box
                                            component="span"
                                            sx={{ display: "flex", flexWrap: "nowrap", whiteSpace: "nowrap" }}
                                        >
                                            DD.MM.YYYY <CodeSpan sx={{ ml: 0.5 }}>31.12.2023</CodeSpan>
                                        </Box>
                                    }
                                />
                            </Box>
                        </Box>
                    </RadioGroup>

                    <RadioGroup
                        sx={{ width: "100%", mt: { xs: 0, sm: 0.5 }, mb: { xs: 0.5, sm: 0 } }}
                        value={props.selectedShowHeader ? "true" : "false"}
                        name="show_header"
                        onChange={handleShowHeaderChange}
                    >
                        <Box sx={{ display: "flex", alignItems: "flex-start", mb: 0 }}>
                            <Typography
                                variant="body2"
                                fontWeight={FONT_WEIGHT_SEMI_BOLD}
                                sx={{ width: 110, flexShrink: 0, mt: 0.7 }}
                            >
                                Include Header:
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", sm: "row" },
                                    gap: { xs: 0.1, sm: 0.5 },
                                    width: "100%",
                                }}
                            >
                                <FormControlLabel
                                    value="true"
                                    control={<Radio size="small" />}
                                    label={<Box component="span">Yes</Box>}
                                />
                                <FormControlLabel
                                    value="false"
                                    control={<Radio size="small" />}
                                    label={<Box component="span">No</Box>}
                                />
                            </Box>
                        </Box>
                    </RadioGroup>
                </Grid>
            </Box>
        )
    },
)
