import { Rule, RuleObject, StoreValue } from "rc-field-form/lib/interface"

export enum FormElementValidatorType {
    REQUIRED_FORM_ELEMENT_VALIDATION_DTO = "RequiredFormElementValidationDTO",
    IS_INT_FORM_ELEMENT_VALIDATION_DTO = "IsIntFormElementValidationDTO",
    IS_EMAIL_FORM_ELEMENT_VALIDATION_DTO = "IsEmailFormElementValidationDTO",
    MIN_LENGTH_FORM_ELEMENT_VALIDATION_DTO = "MinLengthFormElementValidationDTO",
    MAX_LENGTH_FORM_ELEMENT_VALIDATION_DTO = "MaxLengthFormElementValidationDTO",
    MIN_VALUE_FORM_ELEMENT_VALIDATION_DTO = "MinValueFormElementValidationDTO",
    MAX_VALUE_FORM_ELEMENT_VALIDATION_DTO = "MaxValueFormElementValidationDTO",
    SAME_VALUE_FORM_ELEMENT_VALIDATION_DTO = "SameValueFormElementValidationDTO",
}

export interface FormElementValidationDTO {
    type: FormElementValidatorType
}

export interface RequiredFormElementValidationDTO extends FormElementValidationDTO {
    type: FormElementValidatorType.REQUIRED_FORM_ELEMENT_VALIDATION_DTO
}
export interface IsIntFormElementValidationDTO extends FormElementValidationDTO {
    type: FormElementValidatorType.IS_INT_FORM_ELEMENT_VALIDATION_DTO
}
export interface IsEmailFormElementValidationDTO extends FormElementValidationDTO {
    type: FormElementValidatorType.IS_EMAIL_FORM_ELEMENT_VALIDATION_DTO
}
export interface MinLengthFormElementValidationDTO extends FormElementValidationDTO {
    type: FormElementValidatorType.MIN_LENGTH_FORM_ELEMENT_VALIDATION_DTO
    minLength: number
}
export interface MaxLengthFormElementValidationDTO extends FormElementValidationDTO {
    type: FormElementValidatorType.MAX_LENGTH_FORM_ELEMENT_VALIDATION_DTO
    maxLength: number
}
export interface MinValueFormElementValidationDTO extends FormElementValidationDTO {
    type: FormElementValidatorType.MIN_VALUE_FORM_ELEMENT_VALIDATION_DTO
    minValue: number
}
export interface MaxValueFormElementValidationDTO extends FormElementValidationDTO {
    type: FormElementValidatorType.MAX_VALUE_FORM_ELEMENT_VALIDATION_DTO
    maxValue: number
}
export interface SameValueFormElementValidationDTO extends FormElementValidationDTO {
    type: FormElementValidatorType.SAME_VALUE_FORM_ELEMENT_VALIDATION_DTO
    fieldIdentifier: string
    errorMessage: string
}

const requiredValidator = (): Rule => ({
    required: true,
    message: "This field is required.",
})
const emailValidator = (): Rule => ({
    required: true,
    type: "email",
    message: "The input is not valid E-mail!",
})

const sameValueValidator = (fieldIdentifier: any, errorMessage: string): Rule => {
    return ({ getFieldValue }) => ({
        validator(_, value) {
            if (!value || getFieldValue(fieldIdentifier) === value) {
                return Promise.resolve()
            }
            return Promise.reject(new Error(errorMessage))
        },
    })
}

const minLengthValidator = (requirement: number): Rule => ({
    validator(rule, value) {
        if (typeof value === "string" && value.length < requirement) {
            return Promise.reject(`Minimum length for this value is ${requirement} characters.`)
        } else {
            return Promise.resolve()
        }
    },
})

const maxLengthValidator = (requirement: number): Rule => ({
    validator(rule, value) {
        if (typeof value === "string" && value.length > requirement) {
            return Promise.reject(`Maximum length for this value is ${requirement} characters.`)
        } else {
            return Promise.resolve()
        }
    },
})

const isIntValidator = (): Rule => ({
    validator(rule: RuleObject, value: StoreValue) {
        if (typeof value === "number" && !Number.isInteger(value)) {
            return Promise.reject(`Value must be an integer.`)
        } else {
            return Promise.resolve()
        }
    },
})

const minValueValidator = (requirement: number): Rule => ({
    validator(rule, value) {
        if (typeof value === "number" && value < requirement) {
            return Promise.reject(`Minimum value for this field is ${requirement}.`)
        } else {
            return Promise.resolve()
        }
    },
})

const maxValueValidator = (requirement: number): Rule => ({
    validator(rule, value) {
        if (typeof value === "number" && value > requirement) {
            return Promise.reject(`Maximum value for this field is ${requirement}.`)
        } else {
            return Promise.resolve()
        }
    },
})

export const createValidationRules = (validators: FormElementValidationDTO[]): Rule[] => {
    if (!validators) return []

    return validators
        .map((validation) => {
            switch (validation.type) {
                case FormElementValidatorType.REQUIRED_FORM_ELEMENT_VALIDATION_DTO:
                    return requiredValidator()
                case FormElementValidatorType.IS_EMAIL_FORM_ELEMENT_VALIDATION_DTO:
                    return emailValidator()
                case FormElementValidatorType.SAME_VALUE_FORM_ELEMENT_VALIDATION_DTO:
                    return sameValueValidator(
                        (validation as SameValueFormElementValidationDTO).fieldIdentifier,
                        (validation as SameValueFormElementValidationDTO).errorMessage,
                    )
                case FormElementValidatorType.MIN_LENGTH_FORM_ELEMENT_VALIDATION_DTO:
                    return minLengthValidator((validation as MinLengthFormElementValidationDTO).minLength)
                case FormElementValidatorType.MAX_LENGTH_FORM_ELEMENT_VALIDATION_DTO:
                    return maxLengthValidator((validation as MaxLengthFormElementValidationDTO).maxLength)
                case FormElementValidatorType.IS_INT_FORM_ELEMENT_VALIDATION_DTO:
                    return isIntValidator()
                case FormElementValidatorType.MIN_VALUE_FORM_ELEMENT_VALIDATION_DTO:
                    return minValueValidator((validation as MinValueFormElementValidationDTO).minValue)
                case FormElementValidatorType.MAX_VALUE_FORM_ELEMENT_VALIDATION_DTO:
                    return maxValueValidator((validation as MaxValueFormElementValidationDTO).maxValue)
                default:
                    throw new Error(`Unknown validation type: ${validation.type}`)
            }
        })
        .filter((validation) => !!validation)
}
