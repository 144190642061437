import { Box, DialogContentText } from "@mui/material"
import { InfoText } from "domain/ConversionList/components/ConfigPanel/TouchpointProperties/PropertyFilterDialog/InfoText"
import { TouchpointCustomerJourneyRoleSelect } from "domain/ConversionList/components/ConfigPanel/TouchpointProperties/PropertyFilterDialog/TouchpointCustomerJourneyRoleSelect"
import { TouchpointDimensionFilterSelect } from "domain/ConversionList/components/ConfigPanel/TouchpointProperties/PropertyFilterDialog/TouchpointDimensionFilterSelect"
import { TouchpointDimensionFilterValueAutocomplete } from "domain/ConversionList/components/ConfigPanel/TouchpointProperties/PropertyFilterDialog/TouchpointDimensionFilterValueAutocomplete"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { DimensionValue } from "domain/ConversionList/domain/domain"
import { TouchpointCustomerJourneyRole } from "domain/ConversionList/domain/touchpointCustomerJourneyRole"
import { TouchpointFilterDimension } from "domain/ConversionList/domain/touchpointFilterDimension"
import { CustomDialog } from "layout/components/CustomDialog"
import React, { type JSX } from "react"

export interface PropertyFilterDialogBaseProps {
    selectedTouchpointCustomerJourneyRole: TouchpointCustomerJourneyRole
    updateSelectedTouchpointCustomerJourneyRole: (role: TouchpointCustomerJourneyRole) => void
    selectedTouchpointFilterDimension: TouchpointFilterDimension
    updateSelectedTouchpointFilterDimension: (dimension: TouchpointFilterDimension) => void
    selectedTouchpointFilterDimensionValues: DimensionValue[]
    updateSelectedTouchpointFilterDimensionValues: (dimensionValues: DimensionValue[]) => void
    applyButtonText: string
    onApplyButtonClick: () => void
}

export const PropertyFilterDialogBase = ({
    selectedTouchpointCustomerJourneyRole,
    updateSelectedTouchpointCustomerJourneyRole,
    selectedTouchpointFilterDimension,
    updateSelectedTouchpointFilterDimension,
    selectedTouchpointFilterDimensionValues,
    updateSelectedTouchpointFilterDimensionValues,
    applyButtonText,
    onApplyButtonClick,
}: PropertyFilterDialogBaseProps): JSX.Element => {
    const conversionListType = ConversionListContextSelectors.useConversionListType()
    const propertyFilterDialogOpen = ConversionListContextSelectors.usePropertyFilterDialogOpen()
    const closePropertyFilterDialog = ConversionListContextSelectors.useClosePropertyFilterDialog()

    const handleSelectedTouchpointFilterDimensionChange = (dimension: TouchpointFilterDimension) => {
        updateSelectedTouchpointFilterDimension(dimension)
        updateSelectedTouchpointFilterDimensionValues([])
    }

    const content = (
        <DialogContentText component="div">
            <Box
                sx={{
                    display: "flex",
                    flexWrap: {
                        xs: "wrap",
                        sm: "wrap",
                        md: "nowrap",
                    },
                    alignItems: "center",
                    gap: 2,
                    mb: 2,
                    width: "100%",
                }}
            >
                <TouchpointCustomerJourneyRoleSelect
                    selectedTouchpointCustomerJourneyRole={selectedTouchpointCustomerJourneyRole}
                    updateSelectedTouchpointCustomerJourneyRole={updateSelectedTouchpointCustomerJourneyRole}
                />
                <Box sx={{ display: "flex", alignItems: "center" }}>is</Box>
                <TouchpointDimensionFilterSelect
                    selectedTouchpointFilterDimension={selectedTouchpointFilterDimension}
                    updateSelectedTouchpointFilterDimension={handleSelectedTouchpointFilterDimensionChange}
                />
                <TouchpointDimensionFilterValueAutocomplete
                    key={selectedTouchpointFilterDimension}
                    selectedTouchpointFilterDimension={selectedTouchpointFilterDimension}
                    selectedTouchpointFilterDimensionValues={selectedTouchpointFilterDimensionValues}
                    updateSelectedTouchpointFilterDimensionValues={updateSelectedTouchpointFilterDimensionValues}
                />
            </Box>
            <InfoText
                conversionListType={conversionListType}
                touchpointCustomerJourneyRole={selectedTouchpointCustomerJourneyRole}
                infoButtonText="Find out more"
            />
        </DialogContentText>
    )

    return (
        <CustomDialog
            open={propertyFilterDialogOpen}
            header={"Touchpoint filter"}
            content={content}
            maxWidth="md"
            className="component-touchpoint-property-filter-dialog"
            footer={{
                kind: "yesNoButton",
                noText: "Cancel",
                yesText: applyButtonText,
                onYesButtonClick: () => {
                    onApplyButtonClick()
                    closePropertyFilterDialog()
                },
                onNoButtonClick: () => {
                    closePropertyFilterDialog()
                },
                submitting: false,
                isYesButtonDisabled:
                    !selectedTouchpointFilterDimensionValues || selectedTouchpointFilterDimensionValues.length === 0,
            }}
        />
    )
}
