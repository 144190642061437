import { ColumnResponseDTO } from "generated/models"
import * as React from "react"

export const iconRenderer = (value: ColumnResponseDTO) => {
    if (!value) {
        return <React.Fragment />
    }

    const iconClassName = value.icon ? "icon " + value.icon : ""

    // hardcoded workaround because tooltip for the health icons looks weird without a min width
    const tipClassName = value.icon && value.icon.indexOf("health") >= 0 ? "long-text-tip" : ""

    return (
        <div className={"icon-wrapper"}>
            <span
                data-tip={value.tooltip}
                data-tip-classname={tipClassName}
                data-force-tooltip={true}
                className={iconClassName}
            />
        </div>
    )
}
