import { DimensionValueListDTO } from "generated/models"

type ConditionClauseDTOFields = {
    clauseType: ConditionClauseType
}

export type TrueClauseDTO = ConditionClauseDTOFields & {
    clauseType: ConditionClauseType.TRUE
}

export type FalseClauseDTO = ConditionClauseDTOFields & {
    clauseType: ConditionClauseType.FALSE
}

export type NullabilityClauseDTO = ConditionClauseDTOFields & {
    columnName: string
    clauseType: ConditionClauseType.IS_NULL | ConditionClauseType.IS_NOT_NULL
}

export enum ConditionClauseType {
    FALSE = "False",
    TRUE = "True",
    BOOLEAN = "Boolean",
    COMPOSITE_DIMENSION = "CompositeDimension",
    IS_NOT_NULL = "IsNotNull",
    IS_NULL = "IsNull",
    NOT = "Not",
    NUMBER_SET = "NumberSet",
    BOOLEAN_VALUE = "BooleanValue",
    NUMBER = "Number",
    STRING_SET = "StringSet",
    STRING = "String",
}

export enum BooleanOperator {
    AND = "AND",
    OR = "OR",
}

export enum ClauseType {
    CONTAINS = "CONTAINS",
    EQUALS = "EQUALS",
    REGEX = "REGEX",
    IN = "IN",
}

export enum FilterType {
    SINGLE_SELECT = "SINGLE_SELECT",
    MULTI_SELECT = "MULTI_SELECT",
    TEXT = "TEXT",
}

export type DimensionValueFilterConfig = {
    dimension: string
    dimensionValueDTO: DimensionValueListDTO
}
