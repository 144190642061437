import { ColumnLayoutConfigDTO, ContainerElementProperties, LayoutMode, TabLayoutConfigDTO } from "domain/types"
import { ContainerElementDTO, TabElementDTO } from "generated/models"
import React, { type JSX } from "react"
import { AdditionalFilterContextProvider } from "shared/component/layout/context/AdditionalFilterContext"
import ChildrenRenderer from "shared/component/layout/renderers/ChildrenRenderer"
import TabsRenderer from "shared/component/layout/renderers/TabsRenderer"
import { log } from "shared/util/log"

const ContainerElement: React.FC<ContainerElementProperties> = ({
    layoutElementConfig,
}: ContainerElementProperties): JSX.Element => {
    const { layoutConfig, additionalFilters } = layoutElementConfig
    const layoutMode = layoutConfig?.layoutMode

    const createLayoutRenderer = (
        layoutMode: "ROWS" | "COLUMNS" | "TABS",
        layoutElementConfig: ContainerElementDTO,
    ): JSX.Element => {
        switch (layoutMode) {
            case LayoutMode.ROWS:
            case LayoutMode.COLUMNS: {
                return (
                    <ChildrenRenderer
                        key={`${layoutElementConfig.identifier}_ChildrenRenderer`}
                        layoutConfig={layoutElementConfig.layoutConfig as ColumnLayoutConfigDTO}
                        children={Array.from(layoutElementConfig.children || [])}
                    />
                )
            }
            case LayoutMode.TABS: {
                return (
                    <TabsRenderer
                        key={`${layoutElementConfig.identifier}_TabsRenderer`}
                        layoutConfig={layoutElementConfig.layoutConfig as TabLayoutConfigDTO}
                        children={Array.from(layoutElementConfig.children || []) as TabElementDTO[]}
                    />
                )
            }
            default: {
                log.error(
                    "ContainerElement is missing a valid layoutMode, children will not be rendered",
                    layoutElementConfig,
                )
                return <React.Fragment />
            }
        }
    }

    const result = <>{createLayoutRenderer(layoutMode, layoutElementConfig)}</>

    if (additionalFilters) {
        return (
            <AdditionalFilterContextProvider additionalFilters={additionalFilters}>
                {result}
            </AdditionalFilterContextProvider>
        )
    } else {
        return result
    }
}

export default ContainerElement
