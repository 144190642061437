import CheckBoxIcon from "@mui/icons-material/CheckBox"
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank"
import { Autocomplete, Box } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { ColumnUtil } from "domain/ColumnConfigurator/components/ColumnUtil"
import { ColumnConfiguratorContextSlices } from "domain/ColumnConfigurator/context/ColumnConfiguratorContextSlices"
import { useReportingConfigurationContext } from "domain/reporting/ReportingConfigurationContext"
import React, { type JSX } from "react"
import { FONT_WEIGHT_SEMI_BOLD } from "styles/theme/constants"

const createHint = (dimensionCount: number, maxDimensions: number) => {
    const isMaxDimensionsLimitReached = dimensionCount >= maxDimensions
    const isAtLeastOneDimensionSelected = dimensionCount > 0

    if (isMaxDimensionsLimitReached) {
        return {
            type: "MAX_REACHED",
            message: `Maximum of ${maxDimensions} dimensions reached`,
        } as const
    }
    if (!isAtLeastOneDimensionSelected) {
        return {
            type: "ZERO_SELECTED",
            message: "Please select at least one dimension",
        } as const
    }
    return undefined
}

export const DimensionSelectionPanel = (): JSX.Element => {
    const {
        dataDefinitions: { dimensions },
    } = useReportingConfigurationContext()

    const { onColumnSelectAction } = ColumnConfiguratorContextSlices.useColumnSettingsChangeHandlers()
    const selectedDimensions = ColumnConfiguratorContextSlices.useSelectedDimensions()
    const { supportedDimensions } = ColumnConfiguratorContextSlices.useSupportedColumns()
    const widgetStaticConfiguration = ColumnConfiguratorContextSlices.useWidgetStaticConfiguration()

    if (!widgetStaticConfiguration.areDimensionsSupported()) {
        return <React.Fragment />
    }

    const selectedDimensionIdentifiers = ColumnUtil.getIdentifiers(selectedDimensions)

    const sortedDimensions = Array.from(dimensions.entries())
        .filter(([dimensionName]) => supportedDimensions.has(dimensionName))
        .sort((a, b) => a[1].displayName.localeCompare(b[1].displayName))

    const options = sortedDimensions.map(([dimensionName, dimension]) => {
        return { id: dimensionName, title: dimension.displayName }
    })
    const initiallySelected = selectedDimensionIdentifiers.map((dimensionId) => {
        const dimension = dimensions.get(dimensionId)
        return { id: dimensionId, title: dimension?.displayName ?? "N/A" }
    })

    const hint = createHint(selectedDimensionIdentifiers.length, widgetStaticConfiguration.maxDimensions)

    return (
        <Box className="dimension-selection-panel" sx={{ paddingBottom: 2 }}>
            <Box display="flex" alignItems="center" gap={1} mb={2}>
                <Typography variant="h5" sx={{ fontSize: "18px", fontWeight: FONT_WEIGHT_SEMI_BOLD }}>
                    Select Dimensions:
                </Typography>
                {hint && <Box sx={{ color: "error.main" }}>{hint.message}</Box>}
            </Box>
            <Autocomplete
                id="checkboxes-tags-demo"
                multiple
                getOptionDisabled={() => hint?.type === "MAX_REACHED"}
                options={options}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                disableCloseOnSelect
                disableClearable
                getOptionLabel={(option) => option.title}
                defaultValue={initiallySelected}
                size="small"
                renderOption={(props, option) => {
                    const checked = selectedDimensionIdentifiers.includes(option.id)

                    return (
                        <li {...props}>
                            <Checkbox
                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                style={{ marginRight: 8 }}
                                checked={checked}
                            />
                            {option.title}
                        </li>
                    )
                }}
                value={initiallySelected}
                onChange={(_event, _value, _reason, details) => {
                    const id = details?.option?.id
                    if (id !== undefined) {
                        onColumnSelectAction(id, "toggle")
                    }
                }}
                style={{ width: "100%" }}
                renderInput={(params) => <TextField {...params} placeholder="" />}
            />
        </Box>
    )
}
