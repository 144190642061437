import React, { ReactNode, useEffect, useState } from "react"
import { TabState } from "shared/component/layout/renderers/TabsRenderer"

export type TabContextProperties = {
    isTabActive: boolean
    tabIndex: number
}

export type TabPaneContextProperties = {
    children?: ReactNode
    tabState: TabState
    tabIndex: number
}
export const TabPaneContext = React.createContext<TabContextProperties>({ isTabActive: true, tabIndex: 0 })

export const TabPaneContextProvider = (props: TabPaneContextProperties) => {
    const [isTabActive, setIsTabActive] = useState(true)

    useEffect(() => {
        // if the tab will be activated, then change the tab state variable to trigger the useEffect in the children
        if (props.tabState.actualTabIndex == props.tabIndex) {
            setIsTabActive(true)
        } else if (props.tabState.previousTabIndex == props.tabIndex) {
            setIsTabActive(false)
        }
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tabState])

    return (
        <TabPaneContext.Provider
            value={{
                isTabActive: isTabActive,
                tabIndex: props.tabIndex,
            }}
        >
            {props.children}
        </TabPaneContext.Provider>
    )
}
