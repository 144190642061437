import { ColumnConfiguratorContextProvider } from "domain/ColumnConfigurator/context/ColumnConfiguratorContextProvider"
import {
    ColumnConfiguratorLabels,
    ColumnUniqueName,
    DataGroup,
    DataGroupUniqueName,
    Metric,
    MetricsFrontendGroup,
    MetricsFrontendGroupUniqueName,
} from "domain/ColumnConfigurator/types"
import { ConversionListColumnConfiguratorContextProvider } from "domain/ConversionList/components/ConversionListColumnConfiguratorContext"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { CONVERSION_LIST_DATA_GROUP } from "domain/ConversionList/domain/columnConfigurator"
import { ColumnCategory, columnCategories } from "domain/ConversionList/domain/domain"
import { DataDefinitions, ReportingConfigurationContextProvider } from "domain/reporting/ReportingConfigurationContext"
import { ComputationType } from "domain/types"
import { AppContextDTO } from "generated/models"
import React from "react"

export type ConversionListColumnConfiguratorAdapterProps = Readonly<{
    children: React.ReactNode
}>

export const ConversionListColumnConfiguratorAdapter = ({ children }: ConversionListColumnConfiguratorAdapterProps) => {
    const columns = ConversionListContextSelectors.useColumns()

    const reportingConfigurationContextDataSupplier = React.useCallback(
        (_: AppContextDTO) => {
            const metrics: Map<ColumnUniqueName, Metric> = new Map()
            for (const [fieldName, details] of Object.entries(columns.columnDetails)) {
                const metric: Metric = {
                    columnType: "metric",
                    displayName: details.columnConfigDTO.gridColumnProperties.columnHeader,
                    displayNameBaseMetric: details.columnConfigDTO.gridColumnProperties.columnHeader,
                    uniqueName: fieldName,
                    round: 2,
                    computationType: ComputationType.CT_NONE,
                }

                metrics.set(fieldName, metric)
            }

            const dataGroups: Map<DataGroupUniqueName, DataGroup> = new Map()
            dataGroups.set(CONVERSION_LIST_DATA_GROUP, {
                uniqueName: CONVERSION_LIST_DATA_GROUP,
                description: CONVERSION_LIST_DATA_GROUP,
                dimensions: new Set(),
                metrics: new Set(metrics.keys()),
            })

            const metricsFrontendGroups: Map<MetricsFrontendGroupUniqueName, MetricsFrontendGroup> = new Map()
            for (const [columnCategory, details] of Object.entries(columnCategories)) {
                const frontendGroupsMetrics = new Set(
                    Object.values(columns.columnDetails)
                        .filter((column) => column.columnCategory === columnCategory)
                        .map((column) => column.fieldName),
                )

                const metricsFrontendGroup = {
                    uniqueName: columnCategory,
                    displayName: frontendGroupDisplayNames[columnCategory as ColumnCategory],
                    metrics: frontendGroupsMetrics,
                    sortOrder: details.sortOrder,
                }

                metricsFrontendGroups.set(columnCategory, metricsFrontendGroup)
            }

            const dataDefinitions: DataDefinitions = {
                dimensions: new Map(),
                metrics: metrics,
                dataGroups: dataGroups,
                metricsFrontendGroups: metricsFrontendGroups,
                infoTexts: new Map(),
            }

            return Promise.resolve(dataDefinitions)
        },
        [columns],
    )

    return (
        <>
            <ReportingConfigurationContextProvider loadDataDefinitions={reportingConfigurationContextDataSupplier}>
                <ColumnConfiguratorContextProvider labels={labels}>
                    <ConversionListColumnConfiguratorContextProvider>
                        {children}
                    </ConversionListColumnConfiguratorContextProvider>
                </ColumnConfiguratorContextProvider>
            </ReportingConfigurationContextProvider>
        </>
    )
}

const frontendGroupDisplayNames: Record<ColumnCategory, string> = {
    conversion: "Conversion Properties",
    winningTouchpoint: "Touchpoint Properties",
    customParameter: "Custom Parameters",
}

const labels: ColumnConfiguratorLabels = {
    metric: "column",
    metricPlural: "columns",
}
