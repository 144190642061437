import { Alert, Box, Snackbar } from "@mui/material"
import {
    ColumnMenuPropsOverrides,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridColumnMenuProps,
    GridColumnOrderChangeParams,
    GridPinnedColumnFields,
} from "@mui/x-data-grid-pro"
import { GridProColumnMenu } from "@mui/x-data-grid-pro/components/GridProColumnMenu"
import axios, { CancelTokenSource } from "axios"
import { ColumnConfiguratorDialog } from "domain/ColumnConfigurator/components/ColumnConfiguratorDialog"
import { MetricIdentifiableSettings, SelectedState } from "domain/ColumnConfigurator/components/types"
import { ColumnConfiguratorContextSlices } from "domain/ColumnConfigurator/context/ColumnConfiguratorContextSlices"
import { WidgetStaticConfiguration } from "domain/ColumnConfigurator/types"
import { PageErrorMessage } from "domain/core/component/PageErrorMessage"
import { ACTIONS_ID_FIELD, DataGrid } from "domain/datagrid/component/DataGrid"
import DataGridService from "domain/datagrid/service/datagrid.service"
import FilterComponentUtil from "domain/filter/component/FilterComponentUtil"
import { FilterContainer } from "domain/filter/component/FilterContainer"
import DataGridSettingsToolbar from "domain/legacy/widget/DataGridSettingsToolbar"
import { ToolsContext } from "domain/legacy/widget/ToolsContext"
import WidgetHeader from "domain/legacy/widget/WidgetHeader"
import { useDataGridContext } from "domain/legacy/widget/generic/DataGridContext"
import {
    GENERIC_DATA_GRID_DATA_GROUP,
    GENERIC_DATA_GRID_FRONTEND_GROUP,
} from "domain/legacy/widget/generic/GenericDataGridColumnConfiguratorAdapter"
import GenericDataGridSearchForm from "domain/legacy/widget/generic/GenericDataGridSearchForm"
import GridUtil, {
    areRequiredFiltersSet,
    createDataSettings,
    getColumnNamesForShow,
    getVisiblePerDefaultColumnConfigs,
    getWrapperClassName,
} from "domain/legacy/widget/generic/GridUtil"
import {
    ApiErrorDTO,
    DataManagerIdentifier,
    DataSliceDTO,
    FilterState,
    GridDataRowDTO,
    OrderDirection,
} from "domain/types"
import { QueryWidgetSettingsDTO, WidgetType } from "domain/types/backend/widget.types"
import { useGridColumnStateContext } from "domain/user/settings/context/GridColumnStateContext"
import {
    ActionDTO,
    AppContextDTO,
    ColumnConfigDTO,
    ConditionClauseDTO,
    DimensionDTO,
    ExcelExportSettingsDTO,
    ExportQuerySettingsDTO,
    ExportTaskDTO,
    FilterConfigDTO,
    GridElementDTO,
    LoadResponseDTOReportingDataSetDTO,
    PageableDTO,
    PathsDTO,
    SortSettingsDTO,
    TimespanSettingsDTO,
    ToolbarConfigDTO,
} from "generated/models"
import { produce } from "immer"
import { useExportCenterContext } from "layout/MainLayout/ExportCenter/ExportCenterContext"
import React, { type JSX, useCallback, useContext, useEffect, useMemo, useState } from "react"
import Spinner from "shared/component/Spinner"
import { ToolbarComponent } from "shared/component/ToolbarComponent"
import { AdditionalFilterContext } from "shared/component/layout/context/AdditionalFilterContext"
import { TabPaneContext } from "shared/component/layout/context/TabPaneContextProvider"
import { useActionModalContext } from "shared/component/modals/action/ActionModalContext"
import { CustomColumnMenuConfigureColumnsItem } from "shared/component/mui/datagrid/CustomColumnMenuConfigureColumnsItem"
import { CustomColumnMenuResetColumnsItem } from "shared/component/mui/datagrid/CustomColumnMenuResetColumnsItem"
import { GridColumnMenuLeftPinningItem } from "shared/component/mui/datagrid/GridColumnMenuLeftPinningItem"
import { Pagination } from "shared/component/pagination/Pagination"
import ActionService from "shared/service/ActionService"
import ConditionClauseService from "shared/service/conditionClauseService"
import { log } from "shared/util/log"
import { v4 as uuid } from "uuid"

export type DataGridWidgetSettings = QueryWidgetSettingsDTO & {
    defaultOrderBy?: string[]
    defaultOrderDirection: OrderDirection
    path?: string
    showDownload: boolean
    showSettings: boolean
    showFooter: boolean
    supportsRowSelection?: boolean
    hasSearch?: boolean
    toolbar?: ToolbarConfigDTO
    actions?: ActionDTO[]
    paths?: PathsDTO
    requiredFilters?: DimensionDTO[]
    dependsOn?: string[]
    filterConfig?: FilterConfigDTO[]
    data?: DataSliceDTO
    embedded?: boolean
    gridId?: string
    dataManagerIdentifier: DataManagerIdentifier
}

export const DEFAULT_DATA_GRID_WIDGET_SETTINGS: DataGridWidgetSettings = {
    type: WidgetType.DATA_GRID_WIDGET,
    defaultOrderBy: undefined,
    defaultOrderDirection: "ASC",
    showSettings: false,
    // don't show download button because it will be shown on the tools panel level
    showDownload: false,
    showFooter: false,
    requiredFilters: [],
    dependsOn: [],
    embedded: false,
    dataManagerIdentifier: "",
}

export type GenericDataGridWidgetProps = {
    appContext: AppContextDTO
    gridSettings: GridElementDTO
}

export type DataSettingsState = {
    mainDimension: DimensionDTO
    settings: DataGridWidgetSettings
    columns: ColumnConfigDTO[]
    supportedSearchColumns?: string[]
    searchTerm?: string
    pagination: PageableDTO
    sortSettings: SortSettingsDTO
    timespanSettings?: TimespanSettingsDTO
}

export type GridState = {
    userNotAuthorized: boolean
    lastCreatedId: number
    cancelToken?: CancelTokenSource
    filterCancelToken?: CancelTokenSource
}

export type LoadingState = {
    isGridLoading: boolean
}

/**
 * Data grid widget configuring and showing a Material UI based data grid.
 */
export const GenericDataGridWidget: React.FC<GenericDataGridWidgetProps> = ({
    appContext,
    gridSettings,
}: GenericDataGridWidgetProps): JSX.Element => {
    const {
        open: openColumnConfigurator,
        dialogState: columnConfiguratorDialogState,
        getColumnConfiguratorOutputConfiguration,
        close: closeColumnConfigurator,
    } = ColumnConfiguratorContextSlices.useWidgetState()
    const exportCenterContext = useExportCenterContext()
    const toolsContext = useContext(ToolsContext)
    const tabPaneContext = useContext(TabPaneContext)
    const additionalFilterContext = useContext(AdditionalFilterContext)
    const { forceUpdate } = useActionModalContext()
    const userSettingsContext = useGridColumnStateContext()

    const { getRows, updateRows, getSelectedRowIndices, updateSelectedRowIndices, resetSelectedRowIndices } =
        useDataGridContext()

    // TODO: Why is this so messy? Rewrite this
    const generateOriginalDataSettings = (): DataSettingsState => {
        return {
            mainDimension: undefined,
            searchTerm: undefined,
            settings: DEFAULT_DATA_GRID_WIDGET_SETTINGS,
            columns: [],
            pagination: {
                page: 0,
                pageSize: 25,
            } as PageableDTO,
            sortSettings: {
                sortAscending: DEFAULT_DATA_GRID_WIDGET_SETTINGS.defaultOrderDirection === "ASC",
                sortProperties: DEFAULT_DATA_GRID_WIDGET_SETTINGS.defaultOrderBy,
            } as SortSettingsDTO,
            supportedSearchColumns: [],
            timespanSettings: toolsContext?.timespanSettings,
            ...createDataSettings(
                DEFAULT_DATA_GRID_WIDGET_SETTINGS,
                gridSettings.elementConfig,
                DEFAULT_DATA_GRID_WIDGET_SETTINGS.defaultOrderBy,
                DEFAULT_DATA_GRID_WIDGET_SETTINGS.defaultOrderDirection === "ASC",
            ),
        }
    }

    // ############################################################## States ###############################################################
    // true if the grid has been loaded with initial configs
    const [gridFilters, setGridFilters] = useState<FilterConfigDTO[]>(
        Array.from(gridSettings.elementConfig.gridConfig.filterConfigs || []),
    )
    const [gridFilterStates, setGridFilterStates] = useState<FilterState[]>(
        FilterComponentUtil.createInitialFilterStates(
            Array.from(gridSettings.elementConfig.gridConfig.filterConfigs || []),
        ),
    )
    const [currentGridEntriesCount, setCurrentGridEntriesCount] = useState<number | undefined>(undefined)
    // As default has the grid the loading state. After the data are loaded, it will be changed to false
    const [loadingState, setLoadingState] = useState({ isGridLoading: false } as LoadingState)
    const [downloadProcessing, setDownloadProcessing] = useState(false)
    const [gridState, setGridState] = useState({
        userNotAuthorized: false,
        lastCreatedId: 0,
        cancelToken: DataGridService.getCancelTokenSource(),
        filterCancelToken: DataGridService.getCancelTokenSource(),
    } as GridState)
    const [dataSettingsState, setDataSettingsState] = useState(generateOriginalDataSettings())
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("")

    const checkboxSelection =
        gridSettings.elementConfig.gridConfig.visiblePerDefaultColumns.indexOf(ACTIONS_ID_FIELD) >= 0

    const pinnedColumnIdentifiers =
        userSettingsContext.getGridColumnState(gridSettings.elementConfig.gridId)?.pinnedColumnIdentifiers ??
        getVisiblePerDefaultColumnConfigs(gridSettings.elementConfig.gridConfig)
            .filter((column, index) => {
                return (
                    column.gridColumnProperties.isFixed ||
                    (!column.gridColumnProperties.isMetric && index <= 1) ||
                    (index === 2 && !column.gridColumnProperties.isMetric && checkboxSelection)
                )
            })
            .map((column) => column.columnIdentifier)

    const pinnedColumns = useMemo(() => {
        return {
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, ...pinnedColumnIdentifiers],
            right: [ACTIONS_ID_FIELD],
        }
    }, [pinnedColumnIdentifiers])

    const { columns: defaultColumns, pagination, sortSettings } = dataSettingsState
    const selectedColumnIdentifiers =
        userSettingsContext.getGridColumnState(gridSettings.elementConfig.gridId)?.selectedColumnIdentifiers ||
        defaultColumns.map((column) => column.columnIdentifier)

    const onPinnedColumnsChange = useCallback(
        (fields: GridPinnedColumnFields) => {
            userSettingsContext.setGridColumnState(
                gridSettings.elementConfig.gridId,
                selectedColumnIdentifiers,
                fields.left.filter((field) => field !== GRID_CHECKBOX_SELECTION_COL_DEF.field),
            )
        },
        [selectedColumnIdentifiers, userSettingsContext, gridSettings.elementConfig.gridId],
    )

    // ############################################################## useEffects ###########################################################

    /**
     * Reset page to 0 when filters are changed
     */
    useEffect(() => {
        setDataSettingsState(
            produce((draft) => {
                draft.pagination.page = 0
            }),
        )
    }, [gridFilterStates, dataSettingsState.searchTerm, toolsContext?.searchTerm])

    useEffect(() => {
        setGridFilters(Array.from(gridSettings.elementConfig.gridConfig.filterConfigs || []))
        setGridFilterStates(
            FilterComponentUtil.createInitialFilterStates(
                Array.from(gridSettings.elementConfig.gridConfig.filterConfigs || []),
            ),
        )
    }, [gridSettings.elementConfig.gridConfig.filterConfigs])

    /**
     * When switching between tabs: trigger data loading for the active tab; reset inactive tab
     */
    useEffect(() => {
        if (tabPaneContext?.isTabActive) {
            if (currentGridEntriesCount) {
                // reload data grid data if the tab is active
                loadDataForDataSettingsState()
            }
        } else {
            updateLoadingState(false)
            // if tab is not active, reset all row selections (so that when switching back
            // to the now deactivated tab we don't initially see the old selection anymore)
            resetSelectedRowIndices()
        }
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabPaneContext?.isTabActive])

    useEffect(() => {
        log.debug("useEffect for componentDidMount")

        // this callback will be executed when the grid is removed; make sure to cancel any requests that are still open
        return cancelCurrentLoadingState
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Combines filters from the grid and from the tools aware panel.
     * At the moment we prefer grid filters if some are configured.
     * Otherwise, returns tools aware panel filters.
     */
    const combineGridAndToolsFilters = (): FilterState[] => {
        if (gridFilterStates?.length > 0) {
            return gridFilterStates
        } else {
            return toolsContext.filterStates
        }
    }

    /**
     * Reload data when some tools configs have been changed
     */
    useEffect(() => {
        // check whether all required filters are set
        if (
            areRequiredFiltersSet(dataSettingsState.settings.requiredFilters, combineGridAndToolsFilters(), appContext)
        ) {
            setDataSettingsState((prev) => {
                const newDataSettingsState = {
                    ...prev,
                    searchTerm: toolsContext?.searchTerm,
                    timespanSettings: toolsContext?.timespanSettings,
                }

                loadDataForNewDataSettingsState(newDataSettingsState)
                return newDataSettingsState
            })
        }
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // TODO: ESLint does not like complex dependency expressions. Can we simplify this?
        // eslint-disable-next-line react-hooks/exhaustive-deps
        JSON.stringify(toolsContext?.filters),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        JSON.stringify(toolsContext?.timespanSettings),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        JSON.stringify(toolsContext?.searchTerm),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        JSON.stringify(combineGridAndToolsFilters()),
        // reload grid data when action modal changed some date
        forceUpdate,
    ])

    useEffect(() => {
        log.debug("useEffect for downloadProcessing")

        if (downloadProcessing === true || toolsContext?.downloadProcessing === true) {
            const combinedFilter = ConditionClauseService.getCombinedFilterClause(
                combineGridAndToolsFilters(),
                additionalFilterContext.additionalFilters,
                GridUtil.getSearchTerm(
                    dataSettingsState.columns,
                    dataSettingsState.supportedSearchColumns,
                    dataSettingsState.searchTerm,
                ),
            )

            downloadExportData(dataSettingsState, combinedFilter).finally(() => {
                setDownloadProcessing(false)
                toolsContext?.updateDownloadProcessing(false)
            })
        }
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [downloadProcessing, toolsContext?.downloadProcessing])

    useEffect(() => {
        if (toolsContext?.actionState) {
            onClickOnContextMenuAction(toolsContext.actionState.action.identifier, getSelectedRowIndices())
        }
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toolsContext?.actionState])

    // #####################################################################################################################################

    const columnConfiguratorDialogOnApply = useCallback(() => {
        const output = getColumnConfiguratorOutputConfiguration()
        const selectedMetricIdentifiers = output.selectedMetrics.map((column) => column.identifier)
        userSettingsContext.setGridColumnState(
            gridSettings.elementConfig.gridId,
            [...selectedMetricIdentifiers, ACTIONS_ID_FIELD],
            pinnedColumnIdentifiers.filter((column) => selectedMetricIdentifiers.indexOf(column) >= 0),
        )

        closeColumnConfigurator()
    }, [
        columnConfiguratorDialogState,
        getColumnConfiguratorOutputConfiguration,
        closeColumnConfigurator,
        gridSettings.elementConfig.gridId,
        userSettingsContext,
        pinnedColumnIdentifiers,
    ])

    const downloadExportData = async (
        dataSettingsState: DataSettingsState,
        filter: ConditionClauseDTO,
    ): Promise<ExportTaskDTO> => {
        const { settings, columns, sortSettings, timespanSettings } = dataSettingsState

        const columnNames = getColumnNamesForShow(columns)

        const querySettings: ExportQuerySettingsDTO = {
            dataManagerIdentifier: settings.dataManagerIdentifier,
            exportSettings: { type: "ExcelExportSettingsDTO", withHeaders: true } as ExcelExportSettingsDTO,
            querySettings: {
                appContext: appContext,
                columnNames: columnNames,
                filter: filter,
                sortSettings: sortSettings,
                timespanSettings: timespanSettings,
                queryIdentifier: { value: uuid() },
            },
        }

        return await exportCenterContext.createExportTask(settings.paths.serviceContextPath, querySettings)
    }

    const onGridFilterChange = (filterIdentifier: string, value: string | number | string[] | number[]) => {
        const updatedFilterStates = gridFilterStates.map((filterState) => {
            if (FilterComponentUtil.getFilterFormValueColumn(filterState) === filterIdentifier) {
                filterState.value = value
            }

            return filterState
        })

        setGridFilterStates(updatedFilterStates)
        updateDependentFilters(filterIdentifier, value)
    }

    /**
     * Finds the dependent filters and sets additionalFilters to them so that they react to the change
     *
     * @param filterIdentifier
     * @param value
     */
    const updateDependentFilters = (filterIdentifier: string, value: string | number | string[] | number[]) => {
        const changedFilterConfig: FilterConfigDTO = gridFilters.find((filter) => {
            return FilterComponentUtil.getFilterFormValueColumn(filter) === filterIdentifier
        })
        const changedFilterState: FilterState = {
            selectFormElement: changedFilterConfig.selectFormElement,
            value: value,
        }

        const conditionClauseDTO = ConditionClauseService.buildFilterQuery([changedFilterState])

        let dependentFilterFound = false
        const updateFilters = gridFilters.map((dependentFilter) => {
            const filterDependsOnTheChangedFilter = FilterComponentUtil.getFilterDependsOn(dependentFilter)?.some(
                (entry) => entry.filterIdentifier === filterIdentifier,
            )

            if (filterDependsOnTheChangedFilter) {
                dependentFilterFound = true
                dependentFilter.selectFormElement.additionalFilters = conditionClauseDTO
            }

            return dependentFilter
        })

        if (dependentFilterFound) {
            setGridFilters(updateFilters)
        }
    }

    const scrollToTop = () => {
        const elements = document.querySelectorAll(
            `.${getWrapperClassName(dataSettingsState.mainDimension)} .spinner-container`,
        )
        elements.forEach((element) => (element.scrollTop = 0))
    }

    /**
     * Loads asynchronously grid data and updates grid states in the "then" callback. On start the loading state will be enabled,
     * after the request is done the loading state will be disabled.
     *
     * @param dataSettingsState
     * @param filters
     * @param additionalFilters
     */
    const loadDataAsync = (
        dataSettingsState: DataSettingsState,
        filters: FilterState[],
        additionalFilters?: ConditionClauseDTO,
    ): void => {
        enableLoadingState()

        // cancel possible running requests, if they run with this cancel token
        const newCancelToken = cancelRunningRequestsAndGenerateNewToken()

        // combine filters, additionalFilters and the search term to one ConditionClauseDTO
        const combinedFilter = ConditionClauseService.getCombinedFilterClause(
            filters,
            additionalFilters,
            GridUtil.getSearchTerm(
                dataSettingsState.columns,
                dataSettingsState.supportedSearchColumns,
                dataSettingsState.searchTerm,
            ),
        )

        DataGridService.loadData(dataSettingsState, combinedFilter, newCancelToken)
            .then((loadResponseDTO: LoadResponseDTOReportingDataSetDTO | ApiErrorDTO) => {
                const isError =
                    !!loadResponseDTO["errors"] ||
                    // loadResponseDTO should never have a status property but is has sometimes
                    // we need to find out where that DTO format comes from: UI-1492
                    // @ts-expect-error see above
                    loadResponseDTO.httpStatus == "INTERNAL_SERVER_ERROR" ||
                    // @ts-expect-error see above
                    (loadResponseDTO.status && loadResponseDTO.status == 500)

                if (isError) {
                    loadDataErrorCallback(loadResponseDTO as ApiErrorDTO)
                } else {
                    loadDataSuccessCallback(
                        loadResponseDTO as LoadResponseDTOReportingDataSetDTO,
                        dataSettingsState,
                        filters,
                        additionalFilters,
                    )
                }
            })
            .finally(disableLoadingState)
    }

    /**
     * Cancels possible running requests, if they run with this cancel token,
     * and generates a new one.
     */
    const cancelRunningRequestsAndGenerateNewToken = (): CancelTokenSource => {
        gridState.cancelToken?.cancel()

        const newCancelToken = DataGridService.getCancelTokenSource()
        setGridState((prev) => {
            return { ...prev, cancelToken: newCancelToken }
        })

        return newCancelToken
    }

    /**
     * Callback, that will be executed if the grid data (loaddata requests) are loaded and there are no errors
     *
     * @param loadResponseDTO
     * @param dataSettingsState
     * @param filters
     * @param additionalFilters
     */
    const loadDataSuccessCallback = (
        loadResponseDTO: LoadResponseDTOReportingDataSetDTO,
        dataSettingsState: DataSettingsState,
        filters: FilterState[],
        additionalFilters?: ConditionClauseDTO,
    ) => {
        updateSelectedRowIndices([])

        const rows = ActionService.enrichRowsWithActions(
            loadResponseDTO.dataSet,
            dataSettingsState,
            dataSettingsState.settings.actions,
            null,
            () => loadDataAsync(dataSettingsState, filters, additionalFilters),
            additionalFilters,
        )

        updateRows(rows)

        if (
            loadResponseDTO.paginationInfo &&
            currentGridEntriesCount !== loadResponseDTO.paginationInfo.totalEntities
        ) {
            setCurrentGridEntriesCount(loadResponseDTO.paginationInfo.totalEntities)
        }

        scrollToTop()
    }

    /**
     * Callback, that will be executed if the loaddata request has error
     */
    const loadDataErrorCallback = (e: ApiErrorDTO) => {
        if (axios.isCancel(e) || e.message === "Cancel" || e.message.includes("CanceledError")) {
            log.debug("Request canceled")
        } else {
            setSnackbarMessage("We're sorry, an unexpected error occurred while loading your data.")
            setSnackbarOpen(true)
            log.error("GenericDataGridWidget - data rows could not be loaded: ", e.message)

            updateSelectedRowIndices([])
            updateRows(undefined)
            setCurrentGridEntriesCount(0)
        }
    }

    /**
     * Sets loading state to true
     */
    const enableLoadingState = () => updateLoadingState(true)

    /**
     * Sets loading state to false
     */
    const disableLoadingState = () => updateLoadingState(false)

    /**
     * Saves loadingState
     *
     * @param isGridLoading
     */
    const updateLoadingState = (isGridLoading: boolean) => {
        setLoadingState({ isGridLoading })
    }

    const loadDataForDataSettingsState = () => loadDataForNewDataSettingsState(dataSettingsState)
    const loadDataForNewDataSettingsState = (dataSettingsState: DataSettingsState) =>
        loadDataAsync(dataSettingsState, combineGridAndToolsFilters(), additionalFilterContext.additionalFilters)

    /**
     * Cancels all requests for the current loading state
     */
    const cancelCurrentLoadingState = () => {
        const { cancelToken, filterCancelToken } = gridState

        cancelToken?.cancel()
        filterCancelToken?.cancel()
        setGridState((prev) => {
            return {
                ...prev,
                cancelToken: undefined,
                filterCancelToken: undefined,
            }
        })
    }

    const onSort = useCallback(
        (orderBy: string, sortAscending: boolean) => {
            const newDataSettingsState = {
                ...dataSettingsState,
                pagination: {
                    ...dataSettingsState.pagination,
                } as PageableDTO,
                sortSettings: {
                    sortProperties: [orderBy],
                    sortAscending: sortAscending,
                } as SortSettingsDTO,
            }

            setDataSettingsState(newDataSettingsState)
            loadDataForNewDataSettingsState(newDataSettingsState)
        },
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dataSettingsState, getRows()],
    )

    /**
     * TODO: add generic download handling
     */
    const onDownload = useCallback(() => {
        setDownloadProcessing(true)
    }, [])

    const onPageChange = (newPage: number, newPageSize: number) => {
        const newDataSettingsState = produce(dataSettingsState, (draft) => {
            draft.pagination.page = newPage
            draft.pagination.pageSize = newPageSize
        })

        setDataSettingsState(newDataSettingsState)
        loadDataForNewDataSettingsState(newDataSettingsState)
    }

    const showResults = areRequiredFiltersSet(
        dataSettingsState.settings.requiredFilters,
        combineGridAndToolsFilters(),
        appContext,
    )
    const { userNotAuthorized } = gridState

    const columns = selectedColumnIdentifiers
        .map((columnIdentifier) => defaultColumns.find((column) => column.columnIdentifier === columnIdentifier))
        .filter((column) => column !== undefined) as ColumnConfigDTO[]

    const onColumnOrderChange = useCallback(
        (params: GridColumnOrderChangeParams) => {
            userSettingsContext.setGridColumnState(
                gridSettings.elementConfig.gridId,
                produce(selectedColumnIdentifiers, (draft) => {
                    draft.splice(params.oldIndex - 1, 1)
                    draft.splice(params.targetIndex - 1, 0, params.column.field)
                }),
                pinnedColumnIdentifiers,
            )
        },
        [selectedColumnIdentifiers, gridSettings.elementConfig.gridId, userSettingsContext],
    )

    const {
        title,
        toolbar,
        actions,
        // don't show download button because it will be shown on the tools panel level
        showDownload = false,
        showSettings = false,
        supportsRowSelection = actions?.length > 0,
    } = dataSettingsState.settings

    // there is no need to hide the grid content as long as we know which columns to display; we want to show header and pagination as quickly
    // as possible, even if rows are still loading
    const hideContent = !columns
    const hidePagination = !columns || !getRows()

    /**
     * get data rows from grid page for indices
     *
     * @param indices
     */
    const getRowsForIndices = (indices: number[]): GridDataRowDTO[] => {
        const result = getRows()?.rows?.filter((row, index) => indices.includes(index))

        return result || []
    }

    /**
     * Callback, that will be invoked on clicking to some context menu action
     */
    const onClickOnContextMenuAction = useCallback(
        (
            actionIdentifier: "CREATE" | "EDIT" | "DELETE" | "DEACTIVATE" | "ACTIVATE" | "DOWNLOAD",
            invokeRowIndices: number[],
        ) => {
            const action: ActionDTO = actions.find((action) => action.identifier === actionIdentifier)

            ActionService.invokeAction(
                action,
                dataSettingsState.settings.actions,
                dataSettingsState.settings.paths,
                dataSettingsState.mainDimension,
                getRowsForIndices(invokeRowIndices),
                null,
                () => loadDataForNewDataSettingsState(dataSettingsState),
                ConditionClauseService.filterConditionClauseBySupportedFilters(
                    additionalFilterContext.additionalFilters,
                    Array.from(action.supportedAdditionalFilters || []),
                ),
            )
        },
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dataSettingsState, getRows(), actions],
    )

    const toolbarOnInvoke = useCallback(
        (action: ActionDTO) => {
            onClickOnContextMenuAction(action.identifier, getSelectedRowIndices())
        },
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dataSettingsState, getRows(), actions, getSelectedRowIndices()],
    )

    const eligibleSelectedRowsWithMemo = useMemo(() => {
        return GridUtil.eligibleSelectedRows(getSelectedRowIndices(), getRows())
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSettingsState.columns, getSelectedRowIndices() /*, getRows()*/])

    const onOpenCoCoButtonClick = useCallback(() => {
        const selectedMetrics: MetricIdentifiableSettings[] = selectedColumnIdentifiers
            .map((columnIdentifier) =>
                dataSettingsState.columns.find((column) => column.columnIdentifier === columnIdentifier),
            )
            .filter((column) => column !== undefined)
            // We don't want to show the "actions" column in CoCo
            // However, note that we need to make sure we don't lose it after saving the columns from CoCo
            .filter((column) => column.columnIdentifier !== ACTIONS_ID_FIELD)
            .map((column) => ({
                identifier: column.columnIdentifier,
                showBars: false,
            }))

        const selectedState: SelectedState = new SelectedState({
            selectedMetrics,
            selectedDimensions: [],
            widgetId: undefined,
            leftPinnedMetrics: pinnedColumnIdentifiers,
        })

        const widgetStaticConfiguration = new WidgetStaticConfiguration({
            supportedColumnSettings: [],
            maxDimensions: 0,
            supportedDataGroups: [GENERIC_DATA_GRID_DATA_GROUP],
            initiallyExpandedGroups: new Set([GENERIC_DATA_GRID_FRONTEND_GROUP]),
        })

        openColumnConfigurator(selectedState, widgetStaticConfiguration)
    }, [dataSettingsState.columns, selectedColumnIdentifiers, pinnedColumnIdentifiers, openColumnConfigurator])

    const onResetCoCoButtonClock = useCallback(() => {
        setDataSettingsState(
            produce(dataSettingsState, (draft) => {
                draft.columns = generateOriginalDataSettings().columns
            }),
        )
        userSettingsContext.deleteGridColumnState(gridSettings.elementConfig.gridId)
    }, [
        setDataSettingsState,
        generateOriginalDataSettings,
        userSettingsContext,
        gridSettings.elementConfig.gridId,
        dataSettingsState,
    ])

    const handleSnackbarClose = () => {
        setSnackbarOpen(false)
    }

    return (
        <div className={"datagrid-widget"}>
            <div className={"content-header"}>
                <div className="widget-header">
                    <WidgetHeader
                        title={title}
                        showSettings={showSettings}
                        showDownload={false}
                        onDownload={undefined}
                        renderSettings={() => <DataGridSettingsToolbar />}
                    />
                </div>
            </div>
            <div className={"content-body"}>
                <div className={"datagrid-controls panel-controls"}>
                    <div className="datagrid-filters-and-search panel-form-elements">
                        <FilterContainer
                            filters={Array.from(gridSettings.elementConfig.gridConfig.filterConfigs || [])}
                            filterOnChange={onGridFilterChange}
                        />
                        {dataSettingsState.settings.hasSearch && <GenericDataGridSearchForm />}
                        {(toolbar || showDownload) && (
                            <>
                                <ToolbarComponent
                                    config={toolbar}
                                    actions={actions}
                                    onInvoke={toolbarOnInvoke}
                                    showDownload={showDownload}
                                    disableButtons={hideContent || !showResults}
                                    onDownload={onDownload}
                                    downloadProcessing={downloadProcessing}
                                    selectedRows={eligibleSelectedRowsWithMemo}
                                />
                                <ColumnConfiguratorDialog onApply={columnConfiguratorDialogOnApply} />
                            </>
                        )}
                    </div>
                </div>

                {!showResults && !userNotAuthorized && (
                    <div>
                        <Alert severity="info" sx={{ alignItems: "flex-start" }}>
                            <div>
                                Not all required filters have been set
                                <div>
                                    Missing selection for:{" "}
                                    <strong>
                                        {" "}
                                        {dataSettingsState.settings.requiredFilters
                                            .filter(
                                                (filterDimension) =>
                                                    !areRequiredFiltersSet(
                                                        [filterDimension],
                                                        toolsContext.filterStates,
                                                        appContext,
                                                    ),
                                            )
                                            .map((filterDimension) => filterDimension.displayName)
                                            .join(", ")}{" "}
                                    </strong>
                                </div>
                            </div>
                        </Alert>
                    </div>
                )}

                {userNotAuthorized && (
                    <PageErrorMessage
                        type={"info"}
                        title={"We're sorry, but we were unable to load the requested data"}
                    >
                        Your user account might lack permissions to access this area.{" "}
                        <p>
                            If you have any questions about this, please reach out to our support team at{" "}
                            <a href="mailto:support@exactag.com">support@exactag.com</a>.
                        </p>
                    </PageErrorMessage>
                )}

                <Box className={`datagrid-table-wrapper ${getWrapperClassName(dataSettingsState.mainDimension)}`}>
                    <Spinner spinning={loadingState.isGridLoading || !tabPaneContext?.isTabActive}>
                        {showResults && !hideContent && (
                            <DataGrid
                                columns={columns}
                                sortAscending={sortSettings.sortAscending ?? true}
                                sortProperties={sortSettings.sortProperties}
                                onSort={onSort}
                                onClickOnContextMenuAction={onClickOnContextMenuAction}
                                onColumnOrderChange={onColumnOrderChange}
                                customColumnMenu={{
                                    slot: CustomColumnMenu,
                                    slotProps: {
                                        openColumnConfigurator: onOpenCoCoButtonClick,
                                        resetGridColumnState: onResetCoCoButtonClock,
                                    },
                                }}
                                pinnedColumns={pinnedColumns}
                                onPinnedColumnsChange={onPinnedColumnsChange}
                            />
                        )}
                    </Spinner>
                </Box>

                <div className={"main-pagination-wrapper"}>
                    {showResults && !hidePagination && (
                        <div className={"main-pagination"}>
                            <Pagination
                                page={pagination.page}
                                pageSize={pagination.pageSize}
                                totalEntities={currentGridEntriesCount}
                                entitiesOnPage={getRows()?.rows?.length ?? 0}
                                onPageChange={onPageChange}
                            />
                        </div>
                    )}
                </div>
            </div>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert onClose={handleSnackbarClose} severity="error">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    )
}

declare module "@mui/x-data-grid-pro" {
    interface ColumnMenuPropsOverrides {
        openColumnConfigurator?: () => void
        resetGridColumnState?: () => void
    }
}

const CustomColumnMenu = ({
    openColumnConfigurator,
    resetGridColumnState,
    ...props
}: GridColumnMenuProps & ColumnMenuPropsOverrides) => {
    return (
        <GridProColumnMenu
            {...props}
            slots={{
                columnMenuPinningItem: GridColumnMenuLeftPinningItem,
                columnMenuConfigureColumnsItem: openColumnConfigurator
                    ? CustomColumnMenuConfigureColumnsItem
                    : undefined,
                columnMenuResetColumnsItem: openColumnConfigurator ? CustomColumnMenuResetColumnsItem : undefined,
            }}
            slotProps={{
                columnMenuConfigureColumnsItem: {
                    displayOrder: 30,
                    openColumnConfigurator: openColumnConfigurator,
                    hideMenu: props.hideMenu,
                },
                columnMenuResetColumnsItem: {
                    displayOrder: 31,
                    resetGridColumnState: resetGridColumnState,
                    hideMenu: props.hideMenu,
                },
            }}
        />
    )
}

export default GenericDataGridWidget
