export enum ExportTaskErrorType {
    ERROR = "ERROR",
    TIMEOUT = "TIMEOUT",
    TOO_MANY_ROWS_EXCEL = "TOO_MANY_ROWS_EXCEL",
    TOO_MANY_ROWS_CSV = "TOO_MANY_ROWS_CSV",
}

export enum ExportTaskFileExtension {
    CSV = "CSV",
    XLSX = "XLSX",
}

export enum ExportTaskState {
    PENDING = "PENDING",
    CANCELLED = "CANCELLED",
    IN_PROGRESS = "IN_PROGRESS",
    COMPLETED = "COMPLETED",
    ERROR = "ERROR",
}
