import { ListMetricsTile } from "domain/ColumnConfigurator/components/ColumnSelectionPanel/MetricSelectionPanel/MetricSelectionAccordion/ListMetricsTile"
import { MatrixMetricsTile } from "domain/ColumnConfigurator/components/ColumnSelectionPanel/MetricSelectionPanel/MetricSelectionAccordion/matrix/MatrixMetricsTile"
import { Tile, TileType } from "domain/ColumnConfigurator/components/ColumnSelectionPanel/MetricSelectionPanel/types"
import { ColumnUniqueName } from "domain/ColumnConfigurator/types"
import React, { type JSX } from "react"
import { assertExhaustive } from "shared/util/TypeUtil"
import { IdentityWrapper, Wrapper } from "shared/util/Wrapper"

export interface MetricsTileProps {
    tile: Tile
    metrics: ReadonlySet<ColumnUniqueName>
    onCheckboxChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
    hasSearchQuery: boolean
    Wrapper?: Wrapper
}

export const MetricsTile = ({
    tile,
    metrics,
    onCheckboxChangeHandler,
    hasSearchQuery,
    Wrapper = IdentityWrapper,
}: MetricsTileProps): JSX.Element => {
    const type = tile.type

    switch (type) {
        case TileType.MATRIX:
            return (
                <MatrixMetricsTile
                    name={tile.uniqueName}
                    metrics={metrics}
                    onCheckboxChangeHandler={onCheckboxChangeHandler}
                    hasSearchQuery={hasSearchQuery}
                    Wrapper={Wrapper}
                />
            )
        case TileType.REGULAR:
            return (
                <ListMetricsTile
                    name={tile.uniqueName}
                    frontendGroupMetrics={metrics}
                    onCheckboxChangeHandler={onCheckboxChangeHandler}
                    Wrapper={Wrapper}
                />
            )
        default:
            return assertExhaustive(type)
    }
}
