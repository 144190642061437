import CloseIcon from "@mui/icons-material/Close"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Drawer, MenuItem, styled } from "@mui/material"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Box from "@mui/material/Box"
import Collapse from "@mui/material/Collapse"
import FormControl from "@mui/material/FormControl"
import Grid from "@mui/material/Grid2"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import InputLabel from "@mui/material/InputLabel"
import OutlinedInput from "@mui/material/OutlinedInput"
import Paper from "@mui/material/Paper"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import { Container } from "@mui/system"
import { PixelTransactionData } from "domain/ConversionList/components/ShowPixels/PixelDrawerOrMessage"
import { Spinner } from "domain/ConversionList/components/ShowPixels/Spinner"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { GridDataRowDTO } from "domain/types"
import React, { ChangeEvent, useCallback } from "react"
import { TransitionGroup } from "react-transition-group"

// Constants for drawer dimensions
const MIN_DRAWER_WIDTH = 750

// Styled component for the drawer content
const DrawerContent = styled(Box)(({ theme: _ }) => ({
    height: "100%",
    backgroundColor: "#FAFBFF", // Light blue background
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
        display: "none",
    },
    msOverflowStyle: "none",
}))

// Styled component for the drawer header
const DrawerHeader = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 2),
    justifyContent: "space-between",
    minHeight: 64,
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: "#FAFBFF", // Match the background
}))

type Pixel = {
    idPixel: number
    pixelName: string
    isGlobal: boolean
    publisherName: string
    blockedByRule: number
    ruleHistory: string
    pixelCode: string
}

const DRAWER_SX = {
    width: MIN_DRAWER_WIDTH,
    flexShrink: 0,
    "& .MuiDrawer-paper": {
        width: MIN_DRAWER_WIDTH,
        boxSizing: "border-box",
        top: 0,
        height: "100%",
        border: "none",
        display: "flex",
        flexDirection: "column",
        overflowX: "visible",
    },
}

type PixelType = "all" | "global" | "publisher"

export const PixelDrawer = ({
    shown,
    onCloseDialog,
    pixelTransactionData,
}: {
    shown: boolean
    onCloseDialog: () => void
    pixelTransactionData: PixelTransactionData
    anchorEl?: HTMLElement | null // Optional as it's not used in the Drawer implementation
}) => {
    const { isPending, isPlaceholderData, error, data } = ConversionListContextSelectors.useLoadPixelQuery({
        ...pixelTransactionData,
        enabled: shown && pixelTransactionData.transactionUid !== "",
    })

    const isLoading = isPending || isPlaceholderData

    const pixels = data?.dataSet?.rows?.map((item) => convertToPixel(item)) ?? []

    function convertToPixel(item: GridDataRowDTO): Pixel {
        return {
            idPixel: item["id_pixel"]?.value as number,
            blockedByRule: item["blocked_by_rule"]?.value as number,
            isGlobal: item["is_global"]?.value as boolean,
            pixelCode: item["pixel_code"]?.value as string,
            pixelName: item["pixel_name"]?.value as string,
            publisherName: item["publisher_name"]?.value as string,
            ruleHistory: item["rule_history"]?.value as string,
        }
    }

    const [pixelType, setPixelType] = React.useState<PixelType>("all")
    const [search, setSearch] = React.useState("")

    const handleFilterChange = useCallback(
        (event: SelectChangeEvent) => {
            setPixelType(event.target.value as PixelType)
        },
        [setPixelType],
    )

    const handleSearchChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setSearch(event.target.value as string)
        },
        [setSearch],
    )

    const resetSearch = useCallback(() => {
        setSearch("")
    }, [setSearch])

    const filteredPixels = pixels.filter(
        (pixel) =>
            (pixelType === "all" ||
                (pixelType === "global" && pixel.isGlobal) ||
                (pixelType === "publisher" && !pixel.isGlobal)) &&
            (search === "" ||
                pixel.pixelName.toLowerCase().includes(search.toLowerCase()) ||
                pixel.pixelCode.toLowerCase().includes(search.toLowerCase()) ||
                pixel.ruleHistory.toLowerCase().includes(search.toLowerCase())),
    )

    return (
        <Drawer
            sx={DRAWER_SX}
            variant="persistent"
            anchor="right"
            open={shown}
            slotProps={{
                paper: {
                    elevation: 16,
                },
            }}
            onClose={onCloseDialog}
        >
            <DrawerContent className="component-ShowPixelsDialog">
                <DrawerHeader>
                    <Typography variant="h6">Show pixels</Typography>
                    <IconButton onClick={onCloseDialog}>
                        <CloseIcon />
                    </IconButton>
                </DrawerHeader>

                <Box>
                    <PixelDrawerControlPanel
                        handleFilterChange={handleFilterChange}
                        handleSearchChange={handleSearchChange}
                        resetSearch={resetSearch}
                        search={search}
                        pixelType={pixelType}
                    />
                    {error ? <div>"An error has occurred: " + error.message</div> : null}

                    {!error && (
                        <Box
                            className={"pixel-content"}
                            position="relative"
                            width="100%"
                            minHeight="calc(100vh - 145px)"
                        >
                            <PixelDrawerBody pixels={pixels} filteredPixels={filteredPixels} />
                            {isLoading && <Spinner />}
                        </Box>
                    )}
                </Box>
            </DrawerContent>
        </Drawer>
    )
}

type PixelDrawerBodyProps = {
    pixels: Pixel[]
    filteredPixels: Pixel[]
}

const PixelDrawerBody = ({ pixels, filteredPixels }: PixelDrawerBodyProps) => {
    return (
        <Container
            sx={{
                overflowY: "auto",
                pb: 1, // the bottom needs some padding for the bottom border and shadow not to be cut off
                pl: "3px", // visually, this padding gets eaten by the box shadow, so the box still looks aligned
                pr: 1, // to the right we need more space for when the scrollbar is visible
            }}
            disableGutters={true}
        >
            {pixels.length === 0 ? (
                <Container fixed sx={{ textAlign: "center", padding: 3 }}>
                    No Pixels have been delivered for this conversion
                </Container>
            ) : (
                <TransitionGroup>
                    {filteredPixels.map((pixel) => (
                        <Collapse key={pixel.idPixel}>
                            <Pixel pixel={pixel} />
                        </Collapse>
                    ))}
                    {filteredPixels.length === 0 ? (
                        <Collapse key={"noSearchResult"}>
                            <Container fixed sx={{ textAlign: "center", padding: 3 }}>
                                No pixels match your search criteria
                            </Container>
                        </Collapse>
                    ) : null}
                </TransitionGroup>
            )}
        </Container>
    )
}

type PixelDrawerControlPanelProps = {
    handleFilterChange: (event: SelectChangeEvent) => void
    handleSearchChange: (event: ChangeEvent<HTMLInputElement>) => void
    resetSearch: () => void
    search: string
    pixelType: PixelType
}

const PixelDrawerControlPanel = ({
    handleFilterChange,
    handleSearchChange,
    resetSearch,
    search,
    pixelType,
}: PixelDrawerControlPanelProps) => {
    return (
        <Box sx={{ display: "flex", gap: 2, marginLeft: 2, marginTop: 2 }}>
            <FormControl sx={{ minWidth: 180 }} variant="outlined" size="small">
                <InputLabel htmlFor="filter-type-search">Search</InputLabel>
                <OutlinedInput
                    size="small"
                    type="text"
                    id="filter-type-search"
                    value={search}
                    onChange={handleSearchChange}
                    endAdornment={
                        search ? (
                            <InputAdornment position="end">
                                <IconButton aria-label="reset search filter" onClick={resetSearch} edge="end">
                                    <CloseIcon />
                                </IconButton>
                            </InputAdornment>
                        ) : (
                            <InputAdornment position="end">
                                <IconButton aria-label="reset search filter" edge="end" disabled>
                                    <CloseIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }
                    label="Search"
                />
            </FormControl>
            <FormControl sx={{ minWidth: 150 }} size="small">
                <InputLabel id="filter-type-label">Pixel type</InputLabel>
                <Select
                    size="small"
                    labelId="filter-type-label"
                    value={pixelType}
                    label="Pixel type"
                    onChange={handleFilterChange}
                >
                    <MenuItem value={"all" satisfies PixelType}>All</MenuItem>
                    <MenuItem value={"publisher" satisfies PixelType}>Publisher Pixel</MenuItem>
                    <MenuItem value={"global" satisfies PixelType}>Global Pixel</MenuItem>
                </Select>
            </FormControl>
        </Box>
    )
}

const Pixel = ({ pixel }: { pixel: Pixel }) => {
    // Note: This has to be a Box at top level, with a paper the collapse outer layer does not work,
    //       nothing would be shown
    return (
        <Box>
            <Paper
                elevation={0}
                sx={{
                    padding: 2,
                    marginLeft: 2,
                    marginRight: 1,
                    marginTop: 2, // 16px
                    backgroundColor: "#FFFFFF",
                    boxShadow: "0px 2px 4px rgba(57, 75, 121, 0.08)", // Subtle shadow
                    borderRadius: "6px",
                }}
            >
                <Grid container spacing={2}>
                    <Grid size={{ xs: 12, md: 2 }}>
                        <Typography fontWeight={600}>Pixel type:</Typography>
                    </Grid>
                    <Grid size={{ xs: 12, md: 10 }}>{pixel.isGlobal ? "Global" : "Publisher"}</Grid>
                    <Grid size={{ xs: 12, md: 2 }}>
                        <Typography fontWeight={600}>Pixel name:</Typography>
                    </Grid>
                    <Grid size={{ xs: 12, md: 10 }}>{pixel.pixelName}</Grid>
                    <Grid size={{ xs: 12, md: 2 }}>
                        <Typography fontWeight={600}>Pixel code:</Typography>
                    </Grid>
                    <Grid size={{ xs: 12, md: 10 }}>
                        <TextField
                            fullWidth
                            multiline
                            rows={4}
                            defaultValue={pixel.pixelCode}
                            inputProps={{
                                readOnly: true,
                                style: {
                                    fontFamily: "'Roboto Mono', monospace",
                                    fontSize: "11px",
                                    padding: "2px 5px",
                                },
                            }}
                            sx={{
                                "& .MuiInputBase-root": {
                                    backgroundColor: "#FAFBFF",
                                },
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ marginTop: 2 }}>
                    <Grid size={12}>
                        <Accordion
                            elevation={0}
                            sx={{
                                padding: 0,
                                "& .MuiAccordionSummary-expandIconWrapper": {
                                    transform: "rotate(-90deg)",
                                },
                                "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                                    transform: "rotate(0deg)",
                                },
                                "& .MuiAccordionSummary-content": {
                                    margin: 0,
                                    padding: 0,
                                },
                                "& .MuiAccordionSummary-root": {
                                    padding: 0,
                                    minHeight: "unset",
                                },
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={"panel" + pixel.idPixel + "-content"}
                                id={"panel" + pixel.idPixel + "-header"}
                            >
                                <Typography fontWeight={600}>Pixel rule checks:</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    defaultValue={pixel.ruleHistory}
                                    inputProps={{
                                        readOnly: true,
                                        style: {
                                            fontFamily: "'Roboto Mono', monospace",
                                            fontSize: "11px",
                                            padding: "2px 5px",
                                        },
                                    }}
                                    sx={{
                                        "& .MuiInputBase-root": {
                                            backgroundColor: "#FAFBFF",
                                        },
                                    }}
                                />
                            </AccordionDetails>
                        </Accordion>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    )
}
