import { FormFieldConfigDTO } from "generated/models"
import { produce } from "immer"
import {
    FormElementValidatorType,
    MaxLengthFormElementValidationDTO,
    MaxValueFormElementValidationDTO,
    MinLengthFormElementValidationDTO,
    MinValueFormElementValidationDTO,
    SameValueFormElementValidationDTO,
} from "shared/util/validation"
import * as yup from "yup"
import { NumberSchema, Schema, StringSchema } from "yup"

type ValidationRule = {
    enabled: boolean
    schema: Schema
}

type ValidationRules = {
    [dimensionIdentifier: string]: ValidationRule
}

export class ValidationState {
    private readonly validationRules: ValidationRules
    private yupSchema: Schema | undefined

    private constructor(validationRules: ValidationRules) {
        this.validationRules = validationRules
    }

    static parseValidationSchema(formFieldConfigs: FormFieldConfigDTO[]): ValidationState {
        const validationRulesArr = formFieldConfigs.map((config) => {
            return {
                [config.dimensionIdentifier]: {
                    enabled: true,
                    schema: ValidationState.parseFormFieldValidations(config),
                },
            }
        })

        const validationRules: ValidationRules = Object.assign({}, ...validationRulesArr)
        return new ValidationState(validationRules)
    }

    enableRule(dimensionIdentifier: string): ValidationState {
        return this.setRuleStatus(dimensionIdentifier, true)
    }

    disableRule(dimensionIdentifier: string): ValidationState {
        return this.setRuleStatus(dimensionIdentifier, false)
    }

    toYupSchema() {
        if (this.yupSchema) {
            return this.yupSchema
        }

        const schema = {}

        for (const [dimensionIdentifier, rule] of Object.entries(this.validationRules)) {
            if (rule.enabled) {
                const [dimension, nameOrValue] = dimensionIdentifier.split(".")

                schema[dimension] = yup.object({ [nameOrValue]: rule.schema })
            }
        }

        this.yupSchema = yup.object().shape(schema)
        return this.yupSchema
    }

    private setRuleStatus(dimensionIdentifier: string, status: boolean): ValidationState {
        if (
            !Object.prototype.hasOwnProperty.call(this.validationRules, dimensionIdentifier) ||
            this.validationRules[dimensionIdentifier].enabled == status
        ) {
            return this
        }

        const updatedValidationRules = produce(this.validationRules, (draft) => {
            draft[dimensionIdentifier].enabled = status
        })

        return new ValidationState(updatedValidationRules)
    }

    private static parseFormFieldValidations = (formFieldConfig: FormFieldConfigDTO) => {
        let yupChain: Schema

        if (
            Array.from(formFieldConfig.validation || [])?.some(
                (validation) => validation.type === FormElementValidatorType.IS_INT_FORM_ELEMENT_VALIDATION_DTO,
            )
        ) {
            yupChain = yup.number().optional().nullable()
        } else if (
            Array.from(formFieldConfig.validation || [])?.some(
                (validation) => validation.type === FormElementValidatorType.IS_EMAIL_FORM_ELEMENT_VALIDATION_DTO,
            )
        ) {
            yupChain = yup.string().optional().nullable().email("Invalid email address.")
        } else {
            yupChain = yup.string().optional().nullable()
        }

        formFieldConfig.validation?.forEach((validation) => {
            switch (validation.type) {
                case FormElementValidatorType.IS_INT_FORM_ELEMENT_VALIDATION_DTO:
                    break
                case FormElementValidatorType.IS_EMAIL_FORM_ELEMENT_VALIDATION_DTO:
                    break
                case FormElementValidatorType.REQUIRED_FORM_ELEMENT_VALIDATION_DTO: {
                    yupChain = yupChain.required("This field is required.")
                    break
                }
                case FormElementValidatorType.MIN_LENGTH_FORM_ELEMENT_VALIDATION_DTO: {
                    const minLength = (validation as MinLengthFormElementValidationDTO).minLength
                    yupChain = (yupChain as StringSchema).min(
                        minLength,
                        `Minimum length for this field is ${minLength}.`,
                    )
                    break
                }
                case FormElementValidatorType.MAX_LENGTH_FORM_ELEMENT_VALIDATION_DTO: {
                    const maxLength = (validation as MaxLengthFormElementValidationDTO).maxLength
                    yupChain = (yupChain as StringSchema).max(
                        maxLength,
                        `Maximum length for this field is ${maxLength}.`,
                    )
                    break
                }
                case FormElementValidatorType.MIN_VALUE_FORM_ELEMENT_VALIDATION_DTO: {
                    const minValue = (validation as MinValueFormElementValidationDTO).minValue
                    yupChain = (yupChain as NumberSchema).min(minValue, `Minimum value for this field is ${minValue}.`)
                    break
                }
                case FormElementValidatorType.MAX_VALUE_FORM_ELEMENT_VALIDATION_DTO: {
                    const maxValue = (validation as MaxValueFormElementValidationDTO).maxValue
                    yupChain = (yupChain as NumberSchema).max(maxValue, `Maximum value for this field is ${maxValue}.`)
                    break
                }
                case FormElementValidatorType.SAME_VALUE_FORM_ELEMENT_VALIDATION_DTO: {
                    const { fieldIdentifier, errorMessage } = validation as SameValueFormElementValidationDTO
                    const [otherDimension, otherNameOrValue] = fieldIdentifier.split(".")
                    yupChain = yupChain.test("SameValueFormElementValidation", errorMessage, (thisValue, context) => {
                        // TODO: 'from[1]' feels hacky. Does it work for all cases?
                        const otherValue = context.from[1].value[otherDimension][otherNameOrValue]
                        return thisValue === otherValue
                    })
                    break
                }
            }
        })

        return yupChain
    }
}
