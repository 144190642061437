import CloseIcon from "@mui/icons-material/Close"
import { Box, IconButton, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import React from "react"

type Props = {
    onClose: () => void
}

export const CustomerJourneyDrawerHeader = ({ onClose }: Props) => {
    return (
        <DrawerHeader>
            <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography variant="subtitle1" fontWeight={600} sx={{ mr: 1 }}>
                    Customer Journey
                </Typography>
            </Box>
            <IconButton onClick={onClose} edge="end" aria-label="close drawer" size="small">
                <CloseIcon fontSize="small" />
            </IconButton>
        </DrawerHeader>
    )
}

const DrawerHeader = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1.5, 2.5),
    borderBottom: `1px solid rgba(117, 127, 159, 0.15)`, // Match the separator style
    backgroundColor: "#FAFBFF", // Match the timeline background color
}))
