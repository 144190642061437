import { TextField as MuiTextField } from "@mui/material"
import { FormElementDTO } from "domain/types"
import React, { type JSX } from "react"
import { getUniqueId } from "shared/component/forms/elements/primitives/util"

export type NonEditableFormElementProps = {
    layoutElementConfig: FormElementDTO
    value: any
}

export const FormElementNonEditableInput = ({
    layoutElementConfig,
    value,
}: NonEditableFormElementProps): JSX.Element => {
    const name = layoutElementConfig.formFieldConfig.dimensionIdentifier
    const label = layoutElementConfig.formFieldConfig.displayName || ""
    const reason = layoutElementConfig.formFieldConfig.readOnlyReason || "This field cannot be edited."

    const { current: id } = React.useRef(getUniqueId(name))
    const textField = (
        <MuiTextField
            InputLabelProps={{ shrink: true }}
            label={label}
            id={id}
            value={value}
            name={name}
            helperText={reason}
            size={"small"}
            type={"text"}
            disabled={true}
        />
    )

    return <>{textField}</>
}
