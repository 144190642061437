import { ColumnItem, ColumnSelector } from "./ColumnSelector"
import { PredefinedValuesSelector } from "./PredefinedValuesSelector"
import { SearchInput } from "./SearchInput"
import { SPECIAL_COLUMNS } from "./specialSearchColumns"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { columnCategories } from "domain/ConversionList/domain/domain"
import { produce } from "immer"
import React from "react"

/**
 * Main search component that combines column selection and value input
 */
export const Search = () => {
    const columns = ConversionListContextSelectors.useColumns()
    const searchConfig = ConversionListContextSelectors.useSearchConfig()
    const updateSearchConfig = ConversionListContextSelectors.useUpdateSearchConfig()

    // Get sorted list of searchable columns
    const groupedSupportedSearchColumns = React.useMemo(() => {
        const searchColumns = columns.fieldNames
            .filter((fieldName) => columns.columnDetails[fieldName]!.supportsSearch)
            .map((fieldName) => ({
                fieldName: fieldName,
                label: columns.columnDetails[fieldName]!.columnConfigDTO.gridColumnProperties.columnHeader,
                columnCategory: columns.columnDetails[fieldName]!.columnCategory,
            }))

        searchColumns.sort((a, b) => {
            const categoryA = columns.columnDetails[a.fieldName]!.columnCategory
            const categoryB = columns.columnDetails[b.fieldName]!.columnCategory
            if (categoryA === categoryB) {
                return columns.columnDetails[
                    a.fieldName
                ]!.columnConfigDTO.gridColumnProperties.columnHeader.localeCompare(
                    columns.columnDetails[b.fieldName]!.columnConfigDTO.gridColumnProperties.columnHeader,
                )
            }
            return columnCategories[categoryA].sortOrder - columnCategories[categoryB].sortOrder
        })

        return searchColumns
    }, [columns])

    // Get the currently selected column
    const currentAutocompleteValue = React.useMemo(() => {
        return searchConfig.fieldName
            ? groupedSupportedSearchColumns.find((column) => column.fieldName === searchConfig.fieldName) || null
            : null
    }, [searchConfig.fieldName, groupedSupportedSearchColumns])

    // Event handlers
    const handleAutocompleteChange = (
        _event: React.SyntheticEvent<Element, Event>,
        newValue: ColumnItem | null,
        _reason: any,
    ) => {
        updateSearchConfig(
            produce(searchConfig, (draft) => {
                draft.fieldName = newValue?.fieldName
                draft.searchTerm = undefined // Reset search term when changing column
            }),
        )
    }

    const handleSearchFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateSearchConfig(
            produce(searchConfig, (draft) => {
                draft.searchTerm = event.target.value
            }),
        )
    }

    const handlePredefinedValueChange = (value: string) => {
        updateSearchConfig(
            produce(searchConfig, (draft) => {
                draft.searchTerm = value
            }),
        )
    }

    const handleClearSearch = () => {
        updateSearchConfig(
            produce(searchConfig, (draft) => {
                draft.searchTerm = undefined
            }),
        )
    }

    // Check if the current column is a special column with predefined values
    const isSpecialColumn =
        searchConfig.fieldName && Object.prototype.hasOwnProperty.call(SPECIAL_COLUMNS, searchConfig.fieldName)
    const columnConfig = searchConfig.fieldName ? SPECIAL_COLUMNS[searchConfig.fieldName] : undefined

    return (
        <>
            <ColumnSelector
                columns={groupedSupportedSearchColumns}
                currentValue={currentAutocompleteValue}
                onChange={handleAutocompleteChange}
            />

            {isSpecialColumn && columnConfig ? (
                <PredefinedValuesSelector
                    value={searchConfig.searchTerm}
                    onChange={handlePredefinedValueChange}
                    columnConfig={columnConfig}
                />
            ) : (
                <SearchInput
                    value={searchConfig.searchTerm || ""}
                    onChange={handleSearchFieldChange}
                    onClear={handleClearSearch}
                />
            )}
        </>
    )
}
