import ViewColumnIcon from "@mui/icons-material/ViewColumn"
import { GridColumnMenuItemProps } from "@mui/x-data-grid-pro"
import * as React from "react"
import { CustomColumnMenuItem } from "shared/component/mui/datagrid/CustomColumnMenuItem"

export const CustomColumnMenuConfigureColumnsItem = ({ openColumnConfigurator, hideMenu }: GridColumnMenuItemProps) => {
    return (
        <CustomColumnMenuItem
            label="Configure columns"
            onClick={(event) => {
                hideMenu(event)
                openColumnConfigurator()
            }}
            IconComponent={ViewColumnIcon}
        />
    )
}
