import { Autocomplete, TextField } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { CJProcessingState } from "domain/ConversionList/domain/cjProcessingState"
import React from "react"

type CJProcessingStateOption = {
    value: CJProcessingState
    label: string
}

const cjProcessingStateOptions: CJProcessingStateOption[] = [
    { value: "in_progress", label: "Customer journey in progress" },
    { value: "complete", label: "Customer journey complete" },
]

export const CJProcessingStateFilter = () => {
    const cjProcessingStateSelection = ConversionListContextSelectors.useCJProcessingStateSelection()
    const updateCJProcessingStateSelection = ConversionListContextSelectors.useUpdateCJProcessingStateSelection()

    // Find the option object that matches the current selection
    const selectedOption = cjProcessingStateSelection
        ? cjProcessingStateOptions.find((option) => option.value === cjProcessingStateSelection)
        : null

    const handleChange = (_: React.SyntheticEvent, option: CJProcessingStateOption | null) => {
        updateCJProcessingStateSelection(option?.value ?? "")
    }

    return (
        <FormControl sx={{ mt: 0.5, width: "100%" }} className="cj-processing-state-filter-container">
            <Autocomplete
                className="cj-processing-state-filter"
                value={selectedOption}
                sx={{ width: "100%", display: "inline-block", mb: 0.25 }}
                size="small"
                options={cjProcessingStateOptions}
                getOptionLabel={(option) => option.label}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        InputProps={{
                            ...params.InputProps,
                        }}
                        label=""
                        placeholder="Processing State"
                    />
                )}
            />
        </FormControl>
    )
}
