import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { IconButton, Tooltip } from "@mui/material"
import { ContainerSizeContext } from "domain/legacy/widget/ContainerSizeContext"
import CustomEChart from "domain/legacy/widget/CustomEChart"
import ResponsiveWidgetSettingsUtil from "domain/legacy/widget/ResponsiveWidgetSettingsUtil"
import { Widget } from "domain/legacy/widget/Widget"
import { WidgetContext } from "domain/legacy/widget/WidgetContext"
import { ResponsiveWidgetSettings } from "domain/types/backend/widget.types"
import { Size } from "domain/types/frontend/dimension.types"
import { EChartsOption } from "echarts-for-react/src/types"
import { LoadResponseDTOReportingDataSetDTO } from "generated/models"
import React, { useContext, useEffect, useState } from "react"
import { log } from "shared/util/log"

type Props = {
    isLoading: boolean
    hasLegend: boolean
    response?: LoadResponseDTOReportingDataSetDTO
    widgetSettingsPanel?: React.ReactNode
    echartsOption: EChartsOption
    // optional additional logic to adjust layout after resize
    onContainerResize?: (
        responsiveWidgetSettings: ResponsiveWidgetSettings,
        containerSize: Size,
    ) => ResponsiveWidgetSettings
}

/**
 * This component handles generic resize logic for all echarts
 *
 * @param props
 * @constructor
 */
const EChartWidget = (props: Props) => {
    const [echartsOption, setEchartsOption] = useState<EChartsOption>({})
    const [isLegendExpanded, setIsLegendExpanded] = useState(false)
    const containerSizeContext = useContext(ContainerSizeContext)
    const widgetContext = useContext(WidgetContext)

    const applyResponsiveWidgetSettings = (): void => {
        // execute general [onContainerResize] logic
        const responsiveWidgetSettings: ResponsiveWidgetSettings = props.echartsOption
            ? onContainerResize(props.echartsOption, containerSizeContext.containerSize)
            : props.echartsOption

        // execute custom widget [props.onContainerResize] logic, if available
        const customizedResponsiveWidgetSettings =
            props.onContainerResize && responsiveWidgetSettings
                ? props.onContainerResize(responsiveWidgetSettings, containerSizeContext.containerSize)
                : responsiveWidgetSettings

        setEchartsOption(customizedResponsiveWidgetSettings.echartsOption)
    }

    useEffect(() => {
        applyResponsiveWidgetSettings()
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.echartsOption, containerSizeContext.containerSize])

    useEffect(() => {
        applyResponsiveWidgetSettings()
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLegendExpanded])

    /**
     * Callback for adjusting general echarts configs after container resize to make widget responsive
     *
     * @param echartsOption
     * @param containerSize
     */
    const onContainerResize = (echartsOption: EChartsOption, containerSize: Size): ResponsiveWidgetSettings => {
        log.debug(
            `width: ${containerSizeContext.containerSize.width}, height: ${containerSizeContext.containerSize.height}`,
        )
        const responsiveWidgetSettings = ResponsiveWidgetSettingsUtil.getResponsiveWidgetSettings(
            containerSize,
            echartsOption,
            isLegendExpanded,
            props.hasLegend,
        )

        widgetContext.updateIsLegendButtonVisible(responsiveWidgetSettings.isLegendButtonVisible)

        return responsiveWidgetSettings
    }

    const toggleLegend = () => {
        setIsLegendExpanded((prev) => !prev)
    }

    const chart = echartsOption ? (
        <CustomEChart echartsOption={echartsOption} theme={widgetContext.widgetSettings?.theme} />
    ) : (
        <div />
    )

    return (
        <Widget isLoading={props.isLoading} response={props.response}>
            {props.widgetSettingsPanel}
            <Tooltip title={isLegendExpanded ? "Collapse Legend" : "Expand Legend"} placement="bottom-end">
                {props.hasLegend && (
                    <IconButton
                        sx={{
                            display: !widgetContext.isLegendButtonVisible ? "none" : "flex",
                            position: "absolute",
                            right: 0,
                            top: 2,
                            zIndex: 5,
                            bgcolor: "#fff",
                        }}
                        onClick={toggleLegend}
                        size="large"
                    >
                        {isLegendExpanded ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                )}
            </Tooltip>
            {chart}
        </Widget>
    )
}

export default EChartWidget
