import CloseIcon from "@mui/icons-material/Close"
import SaveIcon from "@mui/icons-material/Save"
import { Theme, useTheme } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle, { DialogTitleProps } from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import { Breakpoint, SxProps } from "@mui/system"
import * as React from "react"
import type { JSX } from "react"
import { assertExhaustive } from "shared/util/TypeUtil"

export interface CustomDialogTitleProps extends DialogTitleProps {
    onClose?: () => void
    closeButtonDisabled?: boolean
}

export function CustomDialogTitle({
    children,
    onClose,
    closeButtonDisabled = false,
    ...other
}: CustomDialogTitleProps) {
    const theme = useTheme()

    return (
        <DialogTitle className={"custom-dialog-title"} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    disabled={closeButtonDisabled}
                    sx={{
                        position: "absolute",
                        right: "22px",
                        top: "16px",
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    )
}

export type CustomDialogsProps = {
    open: boolean
    header: string | JSX.Element
    content: JSX.Element
    footer: FooterProps
    onClose?: (event?: React.SyntheticEvent, reason?: string) => void
    modalWidth?: number
    modalMinHeight?: number
    closeButtonDisabled?: boolean
    maxWidth?: Breakpoint | false
    className?: string
    sx?: SxProps<Theme>
}

export type FooterProps =
    | {
          kind: "yesNoButton"
          noText: string
          yesText: string
          onYesButtonClick: (event?: React.SyntheticEvent) => void
          onNoButtonClick: (event?: React.SyntheticEvent) => void
          submitting: boolean
          isYesButtonDisabled?: boolean
      }
    | {
          kind: "yesButton"
          yesText: string
          onYesButtonClick: (event?: React.SyntheticEvent) => void
          submitting: boolean
          isYesButtonDisabled?: boolean
      }
    | { kind: "custom"; content: JSX.Element }
    | { kind: "none" }

export const CustomDialog = ({
    open,
    header,
    onClose,
    content,
    footer,
    modalMinHeight,
    modalWidth,
    closeButtonDisabled = false,
    maxWidth = false,
    className = "",
    sx = {},
}: CustomDialogsProps) => {
    const footerToRender = getFooterToRender(footer)
    const classNames = ["custom-dialog", className].join(" ")

    return (
        <Dialog
            className={classNames}
            onClose={onClose}
            open={open}
            maxWidth={maxWidth}
            disableEscapeKeyDown={closeButtonDisabled}
            sx={sx}
        >
            <CustomDialogTitle onClose={onClose} closeButtonDisabled={closeButtonDisabled}>
                {header}
            </CustomDialogTitle>
            <DialogContent className={"dialog-content"} sx={{ minHeight: modalMinHeight, width: modalWidth }}>
                {content}
            </DialogContent>
            {footerToRender}
        </Dialog>
    )
}

export const getFooterToRender = (footer: FooterProps): JSX.Element => {
    switch (footer.kind) {
        case "yesNoButton":
            return (
                <DialogActions className={"dialog-actions"}>
                    <Button
                        className={"custom-dialog-no-button"}
                        size={"large"}
                        color="info"
                        variant="outlined"
                        onClick={footer.onNoButtonClick}
                    >
                        {footer.noText}
                    </Button>
                    &nbsp;
                    <Button
                        className={"custom-dialog-yes-button"}
                        size={"large"}
                        loading={footer.submitting}
                        loadingPosition={footer.submitting ? "start" : null}
                        startIcon={footer.submitting ? <SaveIcon /> : null}
                        variant="contained"
                        onClick={footer.onYesButtonClick}
                        disabled={footer.isYesButtonDisabled}
                    >
                        {footer.yesText}
                    </Button>
                </DialogActions>
            )
        case "yesButton":
            return (
                <DialogActions className={"dialog-actions"}>
                    <Button
                        className={"custom-dialog-yes-button"}
                        size={"large"}
                        loading={footer.submitting}
                        loadingPosition={footer.submitting ? "start" : null}
                        startIcon={footer.submitting ? <SaveIcon /> : null}
                        variant="contained"
                        onClick={footer.onYesButtonClick}
                        disabled={footer.isYesButtonDisabled}
                    >
                        {footer.yesText}
                    </Button>
                </DialogActions>
            )
        case "custom":
            return footer.content
        case "none":
            return <></>
        default:
            return assertExhaustive(footer)
    }
}
