export type ConversionType = "sale" | "lead"

export const CONVERSION_TYPES: Readonly<Record<ConversionType, { id: string }>> = {
    sale: { id: "pps" },
    lead: { id: "ppl" },
}

export type ConversionTypeSelection = Readonly<Record<ConversionType, boolean>>

export const DEFAULT_CONVERSION_TYPE_SELECTION: ConversionTypeSelection = {
    sale: true,
    lead: true,
}
