import {
    Autocomplete as MuiAutocomplete,
    Popper,
    TextField,
    Typography,
    autocompleteClasses,
    useMediaQuery,
    useTheme,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import React from "react"
import { ListChildComponentProps, VariableSizeList } from "react-window"
import { useFormContext } from "shared/component/forms/FormContext"
import { getUniqueId } from "shared/component/forms/elements/primitives/util"

export interface AutocompleteProps {
    name: string
    label: string
    isLoading: boolean
    disabled?: boolean
    options?: { optionIdentifier: number | string; optionLabel: string }[]
    onChange?: (value: number | string, textValue: string) => void
    required?: boolean
}

export const Autocomplete = ({
    name,
    label,
    isLoading,
    options,
    onChange: onChangeProp,
    disabled = false,
    required = false,
}: AutocompleteProps) => {
    const { current: id } = React.useRef(getUniqueId(name))

    const { formik } = useFormContext()

    const fieldProps = formik.getFieldProps(name)
    const fieldMeta = formik.getFieldMeta(name)
    const fieldHelpers = formik.getFieldHelpers(name)
    const theme = useTheme()

    const onChange = (
        _: React.ChangeEvent<HTMLInputElement>,
        option: { optionIdentifier: number | string; optionLabel: string } | null,
    ) => {
        fieldHelpers.setValue(option?.optionIdentifier)
        if (onChangeProp) {
            onChangeProp(option?.optionIdentifier, option?.optionLabel)
        }
    }

    return (
        <>
            {isLoading ? (
                <MuiAutocomplete
                    disabled={true}
                    value={"Loading..."}
                    className={name + " form-field form-field-autocomplete"}
                    sx={{ marginTop: theme.spacing(1) }}
                    renderInput={(params) => <TextField label={label} {...params} size={"medium"} />}
                    options={[]}
                />
            ) : (
                <MuiAutocomplete<{ optionIdentifier: number | string; optionLabel: string }>
                    id={id}
                    className={name + " form-field form-field-autocomplete"}
                    sx={{ marginTop: theme.spacing(1) }}
                    PopperComponent={StyledPopper}
                    ListboxComponent={ListboxComponent}
                    value={
                        fieldProps.value
                            ? {
                                  optionIdentifier: fieldProps.value,
                                  optionLabel:
                                      options.find((o) => o.optionIdentifier === fieldProps.value)?.optionLabel ??
                                      "Loading...",
                              }
                            : null
                    }
                    disabled={disabled}
                    onChange={onChange}
                    onBlur={formik.handleBlur}
                    options={options ?? []}
                    getOptionLabel={(option) => option?.optionLabel}
                    isOptionEqualToValue={(option, value) => option.optionIdentifier === value.optionIdentifier}
                    renderOption={(props, option, state) => [props, option, state.index] as React.ReactNode}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            name={fieldProps.name}
                            error={fieldMeta.touched && Boolean(fieldMeta.error)}
                            helperText={fieldMeta.touched && fieldMeta.error}
                            size={"medium"}
                            placeholder={"Not set"}
                        />
                    )}
                />
            )}
        </>
    )
}

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        "& ul": {
            padding: 0,
            margin: 0,
        },
    },
})

const ListboxComponent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLElement>>(
    function ListboxComponent(props, ref) {
        const { children, ...other } = props
        const itemData: React.ReactElement<any>[] = []
        ;(children as React.ReactElement<any>[]).forEach(
            (item: React.ReactElement<any> & { children?: React.ReactElement<any>[] }) => {
                itemData.push(item)
                itemData.push(...(item.children || []))
            },
        )

        const theme = useTheme()
        const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
            noSsr: true,
        })
        const itemCount = itemData.length
        const itemSize = smUp ? 36 : 48

        const getChildSize = (child: React.ReactElement<any>) => {
            if (Object.prototype.hasOwnProperty.call(child, "group")) {
                return 48
            }

            return itemSize
        }

        const getHeight = () => {
            if (itemCount > 8) {
                return 8 * itemSize
            }
            return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
        }

        const gridRef = useResetCache(itemCount)

        return (
            <div ref={ref}>
                <OuterElementContext.Provider value={other}>
                    <VariableSizeList
                        itemData={itemData}
                        height={getHeight() + 2 * LISTBOX_PADDING}
                        width="100%"
                        ref={gridRef}
                        outerElementType={OuterElementType}
                        innerElementType="ul"
                        itemSize={(index) => getChildSize(itemData[index])}
                        overscanCount={5}
                        itemCount={itemCount}
                    >
                        {renderRow}
                    </VariableSizeList>
                </OuterElementContext.Provider>
            </div>
        )
    },
)

const LISTBOX_PADDING = 8 // px

function renderRow(props: ListChildComponentProps) {
    const { data, index, style } = props
    const dataSet = data[index]
    const inlineStyle = {
        ...style,
        top: (style.top as number) + LISTBOX_PADDING,
    }

    return (
        <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
            {dataSet[1].optionLabel}
        </Typography>
    )
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = React.useContext(OuterElementContext)
    return <div ref={ref} {...props} {...outerProps} />
})

function useResetCache(data: any) {
    const ref = React.useRef<VariableSizeList>(null)
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true)
        }
    }, [data])
    return ref
}
