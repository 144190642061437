import { FormElementCheckbox } from "./FormElementCheckbox"
import { FormElementInput } from "./FormElementInput"
import { FormElementNonEditableInput } from "./FormElementNonEditableInput"
import { FormElementNumberInput } from "./FormElementNumberInput"
import { FormElementPasswordInput } from "./FormElementPasswordInput"
import { FormElementSelect } from "./FormElementSelect"
import { FormElementTextarea } from "./FormElementTextarea"
import { FormElementToggleButton } from "./FormElementToggleButton"
import { FormElementProperties, FormSelectElementProperties, LayoutElementType } from "domain/types"
import { produce } from "immer"
import React, { type JSX, useEffect, useLayoutEffect, useState } from "react"
import { useFormContext } from "shared/component/forms/FormContext"
import { useRootElementContext } from "shared/component/layout/context/RootElementContext"
import FormUtil from "shared/util/FormUtil"
import LayoutUtil from "shared/util/LayoutUtil"
import { log } from "shared/util/log"
import { FormElementValidatorType } from "shared/util/validation"

export const FormElement = (formElementProperties: FormElementProperties): JSX.Element => {
    // NOTE: FormElement *might* be used outside a FormContextProvider
    const formContext = useFormContext(false) // false = do not throw error if no context is found
    const { elementSettings } = useRootElementContext()

    const layoutElementConfig = formElementProperties.layoutElementConfig

    const [isEditable, setIsEditable] = useState(true)

    const uiFormConfig = formContext?.uiFormConfig

    const rows = uiFormConfig?.itemData

    const isEditMode = formContext ? FormUtil.getIsEditMode(formContext.uiFormConfig.formConfig) : false

    const formFieldConfig = formElementProperties.layoutElementConfig.formFieldConfig
    const isRequiredField =
        formFieldConfig.validation && Array.isArray(formFieldConfig.validation)
            ? formFieldConfig.validation.find(
                  (validator) => validator.type === FormElementValidatorType.REQUIRED_FORM_ELEMENT_VALIDATION_DTO,
              ) !== undefined
            : false
    const singleEdit = rows?.length === 1
    const label: string = formFieldConfig.displayName

    const showNotSetOption = !isRequiredField
    const showNoChangeOption = isEditMode && !singleEdit

    const identifier = layoutElementConfig.formFieldConfig.dimensionIdentifier

    /**
     * Sets the default values and updated the editable state once after the element is mounted
     */
    useLayoutEffect(() => {
        const update = async () => {
            await updateIsEditable()

            // set the default value when the item is mounted the first time
            if (formContext) {
                // this sets initial value (if available) or the default one
                await formContext.setFormElementValueAndUpdateContextSettingsForInitialValues(layoutElementConfig)
            }
        }

        update().catch((reason) => log.debug(reason))
        // Missing deps are intentional here, we want to run this only once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    /**
     * Updates editable state after the formContext settings was changed
     */
    useEffect(() => {
        updateIsEditable().catch((reason) => log.debug(reason))
        // TODO: is it safe to add the missing dependencies?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementSettings])

    /**
     * Updates editable state if the formContext settings match the element editableOn conditions
     */
    const updateIsEditable = (): Promise<void> => {
        if (layoutElementConfig.formFieldConfig?.readOnly) {
            setIsEditable(false)
            updateReadOnlyContextState(false)
            formContext.disableValidation(layoutElementConfig.formFieldConfig.dimensionIdentifier)

            return Promise.resolve()
        }

        return LayoutUtil.evaluateElementSettingWithConditionThenUpdate(
            layoutElementConfig.formFieldConfig?.editableOn,
            elementSettings,
            async (newIsEditable: boolean) => {
                setIsEditable(newIsEditable)

                if (isEditable !== newIsEditable && formContext) {
                    // "newIsEditable" is the new state, "editable" is the old one; no need to update if old and new state are the same, this could be caused by calling both useEffect methods during intialization
                    if (newIsEditable) {
                        // if element is set to editable, then restore his initial value and update if necessary the formContext settings
                        await formContext.setFormElementValueAndUpdateContextSettingsForInitialValues(
                            layoutElementConfig,
                        )
                    } else {
                        // if the element is set to readonly and the element has default value, then set the default value into the element
                        // otherwise reset the value
                        if (layoutElementConfig.formFieldConfig?.defaultValue) {
                            await formContext.formik.setFieldValue(
                                identifier,
                                layoutElementConfig.formFieldConfig?.defaultValue,
                            )
                        } else {
                            // reset the field if it is not editable
                            await formContext.formik.setFieldValue(identifier, "")
                        }
                    }

                    updateReadOnlyContextState(newIsEditable)

                    // Update form validation state: we only want to validate editable fields
                    const updateValidationState = newIsEditable
                        ? formContext.enableValidation
                        : formContext.disableValidation
                    updateValidationState(identifier)
                }
            },
        )
    }

    /**
     * Save identifier in the form context as readonly/editable
     *
     * @param isEditable
     */
    const updateReadOnlyContextState = (isEditable: boolean) => {
        formContext.setReadOnlyElements(
            produce((draft) => {
                if (isEditable) {
                    draft.delete(identifier)
                } else {
                    draft.add(identifier)
                }
            }),
        )
    }

    if (!!layoutElementConfig.formFieldConfig.readOnly && isEditMode) {
        const nonEditableValue = FormUtil.getNonEditableValue(layoutElementConfig, rows)

        // if the element is read only, then show FormElementNonEditableInput
        return <FormElementNonEditableInput layoutElementConfig={layoutElementConfig} value={nonEditableValue} />
    }

    formElementProperties = {
        ...formElementProperties,
        showNotSetOption,
        showNoChangeOption,
        isRequiredField,
        singleEdit,
        label,
        itemData: rows,
        editable: isEditable,
        onChangeListener: (value) => {
            formContext?.onChange(value, identifier)
            formContext?.customFormFieldListeners[formFieldConfig.onChangeListener]?.(value)
        },
    }

    let element = undefined
    switch (formElementProperties.layoutElementConfig.elementType) {
        case LayoutElementType.FORM_ELEMENT_INPUT: {
            element = <FormElementInput {...formElementProperties} />
            break
        }
        case LayoutElementType.FORM_ELEMENT_SELECT: {
            element = <FormElementSelect {...(formElementProperties as FormSelectElementProperties)} />
            break
        }
        case LayoutElementType.FORM_ELEMENT_CHECKBOX: {
            element = <FormElementCheckbox {...formElementProperties} />
            break
        }
        case LayoutElementType.FORM_ELEMENT_TOGGLE_BUTTON: {
            element = <FormElementToggleButton {...formElementProperties} />
            break
        }
        case LayoutElementType.FORM_ELEMENT_INPUT_PASSWORD: {
            element = <FormElementPasswordInput {...formElementProperties} />
            break
        }
        case LayoutElementType.FORM_ELEMENT_TEXTAREA: {
            element = <FormElementTextarea {...formElementProperties} />
            break
        }
        case LayoutElementType.NUMBER_FORM_ELEMENT_INPUT: {
            element = <FormElementNumberInput {...formElementProperties} />
            break
        }
        default: {
            log.error(
                `FormElement: No component found for element type ${formElementProperties.layoutElementConfig.elementType}`,
            )
        }
    }

    return element
}
