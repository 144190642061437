import { ConversionList } from "domain/ConversionList/components/ConversionList"
import { ConversionListContextProvider } from "domain/ConversionList/context/ConversionListContextProvider"
import { ConversionListExternalDependencyContextProvider } from "domain/ConversionList/context/ConversionListExternalDependencyContextProvider"
import { ConversionListType, TimeRange } from "domain/ConversionList/domain/domain"
import { GridElementConfigDTO } from "generated/models"
import React from "react"

export interface ConversionListContainerProps {
    type: ConversionListType
    initialTimeRange: TimeRange
    gridElementConfig: GridElementConfigDTO
    updateSelectedColumnIdentifiers: (
        selectedColumnIdentifiers: ReadonlyArray<string>,
        pinnedColumnIdentifiers: ReadonlyArray<string>,
    ) => void
    resetGridColumnState: () => void
    ConversionListExternalDependencyContextOverride?: typeof ConversionListExternalDependencyContextProvider
}

export const ConversionListContainer = ({
    type,
    initialTimeRange,
    gridElementConfig,
    updateSelectedColumnIdentifiers,
    resetGridColumnState,
    ConversionListExternalDependencyContextOverride,
}: ConversionListContainerProps) => {
    return (
        <ConversionListContextProvider
            type={type}
            initialTimeRange={initialTimeRange}
            gridElementConfig={gridElementConfig}
            updateSelectedColumnIdentifiers={updateSelectedColumnIdentifiers}
            resetGridColumnState={resetGridColumnState}
            ConversionListExternalDependencyContextOverride={ConversionListExternalDependencyContextOverride}
        >
            <div
                className="conversion-list-container"
                style={{ height: "100%", display: "flex", flexDirection: "column" }}
            >
                <ConversionList />
            </div>
        </ConversionListContextProvider>
    )
}
