// Grid version 2
import { ColumnSelectionPanel } from "./ColumnSelectionPanel/ColumnSelectionPanel"
import { SelectedColumnsSidebar } from "./SelectedColumnsSidebar/SelectedColumnsSidebar"
import { useTheme } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import Grid from "@mui/material/Grid2"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useReportingConfigurationContext } from "domain/reporting/ReportingConfigurationContext"
import React, { type JSX } from "react"

export const ColumnConfigurator = (): JSX.Element => {
    const { initialized } = useReportingConfigurationContext()

    const theme = useTheme()
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"))

    // We already check this higher up in the component tree (ColumnConfiguratorDialog), so this will never render
    // in production. However, we want to be able to render this component in isolation in Storybook, so we need
    // to check it here as well.
    if (!initialized) {
        return <CircularProgress />
    }

    return (
        <Grid container className="column-configurator" sx={{ minHeight: "100%" }} flexGrow={1}>
            <Grid
                padding={isSmallScreen ? 1 : 3}
                flexGrow={1}
                className={"column-selection-panel-wrappeer"}
                size={{
                    xs: 6,
                    sm: 9,
                }}
            >
                <ColumnSelectionPanel />
            </Grid>
            <Grid
                sx={{ minHeight: "100%" }}
                className={"selected-columns-sidebar-wrappeer"}
                size={{
                    xs: 6,
                    sm: 3,
                }}
            >
                <SelectedColumnsSidebar />
            </Grid>
        </Grid>
    )
}
