import { Backdrop, Box } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import React from "react"

export interface SpinnerProps {
    spinning: boolean
    children: React.ReactNode
}

export const Spinner = (props: SpinnerProps) => {
    return (
        <Box
            className={`spinner-wrapper ` + props.spinning ? "spinning" : "not-spinning"}
            sx={{ position: "relative!important", width: "100%", height: "100%" }}
        >
            <Backdrop
                open={props.spinning}
                className={"spinner-backdrop"}
                sx={{
                    position: "absolute",
                    backgroundColor: "rgba(255,255,255,0.5)",
                    color: "#000",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
            >
                <CircularProgress />
            </Backdrop>
            <Box className={"spinner-content"}>{props.children}</Box>
        </Box>
    )
}

export default Spinner
