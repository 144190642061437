import { AlignHorizontalLeft, AlignVerticalBottom, AlignVerticalTop } from "@mui/icons-material"
import { FormLabel, Radio, RadioGroup, Stack, Switch, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material"
import { styled } from "@mui/material/styles"
import { ContainerSizeContext } from "domain/legacy/widget/ContainerSizeContext"
import { WidgetContext } from "domain/legacy/widget/WidgetContext"
import { WidgetSettingsPanel } from "domain/legacy/widget/WidgetSettingsPanel"
import { BarDirection, ColorTheme, TopNBarWidgetSettingsDTO } from "domain/types/backend/widget.types"
import React, { useContext } from "react"
import * as R from "remeda"
import { v1 as uuid } from "uuid"

const ThemeToggleButton = styled(ToggleButton)(({ theme }) => ({
    width: "24px",
    height: "24px",
    padding: 0,
    margin: "0 4px",
    border: "1px solid #ccc",
    borderRadius: "4px !important",
    minWidth: "unset",
    "&.theme-exactag": {
        background:
            "linear-gradient(135deg, #4285f4 25%, #ea4335 25%, #ea4335 50%, #fbbc05 50%, #fbbc05 75%, #34a853 75%)",
        borderRadius: "4px !important",
    },
    "&.theme-monochrome_red": {
        backgroundColor: "#ea4335",
        borderRadius: "4px !important",
        "&.Mui-selected": {
            "&:hover": {
                backgroundColor: "#ea4335",
            },
        },
    },
    "&.theme-monochrome_orange": {
        backgroundColor: "#ff7043",
        borderRadius: "4px !important",
        "&.Mui-selected": {
            "&:hover": {
                backgroundColor: "#ff7043",
            },
        },
    },
    "&.theme-monochrome_yellow": {
        backgroundColor: "#fbbc05",
        borderRadius: "4px !important",
        "&.Mui-selected": {
            "&:hover": {
                backgroundColor: "#fbbc05",
            },
        },
    },
    "&.theme-monochrome_green": {
        backgroundColor: "#34a853",
        borderRadius: "4px !important",
        "&.Mui-selected": {
            "&:hover": {
                backgroundColor: "#34a853",
            },
        },
    },
    "&.theme-monochrome_turqouise": {
        backgroundColor: "rgb(109, 216, 230)",
        borderRadius: "4px !important",
        "&.Mui-selected": {
            "&:hover": {
                backgroundColor: "rgb(109, 216, 230)",
            },
        },
    },
    "&.theme-monochrome_blue": {
        backgroundColor: "#4285f4",
        borderRadius: "4px !important",
        "&.Mui-selected": {
            "&:hover": {
                backgroundColor: "#4285f4",
            },
        },
    },
    "&.theme-monochrome_purple": {
        backgroundColor: "#9c27b0",
        borderRadius: "4px !important",
        "&.Mui-selected": {
            "&:hover": {
                backgroundColor: "#9c27b0",
            },
        },
    },
    "&.theme-monochrome_gray": {
        backgroundColor: "#9e9e9e",
        borderRadius: "4px !important",
        "&.Mui-selected": {
            "&:hover": {
                backgroundColor: "#9e9e9e",
            },
        },
    },
    "&.Mui-selected": {
        border: "2px solid #000 !important",
        borderRadius: "4px !important",
        "&:hover": {
            opacity: 0.5,
        },
    },
    "&:hover": {
        opacity: 0.8,
        borderRadius: "4px !important",
    },
}))

/**
 * @constructor
 */
export const TopNBarWidgetSettingsPanel = () => {
    const containerSizeContext = useContext(ContainerSizeContext)
    const widgetDataContext = useContext(WidgetContext)
    const { widgetSettings, updateWidgetSettings } = widgetDataContext
    const topNBarWidgetSettings = widgetSettings as TopNBarWidgetSettingsDTO

    if (!containerSizeContext.containerSize.height || !containerSizeContext.containerSize.width) {
        return null
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | null, field: string) => {
        if (!event) return
        const newSettings: TopNBarWidgetSettingsDTO = R.clone(topNBarWidgetSettings)
        newSettings[field] = event.target.value
        updateWidgetSettings(newSettings)
    }

    const handleSwitchChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const newSettings: TopNBarWidgetSettingsDTO = R.clone(topNBarWidgetSettings)
        newSettings[field] = event.target.checked
        updateWidgetSettings(newSettings)
    }

    const createThemeOptions = () => {
        const themeOptions = [
            { label: "Multi-Colored", value: ColorTheme.MULTICOLOR },
            { label: "Red", value: ColorTheme.MONOCHROME_RED },
            { label: "Orange", value: ColorTheme.MONOCHROME_ORANGE },
            { label: "Yellow", value: ColorTheme.MONOCHROME_YELLOW },
            { label: "Green", value: ColorTheme.MONOCHROME_GREEN },
            { label: "Turquoise", value: ColorTheme.MONOCHROME_TURQUOISE },
            { label: "Blue", value: ColorTheme.MONOCHROME_BLUE },
            { label: "Purple", value: ColorTheme.MONOCHROME_PURPLE },
            { label: "Gray", value: ColorTheme.MONOCHROME_GRAY },
        ]

        return (
            <ToggleButtonGroup
                value={topNBarWidgetSettings.theme}
                exclusive
                onChange={(_, value) => {
                    if (value !== null) {
                        const newSettings = R.clone(topNBarWidgetSettings)
                        newSettings.theme = value
                        updateWidgetSettings(newSettings)
                    }
                }}
            >
                {themeOptions.map((themeOption) => (
                    <Tooltip key={uuid()} title={themeOption.label}>
                        <ThemeToggleButton
                            value={themeOption.value}
                            className={`theme-${themeOption.value.toLowerCase()}`}
                            aria-label={themeOption.label}
                        />
                    </Tooltip>
                ))}
            </ToggleButtonGroup>
        )
    }

    return (
        <WidgetSettingsPanel>
            <Stack
                spacing={2}
                className={
                    containerSizeContext.containerSize.width < 400 || containerSizeContext.containerSize.height < 400
                        ? "condensed-form"
                        : ""
                }
            >
                <Stack direction="row" spacing={2} alignItems="center">
                    <FormLabel sx={{ minWidth: "80px" }}>Bar direction</FormLabel>
                    <ToggleButtonGroup
                        value={topNBarWidgetSettings.barDirection}
                        exclusive
                        onChange={(_, value) => {
                            if (value !== null) {
                                const newSettings = R.clone(topNBarWidgetSettings)
                                newSettings.barDirection = value
                                updateWidgetSettings(newSettings)
                            }
                        }}
                    >
                        <ToggleButton value={BarDirection.VERTICAL} size={"small"}>
                            <Tooltip title="Vertical">
                                <AlignVerticalBottom />
                            </Tooltip>
                        </ToggleButton>
                        <ToggleButton value={BarDirection.HORIZONTAL} size={"small"}>
                            <Tooltip title="Horizontal">
                                <AlignHorizontalLeft />
                            </Tooltip>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Stack>

                <Stack direction="row" spacing={2} alignItems="center">
                    <FormLabel sx={{ minWidth: "80px" }}>Max. entries</FormLabel>
                    <ToggleButtonGroup
                        value={topNBarWidgetSettings.topNElements}
                        exclusive
                        onChange={(_, value) => {
                            if (value !== null) {
                                const newSettings = R.clone(topNBarWidgetSettings)
                                newSettings.topNElements = value
                                updateWidgetSettings(newSettings)
                            }
                        }}
                    >
                        <ToggleButton value={5} size={"small"}>
                            5
                        </ToggleButton>
                        <ToggleButton value={10} size={"small"}>
                            10
                        </ToggleButton>
                        <ToggleButton value={20} size={"small"}>
                            20
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Stack>

                <Stack direction="row" spacing={2} alignItems="center">
                    <FormLabel sx={{ minWidth: "80px" }}>Show others</FormLabel>
                    <Switch
                        size={"small"}
                        checked={topNBarWidgetSettings.showOthers}
                        onChange={handleSwitchChange("showOthers")}
                    />
                </Stack>

                <Stack direction="row" spacing={2} alignItems="center">
                    <FormLabel sx={{ minWidth: "80px" }}>Stack bars</FormLabel>
                    <Switch
                        size={"small"}
                        checked={topNBarWidgetSettings.stacked}
                        onChange={handleSwitchChange("stacked")}
                    />
                </Stack>

                <Stack direction="row" spacing={2} alignItems="center">
                    <FormLabel sx={{ minWidth: "80px" }}>Show values</FormLabel>
                    <Switch
                        size={"small"}
                        checked={topNBarWidgetSettings.showValues}
                        onChange={handleSwitchChange("showValues")}
                    />
                </Stack>

                <Stack direction="row" spacing={2} alignItems="center">
                    <FormLabel sx={{ minWidth: "80px" }}>Theme</FormLabel>
                    {createThemeOptions()}
                </Stack>

                <Stack direction="row" spacing={2} alignItems="center">
                    <FormLabel sx={{ minWidth: "80px" }}>Show pattern</FormLabel>
                    <Switch
                        size={"small"}
                        checked={topNBarWidgetSettings.showDecalPattern}
                        onChange={handleSwitchChange("showDecalPattern")}
                    />
                </Stack>
            </Stack>
        </WidgetSettingsPanel>
    )
}
