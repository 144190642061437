import { TabContext, TabList } from "@mui/lab"
import { Alert, Box, useTheme } from "@mui/material"
import Typography from "@mui/material/Typography"
import { TagsUtil } from "domain/ColumnConfigurator/TagsUtil"
import { AttributionTab } from "domain/ColumnConfigurator/components/ColumnSelectionPanel/MetricSelectionPanel/MetricSelectionAccordion/matrix/AttributionTab"
import ReportingMethodInfoDialogTrigger from "domain/ColumnConfigurator/components/ColumnSelectionPanel/MetricSelectionPanel/MetricSelectionAccordion/matrix/ReportingMethodInfoDialogTrigger"
import TooltipTab from "domain/ColumnConfigurator/components/ColumnSelectionPanel/MetricSelectionPanel/TooltipTab"
import { ColumnUniqueName, ToCnvAttributionTiming, ToTPAttributionTiming } from "domain/ColumnConfigurator/types"
import React, { type JSX } from "react"
import { IdentityWrapper, Wrapper } from "shared/util/Wrapper"
import { FONT_WEIGHT_BOLD } from "styles/theme/constants"

export interface MatrixMetricsTileProps {
    name: string
    metrics: ReadonlySet<ColumnUniqueName>
    onCheckboxChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
    hasSearchQuery: boolean
    Wrapper?: Wrapper
}

export const MatrixMetricsTile = ({
    name,
    metrics,
    onCheckboxChangeHandler,
    hasSearchQuery,
    Wrapper = IdentityWrapper,
}: MatrixMetricsTileProps): JSX.Element | null => {
    const tocnvMetrics = React.useMemo(
        () => TagsUtil.filterMetricsByTag(metrics, ToCnvAttributionTiming.instance.tag),
        [metrics],
    )
    const totpMetrics = React.useMemo(
        () => TagsUtil.filterMetricsByTag(metrics, ToTPAttributionTiming.instance.tag),
        [metrics],
    )

    const hasToCnvMetrics = tocnvMetrics.size > 0
    const hasToTPMetrics = totpMetrics.size > 0
    const theme = useTheme()

    const [selectedAttributionTimingTab, setSelectedAttributionTimingTab] = React.useState<string>(() => {
        if (hasToCnvMetrics) {
            return ToCnvAttributionTiming.instance.id
        }
        return ToTPAttributionTiming.instance.id
    })

    const tabToShow = React.useMemo(() => {
        if (hasToCnvMetrics && hasToTPMetrics) {
            return selectedAttributionTimingTab
        }
        if (hasToCnvMetrics) {
            return ToCnvAttributionTiming.instance.id
        }
        if (hasToTPMetrics) {
            return ToTPAttributionTiming.instance.id
        }
        return undefined
    }, [hasToCnvMetrics, hasToTPMetrics, selectedAttributionTimingTab])

    if (tabToShow === undefined) {
        return null
    }

    const handleChange = (_event: React.SyntheticEvent, value: any) => {
        setSelectedAttributionTimingTab(value)
    }

    const createReportingMethodHeader = (method: string) => (
        <Typography component="div">
            <h4 className={"header-subtle"}>Reporting method:</h4>
            <Box sx={{ fontWeight: FONT_WEIGHT_BOLD, fontSize: "13px", marginTop: "2px" }}>{method}</Box>
        </Typography>
    )

    return (
        <Wrapper>
            <TabContext key={name} value={tabToShow}>
                <Box className={"tile matrix-metrics-tile"} sx={{ columnCount: 1, margin: `0 -16px` }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                            onChange={handleChange}
                            aria-label="Attribution Results"
                            sx={{
                                "& .MuiBadge-badge": { right: "10px", top: "2px" },
                                "& .MuiTabs-flexContainer": { justifyContent: "space-around" },
                                "& .MuiButtonBase-root": {
                                    width: "100%",
                                    [theme.breakpoints.down("md")]: {
                                        width: "unset",
                                    },
                                },
                            }}
                            centered
                        >
                            {hasToCnvMetrics && (
                                <TooltipTab
                                    label={createReportingMethodHeader("Time of Conversion (ToCnv)")}
                                    value={ToCnvAttributionTiming.instance.id}
                                    tooltip={
                                        <>
                                            {/*<h4 className={'header-subtle'}>Reporting method:</h4><h3>Time of Conversion (ToCnv)</h3>*/}
                                            <p>
                                                Reports conversion shares{" "}
                                                <strong>at the moment of the conversion</strong>
                                            </p>
                                            <p>
                                                {" "}
                                                For instance, if touchpoints are spread across a week and a conversion
                                                occurs on Friday, ToCnv reports the entire conversion on this day. This
                                                method provides a slightly delayed but stable view of touchpoint impact,
                                                that is suited for comparison with different attribution models.
                                            </p>
                                            <Alert severity="info">
                                                Reporting by Time of Conversion is ideal for summarizing performance in
                                                the most recent period, like the previous week, especially when
                                                immediate updates from recent touchpoints are less relevant.
                                            </Alert>
                                            <p style={{ fontStyle: "italic" }}>
                                                {" "}
                                                Note: Both methods (ToTP and ToCnv) consider the same attribution shares
                                                for each touchpoint but differ in their reporting time.
                                            </p>
                                            <ReportingMethodInfoDialogTrigger />
                                        </>
                                    }
                                    badgeContent={hasSearchQuery ? tocnvMetrics.size : undefined}
                                />
                            )}
                            {hasToTPMetrics && (
                                <TooltipTab
                                    label={createReportingMethodHeader("Time of Touchpoint (ToTP)")}
                                    value={ToTPAttributionTiming.instance.id}
                                    tooltip={
                                        <>
                                            {/*<h4 className={'header-subtle'}>Reporting method:</h4><h3>Time of Touchpoint (ToTP)</h3>*/}
                                            <p>
                                                Reports conversion shares{" "}
                                                <strong>based on when each touchpoint appears.</strong>
                                            </p>
                                            <p>
                                                For instance, if a Customer Journey runs over a week with one touchpoint
                                                per day, ToTP reports a share of the conversion on each day. This method
                                                gives you an up-to-date view, reflecting the immediate impact of each
                                                touchpoint. If reporting short-term the data for a specific period
                                                becomes more complete as additional user journeys convert.
                                            </p>
                                            <Alert severity="info">
                                                Reporting by ToTP is especially useful for assessing the impact of
                                                campaigns during a certain reporting period and is recommended for
                                                analyzing cost efficiency.
                                            </Alert>
                                            <p style={{ fontStyle: "italic" }}>
                                                {" "}
                                                Note: Both methods (ToTP and ToCnv) consider the same attribution shares
                                                for each touchpoint but differ in their reporting time.
                                            </p>
                                            <ReportingMethodInfoDialogTrigger />
                                        </>
                                    }
                                    badgeContent={hasSearchQuery ? totpMetrics.size : undefined}
                                />
                            )}
                        </TabList>
                    </Box>
                    {hasToCnvMetrics && (
                        <AttributionTab
                            attributionTiming={ToCnvAttributionTiming.instance}
                            attributionMetrics={tocnvMetrics}
                            onCheckboxChangeHandler={onCheckboxChangeHandler}
                            hasSearchQuery={hasSearchQuery}
                            tabPosition={hasToTPMetrics ? "left" : "centered"}
                        />
                    )}
                    {hasToTPMetrics && (
                        <AttributionTab
                            attributionTiming={ToTPAttributionTiming.instance}
                            attributionMetrics={totpMetrics}
                            onCheckboxChangeHandler={onCheckboxChangeHandler}
                            hasSearchQuery={hasSearchQuery}
                            tabPosition={hasToCnvMetrics ? "right" : "centered"}
                        />
                    )}
                </Box>
            </TabContext>
        </Wrapper>
    )
}
