import {
    BingSvgIcon,
    ClickIcon,
    DefaultSvgIcon,
    DesktopSvgIcon,
    GoogleSvgIcon,
    NewsletterSvgIcon,
    SearchEngineSvgIcon,
    ShoppingBagCustomIcon,
    SmartphoneSvgIcon,
    SocialSvgIcon,
    TikTokSvgIcon,
    ViewIcon,
} from "../svgImages"
import { Box, Typography, createSvgIcon } from "@mui/material"
import { styled } from "@mui/material/styles"
import React from "react"

// Format the date "2024-09-21" as "August 14, 2024"
const formatDate = (dateString: string): string => {
    if (!dateString) return ""

    const date = new Date(dateString)
    return date.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
    })
}

type Props = {
    touchPoints: TouchPoint[]
}

export const CustomerJourneyTimelineContainer = ({ touchPoints }: Props) => {
    return (
        <TimelineContainer>
            {/* Removed the Customer Journey Timeline header */}

            {/* Group touchpoints by date */}
            {Object.entries(
                touchPoints.reduce<Record<string, typeof touchPoints>>((groups, touchpoint) => {
                    const date = touchpoint.date || ""
                    if (!groups[date]) {
                        groups[date] = []
                    }
                    groups[date].push(touchpoint)
                    return groups
                }, {}),
            )
                // Sort days in descending order (newest first)
                .sort(([dateA], [dateB]) => {
                    return new Date(dateB).getTime() - new Date(dateA).getTime()
                })
                .map(([date, touchPointsForDay], dayIndex, allDays) => {
                    // Sort touchpoints within each day in descending order (newest first)
                    const sortedTouchPoints: TouchPoint[] = [...touchPointsForDay].sort((a, b) => {
                        // Sort by conversion status first (conversions should come first)
                        if (a.isConversion && !b.isConversion) return -1
                        if (!a.isConversion && b.isConversion) return 1

                        // Then sort by time (most recent first)
                        return b.time.localeCompare(a.time)
                    })

                    // Check if this is the last day group with touchpoints
                    const isLastDayGroup = dayIndex === allDays.length - 1

                    return (
                        <DayGroup key={date} className="cl-journey-day-group">
                            <DayHeader className="cl-journey-day-header">
                                <Typography variant="subtitle2" fontWeight={600}>
                                    {formatDate(date)}
                                </Typography>
                                <TouchpointCount>{touchPointsForDay.length} touchpoints</TouchpointCount>
                            </DayHeader>

                            <Timeline className="cl-journey-timeline">
                                {sortedTouchPoints.map((touchPoint, index, array) => {
                                    // Determine if this is the last touchpoint in this day group
                                    const isLastInGroup = index === array.length - 1
                                    // Determine if this is the very last touchpoint overall
                                    const isLastTouchpoint = isLastDayGroup && isLastInGroup

                                    return (
                                        <TimelineItem key={touchPoint.id} className="cl-journey-timeline-item">
                                            {/* Column 1: Timestamp */}
                                            <TimeLabel className="cl-journey-time-label">{touchPoint.time}</TimeLabel>

                                            {/* Column 2: Timeline visualization with dot and line segment */}
                                            <TimelineColumn
                                                isConversion={touchPoint.isConversion}
                                                isLastTouchpoint={isLastTouchpoint}
                                                className="cl-journey-timeline-column"
                                            >
                                                {/* Dot - no icon for conversions */}
                                                <TimelineDot
                                                    isConversion={touchPoint.isConversion}
                                                    touchpointType={touchPoint.channel}
                                                    className={
                                                        touchPoint.isConversion
                                                            ? "cl-journey-timeline-dot-conversion"
                                                            : "cl-journey-timeline-dot"
                                                    }
                                                >
                                                    {/* Removed conversion icon */}
                                                </TimelineDot>
                                            </TimelineColumn>

                                            {/* Column 3: Touchpoint details card */}
                                            <Box
                                                sx={{
                                                    position: "relative",
                                                    display: "flex",
                                                    alignItems: "flex-start",
                                                    padding: "3px 0",
                                                    paddingBottom: isLastInGroup ? "5px" : "3px",
                                                }}
                                                className="cl-journey-touchpoint-container"
                                            >
                                                <TouchpointCard
                                                    role={touchPoint.role}
                                                    isConversion={touchPoint.isConversion}
                                                    touchpointType={touchPoint.channel}
                                                    className={
                                                        touchPoint.isConversion
                                                            ? "cl-journey-card-conversion"
                                                            : "cl-journey-card"
                                                    }
                                                >
                                                    {/* Card content remains the same */}
                                                    {touchPoint.isConversion ? (
                                                        <>
                                                            <TouchpointTitle className="cl-journey-touchpoint-title">
                                                                Conversion
                                                            </TouchpointTitle>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <TouchpointTitle className="cl-journey-touchpoint-title">
                                                                <Typography
                                                                    sx={{
                                                                        fontSize: "0.875rem",
                                                                        fontWeight: 600,
                                                                        lineHeight: 1.5,
                                                                    }}
                                                                >
                                                                    {touchPoint.channel}
                                                                </Typography>

                                                                {/* Paid/Organic tag - moved next to headline */}
                                                                <ValueNameItem
                                                                    sx={{
                                                                        ml: 1,
                                                                        mr: "auto",
                                                                    }}
                                                                >
                                                                    {touchPoint.marketing ? (
                                                                        <Typography
                                                                            component="span"
                                                                            sx={{
                                                                                fontSize: "11px",
                                                                            }}
                                                                        >
                                                                            Paid
                                                                        </Typography>
                                                                    ) : (
                                                                        <Typography
                                                                            component="span"
                                                                            sx={{
                                                                                color: "#9e9e9e",
                                                                                fontSize: "11px",
                                                                            }}
                                                                        >
                                                                            Organic
                                                                        </Typography>
                                                                    )}
                                                                </ValueNameItem>

                                                                <Box
                                                                    sx={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        gap: 0.5,
                                                                    }}
                                                                >
                                                                    {/* Identity type tag */}
                                                                    <ValueNameItem>
                                                                        {touchPoint.identityType}
                                                                    </ValueNameItem>

                                                                    {/* Device type icon only */}
                                                                    {touchPoint.deviceType && (
                                                                        <IconOnlyTag>
                                                                            {getDeviceTypeIcon(touchPoint.deviceType)}
                                                                        </IconOnlyTag>
                                                                    )}

                                                                    {/* Click/View icon tag - moved to rightmost position */}
                                                                    <IconOnlyTag>
                                                                        {getTouchpointIcon(touchPoint.type)}
                                                                    </IconOnlyTag>
                                                                </Box>
                                                            </TouchpointTitle>

                                                            <TouchpointInfo className="cl-journey-touchpoint-info">
                                                                {!touchPoint.isConversion && (
                                                                    <>
                                                                        {/* Provider tag */}
                                                                        <ProviderPublisherTag
                                                                            sx={{
                                                                                marginRight:
                                                                                    touchPoint.publisher &&
                                                                                    touchPoint.publisher !== "Internal"
                                                                                        ? "10px"
                                                                                        : 0,
                                                                            }}
                                                                        >
                                                                            {getIcon(touchPoint.provider) ||
                                                                                getTouchpointIcon(touchPoint.type)}
                                                                            {touchPoint.provider}
                                                                        </ProviderPublisherTag>

                                                                        {/* Publisher tag */}
                                                                        {touchPoint.publisher &&
                                                                            touchPoint.publisher !== "Internal" && (
                                                                                <ProviderPublisherTag
                                                                                    sx={{
                                                                                        marginRight: 0,
                                                                                    }}
                                                                                >
                                                                                    {getIcon(touchPoint.publisher)}
                                                                                    {touchPoint.publisher}
                                                                                </ProviderPublisherTag>
                                                                            )}

                                                                        {/* Green verification tags - randomly added based on ID */}
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                ml: "auto",
                                                                                gap: 0.5,
                                                                                flexWrap: "wrap",
                                                                                justifyContent: "flex-end",
                                                                            }}
                                                                        >
                                                                            {/* Add Product ID tag to even IDs */}
                                                                            {touchPoint.id % 4 === 0 && (
                                                                                <VerificationTag>
                                                                                    <CheckIcon />
                                                                                    Product ID
                                                                                </VerificationTag>
                                                                            )}

                                                                            {/* Add Click ID tag to IDs divisible by 3 */}
                                                                            {touchPoint.subId && (
                                                                                <VerificationTag>
                                                                                    <CheckIcon />
                                                                                    Click ID
                                                                                </VerificationTag>
                                                                            )}
                                                                        </Box>
                                                                    </>
                                                                )}
                                                            </TouchpointInfo>
                                                        </>
                                                    )}
                                                </TouchpointCard>
                                            </Box>
                                        </TimelineItem>
                                    )
                                })}
                            </Timeline>
                        </DayGroup>
                    )
                })}
        </TimelineContainer>
    )
}

const TimelineContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    flexGrow: 1,
    backgroundColor: "#FAFBFF", // Add light blue background color
    // Removed overflowY: "auto" to let the parent handle scrolling
}))

// Timeline container for date groups
export const DayGroup = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(1), // Reduced from 2 spacing units to 1
}))

const DayHeader = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1, 1),
    borderBottom: `1px solid rgba(117, 127, 159, 0.15)`, // Using the new color with low opacity for border
    marginBottom: theme.spacing(1.5), // Increased spacing below the header
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: "transparent", // Transparent background
    "& .MuiTypography-root": {
        color: "#757f9f", // Updated to match the new timeline color
    },
}))

// Timeline touchpoint count
const TouchpointCount = styled(Typography)(({ theme }) => ({
    fontSize: "0.7rem",
    color: theme.palette.grey[400],
    fontWeight: 600,
    textTransform: "uppercase",
}))

// Timeline grid container with fixed column layout
const Timeline = styled(Box)(({ theme: _ }) => ({
    display: "grid",
    gridTemplateColumns: "55px 40px 1fr", // Reduced widths to create a more compact layout
    position: "relative",
}))

// Timeline item (single row)
const TimelineItem = styled(Box)(({ theme: _ }) => ({
    display: "contents", // This allows children to be placed directly in the grid
}))

// Time label
const TimeLabel = styled(Typography)(({ theme }) => ({
    fontSize: "0.7rem",
    color: "#757f9f", // Updated to match the new timeline color for consistency
    textAlign: "right",
    paddingLeft: 0,
    paddingRight: theme.spacing(0.25),
    paddingTop: theme.spacing(1.325), // Increased from 1.2 to add ~1px more
    paddingBottom: 0,
    display: "flex",
    height: "100%",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    fontWeight: 600,
}))

// Timeline column that contains the vertical line and dot
const TimelineColumn = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isConversion" && prop !== "isLastTouchpoint",
})<{
    isConversion?: boolean
    isLastTouchpoint?: boolean
}>(({ theme, isConversion, isLastTouchpoint }) => ({
    position: "relative",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "flex-start",
    paddingTop: isConversion ? theme.spacing(2.25) : theme.spacing(2.45), // Added ~2px more padding (0.25 spacing units)
    paddingBottom: 0,
    justifyContent: "center",
    "&::before": {
        content: '""',
        position: "absolute",
        width: 1, // Reduced from 2px to 1px
        backgroundColor: "#757f9f", // Using the new color with 100% opacity
        left: "calc(50% - 0.5px)", // Adjusted center calculation for 1px width
        top: isConversion ? "50%" : 0,
        bottom: isLastTouchpoint ? "50%" : 0,
        zIndex: 0,
    },
}))

// Timeline dot container
const TimelineDot = styled("div")<StyledProps>(({ isConversion }) => ({
    width: isConversion ? 8 : 6, // Slightly increased conversion dot size to be visible without icon
    height: isConversion ? 8 : 6, // Slightly increased conversion dot size to be visible without icon
    borderRadius: "50%",
    position: "relative",
    backgroundColor: "#757f9f", // Use the same color for all dots
}))

// Timeline touchpoint card content and styles
export const TouchpointCard = styled(Box, {
    shouldForwardProp: (prop) => prop !== "isConversion" && prop !== "touchpointType" && prop !== "role",
})<{ isConversion?: boolean; touchpointType: string; role: string }>(({ theme, isConversion }) => ({
    flexGrow: 1,
    padding: theme.spacing(1.2, 1.5, isConversion ? 1.5 : 0.6, 0.75), // Reduced left padding from 1.5 to 0.75
    backgroundColor: "#FFFFFF", // White background
    boxShadow: "0px 2px 4px rgba(57, 75, 121, 0.08)", // Subtle shadow using timeline color
    borderRadius: "6px",
    position: "relative",
    border: "none",
    overflow: "hidden",
    maxWidth: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
}))

// Title style for touchpoint cards
const TouchpointTitle = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "flex-start", // Changed from center/default to align items to the top
    flexWrap: "wrap",
    fontWeight: 600,
    fontSize: "0.875rem",
    margin: 0,
    marginBottom: theme.spacing(0.5),
    lineHeight: 1.5,
    color: theme.palette.text.primary,
    "& > span": {
        marginRight: theme.spacing(0.5),
    },
}))

// Info style for touchpoint details
const TouchpointInfo = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    color: theme.palette.text.secondary,
    fontSize: "0.75rem",
    lineHeight: 1.3,
    padding: 0,
    margin: 0,
    "& .separator": {
        display: "inline-block",
        width: "4px",
        height: "4px",
        borderRadius: "50%",
        backgroundColor: theme.palette.grey[400],
        margin: "0 6px",
    },
}))
// Value name item for tags in timeline view - Replace box shadow with light gray border
const ValueNameItem = styled(Box)(({ theme }) => ({
    display: "inline-flex",
    alignItems: "center",
    padding: "2px 8px",
    margin: "0 4px 4px 0",
    backgroundColor: theme.palette.common.white,
    border: `1px solid #e8e8e8`, // Lighter gray border
    borderRadius: "4px",
    fontSize: "11px", // Reduced from 0.75rem (12px) to 11px
    lineHeight: 1.3,
    height: "22px", // Fixed height to ensure consistency
    boxSizing: "border-box",
    "& img, & svg": {
        width: 14,
        height: 14,
        marginRight: 4,
    },
}))

// Special version of ValueNameItem just for icon-only tags
const IconOnlyTag = styled(ValueNameItem)({
    padding: "4px",
    width: 22,
    height: 22, // Height already standardized at 22px
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img, & svg": {
        marginRight: 0,
    },
})

// Function to get touchpoint icon based on type
const getTouchpointIcon = (type: string) => {
    switch (type) {
        case "Click":
            return <ClickIcon />
        case "View":
            return <ViewIcon />
        case "Conversion":
            return <ShoppingBagCustomIcon />
        default:
            return <ViewIcon />
    }
}

// Function to get publisher/provider icon based on name
const getIcon = (name: string) => {
    // The order matters - check for parent name before variants (like Bing before Bing Ads)
    if (name === "Bing") return <BingSvgIcon sx={{ width: 16, height: 16, mr: 0.5 }} />
    if (name === "Google") return <GoogleSvgIcon sx={{ width: 16, height: 16, mr: 0.5 }} />
    if (name.includes("Bing")) return <BingSvgIcon sx={{ width: 16, height: 16, mr: 0.5 }} />
    if (name.includes("Google")) return <GoogleSvgIcon sx={{ width: 16, height: 16, mr: 0.5 }} />
    if (name.includes("TikTok")) return <TikTokSvgIcon sx={{ width: 16, height: 16, mr: 0.5 }} />
    if (name.includes("Social Media")) return <SocialSvgIcon sx={{ width: 16, height: 16, mr: 0.5 }} />
    if (name === "Newsletter") return <NewsletterSvgIcon sx={{ width: 16, height: 16, mr: 0.5 }} />
    if (name === "Search Engine") return <SearchEngineSvgIcon sx={{ width: 16, height: 16, mr: 0.5 }} />
    if (name === "Website" || name === "Internal") return <DefaultSvgIcon sx={{ width: 16, height: 16, mr: 0.5 }} />

    // Return a generic icon for any other publisher/provider
    return <DefaultSvgIcon sx={{ width: 16, height: 16, mr: 0.5 }} />
}

// Function to get device type icon
const getDeviceTypeIcon = (deviceType: string) => {
    // Should use icons from device_type/images folder
    switch (deviceType) {
        case "Desktop":
            // Using icons from device_type/images folder instead
            return <DesktopSvgIcon />
        case "Smartphone":
            // Using icons from device_type/images folder instead
            return <SmartphoneSvgIcon />
        default:
            return <DesktopSvgIcon />
    }
}

// Borderless version of ValueNameItem for publisher and provider
const ProviderPublisherTag = styled(Box)(() => ({
    display: "inline-flex",
    alignItems: "center",
    padding: "2px 0", // Removed the left/right padding
    margin: "0 10px 4px 0", // Apply margin to the entire tag, not just the icon
    backgroundColor: "transparent", // Transparent background
    border: "none", // No border
    borderRadius: "4px",
    fontSize: "0.75rem", // 12px font size
    lineHeight: 1.3,
    height: "22px", // Fixed height to ensure consistency
    boxSizing: "border-box",
    "& img, & svg": {
        width: 14,
        height: 14,
        marginRight: 4, // Reset to original small spacing for icon-to-text
    },
}))

// Special tag for verification data (Product ID, Click ID)
const VerificationTag = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(0.5),
    backgroundColor: "#E9F6EC", // light green background
    color: "#1D7F3A", // dark green text
    borderRadius: "4px",
    padding: "2px 6px",
    fontSize: "11px", // Reduced from 0.75rem (12px) to 11px
    fontWeight: 500,
    height: "22px", // Increased from 20px to 22px for consistency
    boxSizing: "border-box",
    "& .MuiSvgIcon-root": {
        fontSize: 12, // Reduced from 14px to 12px
    },
}))

const CheckIcon = createSvgIcon(
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 6L5 9L10 4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>,
    "Check",
)

// Define types to fix linter errors
interface StyledProps {
    isConversion?: boolean
    touchpointType?: string
    isTouchpointSelected?: boolean
}
