import { TabPanel } from "@mui/lab"
import { Alert, Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { ModelUtil } from "domain/ColumnConfigurator/ModelUtil"
import { MemoizedMetricCheckboxWithTooltip } from "domain/ColumnConfigurator/components/ColumnSelectionPanel/MetricSelectionPanel/MetricSelectionAccordion/MetricCheckboxWithTooltip"
import { TileUtil } from "domain/ColumnConfigurator/components/ColumnSelectionPanel/MetricSelectionPanel/TileUtil"
import { ColumnUtil } from "domain/ColumnConfigurator/components/ColumnUtil"
import { ColumnConfiguratorContextSlices } from "domain/ColumnConfigurator/context/ColumnConfiguratorContextSlices"
import { ColumnUniqueName, Metric, Model, ValueType } from "domain/ColumnConfigurator/types"
import { useReportingConfigurationContext } from "domain/reporting/ReportingConfigurationContext"
import CustomTooltip from "layout/components/CustomTooltip"
import React, { type JSX } from "react"
import ArrayUtil from "shared/util/ArrayUtil"
import MapUtil from "shared/util/MapUtil"
import { FONT_WEIGHT_MEDIUM, FONT_WEIGHT_REGULAR, FONT_WEIGHT_SEMI_BOLD } from "styles/theme/constants"

type Params = {
    valueType: ValueType
    /**
     * Metrics which must already be filtered by value type (Updated/Original/Returned)
     */
    filteredMetrics: Set<ColumnUniqueName>
    onCheckboxChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const ValueTab = ({ valueType, filteredMetrics, onCheckboxChangeHandler }: Params): JSX.Element => {
    const {
        helpers: { getColumn, getMetric },
    } = useReportingConfigurationContext()

    const selectedMetrics = ColumnConfiguratorContextSlices.useSelectedMetrics()
    const dimensionMetricCompatibility = ColumnConfiguratorContextSlices.useDimensionMetricCompatibility()

    const columnCheckboxStates = React.useMemo(
        () =>
            TileUtil.getMetricsFrontendGroupCheckboxState(
                filteredMetrics,
                dimensionMetricCompatibility,
                ColumnUtil.getIdentifiers(selectedMetrics),
            ),
        [filteredMetrics, dimensionMetricCompatibility, selectedMetrics],
    )

    const getCheckBox = (columnIdentifier: ColumnUniqueName): JSX.Element => {
        const checkboxState = columnCheckboxStates.get(columnIdentifier)

        if (!checkboxState || checkboxState.visibility === "invisible") {
            return <></>
        }

        const column = getColumn(columnIdentifier)!

        return (
            <MemoizedMetricCheckboxWithTooltip
                key={columnIdentifier}
                columnUniqueName={columnIdentifier}
                columnDisplayName={column.displayName}
                withCheckboxLabel={false}
                isDisabled={checkboxState.visibility === "disabled"}
                checked={checkboxState.checked}
                onChange={onCheckboxChangeHandler}
            />
        )
    }

    const groupedMetrics = React.useMemo(() => {
        // All available metrics for the current value type
        const metrics = ArrayUtil.mapNotNullOrUndefined(Array.from(filteredMetrics.values()), getMetric)

        // By grouping metrics by their shortened display name, we collect all available models for each metric
        const groupedByDisplayName = MapUtil.groupBy(metrics, (metric) => metric.displayNameBaseMetric)

        // We're sure that all metrics have a model, so we can use ! here

        const groupByModel = (metrics: Metric[]) => MapUtil.groupBy(metrics, (metric) => ModelUtil.getModel(metric)!)

        const takeFirstMetric = (models: Map<Model, Metric[]>) => MapUtil.mapValues(models, (metrics) => metrics[0]!)

        // Group all metrics by their shortened display name and model
        const groupedByDisplayNameAndModel = MapUtil.mapValues(groupedByDisplayName, (metrics) =>
            takeFirstMetric(groupByModel(metrics)),
        )

        // Ensure the iteration order of the map is by display name
        return MapUtil.sort(groupedByDisplayNameAndModel, ([displayNameA, _modelsA], [displayNameB, _modelsB]) =>
            displayNameA.localeCompare(displayNameB),
        )
    }, [filteredMetrics, getMetric])

    const { hasDigital, hasEnhanced, hasAVM, hasCommission } = React.useMemo(() => {
        const modelTypes = new Set(
            Array.from(groupedMetrics.values())
                .map((models) => Array.from(models.keys()))
                .flat(),
        )

        return {
            hasDigital: modelTypes.has(Model.DIGITAL),
            hasEnhanced: modelTypes.has(Model.ENHANCED),
            hasAVM: modelTypes.has(Model.AVM),
            hasCommission: modelTypes.has(Model.COMMISSION),
        }
    }, [groupedMetrics])

    return (
        <StyledTabPanel value={valueType.id} className={"value-tab"}>
            <TableContainer component={"div"}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            {hasDigital && (
                                <TableCell align="center">
                                    <CustomTooltip
                                        customWidth={400}
                                        className={"info-tooltip"}
                                        title={
                                            <Typography>
                                                <h4 className={"header-subtle"}>Measurement Scope:</h4>
                                                <h2>Data-driven Attribution (DDA)</h2>
                                                Measurement covers all digital channels.
                                                <Alert severity="info">
                                                    Best for granular short-term performance reviews from a digital-only
                                                    perspective.
                                                </Alert>
                                                {hasAVM && (
                                                    <p style={{ fontStyle: "italic" }}>
                                                        Note: When using Full View Impact Modeling, data-driven
                                                        Attribution will only consider clicks. <br />
                                                        View measurement based on aggregated Views will be part of the
                                                        Data-driven Attribution with View Impact.
                                                    </p>
                                                )}
                                            </Typography>
                                        }
                                        placement="top"
                                    >
                                        <Box>
                                            <span className={"measurement-type"}>Data-driven Attribution</span>
                                        </Box>
                                    </CustomTooltip>
                                </TableCell>
                            )}

                            {hasAVM && (
                                <TableCell align="center">
                                    <CustomTooltip
                                        customWidth={400}
                                        className={"info-tooltip"}
                                        title={
                                            <Typography>
                                                <h4 className={"header-subtle"}>Measurement Scope:</h4>
                                                <h2>Data-driven Attribution with Full View Impact (DDA with Views)</h2>
                                                Measurement covers the full scope of digital channels including modeled
                                                view impact.
                                                <Alert severity="info">
                                                    {" "}
                                                    Best for near-term performance analysis including View effects.
                                                </Alert>
                                            </Typography>
                                        }
                                        placement="top"
                                    >
                                        <Box>
                                            <span className={"measurement-type"}>Data-driven Attribution</span>
                                            <br />
                                            <span className={"measurement-attribute"}>with View Impact</span>
                                        </Box>
                                    </CustomTooltip>
                                </TableCell>
                            )}
                            {hasEnhanced && (
                                <TableCell align="center">
                                    <CustomTooltip
                                        customWidth={400}
                                        className={"info-tooltip"}
                                        title={
                                            <Typography>
                                                <h4 className={"header-subtle"}>Measurement Scope:</h4>
                                                <h2>Data-driven Attribution + MMM-Enhancements (DDA Enhanced)</h2>
                                                Measurement covers all digital channels enhanced by modeled offline or
                                                baseline impact.
                                                <Alert severity="info">
                                                    {" "}
                                                    Best for evaluating performance with both digital and offline
                                                    contexts.
                                                </Alert>
                                            </Typography>
                                        }
                                        placement="top"
                                    >
                                        <Box>
                                            <span className={"measurement-type"}>Data-driven Attribution</span>
                                            <br />
                                            <span className={"measurement-attribute"}>+ MMM-Enhancements</span>
                                        </Box>
                                    </CustomTooltip>
                                </TableCell>
                            )}
                            {hasCommission && (
                                <TableCell align="center">
                                    <CustomTooltip
                                        customWidth={400}
                                        className={"info-tooltip"}
                                        title={
                                            <Typography>
                                                <h4 className={"header-subtle"}>Measurement Scope:</h4>
                                                <h2>Rule-based Attribution (Commission)</h2>
                                                Uses your active rule-based commission model in digital channels.
                                                <Alert severity="info">
                                                    {" "}
                                                    Best for assessing performance based on your specific commission
                                                    strategy.
                                                </Alert>
                                                <p style={{ fontStyle: "italic" }}>
                                                    Note: The rule-based commission model is only available under the
                                                    Time of Conversion reporting method.
                                                </p>
                                            </Typography>
                                        }
                                        placement="top"
                                    >
                                        <Box>
                                            <span className={"measurement-type"}>Rule-based Attribution</span>
                                            <br />
                                            <span className={"measurement-attribute"}>Commission</span>
                                        </Box>
                                    </CustomTooltip>
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.from(groupedMetrics.keys()).map((metricDisplayName: string) => {
                            const models: Map<Model, Metric> =
                                groupedMetrics.get(metricDisplayName) ?? new Map<Model, Metric>()
                            const digitalModelColumn = models.get(Model.DIGITAL)
                            const enhancedModelColumn = models.get(Model.ENHANCED)
                            const avmModelColumn = models.get(Model.AVM)
                            const commissionModelColumn = models.get(Model.COMMISSION)

                            const renderRow = hasDigital || hasEnhanced || hasAVM || hasCommission

                            return renderRow ? (
                                <TableRow
                                    key={metricDisplayName}
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row" sx={{ width: "360px" }}>
                                        {metricDisplayName}
                                    </TableCell>
                                    {hasDigital && (
                                        <TableCell align="center">
                                            {digitalModelColumn ? getCheckBox(digitalModelColumn.uniqueName) : ""}
                                        </TableCell>
                                    )}
                                    {hasAVM && (
                                        <TableCell align="center">
                                            {avmModelColumn ? getCheckBox(avmModelColumn.uniqueName) : ""}
                                        </TableCell>
                                    )}
                                    {hasEnhanced && (
                                        <TableCell align="center">
                                            {enhancedModelColumn ? getCheckBox(enhancedModelColumn.uniqueName) : ""}
                                        </TableCell>
                                    )}
                                    {hasCommission && (
                                        <TableCell align="center">
                                            {commissionModelColumn ? getCheckBox(commissionModelColumn.uniqueName) : ""}
                                        </TableCell>
                                    )}
                                </TableRow>
                            ) : undefined
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </StyledTabPanel>
    )
}

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
    padding: "0!important",
    border: 0,
    "& .measurement-attribute, .measurement-type": {
        color: theme.palette.text.primary,
        fontWeight: FONT_WEIGHT_SEMI_BOLD,
    },
    "& .measurement-attribute": {
        display: "inline-block",
        paddingTop: "3px",
        fontWeight: FONT_WEIGHT_REGULAR,
    },
    "& .MuiTableCell-root.MuiTableCell-head": {
        verticalAlign: "top",
        width: "120px",
        padding: "10px 5px",
        lineHeight: "120%",
        fontWeight: FONT_WEIGHT_SEMI_BOLD,
    },
    "& .MuiTableRow-root:hover": {
        transition: "background 0.1s ease-in-out",
        background: theme.palette.primaryShades[50],
    },
    "& .MuiTableCell-root": {
        fontSize: "12px!important",
        lineHeight: "100%",
        padding: "2px 0",
        borderWidth: 0,
    },
    "& th.MuiTableCell-root": {
        paddingLeft: theme.spacing(2),
        fontWeight: FONT_WEIGHT_MEDIUM,
    },
    "& .MuiFormControlLabel-root": {
        margin: 0,
    },
    "& .MuiCheckbox-root": {
        padding: "4px",
        "& .MuiSvgIcon-root": {
            height: "20px",
            width: "20px",
        },
    },
}))
