// can be used to e.g. define the dimensions of a component

export type Size = {
    width: number
    height: number
}

// Width and height of a component will be classified into one of these size categories based on breakpoints (e.g. < 400 px = size small,
// 401 - 600 px = size medium, etc.)
export enum SizeCategory {
    X_SMALL = "x-small",
    SMALL = "small",
    MEDIUM = "medium",
    LARGE = "large",
}

export type SizeCategories = {
    width: SizeCategory
    height: SizeCategory
}

/**
 * A list of SizeBreakpoints can be used to define which size should be mapped to which category; e.g. SizeCategory.SMALL, max=500 means
 * that a size <=500px will result in SizeCategory.SMALL
 */
export type SizeBreakpoint = {
    category: SizeCategory
    // optional because it is not necessary for the smallest size
    min?: number
    // optional because it is not necessary for the largest size
    max?: number
}
