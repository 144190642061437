import { Autocomplete, TextField } from "@mui/material"
import FormControl from "@mui/material/FormControl"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { ConversionStateSelection } from "domain/ConversionList/domain/conversionState"
import React from "react"

const conversionStatusOptions: ConversionStateSelection[] = ["open", "valid", "canceled", "changed"]

export const ConversionStateFilter = () => {
    const conversionStatusSelection = ConversionListContextSelectors.useConversionStateSelection()
    const updateConversionStatusSelection = ConversionListContextSelectors.useUpdateConversionStateSelection()

    const handleChange = (_: React.SyntheticEvent, value: ConversionStateSelection | null) => {
        updateConversionStatusSelection(value ?? "")
    }

    return (
        <FormControl sx={{ mt: 0.5, width: "100%" }} className="conversion-state-filter-container">
            <Autocomplete
                className="conversion-state-filter conversion-list-conversion-state-filter"
                value={conversionStatusSelection}
                sx={{ width: "100%", display: "inline-block", mb: 0.25 }}
                size="small"
                options={conversionStatusOptions}
                onChange={handleChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        size="small"
                        InputProps={{
                            ...params.InputProps,
                        }}
                        label=""
                        placeholder="Conversion State"
                    />
                )}
            />
        </FormControl>
    )
}
