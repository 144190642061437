import { SpecialColumnOption } from "./specialSearchColumns"
import ClearIcon from "@mui/icons-material/Clear"
import { InputAdornment, MenuItem, Select } from "@mui/material"
import React from "react"

/**
 * Component for selecting from predefined values for special columns
 */
export const PredefinedValuesSelector = ({
    value,
    onChange,
    columnConfig,
}: {
    value: string | undefined
    onChange: (value: string) => void
    columnConfig: {
        values: string[] | SpecialColumnOption[]
        hasCustomMapping?: boolean
    }
}) => {
    const hasCustomMapping = columnConfig?.hasCustomMapping || false

    // Convert the values to a consistent format for rendering
    const options = React.useMemo(() => {
        if (!columnConfig?.values) return []

        if (hasCustomMapping) {
            // The values are already in {display, value} format
            return columnConfig.values as SpecialColumnOption[]
        } else {
            // Convert simple string values to the display/value format
            return (columnConfig.values as string[]).map((val) => ({
                display: val,
                value: val,
            }))
        }
    }, [columnConfig, hasCustomMapping])

    const handleClear = () => {
        onChange("")
    }

    return (
        <div style={{ position: "relative", width: 300, maxWidth: "100%" }}>
            <Select
                className="search-predefined-values-select"
                size="small"
                value={value || ""}
                onChange={(e) => onChange(e.target.value)}
                displayEmpty
                fullWidth
            >
                <MenuItem value="">
                    <em>Select a value</em>
                </MenuItem>
                {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option.display}
                    </MenuItem>
                ))}
            </Select>
            {value && (
                <InputAdornment
                    position="end"
                    style={{
                        position: "absolute",
                        right: "28px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        zIndex: 1,
                        cursor: "pointer",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "24px",
                        height: "24px",
                    }}
                    onClick={handleClear}
                >
                    <ClearIcon sx={{ fontSize: "18px" }} />
                </InputAdornment>
            )}
        </div>
    )
}
