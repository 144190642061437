import {
    IS_CLOSER,
    IS_INFLUENCER,
    IS_INTRODUCER,
    IS_INVOLVED,
    IS_WINNER,
} from "domain/ConversionList/domain/dimensionIdentifiers"

export type TouchpointCustomerJourneyRole = "closer" | "involved" | "introducer" | "influencer" | "winner"

export const TOUCHPOINT_CUSTOMER_JOURNEY_ROLES: Readonly<
    Record<
        TouchpointCustomerJourneyRole,
        {
            displayName: string
            dimensionIdentifier: string
        }
    >
> = {
    winner: {
        displayName: "Winner",
        dimensionIdentifier: IS_WINNER,
    },
    closer: {
        displayName: "Closer",
        dimensionIdentifier: IS_CLOSER,
    },
    involved: {
        displayName: "Involved",
        dimensionIdentifier: IS_INVOLVED,
    },
    introducer: {
        displayName: "Introducer",
        dimensionIdentifier: IS_INTRODUCER,
    },
    influencer: {
        displayName: "Influencer",
        dimensionIdentifier: IS_INFLUENCER,
    },
}
