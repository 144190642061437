import ClearIcon from "@mui/icons-material/Clear"
import { IconButton, InputAdornment, TextField, TextFieldProps, TextFieldVariants } from "@mui/material"
import React from "react"

export type ClearableTextFieldProps<Variant extends TextFieldVariants> = {
    onClear: () => void
    value: unknown // Component must be used in a controlled way and therefore value is required
} & TextFieldProps<Variant>

export const ClearableTextField = <Variant extends TextFieldVariants>({
    onClear,
    ...props
}: ClearableTextFieldProps<Variant>) => {
    const InputProps: TextFieldProps<Variant>["InputProps"] = {
        ...props.InputProps,
        endAdornment: props.value && (
            <InputAdornment position="end">
                <IconButton onClick={onClear}>
                    <ClearIcon />
                </IconButton>
            </InputAdornment>
        ),
    }

    const sx = {
        ...props.sx,
        "&:hover .MuiInputAdornment-positionEnd": {
            visibility: "visible",
        },
        ".MuiInputAdornment-positionEnd": {
            visibility: "hidden",
        },
    }

    return <TextField {...props} InputProps={InputProps} sx={sx} />
}
