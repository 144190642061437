import { Stack } from "@mui/material"
import { ColumnResponseDTO } from "generated/models"
import * as React from "react"
import { FunctionComponent, SVGProps } from "react"

type Props = {
    Icon?: FunctionComponent<SVGProps<SVGSVGElement>> | undefined
    value: ColumnResponseDTO
}

/**
 * Renders an SVG icon with a value name
 *
 * @param Icon
 * @param value
 * @constructor
 */
const SvgValueNameRenderer = ({ Icon, value }: Props) => {
    if (!value) {
        return <React.Fragment />
    }

    const contentValue = value.name ?? value.value

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <div style={{ width: "16px", height: "16px", display: "flex", alignItems: "center", lineHeight: 1 }}>
                {Icon && <Icon style={{ maxWidth: "16px", maxHeight: "16px" }} />}
            </div>
            <span style={{ whiteSpace: "nowrap" }}>{contentValue}</span>
        </Stack>
    )
}

export default SvgValueNameRenderer
