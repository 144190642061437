import { ExpandCollapseButtonState } from "../../types"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Button from "@mui/material/Button"
import React, { type JSX } from "react"

export interface ExpandCollapseButtonProps {
    buttonState: ExpandCollapseButtonState
    onClick: () => void
}

export const ExpandCollapseButton = ({ buttonState, onClick }: ExpandCollapseButtonProps): JSX.Element => {
    const { text, IconComponent } = getButtonAppearance(buttonState)

    return (
        <div>
            <Button
                variant="contained"
                color={"primary"}
                size={"small"}
                onClick={onClick}
                startIcon={<IconComponent />}
                className={"metrics-action-button"}
            >
                {text}
            </Button>
        </div>
    )
}

const getButtonAppearance = (buttonState: ExpandCollapseButtonState) => {
    switch (buttonState) {
        case ExpandCollapseButtonState.COLLAPSED:
            return {
                text: "Expand All",
                IconComponent: ExpandMoreIcon,
            }
        case ExpandCollapseButtonState.EXPANDED:
            return {
                text: "Collapse All",
                IconComponent: ExpandLessIcon,
            }
    }
}
