/**
 * Creates payload used for editing form.
 * There might be multiple [ids] because multiple items may be edited at the same time and get the same [values] assigned.
 *
 * @param identifierColumn The column identifier of the item to edit, e.g. line_item_id
 * @param ids The IDs of the items to edit.
 * @param values The key/values which are edited.
 */
const createEditPayload = (
    identifierColumn: string,
    ids: any[],
    values: { [key: string]: any },
): { [key: string]: any }[] => {
    return ids.map((id) => {
        const row = { [identifierColumn]: id } // e.g. { line_item_id: 123}

        Object.keys(values).forEach((key) => {
            const currentValue = values[key]
            if (currentValue !== "__NO_CHANGE__" && currentValue !== undefined) {
                row[key] = currentValue
            }

            // 'remove' for multi-selects
            if (Array.isArray(values[key])) {
                const vals = values[key].filter((item) => item !== null)
                row[key] = vals.length ? vals : null
            }
        })
        return row
    })
}

const PayloadUtil = { createEditPayload }
export default PayloadUtil
