import { Button } from "@mui/material"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import React from "react"

export const AddPropertyFilterButton = () => {
    const openPropertyFilterDialog = ConversionListContextSelectors.useOpenPropertyFilterDialog()

    const handleClick = () => {
        openPropertyFilterDialog({ type: "add" })
    }

    return (
        <Button variant="outlined" size="small" onClick={handleClick}>
            + Add filter
        </Button>
    )
}
