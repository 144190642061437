import { Autocomplete, AutocompleteProps, Box, ChipTypeMap } from "@mui/material"
import React, { forwardRef } from "react"
import { Virtualizer } from "virtua"

export const VirtualizedAutocomplete = <
    Value,
    Multiple extends boolean | undefined = false,
    DisableClearable extends boolean | undefined = false,
    FreeSolo extends boolean | undefined = false,
    ChipComponent extends React.ElementType = ChipTypeMap["defaultComponent"],
>(
    props: AutocompleteProps<Value, Multiple, DisableClearable, FreeSolo, ChipComponent>,
) => {
    return <Autocomplete ListboxComponent={ListboxVirtualizationComponent} {...props} />
}

export const ListboxVirtualizationComponent = forwardRef<HTMLElement, React.HTMLAttributes<HTMLElement>>(
    ({ children, ...listboxProps }, ref) => {
        return (
            <Box ref={ref} {...listboxProps}>
                <Virtualizer>{children}</Virtualizer>
            </Box>
        )
    },
)
