import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip"
import { styled } from "@mui/material/styles"
import React from "react"

interface CustomWidthTooltipProps extends TooltipProps {
    customWidth?: number
    offsetX?: number
    offsetY?: number
}

const SimpleCustomTooltip = (props: TooltipProps) => {
    const classes = React.useMemo(
        () => ({
            popper: props.className,
        }),
        [props.className],
    )

    return (
        <Tooltip {...props} arrow={true} classes={classes}>
            {props.children}
        </Tooltip>
    )
}

const FancyCustomTooltip = styled(
    ({ className, customWidth, arrow = true, offsetX, offsetY, ...props }: CustomWidthTooltipProps) => (
        <Tooltip
            {...props}
            arrow={arrow}
            classes={{ popper: className }}
            {...((typeof offsetX !== "undefined" || typeof offsetY !== "undefined") && {
                PopperProps: {
                    modifiers: [
                        {
                            name: "offset",
                            options: {
                                offset: [offsetX || 0, offsetY || 0],
                            },
                        },
                    ],
                },
            })}
        >
            {props.children}
        </Tooltip>
    ),
)(({ customWidth }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: customWidth || 500,
    },
}))

const CustomTooltip = ({
    customWidth,
    offsetX,
    offsetY,
    disableInteractive = true,
    ...props
}: CustomWidthTooltipProps) => {
    if (customWidth || offsetX || offsetY) {
        return (
            <FancyCustomTooltip
                customWidth={customWidth}
                offsetX={offsetX}
                offsetY={offsetY}
                disableInteractive={disableInteractive}
                {...props}
            >
                {props.children}
            </FancyCustomTooltip>
        )
    }
    return (
        <SimpleCustomTooltip disableInteractive={disableInteractive} {...props}>
            {props.children}
        </SimpleCustomTooltip>
    )
}

export default CustomTooltip
