import { FormControlLabel, Switch as MuiSwitch } from "@mui/material"
import React from "react"
import { getUniqueId } from "shared/component/forms/elements/primitives/util"

export interface UncontrolledSwitchProps {
    name: string
    label: string
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    required?: boolean
}

export const UncontrolledSwitch = ({
    name,
    label,
    onChange: onChangeProp,
    required = false,
}: UncontrolledSwitchProps) => {
    const { current: id } = React.useRef(getUniqueId(name))

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChangeProp) {
            onChangeProp(event)
        }
    }

    return (
        <FormControlLabel
            control={
                <MuiSwitch
                    id={id}
                    className={name + " form-field form-field-uncontrolled-switch"}
                    name={name}
                    onChange={onChange}
                    size={"medium"}
                    required={required}
                />
            }
            label={label}
        />
    )
}
