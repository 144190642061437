import { Box, ToggleButton, ToggleButtonGroup, useTheme } from "@mui/material"
import { styled } from "@mui/material/styles"
import { CJMode } from "domain/ConversionList/domain/touchpointType"
import React from "react"

type Props = {
    viewType: ViewType
    handleViewTypeChange: (_event: React.MouseEvent<HTMLElement>, newViewType: ViewType | null) => void
    granularity: CJMode
    handleGranularityChange: (_event: React.MouseEvent<HTMLElement>, newGranularity: CJMode | null) => void
}

export const CustomerJourneyToggleSection = ({
    viewType,
    handleViewTypeChange,
    granularity,
    handleGranularityChange,
}: Props) => {
    const theme = useTheme()

    return (
        <ToggleSection>
            <GridToggleButtonGroup
                value={viewType}
                exclusive
                onChange={handleViewTypeChange}
                aria-label="view type"
                size="small"
                sx={{
                    gridTemplateColumns: "repeat(2, 1fr)", // Two equal columns
                    maxWidth: { xs: "100%", md: 240 }, // Full width on mobile, limited on desktop
                }}
                className="cl-journey-view-toggle"
            >
                <ToggleButton value="timeline" aria-label="timeline view">
                    <Box sx={{ mr: 0.5, display: "flex", alignItems: "center" }}>
                        <TimelineIconCustom />
                    </Box>
                    Timeline
                </ToggleButton>
                <ToggleButton value="detail" aria-label="detail view">
                    <Box sx={{ mr: 0.5, display: "flex", alignItems: "center" }}>
                        <TableIcon />
                    </Box>
                    Detail
                </ToggleButton>
            </GridToggleButtonGroup>

            <StyledToggleButtonGroup
                value={granularity}
                exclusive
                onChange={handleGranularityChange}
                aria-label="journey granularity"
                size="small"
                sx={{
                    display: "flex",
                    flexWrap: "nowrap",
                    gap: 0.5,
                    overflow: "visible",
                    minWidth: "fit-content",
                    flexShrink: 0,
                    justifyContent: { xs: "center", md: "flex-start" }, // Center on mobile, left-align on larger screens
                    [theme.breakpoints.down("md")]: {
                        "& .MuiToggleButtonGroup-grouped": {
                            flex: 1, // Make buttons flex to distribute space evenly on small screens
                            maxWidth: "none", // Allow buttons to grow
                        },
                    },
                }}
                className="cl-journey-granularity-toggle"
            >
                <ToggleButton value={"ALL" satisfies CJMode} aria-label="all touchpoints">
                    All
                </ToggleButton>
                <ToggleButton value={"COMMISSION_RELEVANT" satisfies CJMode} aria-label="commission relevant">
                    Commission relevant
                </ToggleButton>
                <ToggleButton value={"ALL_WINNERS" satisfies CJMode} aria-label="winners only">
                    Winners only
                </ToggleButton>
                <ToggleButton value={"LAST_WINNER" satisfies CJMode} aria-label="main winner">
                    Main winner
                </ToggleButton>
            </StyledToggleButtonGroup>
        </ToggleSection>
    )
}

const ToggleSection = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1.5, 2), // Reduced from padding: theme.spacing(2) to 1.5 for top/bottom
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5), // Reduced from 2 to 1.5
    backgroundColor: "#FAFBFF", // Match the timeline background color
    minWidth: "fit-content", // Ensure it doesn't shrink below content size
    [theme.breakpoints.up("md")]: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "nowrap", // Prevent wrapping on larger screens
    },
}))

// Custom styled toggle button group
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    backgroundColor: "rgba(117, 127, 159, 0.12)", // Subtle version of our timeline color
    borderRadius: "8px",
    padding: theme.spacing(0.3), // Reduced from 0.5 to make more compact
    "& .MuiToggleButtonGroup-grouped": {
        border: "none",
        borderRadius: "6px !important",
        color: "#757f9f", // Match the timeline color
        backgroundColor: "transparent",
        paddingLeft: theme.spacing(2.2),
        paddingRight: theme.spacing(2.2),
        paddingTop: theme.spacing(0.3), // Added reduced top padding
        paddingBottom: theme.spacing(0.3), // Added reduced bottom padding
        whiteSpace: "nowrap", // Prevent text wrapping within buttons
        height: "28px", // Reduced from 32px to 28px
        fontWeight: 500, // Changed from default to medium weight (500)
        "&.Mui-selected": {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.text.primary,
            boxShadow: "0px 2px 4px rgba(117, 127, 159, 0.15)", // Subtle shadow with our color
            fontWeight: 600,
            "&:hover": {
                backgroundColor: theme.palette.common.white,
            },
        },
        "&:hover": {
            backgroundColor: "rgba(117, 127, 159, 0.2)", // Slightly darker on hover
        },
    },
}))

// Custom styled toggle button group with grid layout (for equal width buttons)
const GridToggleButtonGroup = styled(StyledToggleButtonGroup)(({ theme }) => ({
    display: "grid",
    width: "100%",
    "& .MuiToggleButtonGroup-grouped": {
        // Override the padding for the grid buttons to use default padding
        paddingLeft: theme.spacing(1.5),
        paddingRight: theme.spacing(1.5),
        height: "28px", // Ensure same height here too (reduced from 32px)
    },
}))

const TimelineIconCustom = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12.1953" cy="5.89844" r="1.43906" stroke="currentColor" strokeWidth="1.2" />
        <circle cx="12.1953" cy="11.8984" r="1.43906" stroke="currentColor" strokeWidth="1.2" />
        <circle cx="12.1953" cy="18.0703" r="1.43906" stroke="currentColor" strokeWidth="1.2" />
        <rect
            x="11.8555"
            y="13.9336"
            width="0.679688"
            height="2.03906"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0.679688"
        />
        <rect
            x="11.8555"
            y="7.93359"
            width="0.679688"
            height="2.03906"
            fill="currentColor"
            stroke="currentColor"
            strokeWidth="0.679688"
        />
    </svg>
)

// Custom SVG icons for toggle buttons
const TableIcon = () => (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4 15V16.8002C4 17.9203 4 18.4801 4.21799 18.9079C4.40973 19.2842 4.71547 19.5905 5.0918 19.7822C5.5192 20 6.07899 20 7.19691 20H12M4 15V9M4 15H12M12 20V15M12 20H16.8036C17.9215 20 18.4805 20 18.9079 19.7822C19.2842 19.5905 19.5905 19.2842 19.7822 18.9079C20 18.4805 20 17.9215 20 16.8036V15M4 9V7.2002C4 6.08009 4 5.51962 4.21799 5.0918C4.40973 4.71547 4.71547 4.40973 5.0918 4.21799C5.51962 4 6.08009 4 7.2002 4H12M4 9H12M12 15V9M12 15H20M12 4H16.8002C17.9203 4 18.4801 4 18.9079 4.21799C19.2842 4.40973 19.5905 4.71547 19.7822 5.0918C20 5.5192 20 6.07899 20 7.19691V9M12 4V9M12 9H20M20 9V15"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
