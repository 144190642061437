import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { Box, Button, List, ListItem, ListItemText, Popover, Typography } from "@mui/material"
import { ConversionListType } from "domain/ConversionList/domain/domain"
import {
    TOUCHPOINT_CUSTOMER_JOURNEY_ROLES,
    TouchpointCustomerJourneyRole,
} from "domain/ConversionList/domain/touchpointCustomerJourneyRole"
import type { TouchpointFilterDimension } from "domain/ConversionList/domain/touchpointFilterDimension"
import React, { type JSX, useState } from "react"
import { match } from "ts-pattern"

interface InfoTextProps {
    conversionListType: ConversionListType
    touchpointCustomerJourneyRole: TouchpointCustomerJourneyRole
    selectedTouchpointFilterDimension?: TouchpointFilterDimension
    infoButtonText?: string
}

// Create descriptions based on the more detailed info in the popover
const roleDescriptions = {
    winner: "the main touchpoint receiving attribution credit in the commission model",
    closer: "the last touchpoint in a customer journey before conversion",
    influencer: "touchpoints that contributed to the conversion indirectly",
    introducer: "the first touchpoint in a customer journey",
    involved: "touchpoints that actively participated in the customer journey",
}

export const InfoText = ({
    conversionListType,
    touchpointCustomerJourneyRole,
    infoButtonText = "Find out more",
}: InfoTextProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

    const handleShowMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? "role-descriptions-popover" : undefined

    const getBoldRole = () => {
        return (
            <strong>
                {TOUCHPOINT_CUSTOMER_JOURNEY_ROLES[touchpointCustomerJourneyRole].displayName.toLowerCase()}
            </strong>
        )
    }

    const text = match<ConversionListType, JSX.Element>(conversionListType)
        .with("historical", () => {
            return match<TouchpointCustomerJourneyRole, JSX.Element>(touchpointCustomerJourneyRole)
                .with("winner", () => {
                    return (
                        <Typography variant="body2">
                            This filter selects {getBoldRole()} touchpoints: {roleDescriptions.winner}.
                        </Typography>
                    )
                })
                .with("closer", () => {
                    return (
                        <Typography variant="body2">
                            This filter selects {getBoldRole()} touchpoints: {roleDescriptions.closer}.
                        </Typography>
                    )
                })
                .with("influencer", () => {
                    return (
                        <Typography variant="body2">
                            This filter selects {getBoldRole()} touchpoints: {roleDescriptions.influencer}.
                        </Typography>
                    )
                })
                .with("introducer", () => {
                    return (
                        <Typography variant="body2">
                            This filter selects {getBoldRole()} touchpoints: {roleDescriptions.introducer}.
                        </Typography>
                    )
                })
                .with("involved", () => {
                    return (
                        <Typography variant="body2">
                            This filter selects {getBoldRole()} touchpoints: {roleDescriptions.involved}.
                        </Typography>
                    )
                })
                .exhaustive()
        })
        .with("realtime", () => {
            return (
                <Typography variant="body2">
                    This filter selects realtime touchpoints that match the {getBoldRole()} role.
                </Typography>
            )
        })
        .exhaustive()

    return (
        <Box sx={{ mt: 2 }}>
            {text}
            <Button
                startIcon={<InfoOutlinedIcon />}
                size="small"
                onClick={handleShowMoreClick}
                sx={{ mt: 1, textTransform: "none" }}
            >
                {infoButtonText}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                PaperProps={{
                    sx: { maxWidth: 400, p: 1 },
                }}
            >
                <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1, px: 1, pt: 1 }}>
                    Touchpoint roles
                </Typography>
                <List dense sx={{ pt: 0 }}>
                    <ListItem>
                        <ListItemText
                            primaryTypographyProps={{ fontWeight: "bold" }}
                            primary="Winner"
                            secondary="The main touchpoint that receives attribution credit according to the commission model."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primaryTypographyProps={{ fontWeight: "bold" }}
                            primary="Closer"
                            secondary="The last touchpoint in a customer journey before conversion, often important in the commission model."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primaryTypographyProps={{ fontWeight: "bold" }}
                            primary="Introducer"
                            secondary="The first touchpoint in a customer journey, often important in the commission model."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primaryTypographyProps={{ fontWeight: "bold" }}
                            primary="Involved"
                            secondary="Touchpoints that actively participated in the customer journey with direct customer interaction."
                        />
                    </ListItem>
                    <ListItem>
                        <ListItemText
                            primaryTypographyProps={{ fontWeight: "bold" }}
                            primary="Influencer"
                            secondary="Touchpoints that contributed to the conversion indirectly, but weren't the primary winner or directly involved."
                        />
                    </ListItem>
                </List>
            </Popover>
        </Box>
    )
}
