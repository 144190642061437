import { Box } from "@mui/material"
import { styled } from "@mui/material/styles"
import React from "react"
import { currencyMetricRenderer } from "shared/component/renderers/currency-metric.renderer"

/**
 * Formats seconds into readable time format: "X days, Y hours, Z min"
 */
const formatTimeToConversion = (seconds: number): string => {
    const days = Math.floor(seconds / 86400)
    const hours = Math.floor((seconds % 86400) / 3600)
    const minutes = Math.floor((seconds % 3600) / 60)

    const parts = []
    if (days > 0) parts.push(`${days} ${days === 1 ? "day" : "days"}`)
    if (hours > 0) parts.push(`${hours} ${hours === 1 ? "hour" : "hours"}`)
    if (minutes > 0) parts.push(`${minutes} min`)

    return parts.join(", ") || "0 min"
}

type Props = {
    touchpointsCount: number
    timeToConversion: string
    totalPrice: string
    revenue: string
    devices: number
    currency: string
}

export const CustomerJourneyDetailsSection = ({
    touchpointsCount,
    timeToConversion,
    totalPrice,
    revenue,
    devices,
    currency,
}: Props) => {
    const formattedTime = formatTimeToConversion(Number(timeToConversion))

    return (
        <ConversionDetailsSection>
            <JourneySummary>
                This customer journey spanned <HighlightedValue>{formattedTime}</HighlightedValue>, across{" "}
                <HighlightedValue>{devices} devices</HighlightedValue>, and involved{" "}
                <HighlightedValue>{touchpointsCount} touchpoints</HighlightedValue>.
            </JourneySummary>

            <Box
                sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    columnGap: 2,
                    rowGap: 1.5,
                    width: "100%",
                }}
            >
                <DetailItem sx={{ width: "77px" }}>
                    <DetailLabel>TOTAL PRICE</DetailLabel>
                    <DetailValue>{currencyMetricRenderer(totalPrice, currency)}</DetailValue>
                </DetailItem>

                <DetailItem sx={{ width: "77px" }}>
                    <DetailLabel>REVENUE</DetailLabel>
                    <DetailValue>{currencyMetricRenderer(revenue, currency)}</DetailValue>
                </DetailItem>
            </Box>
        </ConversionDetailsSection>
    )
}
const ConversionDetailsSection = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white, // White background to make it stand out
    borderBottom: `1px solid rgba(117, 127, 159, 0.15)`, // Keep subtle separator
    boxShadow: "0px 1px 3px rgba(117, 127, 159, 0.08)", // Add subtle shadow for depth
}))

const DetailItem = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(1),
}))

const DetailLabel = styled(Box)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: "0.75rem",
    fontWeight: 500,
    textTransform: "uppercase",
    lineHeight: 1.2,
    marginBottom: theme.spacing(0.25),
}))

export const DetailValue = styled(Box)(({ theme: _ }) => ({
    fontWeight: 500,
    lineHeight: 1.3,
}))

export const HighlightedValue = styled("span")(({ theme: _ }) => ({
    fontWeight: 700,
}))

export const JourneySummary = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(2.5),
    lineHeight: 1.5,
    fontSize: "13px",
}))
