/**
 * Runs the given supplier and returns the result.
 *
 * @param supplier
 */
export const run = <T>(supplier: () => T): T => {
    return supplier()
}

/**
 * Flattens the given array.
 * @param array
 */
export const flatten = <T>(array: T[][]): T[] => {
    return array.reduce((acc, val) => [...acc, ...val], [])
}
