import { Popover, Typography } from "@mui/material"
import { PixelDrawer } from "domain/ConversionList/components/ShowPixels/PixelDrawer"
import moment from "moment/moment"
import React from "react"

type PixelOrMessageProps = {
    shown: boolean
    anchorEl: HTMLElement | null
    onWarnMessageClose: () => void
    onCloseDialog: () => void
    pixelTransactionData: PixelTransactionData
}

const PIXEL_RETENTION_DAYS = 60

export type PixelTransactionData = {
    transactionUid: string
    timestamp: string
    campaignId: number
}

export const PixelDrawerOrMessage = (props: PixelOrMessageProps) => {
    const { pixelTransactionData, anchorEl, onWarnMessageClose } = props

    const retentionDate = moment().subtract(PIXEL_RETENTION_DAYS, "days")
    const date = moment(pixelTransactionData.timestamp)

    if (date.isBefore(retentionDate)) {
        const open = Boolean(anchorEl)

        return (
            <Popover
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                onClose={onWarnMessageClose}
            >
                <Typography sx={{ p: 1 }}>Conversion pixel data is available for the past 60 days only.</Typography>
            </Popover>
        )
    } else {
        return <PixelDrawer {...props} />
    }
}
