const difference = <T>(setA: ReadonlySet<T>, setB: ReadonlySet<T>): Set<T> => {
    const difference = new Set(setA)
    for (const elem of setB) {
        difference.delete(elem)
    }
    return difference
}

const intersection = <T>(...sets: ReadonlySet<T>[]): Set<T> => {
    if (sets.length === 0) {
        return new Set<T>()
    }

    // Start with the smallest set
    let smallestSetIndex = 0
    for (let i = 1; i < sets.length; i++) {
        if (sets[i].size < sets[smallestSetIndex].size) {
            smallestSetIndex = i
        }
    }

    const intersection = new Set(sets[smallestSetIndex])

    // Remove elements not in all sets
    for (const elem of intersection) {
        for (let i = 0; i < sets.length; i++) {
            if (i !== smallestSetIndex && !sets[i].has(elem)) {
                intersection.delete(elem)
                break
            }
        }
    }

    return intersection
}

const union = <T>(...sets: ReadonlySet<T>[]): Set<T> => {
    const union = new Set<T>()
    for (const set of sets) {
        for (const elem of set) {
            union.add(elem)
        }
    }
    return union
}

/**
 * Returns true iff setA ⊆ setB.
 */
const isSubset = <T>(setA: ReadonlySet<T>, setB: ReadonlySet<T>): boolean => {
    // Iterate through each element in setA
    for (const elem of setA) {
        // If elem is not found in setB, setA ⊈ setB
        if (!setB.has(elem)) {
            return false
        }
    }
    // If all elements were found, setA ⊆ setB
    return true
}

const SetUtil = {
    difference,
    intersection,
    union,
    isSubset,
}

export default SetUtil
