import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import { TrackingStateSelection } from "domain/ConversionList/domain/trackingState"
import React from "react"

export const TrackingStateRadioButtonGroup = () => {
    const trackingStateSelection = ConversionListContextSelectors.useTrackingStateSelection()
    const updateTrackingStateSelection = ConversionListContextSelectors.useUpdateTrackingStateSelection()

    const selectedValues = Object.entries(trackingStateSelection)
        .filter(([, value]) => value)
        .map(([key]) => key)

    const handleChange = (_: React.MouseEvent<HTMLElement>, trackingStates: string[]) => {
        if (trackingStates.length > 0) {
            updateTrackingStateSelection({
                tracked: trackingStates.includes("tracked"),
                "n/a": trackingStates.includes("n/a"),
            } satisfies TrackingStateSelection)
        }
    }

    return (
        <ToggleButtonGroup
            color="primary"
            value={selectedValues}
            onChange={handleChange}
            size="small"
            fullWidth
            sx={{
                "& .MuiToggleButton-root": {
                    padding: "6px 6px", // Reduced horizontal padding
                    whiteSpace: "nowrap", // Prevent text wrapping
                },
            }}
        >
            <ToggleButton value="tracked" sx={{ width: "50%" }}>
                Attributable
            </ToggleButton>
            <ToggleButton value="n/a" sx={{ width: "50%" }}>
                Non-attributable
            </ToggleButton>
        </ToggleButtonGroup>
    )
}
