import {
    CAMPAIGN,
    CHANNEL,
    CONVERSION_LIST_TP_DEVICE_TYPE,
    CONVERSION_LIST_TP_IDENTIFIED_BY,
    CONVERSION_LIST_TP_TS,
    CONVERSION_LIST_TP_TYPE,
    IS_CLOSER,
    IS_CONTENT,
    IS_INTRODUCER,
    IS_WINNER,
    PROVIDER,
    PUBLISHER,
    SUB_ID,
} from "domain/ConversionList/domain/dimensionIdentifiers"
import { ColumnResponseDTO, LoadResponseDTOReportingDataSetDTO } from "generated/models"

export const TouchPointMapper = {
    getTouchPoints: (response: LoadResponseDTOReportingDataSetDTO): TouchPoint[] => {
        return response.dataSet.rows.map((row, index) => {
            const type: string = getNameOrValueOrDefault(row, CONVERSION_LIST_TP_TYPE)
            const isIntroducer = row[IS_INTRODUCER]?.value === 1
            const isCloser = row[IS_CLOSER]?.value === 1
            const isWinner = row[IS_WINNER]?.value === 1
            const isContent = row[IS_CONTENT]?.value === 1

            return {
                id: index + 1,
                date: row[CONVERSION_LIST_TP_TS]?.value?.split(" ")[0] || "",
                time: row[CONVERSION_LIST_TP_TS]?.value?.split(" ")[1] || "",
                type: type,
                channel: getNameOrValueOrDefault(row, CHANNEL),
                provider: getNameOrValueOrDefault(row, PROVIDER),
                publisher: getNameOrValueOrDefault(row, PUBLISHER),
                campaign: getNameOrValueOrDefault(row, CAMPAIGN),
                pageType: undefined,
                pageData: undefined,
                marketing: type === "View" || type === "Click",
                organic: type === "SEO",
                role: isIntroducer
                    ? "Introducer"
                    : isCloser
                      ? "Converter"
                      : isWinner
                        ? "Influencer"
                        : isContent
                          ? "Involved"
                          : "Involved",
                deviceType: getNameOrValueOrDefault(row, CONVERSION_LIST_TP_DEVICE_TYPE),
                isConversion: undefined,
                timeToConversion: undefined,
                winnerPos: undefined,
                revenue: undefined,
                referrer: undefined,
                creative: undefined,
                identityType: getIdentifiedByMapping(row),
                subId: getNameOrValueOrDefault(row, SUB_ID),
            } satisfies TouchPoint
        })
    },
}

const getNameOrValueOrDefault = (row: Record<string, ColumnResponseDTO>, key: string): string => {
    return row[key]?.name ?? row[key]?.value?.toString() ?? ""
}

const getIdentifiedByMapping = (row: Record<string, ColumnResponseDTO>): string => {
    const identifiedBy = getNameOrValueOrDefault(row, CONVERSION_LIST_TP_IDENTIFIED_BY)
    let identifiedByName = "Unknown"
    switch (identifiedBy) {
        case "c": {
            identifiedByName = "Cookie"
            break
        }
        case "f": {
            identifiedByName = "Fingerprint"
            break
        }
        case "x": {
            identifiedByName = "Cross Device"
            break
        }
        default: {
            identifiedByName = "Unknown"
            break
        }
    }

    return identifiedByName
}
