import { Tag, TagsUtil } from "domain/ColumnConfigurator/TagsUtil"
import { ColumnUniqueName, Metric, Model } from "domain/ColumnConfigurator/types"

function getModel(columnName: ColumnUniqueName): Model | undefined
function getModel(metric: Metric): Model | undefined
function getModel(columnNameOrMetric: ColumnUniqueName | Metric): Model | undefined {
    let tags: Tag[]
    if (typeof columnNameOrMetric === "string") {
        tags = TagsUtil.getTags(columnNameOrMetric)
    } else {
        tags = Array.from(columnNameOrMetric.tags ?? [])
    }

    const model = tags.find(
        (tag) => tag === Tag.COMMISSION || tag === Tag.DIGITAL || tag === Tag.ENHANCED || tag === Tag.AVM,
    )

    switch (model) {
        case Tag.COMMISSION:
            return Model.COMMISSION
        case Tag.DIGITAL:
            return Model.DIGITAL
        case Tag.ENHANCED:
            return Model.ENHANCED
        case Tag.AVM:
            return Model.AVM
        default:
            return undefined
    }
}

export const ModelUtil = {
    getModel: getModel,
}
