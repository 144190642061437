import { Button } from "@mui/material"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import React from "react"

export const UpdateButton = () => {
    const hasUnappliedChanges = ConversionListContextSelectors.useHasUnappliedChanges()
    const applyChanges = ConversionListContextSelectors.useApplyChanges()

    return (
        <Button
            className="update-button conversion-list-show-result-button"
            disabled={!hasUnappliedChanges}
            onClick={(_) => applyChanges()}
            variant="contained"
            size="medium"
            fullWidth
            sx={{
                transition: "all 0.3s ease",
                width: "100%",
                "&:active": {
                    backgroundColor: "primary.dark",
                    color: "primary.contrastText",
                    opacity: 0.9,
                },
            }}
        >
            Apply Changes
        </Button>
    )
}
