import { SelectAllNoneCheckboxProps } from "shared/component/mui/SelectAllNoneCheckbox"

export enum ExpandCollapseButtonState {
    COLLAPSED,
    EXPANDED,
}

export interface MetricCheckboxState {
    readonly visibility: "invisible" | "visible" | "disabled"
    readonly checked: boolean
}

export type TileUniqueName = string

export enum TileType {
    REGULAR = "REGULAR",
    MATRIX = "MATRIX",
}

export interface Tile {
    readonly uniqueName: TileUniqueName
    readonly displayName: string
    readonly type: TileType
}

export type SelectAllNoneOption =
    | {
          state: "disabled"
      }
    | SelectAllNoneCheckboxProps
export const SELECT_ALL_NONE_DISABLED: SelectAllNoneOption = { state: "disabled" }
