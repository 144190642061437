import ClearIcon from "@mui/icons-material/Clear"
import SearchIcon from "@mui/icons-material/Search"
import IconButton from "@mui/material/IconButton"
import InputAdornment from "@mui/material/InputAdornment"
import TextField from "@mui/material/TextField"
import { ColumnConfiguratorContextSlices } from "domain/ColumnConfigurator/context/ColumnConfiguratorContextSlices"
import React, { type JSX } from "react"

interface SearchInputProps {
    onQueryChange: (query: string) => void
    query: string
}

export const SearchInput = ({ onQueryChange, query }: SearchInputProps): JSX.Element => {
    const labels = ColumnConfiguratorContextSlices.useLabels()
    const placeholder = `Find ${labels.metricPlural}...`

    return (
        <div>
            <TextField
                placeholder={placeholder}
                type="search"
                size="small"
                sx={{
                    marginBottom: { sm: "16px" },
                }}
                value={query}
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    endAdornment: query.length > 0 && (
                        <IconButton onClick={() => onQueryChange("")}>
                            <ClearIcon />
                        </IconButton>
                    ),
                }}
                onChange={(event) => onQueryChange(event.target.value)}
            />
        </div>
    )
}
