import { Checkbox, FormControlLabel } from "@mui/material"
import { InfoTextContent } from "domain/ColumnConfigurator/components/ColumnSelectionPanel/InfoTextContent"
import { ColumnConfiguratorContextSlices } from "domain/ColumnConfigurator/context/ColumnConfiguratorContextSlices"
import { ColumnUniqueName } from "domain/ColumnConfigurator/types"
import { useReportingConfigurationContext } from "domain/reporting/ReportingConfigurationContext"
import CustomTooltip from "layout/components/CustomTooltip"
import React, { type JSX } from "react"

interface TooltipTitleProps {
    columnUniqueName: ColumnUniqueName
    columnDisplayName: string
    isDisabled: boolean
}

const TooltipTitle = ({ columnUniqueName, columnDisplayName, isDisabled }: TooltipTitleProps): JSX.Element => {
    const {
        dataDefinitions: { dimensions },
    } = useReportingConfigurationContext()
    const dimensionMetricCompatibility = ColumnConfiguratorContextSlices.useDimensionMetricCompatibility()

    if (isDisabled) {
        const incompatibleDimensions = Array.from(
            dimensionMetricCompatibility.getIncompatibleDimensionsForMetric(columnUniqueName) ?? [],
        )
            .map((dimensionName) => dimensions.get(dimensionName)?.displayName)
            .filter((displayName): displayName is string => displayName !== undefined)

        return (
            <>{`This metric is incompatible with the following selected dimensions: ${incompatibleDimensions.join(
                ", ",
            )}`}</>
        )
    }

    return <InfoTextContent columnUniqueName={columnUniqueName} columnDisplayName={columnDisplayName} />
}

interface MetricCheckboxWithTooltipProps {
    columnUniqueName: ColumnUniqueName
    columnDisplayName: string
    withCheckboxLabel: boolean
    isDisabled: boolean
    checked: boolean
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const MetricCheckboxWithTooltip = ({
    columnUniqueName,
    columnDisplayName,
    withCheckboxLabel,
    isDisabled,
    checked,
    onChange,
}: MetricCheckboxWithTooltipProps): JSX.Element => {
    const checkbox = (
        <Checkbox size={"small"} id={columnUniqueName} checked={checked} disabled={isDisabled} onChange={onChange} />
    )

    const checkboxWithLabel = withCheckboxLabel ? (
        <FormControlLabel className={"metric-checkbox"} control={checkbox} label={columnDisplayName} />
    ) : (
        checkbox
    )

    const tooltip = (
        <TooltipTitle
            columnUniqueName={columnUniqueName}
            columnDisplayName={columnDisplayName}
            isDisabled={isDisabled}
        />
    )

    return (
        <CustomTooltip title={tooltip} placement="right">
            {checkboxWithLabel}
        </CustomTooltip>
    )
}

export const MemoizedMetricCheckboxWithTooltip = React.memo(MetricCheckboxWithTooltip)
