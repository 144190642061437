import { Box } from "@mui/material"
import { TimeSpanSelection } from "domain/ConversionList/components/ConfigPanel/TimeSpanSelection"
import moment from "moment"
import React from "react"

/**
 * TimeSpanSelection styled specifically for the Export Dialog
 * This prevents style conflicts with the sidebar TimeSpanSelection component
 */
export const StyledTimeSpanSelection = (props: {
    minDateTime: moment.Moment
    maxDateTime: moment.Moment
    direction: "row" | "column"
}) => {
    return (
        <Box
            className="export-dialog-timespan"
            sx={{
                "& .time-span-selection": {
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: "space-between",
                    width: "100%",
                    "& .time-span-start-row, & .time-span-end-row": {
                        width: { xs: "100%", sm: "48%" },
                        "& .MuiOutlinedInput-root": {
                            fontSize: "14px",
                            height: "40px",
                            backgroundColor: "transparent",
                            "& .MuiInputBase-input": {
                                padding: "8px 10px",
                            },
                            "& fieldset": {
                                backgroundColor: "transparent",
                            },
                            "& .MuiInputAdornment-root .MuiSvgIcon-root": {
                                fontSize: "20px",
                                color: "primary.main",
                            },
                        },
                        "& .MuiTypography-root": {
                            fontSize: "14px",
                            fontWeight: "500",
                            width: "45px",
                            marginRight: 0.7,
                        },
                        "& .MuiDateTimePicker-root": {
                            backgroundColor: "transparent",
                        },
                    },
                    "& .time-span-start-row": {
                        justifyContent: { xs: "flex-start", sm: "flex-start" },
                        "& .MuiTypography-root": {
                            textAlign: "left",
                        },
                    },
                    "& .time-span-end-row": {
                        marginLeft: { xs: 0, sm: 5 },
                        marginTop: { xs: 2, sm: 0 },
                        justifyContent: { xs: "flex-start", sm: "flex-start" },
                        "& .MuiTypography-root": {
                            textAlign: { xs: "left", sm: "right" },
                        },
                    },
                    "& .time-span-start-row .start-date, & .time-span-end-row .end-date": {
                        "& .MuiInputBase-root": {
                            backgroundColor: "transparent",
                        },
                    },
                },
            }}
        >
            <TimeSpanSelection
                minDateTime={props.minDateTime}
                maxDateTime={props.maxDateTime}
                direction={props.direction}
            />
        </Box>
    )
}
