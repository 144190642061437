import { Box, Typography, useTheme } from "@mui/material"
import { styled } from "@mui/material/styles"
import { DesktopSvgIcon, SmartphoneSvgIcon } from "domain/ConversionList/components/CustomerJourney/svgImages"
import React from "react"

type Props = {
    touchPoints: TouchPoint[]
}

export const CustomerJourneyDetailContainer = ({ touchPoints }: Props) => {
    const theme = useTheme()

    return (
        <DetailContainer>
            {/* Removed the Customer Journey Details header */}

            <Box sx={{ overflowX: "auto" }}>
                <table
                    style={{
                        width: "100%",
                        borderCollapse: "collapse",
                        fontSize: "12px",
                        lineHeight: 1.2,
                    }}
                >
                    <thead>
                        <tr
                            style={{
                                borderBottom: `1px solid ${theme.palette.divider}`,
                                backgroundColor: theme.palette.grey[50],
                            }}
                        >
                            <th
                                style={{
                                    padding: "4px 8px",
                                    textAlign: "left",
                                    fontWeight: 600,
                                }}
                            >
                                Time
                            </th>
                            <th
                                style={{
                                    padding: "4px 8px",
                                    textAlign: "left",
                                    fontWeight: 600,
                                }}
                            >
                                Time to Conv.
                            </th>
                            <th
                                style={{
                                    padding: "4px 8px",
                                    textAlign: "left",
                                    fontWeight: 600,
                                }}
                            >
                                Type
                            </th>
                            <th
                                style={{
                                    padding: "4px 8px",
                                    textAlign: "left",
                                    fontWeight: 600,
                                }}
                            >
                                Channel
                            </th>
                            <th
                                style={{
                                    padding: "4px 8px",
                                    textAlign: "left",
                                    fontWeight: 600,
                                }}
                            >
                                Subcampaign
                            </th>
                            <th
                                style={{
                                    padding: "8px 12px",
                                    textAlign: "left",
                                    fontWeight: 600,
                                }}
                            >
                                Publisher
                            </th>
                            <th
                                style={{
                                    padding: "8px 12px",
                                    textAlign: "left",
                                    fontWeight: 600,
                                }}
                            >
                                Win. Pos.
                            </th>
                            <th
                                style={{
                                    padding: "8px 12px",
                                    textAlign: "left",
                                    fontWeight: 600,
                                }}
                            >
                                Win. %
                            </th>
                            <th
                                style={{
                                    padding: "8px 12px",
                                    textAlign: "left",
                                    fontWeight: 600,
                                }}
                            >
                                Revenue
                            </th>
                            <th
                                style={{
                                    padding: "8px 12px",
                                    textAlign: "left",
                                    fontWeight: 600,
                                }}
                            >
                                Device
                            </th>
                            <th
                                style={{
                                    padding: "8px 12px",
                                    textAlign: "left",
                                    fontWeight: 600,
                                }}
                            >
                                Referrer
                            </th>
                            <th
                                style={{
                                    padding: "8px 12px",
                                    textAlign: "left",
                                    fontWeight: 600,
                                }}
                            >
                                Creative
                            </th>
                            <th
                                style={{
                                    padding: "8px 12px",
                                    textAlign: "left",
                                    fontWeight: 600,
                                }}
                            >
                                Identity Type
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {touchPoints.map((touchPoint) => (
                            <tr
                                key={touchPoint.id}
                                style={{
                                    borderBottom: `1px solid ${theme.palette.divider}`,
                                }}
                            >
                                <td
                                    style={{
                                        padding: "8px 12px",
                                        textAlign: "left",
                                    }}
                                >
                                    {touchPoint.time}
                                </td>
                                <td
                                    style={{
                                        padding: "8px 12px",
                                        textAlign: "left",
                                    }}
                                >
                                    {touchPoint?.timeToConversion || "-"}
                                </td>
                                <td
                                    style={{
                                        padding: "8px 12px",
                                        textAlign: "left",
                                    }}
                                >
                                    {touchPoint.type}
                                </td>
                                <td
                                    style={{
                                        padding: "8px 12px",
                                        textAlign: "left",
                                    }}
                                >
                                    {touchPoint.channel}
                                </td>
                                <td
                                    style={{
                                        padding: "8px 12px",
                                        textAlign: "left",
                                    }}
                                >
                                    {touchPoint.campaign}
                                </td>
                                <td
                                    style={{
                                        padding: "8px 12px",
                                        textAlign: "left",
                                    }}
                                >
                                    {touchPoint.publisher}
                                </td>
                                <td
                                    style={{
                                        padding: "8px 12px",
                                        textAlign: "left",
                                    }}
                                >
                                    {touchPoint?.winnerPos || touchPoint.role || "-"}
                                </td>
                                <td
                                    style={{
                                        padding: "8px 12px",
                                        textAlign: "left",
                                    }}
                                >
                                    {touchPoint?.revenue || "-"}
                                </td>
                                <td
                                    style={{
                                        padding: "8px 12px",
                                        textAlign: "left",
                                    }}
                                >
                                    {touchPoint.deviceType && (
                                        <IconOnlyTag>{getDeviceTypeIcon(touchPoint.deviceType)}</IconOnlyTag>
                                    )}
                                </td>
                                <td
                                    style={{
                                        padding: "8px 12px",
                                        textAlign: "left",
                                    }}
                                >
                                    {touchPoint?.referrer || "-"}
                                </td>
                                <td
                                    style={{
                                        padding: "8px 12px",
                                        textAlign: "left",
                                    }}
                                >
                                    {touchPoint?.creative || "-"}
                                </td>
                                <td
                                    style={{
                                        padding: "8px 12px",
                                        textAlign: "left",
                                        display: "flex",
                                        gap: "4px",
                                        alignItems: "center",
                                    }}
                                >
                                    {touchPoint.marketing ? (
                                        <>
                                            <Typography component="span" sx={{ fontSize: "11px" }}>
                                                Paid
                                            </Typography>
                                        </>
                                    ) : (
                                        <Typography component="span" sx={{ color: "#9e9e9e", fontSize: "11px" }}>
                                            Organic
                                        </Typography>
                                    )}
                                </td>
                                <td
                                    style={{
                                        padding: "8px 12px",
                                        textAlign: "left",
                                        display: "flex",
                                        gap: "4px",
                                        alignItems: "center",
                                    }}
                                >
                                    {touchPoint.identityType}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Box>
        </DetailContainer>
    )
}

const DetailContainer = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    flexGrow: 1,
    backgroundColor: "#FAFBFF", // Match the timeline background color
    // Removed overflowY: "auto" to let the parent handle scrolling
}))

// Value name item for tags in timeline view - Replace box shadow with light gray border
export const ValueNameItem = styled(Box)(({ theme }) => ({
    display: "inline-flex",
    alignItems: "center",
    padding: "2px 8px",
    margin: "0 4px 4px 0",
    backgroundColor: theme.palette.common.white,
    border: `1px solid #e8e8e8`, // Lighter gray border
    borderRadius: "4px",
    fontSize: "11px", // Reduced from 0.75rem (12px) to 11px
    lineHeight: 1.3,
    height: "22px", // Fixed height to ensure consistency
    boxSizing: "border-box",
    "& img, & svg": {
        width: 14,
        height: 14,
        marginRight: 4,
    },
}))

// Special version of ValueNameItem just for icon-only tags
export const IconOnlyTag = styled(ValueNameItem)({
    padding: "4px",
    width: 22,
    height: 22, // Height already standardized at 22px
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img, & svg": {
        marginRight: 0,
    },
})

// Function to get device type icon
export const getDeviceTypeIcon = (deviceType: string) => {
    // Should use icons from device_type/images folder
    switch (deviceType) {
        case "Desktop":
            // Using icons from device_type/images folder instead
            return <DesktopSvgIcon />
        case "Smartphone":
            // Using icons from device_type/images folder instead
            return <SmartphoneSvgIcon />
        default:
            return <DesktopSvgIcon />
    }
}
