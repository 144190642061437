const getMinValue = (values: number[]): number => {
    return Math.min(...values) as number
}

const getMaxValue = (values: number[]): number => {
    return Math.max(...values) as number
}

const NumberUtil = {
    getMinValue: getMinValue,
    getMaxValue: getMaxValue,
}
export default NumberUtil
