import { Box } from "@mui/material"
import Grid from "@mui/material/Grid2"
import Typography from "@mui/material/Typography"
import { MetricSelectionAccordion } from "domain/ColumnConfigurator/components/ColumnSelectionPanel/MetricSelectionPanel/MetricSelectionAccordion/MetricSelectionAccordion"
import { MetricsTile } from "domain/ColumnConfigurator/components/ColumnSelectionPanel/MetricSelectionPanel/MetricSelectionAccordion/MetricsTile"
import { MetricSelectionPanelTopbar } from "domain/ColumnConfigurator/components/ColumnSelectionPanel/MetricSelectionPanel/MetricSelectionPanelTopbar/MetricSelectionPanelTopbar"
import { Search } from "domain/ColumnConfigurator/components/ColumnSelectionPanel/MetricSelectionPanel/Search"
import { TileUtil } from "domain/ColumnConfigurator/components/ColumnSelectionPanel/MetricSelectionPanel/TileUtil"
import {
    SELECT_ALL_NONE_DISABLED,
    SelectAllNoneOption,
    Tile,
    TileType,
} from "domain/ColumnConfigurator/components/ColumnSelectionPanel/MetricSelectionPanel/types"
import { ColumnConfiguratorContextSlices } from "domain/ColumnConfigurator/context/ColumnConfiguratorContextSlices"
import {
    ColumnUniqueName,
    Metric,
    MetricsFrontendGroup,
    MetricsFrontendGroupUniqueName,
} from "domain/ColumnConfigurator/types"
import { useReportingConfigurationContext } from "domain/reporting/ReportingConfigurationContext"
import { produce } from "immer"
import React, { type JSX, useState } from "react"
import { run } from "shared/util/FunctionUtil"
import SetUtil from "shared/util/SetUtil"
import { WrapperProps } from "shared/util/Wrapper"
import { log } from "shared/util/log"
import { FONT_WEIGHT_SEMI_BOLD } from "styles/theme/constants"

export const MetricSelectionPanel = (): JSX.Element => {
    const {
        dataDefinitions: { metricsFrontendGroups },
        helpers: { findMetrics },
    } = useReportingConfigurationContext()

    const labels = ColumnConfiguratorContextSlices.useLabels()

    const { onColumnSelectAction } = ColumnConfiguratorContextSlices.useColumnSettingsChangeHandlers()
    const { supportedMetrics } = ColumnConfiguratorContextSlices.useSupportedColumns()
    const dimensionMetricCompatibility = ColumnConfiguratorContextSlices.useDimensionMetricCompatibility()
    const selectedMetrics = ColumnConfiguratorContextSlices.useSelectedMetrics()
    const { expandedGroups, onExpandedGroupsChanged: setExpandedGroups } =
        ColumnConfiguratorContextSlices.useExpandedGroups()
    const expandedGroupsBeforeSearch = React.useRef<Set<MetricsFrontendGroupUniqueName> | null>(null)

    const findSupportedMetrics = React.useCallback(
        (predicate: (metric: Metric) => boolean): ReadonlySet<ColumnUniqueName> => {
            return SetUtil.intersection(findMetrics(predicate), supportedMetrics)
        },
        [findMetrics, supportedMetrics],
    )

    const [search, setSearch] = useState<Search>(new Search(findSupportedMetrics, ""))

    React.useEffect(() => {
        setSearch((oldSearch) => new Search(findSupportedMetrics, oldSearch.getQuery()))
    }, [findSupportedMetrics])

    /**
     * Frontend groups that contain only metrics that are supported by the current configuration and contained in the search result.
     */
    const filteredMetricsFrontendGroups = React.useMemo(() => {
        const result = new Map<MetricsFrontendGroupUniqueName, MetricsFrontendGroup>()

        for (const [groupName, group] of metricsFrontendGroups.entries()) {
            const metrics = SetUtil.intersection(group.metrics, search.getResult().metrics)

            if (metrics.size > 0) {
                result.set(groupName, {
                    ...group,
                    metrics,
                })
            }
        }

        return result
    }, [metricsFrontendGroups, search])

    /**
     * Expand all groups that contain at least one metric that is contained in the search result.
     */
    React.useEffect(() => {
        if (search.getQuery() !== "") {
            const searchResultGroups = new Set<MetricsFrontendGroupUniqueName>()

            for (const group of filteredMetricsFrontendGroups.values()) {
                if (TileUtil.matrixFrontendGroupNames.includes(group.uniqueName)) {
                    searchResultGroups.add(TileUtil.matrixTileIdentifier)
                } else {
                    searchResultGroups.add(group.uniqueName)
                }
            }

            setExpandedGroups(searchResultGroups)
        }
    }, [filteredMetricsFrontendGroups, search, setExpandedGroups])

    React.useEffect(() => {
        // If search is used, back up expanded groups
        if (search.getQuery() !== "" && expandedGroupsBeforeSearch.current === null) {
            log.debug("Backing up expanded groups as search is used")
            expandedGroupsBeforeSearch.current = expandedGroups
        }

        // If search is cleared, restore expanded groups
        if (search.getQuery() === "" && expandedGroupsBeforeSearch.current !== null) {
            log.debug("Restoring expanded groups after search is cleared")
            setExpandedGroups(expandedGroupsBeforeSearch.current)
            expandedGroupsBeforeSearch.current = null
        }
    }, [search, expandedGroups, setExpandedGroups])

    const tiles = React.useMemo(() => TileUtil.getTiles(filteredMetricsFrontendGroups), [filteredMetricsFrontendGroups])

    const onSelectAllClick = React.useCallback(() => {
        const { metrics } = search.getResult()
        const compatibleMetrics = SetUtil.intersection(metrics, dimensionMetricCompatibility.compatibleMetrics)

        for (const metric of compatibleMetrics) {
            onColumnSelectAction(metric, "add")
        }
    }, [onColumnSelectAction, dimensionMetricCompatibility, search])

    const onDeselectAllClick = React.useCallback(() => {
        const { metrics } = search.getResult()

        for (const metric of metrics) {
            onColumnSelectAction(metric, "delete")
        }
    }, [onColumnSelectAction, search])

    const onSearchQueryChanged = React.useCallback(
        (query: string) => {
            const newSearch = new Search(findSupportedMetrics, query)
            setSearch(newSearch)
        },
        [findSupportedMetrics],
    )

    const onMetricCheckboxChangeHandler = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            onColumnSelectAction(e.target.id, "toggle")
        },
        [onColumnSelectAction],
    )

    const makeOnExpandedAreasChangeHandler = React.useCallback(
        (groupName: MetricsFrontendGroupUniqueName) => (isExpanded: boolean) => {
            if (isExpanded) {
                setExpandedGroups(
                    produce(expandedGroups, (draft) => {
                        draft.add(groupName)
                    }),
                )
            } else {
                setExpandedGroups(
                    produce(expandedGroups, (draft) => {
                        draft.delete(groupName)
                    }),
                )
            }
        },
        [expandedGroups, setExpandedGroups],
    )

    const onExpandedAreasChangeHandlers: Map<MetricsFrontendGroupUniqueName, (isExpanded: boolean) => void> =
        React.useMemo(() => {
            const result = new Map()

            for (const tile of tiles.keys()) {
                result.set(tile.uniqueName, makeOnExpandedAreasChangeHandler(tile.uniqueName))
            }

            return result
        }, [tiles, makeOnExpandedAreasChangeHandler])

    const metricSelectionAccordions: JSX.Element[] = React.useMemo(() => {
        const result = []
        for (const tile of tiles.keys()) {
            result.push(
                <MemoizedAccordionContent
                    key={tile.uniqueName}
                    tiles={tiles}
                    tile={tile}
                    search={search}
                    onMetricCheckboxChangeHandler={onMetricCheckboxChangeHandler}
                    isExpanded={expandedGroups.has(tile.uniqueName)}
                    onExpandedAreasChangeHandler={onExpandedAreasChangeHandlers.get(tile.uniqueName)!}
                />,
            )
        }
        return result
    }, [tiles, search, onMetricCheckboxChangeHandler, expandedGroups, onExpandedAreasChangeHandlers])

    const metricSelectionHint = selectedMetrics.length === 0 ? `Please select at least one ${labels.metric}` : undefined
    const metricSelectionHintBox = metricSelectionHint ? (
        <Box sx={{ color: "error.main" }}>{metricSelectionHint}</Box>
    ) : (
        <React.Fragment />
    )

    return (
        <Box className={"metric-selection-panel"}>
            <Grid container sx={{ paddingBottom: "10px" }}>
                <Grid sx={{ paddingBottom: "5px" }}>
                    <Typography variant="h5" sx={{ fontSize: "18px", fontWeight: FONT_WEIGHT_SEMI_BOLD }}>
                        Select {labels.metricPlural.charAt(0).toUpperCase() + labels.metricPlural.slice(1)}:
                    </Typography>
                </Grid>
                <Grid container alignItems="center" sx={{ paddingLeft: "10px" }}>
                    {metricSelectionHintBox}
                </Grid>
            </Grid>
            <MetricSelectionPanelTopbar
                searchQuery={search.getQuery()}
                onSearchQueryChanged={onSearchQueryChanged}
                expandedGroups={expandedGroups}
                onExpandedGroupsChanged={setExpandedGroups}
                searchResultsCount={search.getResult().metrics.size}
                onSelectAllClick={onSelectAllClick}
                onDeselectAllClick={onDeselectAllClick}
            />
            <Box sx={{ flexGrow: 2 }}>
                <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 1, sm: 2, lg: 4 }}>
                    {search.getResult().metrics.size === 0 ? (
                        <Typography variant="body1" gutterBottom>
                            No matching metrics found
                        </Typography>
                    ) : (
                        metricSelectionAccordions
                    )}
                </Grid>
            </Box>
        </Box>
    )
}

const makeTileAccordionWrapper = (
    tile: Tile,
    isExpanded: boolean,
    onExpandedAreasChangeHandler: (isExpanded: boolean) => void,
    selectAllNoneOption?: SelectAllNoneOption,
) => {
    return ({ children }: WrapperProps) => {
        return (
            <Grid key={tile.uniqueName} size={tile.type === TileType.REGULAR ? 1 : 18}>
                <MetricSelectionAccordion
                    accordionDisplayName={tile.displayName}
                    accordionExpanded={isExpanded}
                    onAccordionExpandedChanged={onExpandedAreasChangeHandler}
                    selectAllNoneOption={selectAllNoneOption}
                >
                    {children}
                </MetricSelectionAccordion>
            </Grid>
        )
    }
}

interface AccordionContentProps {
    tiles: Map<Tile, MetricsFrontendGroup[]>
    tile: Tile
    search: Search
    onMetricCheckboxChangeHandler: (e: React.ChangeEvent<HTMLInputElement>) => void
    isExpanded: boolean
    onExpandedAreasChangeHandler: (isExpanded: boolean) => void
}

const AccordionContent = ({
    tiles,
    tile,
    search,
    onMetricCheckboxChangeHandler,
    isExpanded,
    onExpandedAreasChangeHandler,
}: AccordionContentProps) => {
    const allSelectedMetrics = ColumnConfiguratorContextSlices.useSelectedMetrics()
    const { onColumnSelectAction } = ColumnConfiguratorContextSlices.useColumnSettingsChangeHandlers()

    const frontendGroups = tiles.get(tile) ?? []
    const metricsInGroup = SetUtil.union(...frontendGroups.map((group) => group.metrics))

    const selectAllNoneOption: SelectAllNoneOption = run(() => {
        if (tile.type === "MATRIX") {
            return SELECT_ALL_NONE_DISABLED
        }

        const handleSelectAll = () => {
            for (const metric of metricsInGroup) {
                onColumnSelectAction(metric, "add")
            }
        }

        const handleSelectNone = () => {
            for (const metric of metricsInGroup) {
                onColumnSelectAction(metric, "delete")
            }
        }

        const selectedMetricsInGroup = SetUtil.intersection(
            metricsInGroup,
            new Set(allSelectedMetrics.map((metric) => metric.identifier)),
        )

        const state = run(() => {
            // If no metrics are selected, the checkbox is unchecked and the user can select all metrics
            if (selectedMetricsInGroup.size === 0) {
                return "unchecked"
            }

            // If all metrics are selected, the checkbox is checked and the user can deselect all metrics
            if (SetUtil.isSubset(metricsInGroup, selectedMetricsInGroup)) {
                return "checked"
            }

            // If some metrics are selected, the checkbox is indeterminate and the user can deselect all metrics
            return "indeterminate"
        })

        return {
            state,
            onSelectAll: handleSelectAll,
            onSelectNone: handleSelectNone,
        }
    })

    return (
        <MetricsTile
            key={tile.uniqueName}
            tile={tile}
            metrics={metricsInGroup}
            onCheckboxChangeHandler={onMetricCheckboxChangeHandler}
            hasSearchQuery={search.getQuery() !== ""}
            Wrapper={makeTileAccordionWrapper(tile, isExpanded, onExpandedAreasChangeHandler, selectAllNoneOption)}
        />
    )
}

const MemoizedAccordionContent = React.memo(AccordionContent)
