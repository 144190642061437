import { TextField } from "@mui/material"
import React, { useState } from "react"

type Props = {
    totalPages: number
    onPageChange: (page: number) => void
    disabled?: boolean
}

export const GoToPagination = ({ totalPages, onPageChange, disabled = false }: Props) => {
    const [pageInput, setPageInput] = useState("")

    const handleInputChange = (event) => {
        setPageInput(event.target.value)
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        const pageNum = parseInt(pageInput, 10)

        if (pageNum >= 1 && pageNum <= totalPages) {
            onPageChange(pageNum)
            setPageInput("")
        } else if (pageNum > totalPages) {
            onPageChange(totalPages)
            setPageInput("")
        } else {
            onPageChange(1)
            setPageInput("")
        }
    }

    return (
        <div className="go-to-pagination" style={{ marginLeft: "10px" }}>
            <form onSubmit={handleSubmit}>
                <TextField
                    label="Go to page"
                    size="small"
                    variant="outlined"
                    value={pageInput}
                    onChange={handleInputChange}
                    placeholder={`1 to ${totalPages}`}
                    style={{ width: "100px", marginTop: "-2px" }}
                    disabled={disabled}
                />
            </form>
        </div>
    )
}
