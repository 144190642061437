import { UseQueryResult, useQuery, useQueryClient } from "@tanstack/react-query"
import { ExportTaskService } from "domain/exporttask/ExportTaskService"
import { ExportQuerySettingsDTO, ExportTaskDTO, ExportTaskDeleteResponseDTO } from "generated/models"
import { produce } from "immer"
import React, { ReactNode, createContext, useContext, useRef, useState } from "react"
import { useAnalytics } from "shared/analytics/AnalyticsContext"
import { log } from "shared/util/log"

export interface ExportCenterContextProperties {
    children?: ReactNode
    exportTaskCreatedCounter: number
    setPolling: (polling: boolean) => void
    tasks: ExportTaskDTO[]
    setTasks: (tasks: ExportTaskDTO[]) => void
    useLoadExportTaskList: () => UseQueryResult<ExportTaskDTO[], Error>
    invalidateQuery: (polling: boolean) => void
    createExportTask: (serviceContextPath: string, exportSettingsDTO: ExportQuerySettingsDTO) => Promise<ExportTaskDTO>
    cancelExportTask: (task: ExportTaskDTO) => Promise<ExportTaskDTO>
    deleteExportTask: (task: ExportTaskDTO) => Promise<ExportTaskDeleteResponseDTO>
    recreateExportTask: (task: ExportTaskDTO) => Promise<ExportTaskDTO>
}

export interface ExportCenterContextProviderProperties {
    children?: ReactNode
    tasks?: ExportTaskDTO[]
}

const ExportCenterContext = createContext<ExportCenterContextProperties>(undefined)

/**
 * The polling interval is increased by 1 second each time the list is fetched.
 */
const POLL_INTERVAL_STEP_MS = 1_000

/**
 * The maximum interval is 10 seconds.
 */
const MAX_POLL_INTERVAL_MS = 10_000

export const ExportCenterContextProvider = (props: ExportCenterContextProviderProperties) => {
    const [polling, setPolling] = useState(false)
    const [tasks, setTasks] = useState<ExportTaskDTO[]>(props.tasks ?? [])
    const [exportTaskCreatedCounter, setExportTaskCreatedCounter] = useState(0)

    const queryClient = useQueryClient()
    const pollIntervalCounterRef = useRef(0)
    const analyticsService = useAnalytics()

    const updateOrInsertTask = (task: ExportTaskDTO) => {
        setTasks(
            produce((draft) => {
                const index = draft.findIndex((draftTask) => draftTask.id === task.id)
                if (index >= 0) {
                    draft[index] = task
                } else {
                    draft.unshift(task)
                }
            }),
        )
    }

    const deleteTask = (task: ExportTaskDTO) => {
        setTasks(
            produce((draft) => {
                const index = draft.findIndex((draftTask) => draftTask.id === task.id)
                if (index >= 0) {
                    draft.splice(index, 1)
                }
            }),
        )
    }

    const getRefetchInterval = (pollingCounter: number): number => {
        return Math.min(pollingCounter * POLL_INTERVAL_STEP_MS, MAX_POLL_INTERVAL_MS)
    }

    const useLoadExportTaskList = (): UseQueryResult<ExportTaskDTO[], Error> => {
        return useQuery({
            queryKey: ["exportTask", "list", polling],
            queryFn: async () => {
                log.debug(`Loading export task list ${new Date()}`)
                const response = await ExportTaskService.loadExportTaskList()
                if (polling) {
                    pollIntervalCounterRef.current++
                    log.info(
                        "Refetching export task list in " + getRefetchInterval(pollIntervalCounterRef.current) + "ms",
                    )
                } else {
                    pollIntervalCounterRef.current = 0
                }
                return response.tasks
            },
            refetchInterval: () => {
                if (polling) {
                    return getRefetchInterval(pollIntervalCounterRef.current)
                } else {
                    return false
                }
            },
            refetchIntervalInBackground: polling,
        })
    }

    /**
     * This handler is called when a new export task is created.
     * @param task
     */
    const newExportTaskCreatedHandler = (task: ExportTaskDTO) => {
        setPolling(true)
        updateOrInsertTask(task)
        setExportTaskCreatedCounter((previous) => previous + 1)
    }

    return (
        <ExportCenterContext.Provider
            value={{
                exportTaskCreatedCounter: exportTaskCreatedCounter,
                setPolling: (polling: boolean) => setPolling(polling),
                tasks: tasks,
                setTasks: (tasks: ExportTaskDTO[]) => {
                    setTasks(tasks)
                },
                useLoadExportTaskList: useLoadExportTaskList,
                invalidateQuery: (polling: boolean) => {
                    queryClient.removeQueries({ queryKey: ["exportTask", "list", polling] })
                },
                createExportTask: async (
                    serviceContextPath: string,
                    exportSettingsDTO: ExportQuerySettingsDTO,
                ): Promise<ExportTaskDTO> => {
                    analyticsService.trackCustomEvent("Start Download", exportSettingsDTO)
                    const task = await ExportTaskService.createExportTask(serviceContextPath, exportSettingsDTO)
                    newExportTaskCreatedHandler(task)

                    return task
                },
                cancelExportTask: async (task: ExportTaskDTO): Promise<ExportTaskDTO> => {
                    const cancelledTask = await ExportTaskService.cancelExportTask(task)
                    updateOrInsertTask(cancelledTask)

                    return cancelledTask
                },
                deleteExportTask: async (task: ExportTaskDTO): Promise<ExportTaskDeleteResponseDTO> => {
                    const response = await ExportTaskService.deleteExportTask(task)
                    if (response.deleted) {
                        deleteTask(task)
                    } else {
                        log.error("Export task could not be deleted")
                    }

                    return response
                },
                recreateExportTask: async (task: ExportTaskDTO): Promise<ExportTaskDTO> => {
                    const newTask = await ExportTaskService.recreateExportTask(task)
                    newExportTaskCreatedHandler(task)

                    return newTask
                },
            }}
        >
            {props.children}
        </ExportCenterContext.Provider>
    )
}

export const useExportCenterContext = () => {
    return useContext(ExportCenterContext)
}
