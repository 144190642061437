import { Box, Button, Typography } from "@mui/material"
import { ColumnConfiguratorDialog } from "domain/ColumnConfigurator/components/ColumnConfiguratorDialog"
import { useConversionListColumnConfiguratorContext } from "domain/ConversionList/components/ConversionListColumnConfiguratorContext"
import { ExportDialog } from "domain/ConversionList/components/Exports/ExportDialog"
import React, { useState } from "react"
import CogIcon from "shared/component/icons/CogIcon"
import DownloadIcon from "shared/component/icons/DownloadIcon"

export const GridControls = () => {
    const { openColumnConfigurator, onApplyColumnConfigurator } = useConversionListColumnConfiguratorContext()

    const [showExportDialog, setShowExportDialog] = useState(false)

    const handleExportClick = () => {
        setShowExportDialog(!showExportDialog)
    }

    return (
        <Box
            className="grid-controls"
            sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 1.5,
                mb: 0.5,
                mt: 1.5,
                px: 1.5,
                borderBottom: "1px solid",
                borderColor: "divider",
                pb: 1,
                width: "100%",
            }}
        >
            <Typography
                component="h1"
                variant="h1"
                className="conversion-list-title"
                sx={{
                    mt: -0.5,
                    fontWeight: "bold",
                }}
            >
                Conversions
            </Typography>

            <Box
                sx={{
                    display: "flex",
                    gap: 1.5,
                    mt: -0.5,
                }}
            >
                <Button
                    className="column-configurator-configure-columns-button"
                    variant="outlined"
                    size="small"
                    startIcon={<CogIcon />}
                    onClick={openColumnConfigurator}
                    sx={{ minWidth: "140px" }}
                >
                    Configure Columns
                </Button>
                <Button
                    className="download-button"
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<DownloadIcon />}
                    onClick={handleExportClick}
                    sx={{ minWidth: "100px" }}
                >
                    Download
                </Button>
            </Box>
            <ColumnConfiguratorDialog onApply={onApplyColumnConfigurator} />
            <ExportDialog shown={showExportDialog} onCloseDialog={handleExportClick} />
        </Box>
    )
}
