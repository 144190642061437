import { Button, Stack } from "@mui/material"
import React from "react"

type Props = Record<string, never>

type State = {
    showDataGroupsDialog?: boolean
}

/**
 * Renders the settings toolbar for the DataGridWidget
 */
class DataGridSettingsToolbar extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)

        this.state = { showDataGroupsDialog: false }
    }

    showDataGroupsDialog = () => {
        this.setState({ showDataGroupsDialog: true })
    }

    render() {
        return (
            <React.Fragment>
                <Stack direction="row" justifyContent="flex-end" sx={{ mb: 0.75 }}>
                    <Button onClick={this.showDataGroupsDialog} variant="contained" size="small">
                        Configure Columns
                    </Button>
                </Stack>
            </React.Fragment>
        )
    }
}

export default DataGridSettingsToolbar
