import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import MenuItem from "@mui/material/MenuItem"
import { useTheme } from "@mui/material/styles"
import { GridColumnMenuItemProps, GridPinnedColumnPosition } from "@mui/x-data-grid-pro"
import { useGridApiContext } from "@mui/x-data-grid-pro/hooks/utils/useGridApiContext"
import { useGridRootProps } from "@mui/x-data-grid-pro/hooks/utils/useGridRootProps"
import * as React from "react"

/**
 * NOTE: This is a copy of MUI's GridColumnMenuPinningItem, modified to only offer a "pin to left" option.
 */
function GridColumnMenuLeftPinningItem(props: GridColumnMenuItemProps) {
    const { colDef, onClick } = props
    const apiRef = useGridApiContext()
    const rootProps = useGridRootProps()
    const theme = useTheme()

    const pinColumn = React.useCallback(
        (side: GridPinnedColumnPosition) => (event: React.MouseEvent<HTMLElement>) => {
            apiRef.current.pinColumn(colDef.field, side)
            onClick(event)
        },
        [apiRef, colDef.field, onClick],
    )

    const unpinColumn = (event: React.MouseEvent<HTMLElement>) => {
        apiRef.current.unpinColumn(colDef.field)
        onClick(event)
    }
    const pinToLeftMenuItem = (
        <MenuItem onClick={pinColumn(GridPinnedColumnPosition.LEFT)}>
            <ListItemIcon>
                <rootProps.slots.columnMenuPinLeftIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{apiRef.current.getLocaleText("pinToLeft")}</ListItemText>
        </MenuItem>
    )

    if (!colDef) {
        return null
    }

    const side = apiRef.current.isColumnPinned(colDef.field)

    if (side) {
        return (
            <React.Fragment>
                <MenuItem onClick={unpinColumn}>
                    <ListItemIcon />
                    <ListItemText>{apiRef.current.getLocaleText("unpin")}</ListItemText>
                </MenuItem>
            </React.Fragment>
        )
    }

    if (theme.direction === "rtl") {
        return <React.Fragment>{pinToLeftMenuItem}</React.Fragment>
    }
    return <React.Fragment>{pinToLeftMenuItem}</React.Fragment>
}

export { GridColumnMenuLeftPinningItem }
