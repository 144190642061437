import * as React from "react"

export const tooltipRenderer = (value: string | number | boolean, cssClasses: Set<string>) => {
    const cssClassesArray = Array.from(cssClasses || [])
    const classes: string = cssClasses
        ? cssClassesArray.length
            ? cssClassesArray.join(" ")
            : cssClassesArray.toString()
        : ""
    return (
        <span
            data-tip-at={"bottom-left"}
            data-tip-classname={"long-text-tip " + classes}
            data-tip={value}
            data-copy-to-clipboard={true}
            className={classes}
        >
            {value}
        </span>
    )
}
