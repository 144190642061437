/**
 * Groups items by a grouping function.
 *
 * @param items
 * @param toGroup
 */
const groupBy = <T, K>(items: Iterable<T>, toGroup: (item: T) => K): Map<K, T[]> => {
    const resultMap = new Map<K, T[]>()

    for (const item of items) {
        const key = toGroup(item)
        const group = resultMap.get(key)

        if (group) {
            group.push(item)
        } else {
            resultMap.set(key, [item])
        }
    }

    return resultMap
}

/**
 * Maps the values of a map to a new map.
 *
 * @param inputMap
 * @param transform
 */
const mapValues = <K, V, NewV>(inputMap: Map<K, V>, transform: (value: V, key: K) => NewV): Map<K, NewV> => {
    const resultMap = new Map<K, NewV>()

    for (const [key, value] of inputMap.entries()) {
        const newValue = transform(value, key)
        resultMap.set(key, newValue)
    }

    return resultMap
}
/**
 * Sorts a map by its entries.
 *
 * @param map
 * @param compare
 */
const sort = <K, V>(map: Map<K, V>, compare: (a: [K, V], b: [K, V]) => number): Map<K, V> => {
    const sortedEntries = Array.from(map.entries()).sort(compare)
    return new Map(sortedEntries)
}

type Builder<K, V> = (map: Map<K, V>) => void

/**
 * Builds a map using a builder function.
 *
 * @param builder
 */
const buildMap = <K, V>(builder: Builder<K, V>): Map<K, V> => {
    const map = new Map<K, V>()
    builder(map)
    return map
}

const MapUtil = {
    groupBy,
    mapValues,
    sort,
    buildMap,
}

export default MapUtil
