import { MetricTagIndicatorList } from "./MetricTypeIndicatorList/MetricTagIndicatorList"
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import AssessmentIcon from "@mui/icons-material/Assessment"
import CloseIcon from "@mui/icons-material/Close"
import DragIndicatorIcon from "@mui/icons-material/DragIndicator"
import WarningAmberRounded from "@mui/icons-material/WarningAmberRounded"
import { Box, useTheme } from "@mui/material"
import Grid from "@mui/material/Grid2"
import IconButton from "@mui/material/IconButton"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { clsx } from "clsx"
import { Tag } from "domain/ColumnConfigurator/TagsUtil"
import { InfoTextContent } from "domain/ColumnConfigurator/components/ColumnSelectionPanel/InfoTextContent"
import {
    ColumnSelectAction,
    IdentifiableSettings,
    MetricIdentifiableSettings,
} from "domain/ColumnConfigurator/components/types"
import { ColumnConfiguratorContextSlices } from "domain/ColumnConfigurator/context/ColumnConfiguratorContextSlices"
import { ColumnSetting, ColumnUniqueName, DataColumn } from "domain/ColumnConfigurator/types"
import { useReportingConfigurationContext } from "domain/reporting/ReportingConfigurationContext"
import CustomTooltip from "layout/components/CustomTooltip"
import React, { type JSX } from "react"
import { run } from "shared/util/FunctionUtil"

export interface SelectedColumnsSidebarListElementProps {
    column: DataColumn
    onSelectedColumnsChanged: (columnIdentifier: ColumnUniqueName, action: ColumnSelectAction) => void
    onColumnSettingsChanged: (columnSettings: IdentifiableSettings) => void
    onColumnMoved: (columnIdentifier: ColumnUniqueName, newPosition: number | "end") => void
    metricTags?: ReadonlySet<Tag> | undefined
    draggableListeners?: SyntheticListenerMap
}

export const SelectedColumnsSidebarListElement = ({
    column,
    onSelectedColumnsChanged,
    onColumnSettingsChanged,
    onColumnMoved,
    metricTags = new Set(),
    draggableListeners,
}: SelectedColumnsSidebarListElementProps): JSX.Element => {
    const {
        dataDefinitions: { dimensions },
    } = useReportingConfigurationContext()
    const theme = useTheme()

    const selectedMetrics = ColumnConfiguratorContextSlices.useSelectedMetrics()
    const widgetStaticConfiguration = ColumnConfiguratorContextSlices.useWidgetStaticConfiguration()
    const dimensionMetricCompatibility = ColumnConfiguratorContextSlices.useDimensionMetricCompatibility()

    const labels = ColumnConfiguratorContextSlices.useLabels()

    const areBarsSupported =
        column.columnType === "metric" && widgetStaticConfiguration.isColumnSettingSupported(ColumnSetting.SHOW_BARS)
    const showBar =
        areBarsSupported && selectedMetrics.some((metric) => metric.identifier === column.uniqueName && metric.showBars)

    const canBeDragged = Boolean(draggableListeners)

    const isIncompatibleMetric =
        column.columnType === "metric" &&
        dimensionMetricCompatibility.isColumnIncompatibleWithSelectedDimensions(column)

    const warningIcon = run(() => {
        if (isIncompatibleMetric) {
            const incompatibleDimensions = Array.from(
                dimensionMetricCompatibility.getIncompatibleDimensionsForMetric(column.uniqueName) ?? new Set<string>(),
            )
                .map((dimensionName) => dimensions.get(dimensionName)?.displayName)
                .filter((displayName): displayName is string => displayName !== undefined)
            const warningMessage = `The selected metric is incompatible with the following selected dimensions: ${incompatibleDimensions.join(
                ", ",
            )}`

            return (
                <CustomTooltip title={warningMessage} placement="right">
                    <ListItemIcon sx={{ marginTop: "2px", background: theme.palette.background.default }}>
                        <WarningAmberRounded />
                    </ListItemIcon>
                </CustomTooltip>
            )
        }
        return undefined
    })

    const deleteColumn = React.useCallback(() => {
        onSelectedColumnsChanged(column.uniqueName, "delete")
    }, [column.uniqueName, onSelectedColumnsChanged])

    // Clicking on the move to top or bottom buttons can cause tooltips to "flicker" or stay open.
    // We use a key to force this element to re-render when the button is clicked.
    const [key, updateKey] = React.useReducer((x) => x + 1, 0)

    const moveToTop = React.useCallback(() => {
        onColumnMoved(column.uniqueName, 0)
        updateKey()
    }, [column.uniqueName, onColumnMoved])

    const moveToBottom = React.useCallback(() => {
        onColumnMoved(column.uniqueName, "end")
        updateKey()
    }, [column.uniqueName, onColumnMoved])

    const onColumnWithBarChanged = React.useCallback(() => {
        if (column.columnType === "metric") {
            onColumnSettingsChanged({ identifier: column.uniqueName, showBars: !showBar } as MetricIdentifiableSettings)
        }
    }, [column.uniqueName, column.columnType, onColumnSettingsChanged, showBar])

    const displayName = run(() => {
        switch (column.columnType) {
            case "metric":
                return column.displayNameBaseMetric
            case "dimension":
                return column.displayName
        }
    })

    const columnDescription = (
        <Box className={"column-description"}>
            <div className={"column-name"}>{displayName}</div>
            <MetricTagIndicatorList tags={metricTags} />
        </Box>
    )

    const columnDescriptionWithTooltip =
        column.columnType === "metric" ? (
            <CustomTooltip
                title={<InfoTextContent columnUniqueName={column.uniqueName} columnDisplayName={column.displayName} />}
                placement="left"
                offsetY={30}
            >
                {columnDescription}
            </CustomTooltip>
        ) : (
            columnDescription
        )

    const removeTooltipText = `Remove ${column.columnType === "metric" ? labels.metric : column.columnType}`

    return (
        <React.Fragment key={key}>
            <Box className={"selected-columns-sidebar-list-element element"}>
                <ListItem className={"hover-hide-block"} sx={{ padding: "0 6px", marginBottom: "5px" }}>
                    <ListItemIcon
                        sx={{ cursor: canBeDragged ? "grab" : "auto", minWidth: "30px" }}
                        {...draggableListeners}
                    >
                        <DragIndicatorIcon color={canBeDragged ? "inherit" : "disabled"} className={"drag-indicator"} />
                    </ListItemIcon>
                    <ListItemText
                        className={isIncompatibleMetric ? "incompatible-metric" : undefined}
                        sx={{
                            margin: 0,
                        }}
                        primary={
                            <Grid justifyContent={"space-between"} container>
                                <Box alignItems="center" display="flex">
                                    {columnDescriptionWithTooltip}
                                </Box>

                                <Grid justifyContent={"flex-end"} container className={"tools"}>
                                    {isIncompatibleMetric && warningIcon}
                                    {areBarsSupported && (
                                        <Box
                                            alignItems="center"
                                            display="flex"
                                            className={clsx(!showBar && "hover-hide-block-content")}
                                        >
                                            <CustomTooltip
                                                title={"Show Bars for this Column: " + (showBar ? "ON" : "OFF")}
                                                placement="right"
                                            >
                                                <IconButton
                                                    color={showBar ? "primary" : "default"}
                                                    aria-label="With Bars"
                                                    size={"small"}
                                                    onClick={onColumnWithBarChanged}
                                                >
                                                    <AssessmentIcon />
                                                </IconButton>
                                            </CustomTooltip>
                                        </Box>
                                    )}

                                    {canBeDragged && (
                                        <>
                                            <Box
                                                alignItems="center"
                                                display="flex"
                                                className={"hover-hide-block-content"}
                                            >
                                                <CustomTooltip title={"Move to top"} placement="right">
                                                    <IconButton
                                                        aria-label="Move to top"
                                                        size={"small"}
                                                        onClick={moveToTop}
                                                    >
                                                        <ArrowUpwardIcon />
                                                    </IconButton>
                                                </CustomTooltip>
                                            </Box>

                                            <Box
                                                alignItems="center"
                                                display="flex"
                                                className={"hover-hide-block-content"}
                                            >
                                                <CustomTooltip title={"Move to bottom"} placement="right">
                                                    <IconButton
                                                        aria-label="Move to bottom"
                                                        size={"small"}
                                                        onClick={moveToBottom}
                                                    >
                                                        <ArrowDownwardIcon />
                                                    </IconButton>
                                                </CustomTooltip>
                                            </Box>

                                            <Box
                                                alignItems="center"
                                                display="flex"
                                                className={"hover-hide-block-content"}
                                            >
                                                <CustomTooltip title={removeTooltipText} placement="right">
                                                    <IconButton
                                                        aria-label="Remove"
                                                        size={"small"}
                                                        onClick={deleteColumn}
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </CustomTooltip>
                                            </Box>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                        }
                    />
                </ListItem>
            </Box>
        </React.Fragment>
    )
}

export const MemoizedSelectedColumnsSidebarListElement = React.memo(SelectedColumnsSidebarListElement)
