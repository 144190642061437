import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Skeleton, SvgIconProps } from "@mui/material"
import {
    DataGridPro,
    DataGridProProps,
    GridColDef,
    GridColumnMenuPinningItem,
    GridColumnMenuProps,
    GridNoRowsOverlay,
} from "@mui/x-data-grid-pro"
import { GridProColumnMenu } from "@mui/x-data-grid-pro/components/GridProColumnMenu"
import { GridProSlotProps } from "@mui/x-data-grid-pro/models/gridProSlotProps"
import type { UseQueryResult } from "@tanstack/react-query"
import { useConversionListColumnConfiguratorContext } from "domain/ConversionList/components/ConversionListColumnConfiguratorContext"
import { ColumnField } from "domain/dimension/service/DimensionService"
import { GridDataRowDTO } from "domain/types"
import { ColumnConfigDTO, LoadResponseDTOReportingDataSetDTO, PaginationInfoDTO } from "generated/models"
import * as React from "react"
import { CustomColumnMenuConfigureColumnsItem } from "shared/component/mui/datagrid/CustomColumnMenuConfigureColumnsItem"
import { CustomColumnMenuResetColumnsItem } from "shared/component/mui/datagrid/CustomColumnMenuResetColumnsItem"
import { GridColumnMenuLeftPinningItem } from "shared/component/mui/datagrid/GridColumnMenuLeftPinningItem"
import { Pagination } from "shared/component/pagination/Pagination"
import { columnRenderer } from "shared/component/renderers/renderers"
import { run } from "shared/util/FunctionUtil"

export type CustomizedDataGridProps = {
    visibleColumns: ReadonlyArray<ColumnConfigDTO>
    queryResult: UseQueryResult<LoadResponseDTOReportingDataSetDTO, Error>
    onPageChange: (page: number, pageSize: number) => void
    muiDataGridProps?: Partial<DataGridProProps>
    onContextMenu?: (event: React.MouseEvent) => void
    makeGridColDef?: (dataColumn: ColumnConfigDTO) => GridColDef
    pinning?: "left-right" | "left"
    // If paginationQueryResult is provided, the pagination component will be rendered based on this query result
    // Otherwise, the pagination component will be rendered based on the data from queryResult
    paginationQueryResult?: UseQueryResult<PaginationInfoDTO>
}

declare module "@mui/x-data-grid-pro" {
    interface ColumnMenuPropsOverrides {
        pinning: "left-right" | "left"
    }
}

export const CustomizedDataGrid = ({
    visibleColumns,
    queryResult,
    onPageChange,
    muiDataGridProps,
    onContextMenu,
    makeGridColDef = simpleGridColDef,
    pinning = "left-right",
    paginationQueryResult,
}: CustomizedDataGridProps) => {
    const { openColumnConfigurator, resetGridColumnState } = useConversionListColumnConfiguratorContext()
    const columns = visibleColumns.map(makeGridColDef)
    const { isPending, isSuccess, isError, isFetching, data } = queryResult

    const rows = isSuccess
        ? data.dataSet.rows.map((row, index) => ({
              ...makeRow(row),
              id: index,
          }))
        : []

    const slotProps: GridProSlotProps = {
        columnMenu: {
            pinning: pinning,
            openColumnConfigurator: openColumnConfigurator,
            resetGridColumnState: resetGridColumnState,
        },
    }

    if (onContextMenu) {
        slotProps.row = {
            onContextMenu: onContextMenu,
        }
    }

    return (
        <>
            <DataGridPro
                columns={columns}
                rows={rows}
                disableRowSelectionOnClick
                loading={isPending || isFetching}
                hideFooter={true}
                density="compact"
                sx={{
                    minHeight: "400px",
                    "& .MuiDataGrid-detailPanel": {
                        overflow: "visible",
                    },
                }}
                slots={{
                    noRowsOverlay: isError ? ErrorOverlay : GridNoRowsOverlay,
                    columnMenu: CustomColumnMenu,
                    detailPanelExpandIcon: GridExpandIcon,
                    detailPanelCollapseIcon: GridCollapseIcon,
                }}
                slotProps={slotProps}
                {...muiDataGridProps}
            />
            {!paginationQueryResult && isSuccess && data.paginationInfo && (
                <Pagination
                    page={data.paginationInfo.page}
                    pageSize={data.paginationInfo.pageSize}
                    totalEntities={data.paginationInfo.totalEntities}
                    entitiesOnPage={rows.length}
                    onPageChange={onPageChange}
                    disabled={isFetching}
                />
            )}

            {paginationQueryResult && paginationQueryResult.isSuccess && paginationQueryResult.data && (
                <Pagination
                    page={paginationQueryResult.data.page}
                    pageSize={paginationQueryResult.data.pageSize}
                    totalEntities={paginationQueryResult.data.totalEntities}
                    entitiesOnPage={rows.length}
                    onPageChange={onPageChange}
                    disabled={paginationQueryResult.isFetching}
                />
            )}
            {paginationQueryResult && !paginationQueryResult.isSuccess && paginationQueryResult.isFetching && (
                <Skeleton>
                    <Pagination
                        page={0}
                        pageSize={50}
                        totalEntities={100}
                        entitiesOnPage={50}
                        onPageChange={onPageChange}
                    />
                </Skeleton>
            )}
        </>
    )
}

// TODO: Styling
const ErrorOverlay = () => {
    return <p>There was an error while fetching your data. Please try again later.</p>
}

const CustomColumnMenu = ({
    pinning,
    openColumnConfigurator,
    resetGridColumnState,
    ...props
}: GridColumnMenuProps & {
    pinning: "left-right" | "left"
    openColumnConfigurator?: () => void
    resetGridColumnState?: () => void
}) => {
    const columnMenuPinningItem = run(() => {
        switch (pinning) {
            case "left-right":
                return GridColumnMenuPinningItem
            case "left":
                return GridColumnMenuLeftPinningItem
        }
    })

    return (
        <GridProColumnMenu
            {...props}
            slots={{
                // Hide the filter option in the column menu, as it only filters client-side
                columnMenuFilterItem: null,

                columnMenuPinningItem: columnMenuPinningItem,
                columnMenuConfigureColumnsItem: CustomColumnMenuConfigureColumnsItem,
                columnMenuResetColumnsItem: CustomColumnMenuResetColumnsItem,
            }}
            slotProps={{
                columnMenuConfigureColumnsItem: {
                    displayOrder: 30,
                    openColumnConfigurator: openColumnConfigurator,
                    hideMenu: props.hideMenu,
                },
                columnMenuResetColumnsItem: {
                    displayOrder: 31,
                    resetGridColumnState: resetGridColumnState,
                    hideMenu: props.hideMenu,
                },
            }}
        />
    )
}

const simpleGridColDef = (dataColumn: ColumnConfigDTO): GridColDef => {
    return {
        field: dataColumn.columnIdentifier,
        headerName: dataColumn.gridColumnProperties.columnHeader,
        width: dataColumn.gridColumnProperties.width || 150,
        sortable: dataColumn.gridColumnProperties.sortable,
        renderCell: dataColumn.gridColumnProperties.renderer ? columnRenderer(dataColumn) : undefined,
    }
}

type ColumnIdentifier = string
const makeRow = (dataRow: GridDataRowDTO): Record<ColumnIdentifier, any> => {
    const result: Record<ColumnIdentifier, any> = {}
    Object.keys(dataRow).forEach((identifier) => {
        const columnData = dataRow[identifier]
        if (columnData?.value !== undefined) {
            result[ColumnField.valueFieldAsString(identifier)] = columnData
        }
        if (columnData?.name !== undefined) {
            result[ColumnField.nameFieldAsString(identifier)] = columnData
        }
    })
    return result
}

// Custom sized icon components
const GridExpandIcon = (props: SvgIconProps) => <ChevronRightIcon {...props} sx={{ fontSize: "20px", ...props.sx }} />

const GridCollapseIcon = (props: SvgIconProps) => <ExpandMoreIcon {...props} sx={{ fontSize: "20px", ...props.sx }} />
