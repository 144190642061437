import { Chip } from "@mui/material"
import { ColumnResponseDTO } from "generated/models"
import * as React from "react"

const getOptimizationLevelDisplayValue = (level: ColumnResponseDTO): string => {
    return level.value === "campaign_id" ? "Campaign" : level.value === "sub_campaign_id" ? "Sub Campaign" : "Channel"
}

export const optimizationLevelRenderer: React.FunctionComponent<any> = (value: ColumnResponseDTO) => {
    const displayedValue = getOptimizationLevelDisplayValue(value)
    return (
        <Chip
            label={displayedValue}
            color="info"
            sx={{
                borderRadius: "6px",
                margin: "0px",
                "&.MuiChip-root": {
                    [`&.optimization-level-${value.value}`]: {
                        backgroundColor: "#2db7f5",
                        color: "#fff",
                        fontSize: "11px",
                        height: "24px",
                    },
                },
                "& .MuiChip-label": {
                    padding: "0px 6px",
                },
            }}
            className={`optimization-level optimization-level-${value.value}`}
        />
    )
}
