import { ColumnUniqueName, Metric } from "domain/ColumnConfigurator/types"

export interface SearchResult {
    readonly metrics: ReadonlySet<ColumnUniqueName>
}

export class Search {
    private readonly query: string
    private normalizedQuery: string
    private readonly searchResultProvider: () => SearchResult
    private result?: SearchResult

    constructor(
        findMetrics: (predicate: (metric: Metric) => boolean) => ReadonlySet<ColumnUniqueName>,
        query: string,
        searchResultsProvider: () => SearchResult = () => {
            const metrics = findMetrics((metric) => normalize(metric.displayName).includes(this.normalizedQuery))
            return { metrics }
        },
    ) {
        this.query = query
        this.normalizedQuery = normalize(query)
        this.searchResultProvider = searchResultsProvider
    }

    public getQuery(): string {
        return this.query
    }

    public getResult(): SearchResult {
        if (this.result) {
            return this.result
        }
        this.result = this.searchResultProvider()
        return this.result
    }
}

/**
 * The `normalize` function takes a string as input and normalizes it by performing the following operations:
 * 1. Replaces all hyphens and slashes with a space.
 * 2. Removes all periods, commas, and parentheses.
 * 3. Trims leading and trailing white spaces.
 * 4. Converts all characters to lower case.
 *
 * @param {string} queryOrCandidate - The string to be normalized.
 * @returns {string} The normalized string.
 */
export const normalize = (queryOrCandidate: string): string => {
    return queryOrCandidate
        .replace(/[-/]/g, " ")
        .replace(/[.,()]/g, "")
        .trim()
        .toLowerCase()
}
