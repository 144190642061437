import { DataGridContextProvider } from "./DataGridContext"
import { GenericDataGridColumnConfiguratorAdapter } from "domain/legacy/widget/generic/GenericDataGridColumnConfiguratorAdapter"
import GenericDataGridWidget from "domain/legacy/widget/generic/GenericDataGridWidget"
import { AppContextDTO, GridElementDTO } from "generated/models"
import React, { type JSX } from "react"
import { useSelector } from "react-redux"
import ElementHeader from "shared/component/layout/ElementHeader"
import { RootState } from "shared/redux/store"

type GenericDataGridWidgetWrapperProps = {
    gridSettings: GridElementDTO
}

const GenericDataGridWidgetWrapper: React.FC<GenericDataGridWidgetWrapperProps> = React.memo(
    ({ gridSettings }: GenericDataGridWidgetWrapperProps): JSX.Element => {
        const appContext: AppContextDTO = useSelector((state: RootState) => state.appContext.appContext)

        return (
            <GenericDataGridColumnConfiguratorAdapter
                columnConfigs={gridSettings.elementConfig.gridConfig.supportedColumnConfigs}
            >
                <DataGridContextProvider>
                    <ElementHeader header={gridSettings.header} />
                    <GenericDataGridWidget appContext={appContext} gridSettings={gridSettings} />
                </DataGridContextProvider>
            </GenericDataGridColumnConfiguratorAdapter>
        )
    },
)

export default GenericDataGridWidgetWrapper
