import { SvgIcon, SvgIconProps } from "@mui/material"
import React from "react"

const CartIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 48 48">
            <path fill="#009688" d="M18,32c-1.1,0-2-0.9-2-2l0-10l-4,0l0,10c0,3.3,2.7,6,6,6h19v-4H18z" />
            <path
                fill="#009688"
                d="M12.8,10l-0.4-1.3C11.8,7.1,10.3,6,8.6,6H5v4h3.6l5.5,16.6c0.3,0.8,1,1.4,1.9,1.4h19c0.8,0,1.5-0.5,1.8-1.2L44.4,10H12.8z"
            />
            <path fill="#00695C" d="M5 6A2 2 0 1 0 5 10A2 2 0 1 0 5 6Z" />
            <path fill="#37474F" d="M34 36A3 3 0 1 0 34 42 3 3 0 1 0 34 36zM19 36A3 3 0 1 0 19 42 3 3 0 1 0 19 36z" />
            <g>
                <path
                    fill="#607D8B"
                    d="M34 38A1 1 0 1 0 34 40 1 1 0 1 0 34 38zM19 38A1 1 0 1 0 19 40 1 1 0 1 0 19 38z"
                />
            </g>
        </SvgIcon>
    )
}

export default CartIcon
