import { CancelTokenSource } from "axios"
import { FORM_ELEMENT_TYPES, FormElementDTO, LayoutElementType, SelectFormElement } from "domain/types"
import { ConditionClauseDTO, ContainerElementDTO, LayoutElementDTO, SelectFormElementDTO } from "generated/models"
import { ElementSetting } from "shared/component/layout/context/RootElementContext"
import ConditionClauseService from "shared/service/conditionClauseService"
import FormService from "shared/service/form.service"
import UrlService from "shared/service/url.service"

const findFormElements = (container: ContainerElementDTO): FormElementDTO[] => {
    // @ts-expect-error TODO findElementsByType returns a too broad type
    return findElementsByType(container, FORM_ELEMENT_TYPES)
}

const findSelectElements = (container: ContainerElementDTO): SelectFormElementDTO[] => {
    // @ts-expect-error TODO findElementsByType returns a too broad type
    return findElementsByType(container, [LayoutElementType.FORM_ELEMENT_SELECT])
}

const loadSelectElements = async (
    selectElements: SelectFormElementDTO[],
    filterPath: string,
    filter: ConditionClauseDTO,
    cancelToken: CancelTokenSource,
): Promise<SelectFormElement[]> => {
    const promises: Promise<SelectFormElement>[] = selectElements.map((element) => {
        // load dimension values for the select form element only if the element is not read only
        if (!element.formFieldConfig.readOnly) {
            const selectElementPromise: Promise<SelectFormElement> = FormService.loadSelectElementEntries(
                element,
                `${UrlService.getFilterServiceApiUrl()}${filterPath}`,
                ConditionClauseService.combineFilterQueries([filter, element.additionalFilters]),
                cancelToken?.token,
            )

            return selectElementPromise
        } else return Promise.resolve(element)
    })

    return await Promise.all(promises)
}

/**
 * Recursively finds all elements that have a type that is listed in elementTypes
 * @param container
 * @param elementTypes
 */
const findElementsByType = (container: ContainerElementDTO, elementTypes: string[]): LayoutElementDTO[] => {
    const elements: LayoutElementDTO[] = []

    container.children.forEach((element) => {
        // find all elements that match one of the requested types and add them to the result list
        if (elementTypes.indexOf(element.elementType) >= 0) {
            elements.push(element)
        }

        // since our layout might be nested, we have to do this recursively
        if (Object.prototype.hasOwnProperty.call(element, "children")) {
            const childElements: LayoutElementDTO[] = findElementsByType(element as ContainerElementDTO, elementTypes)
            elements.push(...childElements)
        }
    })

    return elements
}

const findFormElementByIdentifier = (container: ContainerElementDTO, identifier: string): FormElementDTO => {
    const elements = findFormElements(container)
    return elements.find((element) => element.formFieldConfig.dimensionIdentifier == identifier)
}

const replaceFormElementByIdentifier = (
    container: ContainerElementDTO,
    newElement: FormElementDTO,
): ContainerElementDTO => {
    const newChildren = Array.from(container.children || []).map((element) => {
        if (
            Object.prototype.hasOwnProperty.call(element, "formFieldConfig") &&
            (element as FormElementDTO).formFieldConfig.dimensionIdentifier ==
                newElement.formFieldConfig.dimensionIdentifier
        ) {
            return newElement
        } else if (Object.prototype.hasOwnProperty.call(element, "children")) {
            return replaceFormElementByIdentifier(element as ContainerElementDTO, newElement)
        } else return element
    })

    return { ...container, children: newChildren }
}

/**
 * Compares conditionClauseDTO with contextElementSettings and executes updateCallback(true/false).
 * The boolean parameter passed to updateCallback indicates whether conditionClauseDTO was evaluated as true or false.
 *
 * When conditionClauseDTO is falsy then updateCallback will not be executed.
 *
 * @param conditionClauseDTO
 * @param contextElementSettings
 * @param updateCallback
 */
const evaluateElementSettingWithConditionThenUpdate = (
    conditionClauseDTO: ConditionClauseDTO,
    contextElementSettings: ElementSetting[],
    updateCallback: (matches: boolean) => Promise<void>,
): Promise<void> => {
    if (conditionClauseDTO) {
        const match = ConditionClauseService.matchesDimensionValueToConditionClause(
            contextElementSettings,
            conditionClauseDTO,
        )
        return updateCallback(match)
    }
    return Promise.resolve()
}

const isFormElement = (layoutElement: LayoutElementDTO): layoutElement is FormElementDTO => {
    return layoutElement.elementType.startsWith("FORM_ELEMENT_")
}

const LayoutUtil = {
    findFormElements,
    findSelectElements,
    findElementsByType,
    evaluateElementSettingWithConditionThenUpdate,
    loadSelectElements: loadSelectElements,
    replaceElementByIdentifier: replaceFormElementByIdentifier,
    findFormElementByIdentifier: findFormElementByIdentifier,
    isFormElement: isFormElement,
}
export default LayoutUtil
