import { GridColumnStateSettingsValueDTO } from "generated/models"
import React from "react"

export type GridColumnStateContextProps = Readonly<{
    getGridColumnState: (gridId: string) => GridColumnStateSettingsValueDTO | null
    setGridColumnState: (gridId: string, selectedColumnIdentifiers: string[], pinnedColumnIdentifiers: string[]) => void
    deleteGridColumnState: (gridId: string) => void
}>

export const GridColumnStateContext = React.createContext<GridColumnStateContextProps | undefined>(undefined)

export const useGridColumnStateContext = (): GridColumnStateContextProps => {
    const context = React.useContext(GridColumnStateContext)
    if (!context) {
        throw new Error("useGridColumnStateContext must be used within a GridColumnStateContextProvider")
    }
    return context
}
