import { DimensionValueListDTO, QuerySettingsDTO } from "generated/models"
import * as api from "shared/service"
import UrlService from "shared/service/url.service"
import UrlUtil from "shared/util/UrlUtil"

// TODO: Safer joining of url components
const LOAD_DIMENSION_VALUES_ENDPOINT = UrlUtil.joinUrl(UrlService.getFilterServiceApiUrl(), "/loaddimensionvalues")

const loadDimensionValues = async (querySettingsDTO: QuerySettingsDTO): Promise<DimensionValueListDTO> => {
    // The first dimension is appended to the URL - this is not required by the backend, but eases debugging
    const firstDimension = querySettingsDTO.columnNames[0]
    const url = `${LOAD_DIMENSION_VALUES_ENDPOINT}/${firstDimension}`

    return api.post(url, querySettingsDTO)
}

const FilterServiceClient = {
    loadDimensionValues: loadDimensionValues,
}

export default FilterServiceClient
