import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import React from "react"

export const ConversionTypeRadioButtonGroup = () => {
    const conversionTypeSelection = ConversionListContextSelectors.useConversionTypeSelection()
    const updateConversionTypeSelection = ConversionListContextSelectors.useUpdateConversionTypeSelection()

    const selectedValues = Object.entries(conversionTypeSelection)
        .filter(([, value]) => value)
        .map(([key]) => key)

    const handleChange = (_: React.MouseEvent<HTMLElement>, conversionTypes: string[]) => {
        if (conversionTypes.length > 0) {
            updateConversionTypeSelection({
                sale: conversionTypes.includes("sale"),
                lead: conversionTypes.includes("lead"),
            })
        }
    }

    return (
        <ToggleButtonGroup
            className="conversion-type-radio-group"
            color="primary"
            value={selectedValues}
            onChange={handleChange}
            size="small"
            fullWidth
            sx={{
                "& .MuiToggleButton-root": {
                    padding: "6px 6px",
                    whiteSpace: "nowrap",
                    boxSizing: "border-box",
                },
            }}
        >
            <ToggleButton className="conversion-type-sale" value="sale" sx={{ width: "50%" }}>
                Sales
            </ToggleButton>
            <ToggleButton className="conversion-type-lead" value="lead" sx={{ width: "50%" }}>
                Leads
            </ToggleButton>
        </ToggleButtonGroup>
    )
}
