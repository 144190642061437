import { Checkbox } from "./primitives/Checkbox"
import { FormElementProperties } from "domain/types"
import React, { type JSX } from "react"
import { useRootElementContext } from "shared/component/layout/context/RootElementContext"

export const FormElementCheckbox = ({ layoutElementConfig, isRequiredField }: FormElementProperties): JSX.Element => {
    const { updateElementSettings } = useRootElementContext()

    const { useAsSetting, formFieldConfig } = layoutElementConfig

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (useAsSetting) {
            updateElementSettings({ key: formFieldConfig.dimensionIdentifier, value: event.target.checked })
        }
    }

    return (
        <Checkbox
            name={formFieldConfig.dimensionIdentifier}
            label={formFieldConfig.displayName}
            onChange={onChange}
            required={isRequiredField}
        />
    )
}
