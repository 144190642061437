import { GridColDef } from "@mui/x-data-grid-pro"
import { ACTIONS_ID_FIELD, HEALTH_ID_FIELD } from "domain/datagrid/component/DataGrid"
import { ColumnConfigDTO } from "generated/models"
import { columnRenderer } from "shared/component/renderers/renderers"

const getColumnConfigurations = (columns: ColumnConfigDTO[]): GridColDef[] => {
    return columns.map((column) => {
        const result: GridColDef = {
            field: column.columnIdentifier,
            headerName: column.gridColumnProperties.columnHeader,
            width: column.gridColumnProperties.width ?? 150,
            sortable: column.gridColumnProperties.sortable,
        }

        if (column.gridColumnProperties.renderer) {
            result.renderCell = columnRenderer(column)
        }

        if (result.field === ACTIONS_ID_FIELD) {
            result.headerName = "Actions"
            result.disableColumnMenu = true
        }

        if (result.field === HEALTH_ID_FIELD) {
            // result.width = 25
            // result.headerName = ''
            result.headerClassName = "health-header"
        }
        return result
    })
}

export const ColumnConfigurer = {
    getColumnConfigurations: getColumnConfigurations,
}
