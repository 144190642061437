import { ColumnField } from "domain/dimension/service/DimensionService"

export const CAMPAIGN = "campaign"
export const CHANNEL = "channel"
export const CONSENT_STATUS = "is_opt_out_conversion"
export const CONVERSION_LIST_TP_DEVICE_TYPE = "conversion_list_tp_device_type"
export const CONVERSION_LIST_TP_IDENTIFIED_BY = "conversion_list_tp_identified_by"
export const CONVERSION_LIST_TP_TS = "conversion_list_tp_ts"
export const CONVERSION_LIST_TP_TTC = "conversion_list_tp_ttc"
export const CONVERSION_LIST_TP_TYPE = "conversion_list_tp_type"
export const CONVERSION_LIST_TTC = "ttc"
export const CONVERSION_STATE = "conversion_state_id"
export const COUNT_DEVICES = "count_devices"
export const COUNT_TOUCHPOINTS = "count_touchpoints"
export const CURRENCY = "currency"
export const CUSTOMER_JOURNEY_ICON_COLUMN_IDENTIFIER = "customer_journey_icon"
export const DEVICE_TYPE = "device_type"
export const DIMENSION_CONVERSION_TYPE = "conversion_type_id"
export const IS_CLOSER = "is_closer"
export const IS_CONTENT = "is_content"
export const IS_DIRECT_CONVERSION = "is_direct_conversion_id"
export const IS_FULL_CONVERSION = "is_full_conversion"
export const IS_INFLUENCER = "is_influencer"
export const IS_INTRODUCER = "is_introducer"
export const IS_INVOLVED = "is_involved"
export const IS_PROVISIONAL = "is_provisional"
export const IS_SOFT_CONVERSION = "is_soft_conversion"
export const IS_TRACKED_CONVERSION = "is_tracked_conversion_id"
export const IS_WINNER = "is_winner"
export const LINE_ITEM = "line_item"
export const PIXEL_ICON_COLUMN_IDENTIFIER = "pixel_icon"
export const PROVIDER = "provider"
export const PUBLISHER = "publisher"
export const REVENUE = "revenue"
export const SUB_CAMPAIGN = "sub_campaign"
export const SUB_ID = "sub_id"
export const TOTAL_PRICE = "total_price"
export const TRANSACTION_TS = "transaction_ts"
export const TRANSACTION_UID = "transaction_uid"

export const CAMPAIGN_VALUE_COLUMN_FIELD = ColumnField.valueFieldAsString(CAMPAIGN)
export const CHANNEL_VALUE_COLUMN_FIELD = ColumnField.valueFieldAsString(CHANNEL)
export const CONVERSION_LIST_TP_TS_VALUE_COLUMN_FIELD = ColumnField.valueFieldAsString(CONVERSION_LIST_TP_TS)
export const CONVERSION_LIST_TTC_VALUE_COLUMN_FIELD = ColumnField.valueFieldAsString(CONVERSION_LIST_TTC)
export const COUNT_DEVICES_VALUE_COLUMN_FIELD = ColumnField.valueFieldAsString(COUNT_DEVICES)
export const COUNT_TOUCHPOINTS_VALUE_COLUMN_FIELD = ColumnField.valueFieldAsString(COUNT_TOUCHPOINTS)
export const CURRENCY_VALUE_COLUMN_FIELD = ColumnField.valueFieldAsString(CURRENCY)
export const CUSTOMER_JOURNEY_ICON_VALUE_COLUMN_FIELD = ColumnField.valueFieldAsString(
    CUSTOMER_JOURNEY_ICON_COLUMN_IDENTIFIER,
)
export const DEVICE_TYPE_VALUE_COLUMN_FIELD = ColumnField.valueFieldAsString(DEVICE_TYPE)
export const PIXEL_ICON_VALUE_COLUMN_FIELD = ColumnField.valueFieldAsString(PIXEL_ICON_COLUMN_IDENTIFIER)
export const REVENUE_VALUE_COLUMN_FIELD = ColumnField.valueFieldAsString(REVENUE)
export const TOTAL_PRICE_VALUE_COLUMN_FIELD = ColumnField.valueFieldAsString(TOTAL_PRICE)
export const TRANSACTION_TS_VALUE_COLUMN_FIELD = ColumnField.valueFieldAsString(TRANSACTION_TS)
export const TRANSACTION_UID_VALUE_COLUMN_FIELD = ColumnField.valueFieldAsString(TRANSACTION_UID)

// Dimension identifiers for the conversions
const CONVERSION_LIST_PREFIX = "dimension_conversion_list"
export const DIMENSION_CONVERSION_LIST_IDENTIFIED_BY = `${CONVERSION_LIST_PREFIX}_identified_by`
export const DIMENSION_CONVERSION_LIST_SESSION_PARAM = `${CONVERSION_LIST_PREFIX}_session_param`
export const DIMENSION_CONVERSION_LIST__UID = `${CONVERSION_LIST_PREFIX}__uid`
export const DIMENSION_CONVERSION_LIST_REPORT_CUSTOM_GROUP_TRACKING = `${CONVERSION_LIST_PREFIX}_report_custom_group_tracking`
export const DIMENSION_CONVERSION_LIST_TS_FIRST_TP = `${CONVERSION_LIST_PREFIX}_ts_first_tp`
