import Stack from "@mui/material/Stack"
import { DimensionSelectionPanel } from "domain/ColumnConfigurator/components/ColumnSelectionPanel/DimensionSelectionPanel/DimensionSelectionPanel"
import { MetricSelectionPanel } from "domain/ColumnConfigurator/components/ColumnSelectionPanel/MetricSelectionPanel/MetricSelectionPanel"
import React, { type JSX } from "react"

export const ColumnSelectionPanel = (): JSX.Element => {
    return (
        <Stack spacing={1}>
            <DimensionSelectionPanel />
            <MetricSelectionPanel />
        </Stack>
    )
}
