import { AddPropertyFilterDialog } from "domain/ConversionList/components/ConfigPanel/TouchpointProperties/PropertyFilterDialog/AddPropertyFilterDialog"
import { EditPropertyFilterDialog } from "domain/ConversionList/components/ConfigPanel/TouchpointProperties/PropertyFilterDialog/EditPropertyFilterDialog"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import React from "react"
import { log } from "shared/util/log"

export const PropertyFilterDialog = () => {
    const propertyFilterDialogMode = ConversionListContextSelectors.usePropertyFilterDialogMode()
    const touchpointPropertyFilters = ConversionListContextSelectors.useTouchpointPropertyFilters()
    const propertyFilterDialogOpen = ConversionListContextSelectors.usePropertyFilterDialogOpen()

    if (!propertyFilterDialogOpen) {
        return <></>
    }

    switch (propertyFilterDialogMode.type) {
        case "add":
            return <AddPropertyFilterDialog />
        case "edit": {
            const { id } = propertyFilterDialogMode
            const touchpointPropertyFilter = touchpointPropertyFilters.find((filter) => filter.id === id)
            const touchpointPropertyFilterIndex = touchpointPropertyFilters.findIndex((filter) => filter.id === id)

            if (!touchpointPropertyFilter || touchpointPropertyFilterIndex === -1) {
                log.error("Could not find touchpoint property filter with id", id)
                // TODO should not happen, but we need better error handling
                return <></>
            }

            return (
                <EditPropertyFilterDialog
                    touchpointPropertyFilter={touchpointPropertyFilter}
                    touchpointPropertyFilterIndex={touchpointPropertyFilterIndex}
                />
            )
        }
    }
}
