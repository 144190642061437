import AddRoundedIcon from "@mui/icons-material/AddRounded"
import CreateRoundedIcon from "@mui/icons-material/CreateRounded"
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded"
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded"
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded"
import { Badge } from "@mui/material"
import { IconTypes, ToolbarButton, ToolbarButtonType } from "domain/types"
import { ActionDTO } from "generated/models"
import React from "react"
import ResponsiveButton from "shared/component/ResponsiveButton"

type Props = {
    button: ToolbarButton
    onInvoke: (action: ActionDTO) => void
    selectedRows?: number
    disabled: boolean
}

const icons = {
    [IconTypes.DELETE]: <DeleteForeverRoundedIcon />,
    [IconTypes.PLUS]: <AddRoundedIcon />,
    [IconTypes.EDIT]: <CreateRoundedIcon />,
    [IconTypes.EYE_INVISIBLE]: <VisibilityOffRoundedIcon />,
    [IconTypes.EYE]: <VisibilityRoundedIcon />,
}

class ToolbarButtonComponent extends React.Component<Props> {
    constructor(props: Props) {
        super(props)

        this.invokeAction = this.invokeAction.bind(this)
    }

    invokeAction = (): void => {
        const { button, onInvoke } = this.props

        onInvoke(button.action)
    }

    render() {
        const { button, selectedRows } = this.props

        // disable/enable needs a wrapper component, otherwise it will be impossible to have a fade in/out animation since
        // display:none will prevent any animations on the element and its child elements (but we need display:none so that
        // hidden buttons don't take up space)
        const unavailable = button.action.selectionConfig.minSelected > selectedRows
        const disabled = this.props.disabled || unavailable
        const icon = button.toolbarButtonDTO.icon ? icons[button.toolbarButtonDTO.icon] : undefined

        let buttonElement
        if (button.toolbarButtonDTO.type == ToolbarButtonType.BUTTON) {
            buttonElement = (
                <ResponsiveButton
                    className="toolbar-button"
                    variant="contained"
                    onClick={this.invokeAction}
                    disabled={disabled || button.toolbarButtonDTO.disabled}
                    data-tip={button.toolbarButtonDTO.disabledTooltip}
                    data-force-tooltip={button.toolbarButtonDTO.disabled}
                >
                    {icon != undefined && icon}
                    <span className="toolbar-button-text">{button.toolbarButtonDTO.label}</span>
                </ResponsiveButton>
            )
        } else if (button.toolbarButtonDTO.type == ToolbarButtonType.ICON) {
            buttonElement = (
                <ResponsiveButton
                    className="toolbar-button"
                    variant="text"
                    onClick={this.invokeAction}
                    disabled={disabled || button.toolbarButtonDTO.disabled}
                    data-tip={button.toolbarButtonDTO.disabledTooltip}
                    data-force-tooltip={button.toolbarButtonDTO.disabled}
                >
                    {icon != undefined && icon}
                </ResponsiveButton>
            )
        }

        const showBadge = selectedRows > 1

        const element = showBadge ? (
            <Badge badgeContent={selectedRows} color={"info"}>
                {buttonElement}
            </Badge>
        ) : (
            buttonElement
        )

        return (
            <React.Fragment>
                <div
                    className={
                        "toolbar-button-wrapper action-button" +
                        (unavailable ? " disabled " : " ") +
                        (button.toolbarButtonDTO.cssClasses &&
                        Array.from(button.toolbarButtonDTO.cssClasses || []).length > 0
                            ? button.toolbarButtonDTO.cssClasses
                            : "default") +
                        "-wrapper"
                    }
                    aria-label={button.toolbarButtonDTO.label}
                >
                    {element}
                </div>
            </React.Fragment>
        )
    }
}

export default ToolbarButtonComponent
