import { ConversionListType } from "domain/ConversionList/domain/domain"
import { match } from "ts-pattern"

export type FullOrSoftConversion = "full" | "soft"
export type FullOrSoftConversionSelection = Readonly<Record<FullOrSoftConversion, boolean>>

const DEFAULT_HISTORICAL_FULL_OR_SOFT_CONVERSION_SELECTION: FullOrSoftConversionSelection = {
    full: true,
    soft: false,
}

const DEFAULT_REALTIME_FULL_OR_SOFT_CONVERSION_SELECTION: FullOrSoftConversionSelection = {
    full: false,
    soft: false,
}

export const defaultFullOrSoftConversionSelection = (
    conversionListType: ConversionListType,
): FullOrSoftConversionSelection => {
    return match(conversionListType)
        .with("historical", () => DEFAULT_HISTORICAL_FULL_OR_SOFT_CONVERSION_SELECTION)
        .with("realtime", () => DEFAULT_REALTIME_FULL_OR_SOFT_CONVERSION_SELECTION)
        .exhaustive()
}
