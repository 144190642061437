import axios, { CancelTokenSource } from "axios"
import DataGridService from "domain/datagrid/service/datagrid.service"
import { DimensionValueFilterConfig, FilterState, FilterType } from "domain/types"
import { DimensionValueListDTO, FilterConfigDTO, QuerySettingsDTO } from "generated/models"
import { ColumnResponseDTO, ConditionClauseDTO, PageableDTO } from "generated/models"
import { prefixRenderer } from "shared/component/renderers/prefix.renderer"
import ConditionClauseService, { DEFAULT_FILTER_VALUES_PAGE_SIZE } from "shared/service/conditionClauseService"
import UrlService from "shared/service/url.service"
import { v4 as uuid } from "uuid"

/**
 * Creates filter option text from ColumnResponseDTO. If the row has a prefix,
 * then create the option in the "prefix > name" format.
 * @param value
 */
const getFilterValue = (value: ColumnResponseDTO): string => (value.prefix ? prefixRenderer(value) : value.name)

const getFilterFormValueColumn = (filter: FilterConfigDTO | FilterState): string | undefined => {
    return filter.selectFormElement?.formFieldConfig?.dimensionIdentifier
}
const getFilterFormNameColumn = (filter: FilterConfigDTO | FilterState): string | undefined => {
    return filter.selectFormElement?.formFieldConfig?.dimensionNameMapping
}

const getFilterDisplayName = (filterConfig: FilterConfigDTO) => {
    return filterConfig.selectFormElement.formFieldConfig.dimensionNameMapping
        ? FilterComponentUtil.getFilterFormNameColumn(filterConfig)
        : FilterComponentUtil.getFilterFormValueColumn(filterConfig)
}

const getFilterDependsOn = (filter: FilterConfigDTO | FilterState) => filter.selectFormElement.selectConfig.dependsOn

/**
 * Creates initial [FilterState]s without filter entries.
 * Filter entries will be loaded by the filter elements on their own.
 *
 * @param filterConfigs
 */
const createInitialFilterStates = (filterConfigs: FilterConfigDTO[]): FilterState[] =>
    filterConfigs?.map(createInitialFilterState) || []

/**
 * Creates initial [FilterState] without filter entries.
 * Filter entries will be loaded by the filter element on their own.
 *
 * @param filterConfig
 */
const createInitialFilterState = (filterConfig: FilterConfigDTO): FilterState => {
    return {
        filterEntries: { entries: [] } as DimensionValueListDTO,
        selectFormElement: filterConfig.selectFormElement,
        inputConfig: undefined,
        value: Array.from(filterConfig.selectedFilterValues),
    } as FilterState
}

const getFilterType = (filter: FilterState) =>
    filter.selectFormElement
        ? filter.selectFormElement.selectConfig.multiSelect
            ? FilterType.MULTI_SELECT
            : FilterType.SINGLE_SELECT
        : FilterType.TEXT

const loadFilterEntries = (
    filter: FilterConfigDTO,
    sortBy: string[],
    url: string,
    cancelToken: CancelTokenSource,
    filters: FilterConfigDTO[] = [],
): Promise<DimensionValueFilterConfig> => {
    const paginationSettings: PageableDTO = {
        page: 0,
        pageSize: DEFAULT_FILTER_VALUES_PAGE_SIZE,
    }
    const identifier = FilterComponentUtil.getFilterFormValueColumn(filter)
    const nameMapping = FilterComponentUtil.getFilterFormNameColumn(filter)

    let filtersToApply: FilterState[] = []
    if (FilterComponentUtil.getFilterDependsOn(filter).length) {
        const filterIds = FilterComponentUtil.getFilterDependsOn(filter).map((f) => f.filterIdentifier)
        filtersToApply = filters.filter((f) => filterIds.includes(FilterComponentUtil.getFilterFormValueColumn(f)))
    }

    const filterClause: ConditionClauseDTO = ConditionClauseService.combineFilterQueries([
        ConditionClauseService.buildFilterQuery(filtersToApply),
        filter.selectFormElement ? filter.selectFormElement.additionalFilters : undefined,
    ])
    const querySettings: QuerySettingsDTO = {
        columnNames: [identifier, nameMapping ? nameMapping : identifier],
        filter: filterClause,
        paginationSettings,
        sortSettings: { sortProperties: sortBy, sortAscending: true },
        queryIdentifier: { value: uuid() },
    }

    // append meta data as URL parameter
    const filterPromise = DataGridService.fetchFilterEntries(
        querySettings,
        `${url}/${querySettings.columnNames[0]}`,
        cancelToken.token,
    )

    return filterPromise.then((filterEntries) => {
        return { dimension: identifier, dimensionValueDTO: filterEntries } as DimensionValueFilterConfig
    })
}

const getFilterLoadValuesUrl = (filterConfig: FilterConfigDTO) =>
    filterConfig.selectFormElement.selectConfig.loadValuesUrl

/**
 * Loads filter values for the [filterConfig]
 * @param filterConfig
 */
const loadFilterValuesAsync = (filterConfig: FilterConfigDTO): Promise<DimensionValueFilterConfig> => {
    const url = getFilterLoadValuesUrl(filterConfig)

    return loadFilterEntries(
        filterConfig,
        [FilterComponentUtil.getFilterDisplayName(filterConfig)],
        url ? url : `${UrlService.getFilterServiceApiUrl()}/loaddimensionvalues`,
        axios.CancelToken.source(),
    )
}

const FilterComponentUtil = {
    getFilterValue: getFilterValue,
    getFilterFormValueColumn: getFilterFormValueColumn,
    getFilterFormNameColumn: getFilterFormNameColumn,
    getFilterDisplayName: getFilterDisplayName,
    getFilterDependsOn: getFilterDependsOn,
    createInitialFilterState: createInitialFilterState,
    createInitialFilterStates: createInitialFilterStates,
    getFilterType: getFilterType,
    loadFilterValuesAsync: loadFilterValuesAsync,
}

export default FilterComponentUtil
