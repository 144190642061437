import { QueryWidgetSettingsDTO } from "domain/types/backend/widget.types"
import { ActionDTO, LayoutConfigDTO, LayoutElementDTO, ToolbarButtonDTO } from "generated/models"

export enum LayoutMode {
    COLUMNS = "COLUMNS",
    ROWS = "ROWS",
    TABS = "TABS",
}

export enum IconTypes {
    DELETE = "delete",
    PLUS = "plus",
    EDIT = "edit",
    EYE_INVISIBLE = "eye-invisible",
    EYE = "eye",
}

export enum LayoutElementType {
    ELEMENT = "ELEMENT",
    CONTAINER = "CONTAINER",
    PANEL = "PANEL",
    TAB = "TAB",
    FORM_ELEMENT_SELECT = "FORM_ELEMENT_SELECT",
    FORM_ELEMENT_TEXTAREA = "FORM_ELEMENT_TEXTAREA",
    FORM_ELEMENT_INPUT_PASSWORD = "FORM_ELEMENT_INPUT_PASSWORD",
    FORM_ELEMENT_INPUT = "FORM_ELEMENT_INPUT",
    NUMBER_FORM_ELEMENT_INPUT = "NUMBER_FORM_ELEMENT_INPUT",
    FORM_ELEMENT_TOGGLE_BUTTON = "FORM_ELEMENT_TOGGLE_BUTTON",
    FORM_ELEMENT_CHECKBOX = "FORM_ELEMENT_CHECKBOX",
    GRID = "GRID",
    TOOLBAR = "TOOLBAR",
    HTML_CONTENT = "HTML_CONTENT",
    WIDGET_ELEMENT_DATA_LIST = "WIDGET_ELEMENT_DATA_LIST",
    TIMESPAN_AWARE_PANEL = "TIMESPAN_AWARE_PANEL",
    TOOL_AWARE_PANEL = "TOOL_AWARE_PANEL",
    WIDGET_ELEMENT = "WIDGET_ELEMENT",
    CONVERSION_LIST_ELEMENT = "CONVERSION_LIST_ELEMENT",
    CONVERSION_LIST_CUSTOMER_JOURNEY_ELEMENT = "CONVERSION_LIST_CUSTOMER_JOURNEY_ELEMENT",
}

export const FORM_ELEMENT_TYPES = [
    LayoutElementType.FORM_ELEMENT_SELECT,
    LayoutElementType.FORM_ELEMENT_TEXTAREA,
    LayoutElementType.FORM_ELEMENT_INPUT,
    LayoutElementType.FORM_ELEMENT_INPUT_PASSWORD,
    LayoutElementType.NUMBER_FORM_ELEMENT_INPUT,
    LayoutElementType.FORM_ELEMENT_TOGGLE_BUTTON,
    LayoutElementType.FORM_ELEMENT_CHECKBOX,
]

export interface ColumnLayoutConfigDTO extends LayoutConfigDTO {}

export interface RowLayoutConfigDTO extends LayoutConfigDTO {}

export interface TabLayoutConfigDTO extends LayoutConfigDTO {}

export interface WidgetElementDTO extends LayoutElementDTO {
    header?: string
    widgetSettings?: QueryWidgetSettingsDTO
}

export type ToolbarButton = {
    toolbarButtonDTO: ToolbarButtonDTO
    action: ActionDTO
}
