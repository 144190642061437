import { Download as DownloadIcon } from "@mui/icons-material"
import { Button, CircularProgress } from "@mui/material"
import { ToolsContext } from "domain/legacy/widget/ToolsContext"
import { ToolbarButton } from "domain/types/backend/layout.types"
import { ActionDTO, ActionDTOIdentifierEnum, ToolbarButtonDTO } from "generated/models"
import React, { type JSX, useContext } from "react"
import ToolbarButtonComponent from "shared/component/ToolbarButtonComponent"

type Props = {
    buttons: ToolbarButtonDTO[]
    actions: ActionDTO[]
    disableButtons?: boolean
}

export const PanelToolbarComponent: React.FC<Props> = React.memo((props: Props): JSX.Element => {
    const toolsContext = useContext(ToolsContext)

    const invokeAction = (action: ActionDTO) => {
        toolsContext.updateActionState(action)
    }

    const onDownload = () => {
        toolsContext?.updateDownloadProcessing(true)
    }

    const createToolbarButtonComponent = (button: ToolbarButtonDTO, index: number, disabled: boolean) => {
        const action = props.actions.find((action) => action.identifier === button.actionIdentifier)
        if (!action) {
            throw new Error("No action was found for the button actionIdentifier " + button.actionIdentifier)
        }

        const toolbarButton = { toolbarButtonDTO: button, action: action } as ToolbarButton

        return (
            <ToolbarButtonComponent
                disabled={disabled}
                button={toolbarButton}
                onInvoke={invokeAction}
                selectedRows={0}
                key={index}
            />
        )
    }

    const { buttons, disableButtons } = props

    const configuredButtons = buttons.map((button, index) => {
        if (button.actionIdentifier == ActionDTOIdentifierEnum.DOWNLOAD) {
            return (
                <div className={"toolbar-button-wrapper default-wrapper"} aria-label="Download" key={"download_button"}>
                    <Button
                        className="toolbar-button"
                        variant="outlined"
                        onClick={onDownload}
                        disabled={disableButtons || toolsContext?.downloadProcessing}
                        startIcon={toolsContext?.downloadProcessing ? <CircularProgress size={20} /> : <DownloadIcon />}
                    >
                        Download
                    </Button>
                </div>
            )
        } else {
            return createToolbarButtonComponent(button, index, disableButtons)
        }
    })

    return <div className="toolbar">{configuredButtons}</div>
})
