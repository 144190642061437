import { ToggleButton, ToggleButtonGroup } from "@mui/material"
import { ConversionListContextSelectors } from "domain/ConversionList/context/ConversionListContextSelectors"
import React from "react"

export const FullOrSoftConversionRadioButtonGroup = () => {
    const conversionListType = ConversionListContextSelectors.useConversionListType()
    const fullOrSoftConversionSelection = ConversionListContextSelectors.useFullOrSoftConversionSelection()
    const updateFullOrSoftConversionSelection = ConversionListContextSelectors.useUpdateFullOrSoftConversionSelection()

    if (conversionListType === "realtime") {
        return <React.Fragment />
    }

    const selectedValues = Object.entries(fullOrSoftConversionSelection)
        .filter(([, value]) => value)
        .map(([key]) => key)

    const handleChange = (_: React.MouseEvent<HTMLElement>, fullOrSoft: string[]) => {
        if (fullOrSoft.length > 0) {
            updateFullOrSoftConversionSelection({
                full: fullOrSoft.includes("full"),
                soft: fullOrSoft.includes("soft"),
            })
        }
    }

    return (
        <ToggleButtonGroup
            color="primary"
            value={selectedValues}
            onChange={handleChange}
            size="small"
            fullWidth
            sx={{
                "& .MuiToggleButton-root": {
                    padding: "6px 6px",
                    whiteSpace: "nowrap",
                    boxSizing: "border-box",
                },
            }}
        >
            <ToggleButton value="full" sx={{ width: "50%" }}>
                Full
            </ToggleButton>
            <ToggleButton value="soft" sx={{ width: "50%" }}>
                Soft
            </ToggleButton>
        </ToggleButtonGroup>
    )
}
