import { TextField } from "@mui/material"
import { FilterState } from "domain/types"
import * as React from "react"

type Props = {
    filter: FilterState
    onChange?: (identifier: string, value: string | number | string[] | number[]) => void
}

export class InputComponent extends React.Component<Props> {
    constructor(props: Props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
    }

    handleChange = (event: any) => {
        const { filter, onChange } = this.props
        onChange(filter.inputConfig.valueColumn, event.target.value)
    }

    render() {
        const { filter } = this.props
        const value = filter.value ? `${filter.value}` : undefined

        return (
            <div className="filter-container">
                <TextField
                    label={filter.inputConfig.label}
                    defaultValue={value}
                    size="small"
                    onKeyDown={(e) => e.key === "Enter" && this.handleChange(e)}
                    fullWidth
                    className="filter-input"
                    variant="outlined"
                />
            </div>
        )
    }
}
