import { ActionPopupConfig } from "domain/types"
import { modalClose, modalOpen } from "shared/component/modals/redux/modals.slice"
import store from "shared/redux/store"
import { log } from "shared/util/log"

export const openPopupAction = (popupConfig: ActionPopupConfig) => {
    store.dispatch(modalOpen(popupConfig))

    if (popupConfig.loadContent) {
        popupConfig.setLoading(true)
        popupConfig
            .loadContent()
            .then((content) => {
                popupConfig.showContent(content)
            })
            .catch((error) => {
                // when an error happens while loading form data: prevent form from being stuck in loading state; instead: show an error message
                // and close the modal immediately
                const errorMessage = `An error occurred${error.status === 403 ? ` while accessing this item` : ``}.`
                log.error(errorMessage, error)

                store.dispatch(modalClose({ identifier: popupConfig.identifier }))
            })
            .finally(() => {
                popupConfig.setLoading(false)
            })
    }
}
