import React, { Dispatch, ReactNode, SetStateAction, useContext, useState } from "react"

type ActionModalContextProperties = {
    forceUpdate: boolean
    setForceUpdate?: Dispatch<SetStateAction<boolean>>
}
export const ActionModalContext = React.createContext<ActionModalContextProperties>({ forceUpdate: false })

type ActionModalContextProviderProperties = {
    children?: ReactNode
}
export const ActionModalContextProvider = ({ children }: ActionModalContextProviderProperties) => {
    const [forceUpdate, setForceUpdate] = useState<boolean>(false)

    return (
        <ActionModalContext.Provider
            value={{
                forceUpdate: forceUpdate,
                setForceUpdate: setForceUpdate,
            }}
        >
            {children}
        </ActionModalContext.Provider>
    )
}

export const useActionModalContext = (): ActionModalContextProperties => {
    const context = useContext(ActionModalContext)
    if (!context) {
        throw new Error("Missing ActionModalContext in its parent.")
    }

    return context
}
