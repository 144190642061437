import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { styled } from "@mui/material/styles"
import { SelectAllNoneOption } from "domain/ColumnConfigurator/components/ColumnSelectionPanel/MetricSelectionPanel/types"
import React, { type JSX } from "react"
import { SelectAllNoneCheckbox } from "shared/component/mui/SelectAllNoneCheckbox"
import { BORDER_RADIUS_SMALL, FONT_WEIGHT_SEMI_BOLD } from "styles/theme/constants"

interface MetricSelectionAccordionProps {
    accordionDisplayName: string
    accordionExpanded: boolean
    onAccordionExpandedChanged: (isExpanded: boolean) => void
    selectAllNoneOption?: SelectAllNoneOption
}

export const MetricSelectionAccordion = ({
    accordionDisplayName,
    accordionExpanded,
    onAccordionExpandedChanged,
    selectAllNoneOption = { state: "disabled" },
    children,
}: React.PropsWithChildren<MetricSelectionAccordionProps>): JSX.Element => {
    const onDimensionExpandedChangedHandler = (_: React.SyntheticEvent, isExpanded: boolean) => {
        onAccordionExpandedChanged(isExpanded)
    }

    return (
        <StyledPaper className={"metrics-selection-accordion"}>
            <Accordion expanded={accordionExpanded} onChange={onDimensionExpandedChangedHandler}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography
                        variant={"h6"}
                        sx={{
                            fontSize: "13px",
                            padding: 0,
                            margin: 0,
                            fontWeight: FONT_WEIGHT_SEMI_BOLD,
                            whiteSpace: "nowrap",
                        }}
                    >
                        {selectAllNoneOption.state !== "disabled" && <SelectAllNoneCheckbox {...selectAllNoneOption} />}
                        {accordionDisplayName}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>{children}</AccordionDetails>
            </Accordion>
        </StyledPaper>
    )
}

const StyledPaper = styled(Paper)(({ theme }) => ({
    border: "1px solid " + theme.palette.divider,
    borderRadius: BORDER_RADIUS_SMALL,
    "& .MuiAccordionSummary-root": {
        overflow: "hidden",
        padding: "8px 8px 8px 5px",
        [theme.breakpoints.down("md")]: {
            paddingLeft: "0px",
        },
    },
    "& .select-all-none": {
        [theme.breakpoints.down("md")]: {
            padding: "0 5px 3px 6px",
        },
    },
    "& .MuiAccordionSummary-content, & .MuiAccordionSummary-content.Mui-expanded": {
        margin: "0!important",
        padding: 0,
    },
    "& .MuiAccordionSummary-root, & .MuiAccordionSummary-root.Mui-expanded": {
        margin: "0!important",
        minHeight: "auto",
    },
    "& .MuiAccordionDetails-root": {
        paddingTop: 0,
    },
}))
