/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime"

/**
 *
 * @export
 * @interface ColumnRendererDTO
 */
export interface ColumnRendererDTO {
    /**
     *
     * @type {Set<string>}
     * @memberof ColumnRendererDTO
     */
    cssClasses: Set<string>
    /**
     *
     * @type {string}
     * @memberof ColumnRendererDTO
     */
    type: ColumnRendererDTOTypeEnum
}

/**
 * @export
 */
export const ColumnRendererDTOTypeEnum = {
    CURRENCY_METRIC: "CURRENCY_METRIC",
    DATA: "DATA",
    DATE: "DATE",
    DATE_TIME: "DATE_TIME",
    DATE_TIME_SECONDS: "DATE_TIME_SECONDS",
    DURATION: "DURATION",
    ICON: "ICON",
    ICON_VALUE_NAME: "ICON_VALUE_NAME",
    INLINE_BUTTONS: "INLINE_BUTTONS",
    NAME: "NAME",
    OPTIMIZATION_LEVEL: "OPTIMIZATION_LEVEL",
    PREFIX_NAME: "PREFIX_NAME",
    POSTFIX_VALUE: "POSTFIX_VALUE",
    VALUE: "VALUE",
    VALUE_COMPARISON: "VALUE_COMPARISON",
    YES_NO: "YES_NO",
} as const
export type ColumnRendererDTOTypeEnum = (typeof ColumnRendererDTOTypeEnum)[keyof typeof ColumnRendererDTOTypeEnum]

/**
 * Check if a given object implements the ColumnRendererDTO interface.
 */
export function instanceOfColumnRendererDTO(value: object): value is ColumnRendererDTO {
    if (!("cssClasses" in value) || value["cssClasses"] === undefined) return false
    if (!("type" in value) || value["type"] === undefined) return false
    return true
}

export function ColumnRendererDTOFromJSON(json: any): ColumnRendererDTO {
    return ColumnRendererDTOFromJSONTyped(json, false)
}

export function ColumnRendererDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ColumnRendererDTO {
    if (json == null) {
        return json
    }
    return {
        cssClasses: json["cssClasses"],
        type: json["type"],
    }
}

export function ColumnRendererDTOToJSON(value?: ColumnRendererDTO | null): any {
    if (value == null) {
        return value
    }
    return {
        cssClasses: Array.from(value["cssClasses"] as Set<any>),
        type: value["type"],
    }
}
