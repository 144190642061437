import { AxiosRequestConfig } from "axios"
import { ExportTaskState } from "domain/exporttask/types"
import {
    CompletedExportTaskDTO,
    ExportQuerySettingsDTO,
    ExportTaskDTO,
    ExportTaskDeleteResponseDTO,
    ExportTaskListResponseDTO,
} from "generated/models"
import * as api from "shared/service"
import UrlService from "shared/service/url.service"
import { log } from "shared/util/log"
import { v1 as uuid } from "uuid"

const EXPORT_TASK_BASE_URL = "/exporttask"
const EXPORT_TASK_CREATE_URL = "/create"
const EXPORT_TASK_CANCEL_URL = "/cancel"
const EXPORT_TASK_DELETE_URL = "/delete"
const EXPORT_TASK_RECREATE_URL = "/recreate"
const EXPORT_TASK_DOWNLOAD_URL = "/download"
const EXPORT_TASK_LIST_URL = "/list"
const EXPORT_TIMEOUT_MS = 60000

const config: AxiosRequestConfig = {
    baseURL: UrlService.getGatewayUrl(),
    timeout: EXPORT_TIMEOUT_MS,
}

/**
 * Create an export task on the service with the serviceContextPath.
 * We still need to send the request to a certain service, because tasks can be executed only on certain services.
 * (e.g. conversions export tasks can only be executed on the reporting service)
 *
 * @param serviceContextPath
 * @param exportSettingsDTO
 */
const createExportTask = async (
    serviceContextPath: string,
    exportSettingsDTO: ExportQuerySettingsDTO,
): Promise<ExportTaskDTO> => {
    return await api.post<ExportTaskDTO>(
        `${serviceContextPath}${EXPORT_TASK_BASE_URL}${EXPORT_TASK_CREATE_URL}`,
        exportSettingsDTO,
        config,
    )
}

const cancelExportTask = async (exportTask: ExportTaskDTO): Promise<ExportTaskDTO> => {
    return await api.patch<ExportTaskDTO>(
        `${UrlService.getUiServiceApiUrl()}${EXPORT_TASK_BASE_URL}${EXPORT_TASK_CANCEL_URL}/${exportTask.id}`,
        {},
        config,
    )
}

const deleteExportTask = async (exportTask: ExportTaskDTO): Promise<ExportTaskDeleteResponseDTO> => {
    return await api.deleteRequest(
        `${UrlService.getUiServiceApiUrl()}${EXPORT_TASK_BASE_URL}${EXPORT_TASK_DELETE_URL}/${exportTask.id}`,
        config,
    )
}

const recreateExportTask = async (exportTask: ExportTaskDTO): Promise<ExportTaskDTO> => {
    return await api.post<ExportTaskDTO>(
        `${UrlService.getUiServiceApiUrl()}${EXPORT_TASK_BASE_URL}${EXPORT_TASK_RECREATE_URL}/${exportTask.id}`,
        {},
        config,
    )
}

/**
 * Load the export task list
 */
const loadExportTaskList = async (): Promise<ExportTaskListResponseDTO> => {
    return api.get(`${UrlService.getUiServiceApiUrl()}${EXPORT_TASK_BASE_URL}${EXPORT_TASK_LIST_URL}`)
}

const downloadExportTaskResult = (task: ExportTaskDTO) => {
    if (task.state === ExportTaskState.COMPLETED) {
        downloadExportTaskResultForId(task.id, (task as CompletedExportTaskDTO).filename)
    } else {
        throw new Error("Can not download export task result. Task is not completed.")
    }
}

/**
 * Download the export task result
 *
 * @param id
 * @param filename
 */
const downloadExportTaskResultForId = (id: string, filename: string) => {
    log.info(`Downloading export task result for id ${id} and filename ${filename}`)

    const link = document.createElement("a")
    link.href = `${UrlService.getUiServiceApiUrl()}${EXPORT_TASK_BASE_URL}${EXPORT_TASK_DOWNLOAD_URL}/${id}`
    link.setAttribute("download", filename)
    link.setAttribute("id", uuid())
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

export const ExportTaskService = {
    createExportTask: createExportTask,
    cancelExportTask: cancelExportTask,
    deleteExportTask: deleteExportTask,
    loadExportTaskList: loadExportTaskList,
    downloadExportTaskResult: downloadExportTaskResult,
    recreateExportTask: recreateExportTask,
}
