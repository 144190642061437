/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { mapValues } from "../runtime"
import type { ActionSelectionConfigurationDTO } from "./ActionSelectionConfigurationDTO"
import {
    ActionSelectionConfigurationDTOFromJSON,
    ActionSelectionConfigurationDTOFromJSONTyped,
    ActionSelectionConfigurationDTOToJSON,
} from "./ActionSelectionConfigurationDTO"
import type { ModalConfigDTO } from "./ModalConfigDTO"
import { ModalConfigDTOFromJSON, ModalConfigDTOFromJSONTyped, ModalConfigDTOToJSON } from "./ModalConfigDTO"

/**
 *
 * @export
 * @interface ActionDTO
 */
export interface ActionDTO {
    /**
     *
     * @type {boolean}
     * @memberof ActionDTO
     */
    affectsTrackingResult?: boolean
    /**
     *
     * @type {string}
     * @memberof ActionDTO
     */
    formType?: ActionDTOFormTypeEnum
    /**
     *
     * @type {string}
     * @memberof ActionDTO
     */
    identifier: ActionDTOIdentifierEnum
    /**
     *
     * @type {ModalConfigDTO}
     * @memberof ActionDTO
     */
    modalConfig?: ModalConfigDTO
    /**
     *
     * @type {ActionSelectionConfigurationDTO}
     * @memberof ActionDTO
     */
    selectionConfig: ActionSelectionConfigurationDTO
    /**
     *
     * @type {Set<string>}
     * @memberof ActionDTO
     */
    supportedAdditionalFilters: Set<string>
    /**
     *
     * @type {string}
     * @memberof ActionDTO
     */
    type: ActionDTOTypeEnum
}

/**
 * @export
 */
export const ActionDTOFormTypeEnum = {
    CREATE: "CREATE",
    EDIT: "EDIT",
} as const
export type ActionDTOFormTypeEnum = (typeof ActionDTOFormTypeEnum)[keyof typeof ActionDTOFormTypeEnum]

/**
 * @export
 */
export const ActionDTOIdentifierEnum = {
    CREATE: "CREATE",
    EDIT: "EDIT",
    DELETE: "DELETE",
    DEACTIVATE: "DEACTIVATE",
    ACTIVATE: "ACTIVATE",
    DOWNLOAD: "DOWNLOAD",
} as const
export type ActionDTOIdentifierEnum = (typeof ActionDTOIdentifierEnum)[keyof typeof ActionDTOIdentifierEnum]

/**
 * @export
 */
export const ActionDTOTypeEnum = {
    OPEN_POPUP: "OPEN_POPUP",
    DELETE: "DELETE",
    DEACTIVATE: "DEACTIVATE",
    ACTIVATE: "ACTIVATE",
    DOWNLOAD: "DOWNLOAD",
} as const
export type ActionDTOTypeEnum = (typeof ActionDTOTypeEnum)[keyof typeof ActionDTOTypeEnum]

/**
 * Check if a given object implements the ActionDTO interface.
 */
export function instanceOfActionDTO(value: object): value is ActionDTO {
    if (!("identifier" in value) || value["identifier"] === undefined) return false
    if (!("selectionConfig" in value) || value["selectionConfig"] === undefined) return false
    if (!("supportedAdditionalFilters" in value) || value["supportedAdditionalFilters"] === undefined) return false
    if (!("type" in value) || value["type"] === undefined) return false
    return true
}

export function ActionDTOFromJSON(json: any): ActionDTO {
    return ActionDTOFromJSONTyped(json, false)
}

export function ActionDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActionDTO {
    if (json == null) {
        return json
    }
    return {
        affectsTrackingResult: json["affectsTrackingResult"] == null ? undefined : json["affectsTrackingResult"],
        formType: json["formType"] == null ? undefined : json["formType"],
        identifier: json["identifier"],
        modalConfig: json["modalConfig"] == null ? undefined : ModalConfigDTOFromJSON(json["modalConfig"]),
        selectionConfig: ActionSelectionConfigurationDTOFromJSON(json["selectionConfig"]),
        supportedAdditionalFilters: json["supportedAdditionalFilters"],
        type: json["type"],
    }
}

export function ActionDTOToJSON(value?: ActionDTO | null): any {
    if (value == null) {
        return value
    }
    return {
        affectsTrackingResult: value["affectsTrackingResult"],
        formType: value["formType"],
        identifier: value["identifier"],
        modalConfig: ModalConfigDTOToJSON(value["modalConfig"]),
        selectionConfig: ActionSelectionConfigurationDTOToJSON(value["selectionConfig"]),
        supportedAdditionalFilters: Array.from(value["supportedAdditionalFilters"] as Set<any>),
        type: value["type"],
    }
}
