import SearchIcon from "@mui/icons-material/Search"
import { InputAdornment } from "@mui/material"
import React from "react"
import { ClearableTextField } from "shared/component/mui/ClearableTextField"

/**
 * Component for displaying free-text search input
 */
export const SearchInput = ({
    value,
    onChange,
    onClear,
}: {
    value: string
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
    onClear: () => void
}) => (
    <ClearableTextField
        className="search-term-input"
        variant="outlined"
        type="search"
        onChange={onChange}
        onClear={onClear}
        sx={{ width: 300, maxWidth: "100%" }}
        size="small"
        value={value}
        InputProps={{
            startAdornment: (
                <InputAdornment position="start">
                    <SearchIcon />
                </InputAdornment>
            ),
        }}
    />
)
