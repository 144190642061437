import React, { type JSX } from "react"

export type WrapperProps = {
    children: React.ReactNode
}

export type Wrapper = (props: WrapperProps) => JSX.Element

/**
 * A wrapper that renders its children as-is.
 */
export function IdentityWrapper({ children }: WrapperProps): JSX.Element {
    return <>{children}</>
}
