/**
 * Types and configurations for special search columns that use predefined values
 */

// Type definitions
export type SpecialColumnOption = { display: string; value: string }

export type SpecialColumnConfig = {
    values: string[] | SpecialColumnOption[]
    hasCustomMapping?: boolean
}

export type SpecialColumnsMap = Record<string, SpecialColumnConfig>

// Special columns that should have pre-defined values instead of free text input
export const SPECIAL_COLUMNS: SpecialColumnsMap = {
    // Device Type columns
    "device_type.value": {
        values: ["Desktop", "Smartphone", "Tablet", "SmartTV", "Bot", "App"],
    },
    "conversion_list_tp_device_type.value": {
        values: ["Desktop", "Smartphone", "Tablet", "SmartTV", "Bot", "App"],
    },
    "conversion_list_identified_by.name": {
        values: [
            { display: "Cross Device", value: "x" },
            { display: "Cookie", value: "c" },
            { display: "Fingerprint", value: "f" },
        ],
        hasCustomMapping: true,
    },
    "conversion_list_tp_type.value": {
        values: ["View", "Postview", "Click", "SEO"],
    },
}
