import { Alert, Snackbar } from "@mui/material"
import axios from "axios"
import FilterComponentUtil from "domain/filter/component/FilterComponentUtil"
import { InputComponent, SelectComponent } from "domain/filter/component/index"
import { ApiErrorDTO, DimensionValueFilterConfig, FilterState, FilterType } from "domain/types"
import { FilterConfigDTO } from "generated/models"
import * as React from "react"
import { useEffect, useState } from "react"
import { assertExhaustive } from "shared/util/TypeUtil"
import { log } from "shared/util/log"

type Props = {
    filter: FilterConfigDTO
    onChange: (identifier: string, value: string | number | string[] | number[]) => void
}
export const FilterElement = (props: Props) => {
    const { filter, onChange } = props
    const [filterState, setFilterState] = useState<FilterState>(FilterComponentUtil.createInitialFilterState(filter))
    const [errorMessage, setErrorMessage] = useState<string | null>(null)
    const { displayName } = filterState.selectFormElement.formFieldConfig

    useEffect(() => {
        setFilterState(FilterComponentUtil.createInitialFilterState(filter))
        FilterComponentUtil.loadFilterValuesAsync(filter)
            .catch((error: ApiErrorDTO) => {
                if (axios.isCancel(error) || error.message === "Cancel") {
                    log.debug("Request canceled")
                } else {
                    log.error(
                        `An error occurred while loading the filter values (${FilterComponentUtil.getFilterFormValueColumn(filter)}): `,
                        error.message,
                    )
                    setErrorMessage("An error occurred while loading the filter values.")
                }

                return {
                    dimension: FilterComponentUtil.getFilterFormValueColumn(filter),
                    dimensionValueDTO: {},
                } as DimensionValueFilterConfig
            })
            .then((filterEntries) => {
                setFilterState((prev) => {
                    return {
                        ...prev,
                        filterEntries: filterEntries.dimensionValueDTO,
                        value:
                            prev.value === null || prev.value === undefined
                                ? Array.from(filter.selectedFilterValues || [])
                                : prev.value,
                    }
                })
            })
    }, [filter])

    const handleChange = (identifier: string, value: string | number | string[] | number[]) => {
        setFilterState((prev: FilterState) => {
            return {
                ...prev,
                value: value,
            }
        })

        onChange(identifier, value)
    }

    const handleCloseError = () => {
        setErrorMessage(null)
    }

    const filterType = FilterComponentUtil.getFilterType(filterState)

    return (
        <>
            <Snackbar
                open={!!errorMessage}
                autoHideDuration={5000}
                onClose={handleCloseError}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert onClose={handleCloseError} severity="error">
                    {errorMessage}
                </Alert>
            </Snackbar>

            {(() => {
                switch (filterType) {
                    case FilterType.SINGLE_SELECT:
                    case FilterType.MULTI_SELECT:
                        return (
                            <SelectComponent
                                filter={filterState}
                                label={displayName}
                                onChange={handleChange}
                                multiple={filterType === FilterType.MULTI_SELECT}
                            />
                        )
                    case FilterType.TEXT:
                        return <InputComponent filter={filterState} onChange={handleChange} />
                    default:
                        assertExhaustive(filterType)
                }
            })()}
        </>
    )
}
