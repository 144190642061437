import {
    DataGridPro,
    GRID_CHECKBOX_SELECTION_COL_DEF,
    GridColumnOrderChangeParams,
    GridPinnedColumnFields,
    GridSortItem,
    useGridApiRef,
} from "@mui/x-data-grid-pro"
import { GridProColumnMenu } from "@mui/x-data-grid-pro/components/GridProColumnMenu"
import { RowActions } from "domain/actions/RowActions"
import { ACTIONS_FIELD, ACTIONS_ID_FIELD, Row } from "domain/datagrid/component/DataGrid"
import { ContextMenuPosition, DataGridContextMenu } from "domain/datagrid/component/DataGridContextMenu"
import { ColumnConfigurer } from "domain/datagrid/component/column/ColumnConfigurer"
import DataGridService from "domain/datagrid/service/datagrid.service"
import { ActionDTOIdentifierEnum, ColumnConfigDTO, ReportingDataSetDTO } from "generated/models"
import React, { type JSX, useState } from "react"
import { CustomColumnMenu } from "shared/component/mui/datagrid/CustomColumnMenu"
import { log } from "shared/util/log"

export type DataGridComponentProps = {
    rows: ReportingDataSetDTO
    columns: ColumnConfigDTO[]
    sortAscending?: boolean | undefined
    sortProperties?: string[] | undefined
    onSort?: (orderBy: string, sortAscending: boolean) => void
    selectedRowIndices: number[]
    onSelect: (selectedRows: number[]) => void
    onClickOnContextMenuAction: (actionIdentifier: ActionDTOIdentifierEnum, invokeRowIndices: number[]) => void
    customColumnMenu?: CustomColumnMenu
    pinnedColumns?: GridPinnedColumnFields
    onPinnedColumnsChange?: (pinnedColumns: GridPinnedColumnFields) => void
    onColumnOrderChange?: ((params: GridColumnOrderChangeParams) => void) | undefined
}

export const DataGridComponent = (props: DataGridComponentProps): JSX.Element => {
    const apiRef = useGridApiRef()

    const selectedRows = React.useMemo(
        () =>
            props.selectedRowIndices.map((rowIndex) => {
                const rowData = props.rows?.rows[rowIndex]
                const rowActions = rowData[ACTIONS_FIELD]?.data as unknown as RowActions
                return { rowActions: rowActions, rowIndex: rowIndex } as Row
            }),
        [props.selectedRowIndices, props.rows?.rows],
    )

    const [contextMenuRows, setContextMenuRows] = useState<Row[]>([])

    const rows = React.useMemo(
        () =>
            props.rows?.rows?.map((row, index) => ({
                ...DataGridService.convertRowToTableStructure(row),
                id: index,
            })) ?? [],
        [props.rows],
    )

    // There seems to be a bug with the row height recalculation in the DataGridPro component that sometimes causes
    // not all rows to be displayed when changing filters. This is a workaround to force the recalculation of the
    // row heights.
    // TODO: Check if this bug persists in newer versions of the DataGrid when they are released (after 7.7.0)
    React.useLayoutEffect(() => {
        log.debug("Resetting row heights")
        apiRef.current.resetRowHeights()
    }, [apiRef, rows])

    const [contextMenuPosition, setContextMenuPosition] = React.useState<ContextMenuPosition | null>(null)

    const columns = ColumnConfigurer.getColumnConfigurations(props.columns)

    const onContextMenu = (event: React.MouseEvent) => {
        event.preventDefault()
        const rowIndex = Number(event.currentTarget.getAttribute("data-id"))
        setContextMenuPosition(
            contextMenuPosition === null ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } : null,
        )

        const isOnSelectedRow = selectedRows.some((row) => row.rowIndex == rowIndex)

        const rowData = props.rows?.rows[rowIndex]
        const rowActions = rowData[ACTIONS_FIELD]?.data as unknown as RowActions
        if (isOnSelectedRow) {
            setContextMenuRows([...selectedRows])
        } else {
            setContextMenuRows([{ rowActions: rowActions, rowIndex: rowIndex } as Row])
        }
    }

    const closeContextMenu = () => {
        setContextMenuPosition(null)
    }

    const onContextMenuActionClick = (actionIdentifier: ActionDTOIdentifierEnum, rowIndices: number[]) => {
        props.onClickOnContextMenuAction(actionIdentifier, rowIndices)
        closeContextMenu()
    }
    const onSortModelChange = (sortItems: GridSortItem[]) => {
        if (sortItems.length > 0) {
            props.onSort(sortItems[0].field, sortItems[0].sort == "asc")
        }
    }

    // show select only if actions column is configured
    const checkboxSelection = props.columns.some((column) => column.columnIdentifier == ACTIONS_ID_FIELD)

    const initialPinnedColumns = React.useMemo(() => {
        return props.columns
            .filter((column, index) => {
                return (
                    column.gridColumnProperties.isFixed ||
                    (!column.gridColumnProperties.isMetric && index <= 1) ||
                    (index === 2 && !column.gridColumnProperties.isMetric && checkboxSelection)
                )
            })
            .map((column) => column.columnIdentifier)
    }, [props.columns, checkboxSelection])

    return (
        <div className={"data-grid-table"} style={{ width: "100%" }}>
            <DataGridPro
                apiRef={apiRef}
                sx={{ minHeight: "400px" }}
                checkboxSelection={checkboxSelection}
                disableColumnFilter
                disableColumnSelector
                density={"compact"}
                columnHeaderHeight={50}
                sortingMode={"server"}
                sortingOrder={["asc", "desc"]}
                onSortModelChange={onSortModelChange}
                onColumnOrderChange={props.onColumnOrderChange}
                getRowHeight={() => "auto"}
                rowSelectionModel={props.selectedRowIndices}
                onRowSelectionModelChange={props.onSelect}
                rows={rows}
                hideFooter
                getRowClassName={() => "datagrid-table-row"}
                columns={columns}
                pinnedColumns={props.pinnedColumns}
                onPinnedColumnsChange={props.onPinnedColumnsChange}
                slots={{
                    columnMenu: props.customColumnMenu ? props.customColumnMenu.slot : GridProColumnMenu,
                }}
                slotProps={{
                    row: {
                        onContextMenu: onContextMenu,
                        style: { cursor: "context-menu" },
                    },
                    columnMenu: props.customColumnMenu ? props.customColumnMenu.slotProps : undefined,
                }}
                initialState={{
                    sorting: {
                        sortModel: props.sortProperties?.map((field) => {
                            return { field: field, sort: props.sortAscending ? "asc" : "desc" }
                        }),
                    },
                    // We only set this if pinnedColumns are not controlled
                    pinnedColumns: props.pinnedColumns
                        ? undefined
                        : {
                              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, ...initialPinnedColumns],
                              right: [ACTIONS_ID_FIELD],
                          },
                }}
            />

            <DataGridContextMenu
                contextMenuPosition={contextMenuPosition}
                contextMenuRows={contextMenuRows}
                closeContextMenu={closeContextMenu}
                onContextMenuActionClick={onContextMenuActionClick}
            />
        </div>
    )
}
