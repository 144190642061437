import { Box, CircularProgress } from "@mui/material"
import React from "react"

export const Spinner = () => {
    return (
        <>
            {/* Opaque overlay panel */}
            <Box
                position="absolute"
                sx={{
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: "rgba(250, 251, 255, 0.6)",
                    zIndex: 999,
                    pointerEvents: "none",
                }}
            />
            {/* Floating spinner */}
            <Box
                position="fixed"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                    top: "50%",
                    right: "315px",
                    transform: "translateX(-50%)",
                    backgroundColor: "transparent",
                    zIndex: 1000,
                }}
            >
                <CircularProgress />
            </Box>
        </>
    )
}
