/**
 * See {@link https://www.typescriptlang.org/docs/handbook/2/narrowing.html#exhaustiveness-checking} for usage.
 */
export const assertExhaustive = (_value: never): never => {
    throw new Error("Exhaustiveness check failed")
}

export type NonEmptyArray<T> = [T, ...T[]]
export type NonEmptyReadonlyArray<T> = ReadonlyArray<T> & NonEmptyArray<T>

const isNonEmpty = <A>(arr: Array<A>): arr is NonEmptyArray<A> => arr.length > 0

/**
 * Examples:
 *
 * `type TupleOfThreeStrings = Tuple<string, 3>;  // [string, string, string]`
 * `type TupleOfFiveNumbers = Tuple<number, 5>;   // [number, number, number, number, number]`
 */
export type Tuple<T, N extends number, R extends T[] = []> = R["length"] extends N ? R : Tuple<T, N, [T, ...R]>
